import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { LocalizationProvider, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  Box,
  List,
  ListItemText,
  Divider,
  Paper,
  Grid,
  ListItemButton,
  Typography,
  Button,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
// import { jsPDF } from "jspdf";
import Papa from "papaparse";
import ConversationWidget from "./ConversationWidget";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import Instructions from "../../../components/alerts/Instructions";

const description = `View all the conversations that took place between the chatbot and users within a specified date range. Click on a conversation to view its details on the right. You can also export the conversation data in CSV format by clicking the "Export Conversations (CSV)" button.`;

// const exportToPDF = (conversations) => {
//   const doc = new jsPDF();
//   conversations.forEach((conversation, index) => {
//     doc.text(`Conversation ${index + 1}`, 10, 10 + 10 * index);
//     conversation.messages.forEach((message, i) => {
//       doc.text(
//         `${message.sender}: ${message.text}`,
//         10,
//         10 + 10 * index + 10 * (i + 1)
//       );
//     });
//   });
//   doc.save("conversations.pdf");
// };

const exportToCSV = (conversations) => {
  const csv = Papa.unparse(
    conversations.flatMap((conversation, index) =>
      conversation.messages.map((message) => ({
        conversation: `Conversation ${index + 1}`,
        sender: message.sender,
        text: message.text,
      }))
    )
  );
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "conversations.csv";
  link.href = url;
  link.click();
};

export default function Conversations() {
  const { tokenId } = useContext(AuthContext);
  const { chatbotData } = useContext(ChatbotContext);
  const { userId, chatbotId } = chatbotData;
  const startDate = dayjs().subtract(1, "month");
  const endDate = dayjs();
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [conversations, setConversations] = useState([]);
  const [removedTag, setRemovedTag] = useState(false);
  const [selectedConversationIndex, setSelectedConversationIndex] =
    useState(null);

  useEffect(() => {
    const fetchConversations = async () => {
      const [startDate, endDate] = dateRange;
      if (startDate && endDate) {
        const response = await axios.get(
          `${
            process.env.REACT_APP_VERCEL_API_BASE_URL
          }/conversations/${chatbotId}/${startDate.toISOString()}/${endDate.toISOString()}`,
          {
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        );
        setConversations(response.data.conversations);
      }
    };

    if (dateRange[1]) {
      fetchConversations();
    }
  }, [dateRange, userId, chatbotId, tokenId]);

  const findAndRemoveTag = () => {
    const elements = document.getElementsByTagName("*");

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element.innerText === "MUI X Missing license key") {
        setRemovedTag(true);
        element.style.display = "none";
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      if (!removedTag) findAndRemoveTag();
    }, 500);
  }, [removedTag]);

  return (
    <div>
      <Instructions description={description} id="conversations" />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Select Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* .css-lmxgfg-MuiFormLabel-root-MuiInputLabel-root */}
            <Box>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={dateRange}
                onChange={(newValue) => {
                  setDateRange(newValue);
                  findAndRemoveTag();
                }}
                autoOk
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "8.5px 14px !important",
                  },
                  "&. .MuiFormLabel-root": {
                    top: "-5px !important",
                  },
                  // input padding
                }}
              />
            </Box>
          </LocalizationProvider>
          {conversations.length > 0 && (
            <>
              {/* <Button
                onClick={() => exportToPDF(conversations)}
                variant="contained"
                color="primary"
                fullWidth
                disableRipple
                disableelevation
                disablefocusripple
                disableTouchRipple
                sx={{ mt: 4 }}
              >
                Export Conversations (PDF)
              </Button> */}
              <Button
                onClick={() => exportToCSV(conversations)}
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                disableRipple
                disableelevation
                disablefocusripple
                disableTouchRipple
                sx={{ mt: 4, mb: 1 }}
              >
                Export Conversations (CSV)
              </Button>
              {/* // show skeleton while loading */}

              <List component={Paper} sx={{ mt: 3, p: 0 }}>
                {conversations
                  .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                  .map((conversation, index) => (
                    <React.Fragment key={index}>
                      <ListItemButton
                        disableRipple
                        disableTouchRipple
                        alignItems="flex-start"
                        selected={index === selectedConversationIndex}
                        onClick={() => setSelectedConversationIndex(index)}
                      >
                        <ListItemText
                          primary={"User: " + conversation.messages[0]?.text}
                          secondary={
                            <React.Fragment>
                              Bot: {conversation.messages[1]?.text.slice(0, 50)}
                              {conversation.messages[1]?.text?.length > 50 &&
                                "..."}
                              <br />
                              <Typography
                                variant="body2"
                                sx={{ color: "gray", mt: 2 }}
                              >
                                {formatDistanceToNow(
                                  new Date(conversation.createdAt)
                                ) + " ago"}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItemButton>
                      {index !== conversations.length - 1 && (
                        <Divider component="li" />
                      )}
                    </React.Fragment>
                  ))}
              </List>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          {selectedConversationIndex !== null && (
            <ConversationWidget
              conversation={conversations[selectedConversationIndex]}
              chatbotData={chatbotData}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
