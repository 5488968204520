import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Skeleton,
  Container,
} from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import { AuthContext } from "../../contexts/AuthContext";
import Instructions from "../../components/alerts/Instructions";
import CreateChatbotDialog from "./Chatbot/components/CreateChatbotDialog";

const description = `Begin creating a chatbot by clicking the "Create Chatbot" button. Click on a chatbot to view and edit its details.`;

export default function Main() {
  let navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { isLoadingChatbots, chatbots, subscriptionSettings, setChatbotData } =
    useContext(ChatbotContext);
  const { maxChatbots } = subscriptionSettings || {};
  const [isCreateChatbotDialogOpen, setIsCreateChatbotDialogOpen] =
    useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleBotSelect = (bot) => {
    navigate(`/chatbots/${bot?.chatbotId}/Chatbot`);
    setChatbotData(bot);
  };

  useEffect(() => {
    if (!authState.isLoading && !authState.isAuth) {
      navigate("/login");
    }
  }, [authState.isAuth, authState.isLoading, navigate]);

  if (!authState.isAuth) {
    navigate("/login");
  }

  return (
    <Container maxWidth="md">
      <CreateChatbotDialog
        open={isCreateChatbotDialogOpen}
        handleClose={() => setIsCreateChatbotDialogOpen(false)}
        setIsCreating={setIsCreating}
        isCreating={isCreating}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h4" sx={{ fontWeight: "bold", mt: 4 }}>
            Chatbots
          </Typography>
          {maxChatbots && (
            <Typography variant="caption" sx={{ mb: 2, ml: 0.5 }}>
              ({chatbots?.length}/{maxChatbots} chatbot limit)
            </Typography>
          )}
        </Box>
        <Button
          className="create-chatbot-btn"
          onClick={() => setIsCreateChatbotDialogOpen(true)}
          variant="contained"
          color="secondary"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          disabled={chatbots?.length >= maxChatbots || isCreating}
          endIcon={
            isCreating ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <AddRounded size={20} color="inherit" />
            )
          }
        >
          Create Chatbot
        </Button>
      </Box>
      <Instructions description={description} id="conversations" />
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {isLoadingChatbots ? (
          <Grid item xs={6} sm={4} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height={200}
              width="100%"
              sx={{ mr: 2, borderRadius: 1 }}
              animation="wave"
            />
          </Grid>
        ) : (
          chatbots?.map((bot, index) => (
            <Grid item xs={6} sm={4} md={4} c key={index}>
              <Card
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => handleBotSelect(bot)}
              >
                <CardActionArea
                  disableRipple
                  disableTouchRipple
                  disablefocusripple="true"
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={
                      bot?.avatarUrl ||
                      "https://dittobot-ai.s3.amazonaws.com/branding/default.png"
                    }
                    alt="chatbot"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      sx={{ fontWeight: 500 }}
                    >
                      {bot?.chatbotName}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}
