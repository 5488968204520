import React, { useEffect, useState, useContext } from "react";
import { TextField, Stack, Button, CircularProgress } from "@mui/material";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import Instructions from "../../../components/alerts/Instructions";

const description =
  "Associate specific keywords with URLs. When a keyword is detected in the chatbot's conversation, it will automatically create a hyperlink to the associated URL. Remember to save your changes when you're done!";

const LinksMap = () => {
  const { tokenId } = useContext(AuthContext);
  const { chatbotData } = useContext(ChatbotContext);
  const { showAlert } = useContext(SnackbarContext);
  const { userId, chatbotId, linksMap } = chatbotData;
  const [links, setLinks] = useState(linksMap || []);
  const [newLink, setNewLink] = useState({ word: "", link: "" });
  const [changed, setChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (linksMap) {
      setLinks(linksMap);
    }
  }, [linksMap]);

  const updateLinks = debounce(async () => {
    setIsSaving(true);
    const filteredLinks = links.filter(
      (link) => link.word !== "" && link.link !== ""
    );

    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot/links`,
        {
          linksMap: filteredLinks,
          chatbotId: chatbotId,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      showAlert("Links updated successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error updating links", "error");
    }
    setIsSaving(false);
  }, 1000);

  const handleChange = (index, type, event) => {
    const newLinksMap = [...links];
    newLinksMap[index][type] = event.target.value;
    setLinks(newLinksMap);
  };

  const handleAddField = () => {
    if (newLink.word === "" || newLink.link === "") return;

    setLinks([...links, newLink]);
    setNewLink({ word: "", link: "" });
    setChanged(true);
  };

  const handleRemoveField = (index) => {
    const newLinksMap = [...links];
    newLinksMap.splice(index, 1);
    setLinks(newLinksMap);
    setChanged(true);
  };

  return (
    <div>
      <Instructions description={description} id="links" />
      {links.map((linkMap, index) => (
        <Stack key={index} direction="row" spacing={2} sx={{ mb: 2 }}>
          <TextField
            size="small"
            InputProps={{
              autoComplete: "off",
            }}
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Key Word(s)"
            value={linkMap.word}
            onChange={(event) => handleChange(index, "word", event)}
          />
          <TextField
            size="small"
            InputProps={{
              autoComplete: "off",
            }}
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Link"
            value={linkMap.link}
            onChange={(event) => handleChange(index, "link", event)}
          />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            InputProps={{
              autoComplete: "off",
            }}
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            onClick={() => handleRemoveField(index)}
            sx={{
              "& .MuiButton-endIcon": {
                ml: 0,
              },
            }}
            endIcon={
              <RemoveRounded
                fontSize="small"
                InputProps={{
                  autoComplete: "off",
                }}
              />
            }
            disabled={isSaving}
          />
        </Stack>
      ))}
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <TextField
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Key Word(s)"
          value={newLink.word}
          onChange={(event) =>
            setNewLink({ ...newLink, word: event.target.value })
          }
        />
        <TextField
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Link"
          value={newLink.link}
          onChange={(event) =>
            setNewLink({ ...newLink, link: event.target.value })
          }
        />
        <Button
          variant="outlined"
          color="primary"
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={handleAddField}
          sx={{
            "& .MuiButton-endIcon": {
              ml: 0,
            },
          }}
          endIcon={
            <AddRounded
              fontSize="small"
              InputProps={{
                autoComplete: "off",
              }}
            />
          }
          disabled={isSaving}
        />
      </Stack>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        InputProps={{
          autoComplete: "off",
        }}
        disableRipple
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        sx={{ mt: 2 }}
        onClick={updateLinks}
        disabled={!changed || isSaving}
        endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
      >
        Save
      </Button>
    </div>
  );
};

export default LinksMap;
