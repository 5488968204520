import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import firebase from "../../firebase";
import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  TextField,
  Stack,
  Paper,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import { debounce } from "lodash";
// import SolanaSignInButton from "../../components/buttons/SignInWithSolanaButton.jsx";

const provider = new GoogleAuthProvider();

const LoginPage = () => {
  const {
    authState: { isAuth, isLoading },
  } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCheckingUser, setIsCheckingUser] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = debounce(async () => {
    await signInWithEmailAndPassword(firebase.auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("user:", user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.response.data.message;
        console.log("errorMessage:", errorMessage);
        if (error.response.data.message.includes("User not found:")) {
          showAlert("User not found", "error");
          const auth = getAuth();
          auth.currentUser.delete();
          return;
        }
        console.log("errorCode:", errorCode);
        console.log("errorMessage:", errorMessage);
      });
  }, 500);

  const handleGoogleAuth = debounce(async () => {
    setIsCheckingUser(true);
    const auth = getAuth();
    await signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // console.log("token:", token);
        // The signed-in user info.
        const user = result.user;
        await axios.get(`${process.env.REACT_APP_VERCEL_API_BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${await user.getIdToken()}`,
          },
        });
        setIsCheckingUser(false);
      })
      .catch(async (error) => {
        console.log("error:", error);
        if (error.response.data.message.includes("User not found")) {
          showAlert("User not found", "error");
          await auth.currentUser.delete();
          return;
        }
        // showAlert("Error logging in", "error");
      });
  }, 500);

  if (isLoading) {
    return <div></div>;
  }

  if (isAuth && !isCheckingUser) {
    navigate("/chatbots");
  }

  return (
    <Container
      maxWidth={"xs"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
        p: 5,
      }}
      component={Paper}
    >
      <Box width={"100%"}>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 4 }}>
          Welcome Back
        </Typography>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          placeholder="Email"
          type="text"
          variant="outlined"
          sx={{ mb: 1 }}
          value={email}
          onChange={handleEmailChange}
        />
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          placeholder="Password"
          type="password"
          variant="outlined"
          sx={{ mb: 1 }}
          value={password}
          onChange={handlePasswordChange}
        />
        <Typography
          variant="body2"
          component="p"
          sx={{
            mb: ".5rem",
            textAlign: "left",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password?{" "}
        </Typography>

        <Button
          onClick={handleLogin}
          variant="contained"
          color="secondary"
          fullWidth
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mb: 1, mt: 2 }}
        >
          Log In
        </Button>
        <Typography
          variant="body1"
          component="p"
          sx={{
            my: ".5rem",
            textAlign: "center",
            width: "100%",
          }}
        >
          Don't have an account?{" "}
          <Typography
            variant="body1"
            color="primary"
            component="span"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </Typography>
        </Typography>

        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Divider sx={{ width: "45%" }} />
          <Typography
            variant="body1"
            component="p"
            sx={{
              my: ".5rem",
              textAlign: "center",
              width: "10%",
            }}
          >
            or
          </Typography>
          <Divider sx={{ width: "45%" }} />
        </Stack>
        <Button
          onClick={handleGoogleAuth}
          variant="outlined"
          color="inherit"
          fullWidth
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          startIcon={<GoogleIcon style={{ fontSize: ".95rem" }} />}
          sx={{ mb: 1.5 }}
        >
          Continue with Google
        </Button>
        {/* <SolanaSignInButton /> */}
      </Box>
    </Container>
  );
};

export default LoginPage;
