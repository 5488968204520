import { Alert, Typography } from "@mui/material";
import React, { useState } from "react";

export default function Instructions({ description, id }) {
  const [showDescription, setShowDescription] = useState(
    !localStorage.getItem(id)
  );
  const handleHideDescription = () => {
    localStorage.setItem(id, true);
    setShowDescription(false);
  };

  if (!showDescription) {
    return null;
  }

  return (
    <Alert
      severity="info"
      color="primary"
      sx={{ mt: 1, mb: 4, color: "lightgray" }}
    >
      {description}
      <Typography
        variant="caption"
        sx={{
          mt: 1.5,
          fontSize: "0.75rem",
          cursor: "pointer",
          display: "block",
        }}
        onClick={handleHideDescription}
      >
        Don't show this message again.
      </Typography>
    </Alert>
  );
}
