import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputLabel,
  Typography,
  Paper,
  IconButton,
  Container,
  Divider,
  Skeleton,
  Chip,
} from "@mui/material";
import {
  CheckCircleRounded,
  CheckRounded,
  FileCopyRounded,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import { AuthContext } from "../../contexts/AuthContext";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import { useNavigate } from "react-router-dom";
import UpgradeButton from "../../components/buttons/UpgradeButton";

const shortenString = (str) => {
  return str?.slice(0, 7) + "..." + str?.slice(-7);
};

const AccountSettings = () => {
  const navigate = useNavigate();
  const {
    userId,
    email,
    handleChangeEmail,
    handleDeleteAccount,
    handleLogout,
    authState,
  } = useContext(AuthContext);
  const { chatbots, subscriptionSettings, apiKey } = useContext(ChatbotContext);
  const {
    active,
    status,
    lookupKey,
    startDate,
    canceledAt,
    maxMessages,
    maxChatbots,
    addOnExtraMessageCredits,
    hasAPIAccess,
    addOnApiAccess,
  } = subscriptionSettings || {};

  const subscriptionMap = {
    maxChatbots: "Max Chatbots",
    maxMessages: "Max Messages",
    maxCharacters: "Max Characters",
    canUseGPT4: "GPT-4o",
    canCaptureContacts: "Capture Contacts",
    canSaveConversations: "Save Conversations",
    canUseIntegrations: "Integrations",
    canUploadAudio: "Upload Audio",
    hasAPIAccess: "API Access",
    canRemoveBranding: "Remove Branding",
    hasDedicatedSupport: "Dedicated Support",
    addOnApiAccess: "Add-On: API Access",
    addOnCustomDomain: "Add-On: Custom Domain",
    addOnExtraMessageCredits: "Add-On: Extra Message Credits Per Month",
    addOnRemoveBranding: "Add-On: Remove Branding",
  };

  const sortedSubscriptionSettingsKeys = Object.keys(
    subscriptionSettings || {}
  ).sort((a, b) => {
    if (a.startsWith("addOn") && b.startsWith("addOn")) return 0;
    if (a.startsWith("addOn")) return 1;
    if (b.startsWith("addOn")) return -1;
    return 0;
  });

  const messagesCount = chatbots?.reduce(
    (acc, curr) => acc + curr.messagesCount,
    0
  );
  const [isChanged, setIsChanged] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [permanentlyDelete, setPermanentlyDelete] = useState("");
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [idCopied, setIdCopied] = useState(false);
  const [apiKeyCopied, setApiKeyCopied] = useState(false);

  const handleCopyId = () => {
    navigator.clipboard.writeText(userId);
    setIdCopied(true);
    setTimeout(() => {
      setIdCopied(false);
    }, 1000);
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    setApiKeyCopied(true);
    setTimeout(() => {
      setApiKeyCopied(false);
    }, 1000);
  };

  useEffect(() => {
    setUserEmail(email);
  }, [email]);

  if (!authState.isAuth) {
    navigate("/login");
  }

  if (Object.keys(subscriptionSettings).length === 0) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Skeleton
          animation="wave"
          height={300}
          sx={{ "-webkit-transform": "none", my: 4 }}
        />
        <Skeleton
          animation="wave"
          height={300}
          sx={{ "-webkit-transform": "none", my: 4 }}
        />
        <Skeleton
          animation="wave"
          height={300}
          sx={{ "-webkit-transform": "none", my: 4 }}
        />
      </Container>
    );
  }

  const formatDate = (date) => {
    const newDate = new Date(date * 1000);
    newDate.setMonth(newDate.getMonth() + 1);
    const newDateString = newDate.toDateString();
    return newDateString;
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          General
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          User ID
        </InputLabel>
        <Stack direction="row" spacing={2}>
          <Typography>{shortenString(userId)}</Typography>
          <IconButton
            variant="outlined"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ p: 0, width: "10px" }}
            onClick={handleCopyId}
          >
            {!idCopied ? (
              <FileCopyRounded fontSize="small" />
            ) : (
              <CheckRounded fontSize="small" />
            )}
          </IconButton>
        </Stack>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>Email</InputLabel>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Email"
          value={userEmail}
          name="email"
          onChange={(e) => {
            setUserEmail(e.target.value);
            setIsChanged(true);
          }}
        />
        <Button
          onClick={() => handleChangeEmail(userEmail)}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 3, mb: 1 }}
          disabled={!isChanged}
        >
          Save
        </Button>
      </Paper>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Usage
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Number of Chatbots
        </InputLabel>
        <Typography>
          {new Intl.NumberFormat("en-US").format(chatbots?.length || 0)} /{" "}
          {new Intl.NumberFormat("en-US").format(maxChatbots)}
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Number of Messages Sent
        </InputLabel>
        <Typography>
          {new Intl.NumberFormat("en-US").format(messagesCount || 0)} /{" "}
          {new Intl.NumberFormat("en-US").format(
            maxMessages + addOnExtraMessageCredits
          )}
        </Typography>
      </Paper>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{ textTransform: "capitalize" }}
          >
            <Box component="span" fontWeight="bold">
              Subscription
            </Box>
            <Box component="span" fontStyle="italic" fontWeight="200" ml={1}>
              — {lookupKey}
            </Box>
          </Typography>

          <Box>
            {lookupKey !== "free" &&
              active &&
              status !== "canceled" &&
              startDate && (
                <Chip
                  size="small"
                  variant="filled"
                  label={`Renews: ${formatDate(startDate)}`}
                  color={
                    active || status === "active" || status?.includes("trial")
                      ? "success"
                      : "error"
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "white",
                    bgcolor:
                      status === "active" || status?.includes("trial")
                        ? "success.main"
                        : "error.main",
                  }}
                />
              )}
            <Chip
              size="small"
              label={
                status === "canceled"
                  ? "Active until: " +
                    new Date(canceledAt * 1000).toDateString()
                  : status
              }
              color={active ? "success" : "error"}
              variant="outlined"
              sx={{
                ml: 1,
                textTransform: "capitalize",
                fontWeight: "bold",
                fontSize: "12px",
                color: "white",
                bgcolor:
                  status === "active" || status?.includes("trial")
                    ? "success.main"
                    : "error.main",
              }}
            />
          </Box>
        </Stack>
        {sortedSubscriptionSettingsKeys.map((key) => {
          const value = subscriptionSettings[key];
          if (!subscriptionMap[key]) return null;
          return (
            <Stack
              direction="row"
              alignItems={"flex-start"}
              key={key}
              spacing={1}
              sx={{ mb: 1 }}
              justifyContent={"space-between"}
            >
              <Typography variant="body1">{subscriptionMap[key]}</Typography>
              {subscriptionMap[key] && value === true ? (
                <CheckCircleRounded
                  sx={{
                    fontSize: "18px",
                    color: "success.main",
                    mt: 0.2,
                  }}
                />
              ) : subscriptionMap[key] && value === false ? (
                <CloseIcon
                  sx={{
                    fontSize: "18px",
                    color: "error.main",
                    mt: 0.2,
                  }}
                />
              ) : (
                subscriptionMap[key] &&
                value && (
                  <Typography variant="body1">
                    {typeof value === "number"
                      ? new Intl.NumberFormat("en-US").format(value)
                      : value}
                  </Typography>
                )
              )}
            </Stack>
          );
        })}
        {(!lookupKey?.includes("ultra") ||
          !lookupKey?.includes("enterprise")) && (
          <Button
            onClick={() => navigate("/pricing")}
            variant="contained"
            color="secondary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ mt: 1, mb: 1, mr: 2 }}
            disabled={isChanged}
          >
            Upgrade
          </Button>
        )}
        {!lookupKey?.includes("free") && (
          <Button
            onClick={() =>
              window.open(
                `https://billing.stripe.com/p/login/8wM03V76vh2MaI0dQQ?prefilled_email=${email}`,
                "_blank"
              )
            }
            variant="outlined"
            color="primary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ mt: 1, mb: 1, mr: 2 }}
            disabled={isChanged}
          >
            Manage Subscription
          </Button>
        )}
      </Paper>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          API
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          API Key
        </InputLabel>
        {hasAPIAccess || addOnApiAccess ? (
          <Stack direction="row" spacing={2}>
            <Typography>{shortenString(apiKey)}</Typography>
            <IconButton
              variant="outlined"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              sx={{ p: 0, width: "10px" }}
              onClick={handleCopyApiKey}
            >
              {!apiKeyCopied ? (
                <FileCopyRounded fontSize="small" />
              ) : (
                <CheckRounded fontSize="small" />
              )}
            </IconButton>
          </Stack>
        ) : (
          <>
            <UpgradeButton />
            <br />
          </>
        )}
        <Button
          onClick={() => navigate("/pricing")}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 3, mb: 1 }}
          disabled={isChanged}
        >
          API Docs
        </Button>
      </Paper>
      <Divider />
      <Button
        onClick={handleLogout}
        variant="contained"
        color="secondary"
        size="large"
        disableRipple
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        sx={{ display: "block", margin: "0 auto", my: 4 }}
        disabled={isChanged}
      >
        Logout
      </Button>
      <Paper
        sx={{
          p: 3,
          bgcolor: "transparent",
          mt: 2,
          mb: 3,
          borderColor: "red",
        }}
      >
        <Typography variant="h5" color="error">
          Danger Zone
        </Typography>
        <Typography variant="body2" sx={{ mb: 3, mt: 2 }}>
          Type "permanently delete" to delete your account
        </Typography>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          sx={{
            "& .MuiInputBase-input": { padding: "10px" },
          }}
          size="small"
          fullWidth
          type="text"
          variant="outlined"
          placeholder="permanently delete"
          value={permanentlyDelete}
          name="permanentlyDelete"
          onChange={(e) => {
            setPermanentlyDelete(e.target.value);
          }}
        />
        <Button
          onClick={() => setAreYouSureOpen(true)}
          variant="outlined"
          color="error"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mr: 2, mt: 2, color: "error.main" }}
          disabled={permanentlyDelete !== "permanently delete"}
        >
          Delete Account
        </Button>
        {areYouSureOpen && (
          <Button
            onClick={handleDeleteAccount}
            variant="contained"
            color="error"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ mt: 2, color: "white" }}
            disabled={permanentlyDelete !== "permanently delete"}
          >
            Yes, I'm sure. Permanently delete my account.
          </Button>
        )}
      </Paper>
    </Container>
  );
};

export default AccountSettings;
