import { Button } from "@mui/material";
import React from "react";

export default function UpgradeButton({ size }) {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size={size || "small"}
      sx={{
        fontSize: size === "small" ? "0.5rem" : "0.875rem",
        animation: "pulse 4s infinite",
        "@keyframes pulse": {
          "0%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(229, 54, 171, 0.7)",
          },
          "70%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 4px rgba(229, 54, 171, 0)",
          },
          "100%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(229, 54, 171, 0)",
          },
        },
      }}
      onClick={() => {
        window.open("https://diddo.chat/pricing", "_blank");
      }}
    >
      Upgrade to unlock
    </Button>
  );
}
