import React, { useContext, useState } from "react";
import {
  Button,
  FormControlLabel,
  InputLabel,
  Switch,
  TextField,
  CircularProgress,
  Fab,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import LanguageSelect from "./LanguageSelect";

const ChatInterfaceForm = ({ formData }) => {
  const {
    primaryColor,
    setPrimaryColor,
    textColor,
    setTextColor,
    avatarUrl,
    setAvatarUrl,
    darkMode,
    setDarkMode,
    suggestedQuestions,
    setSuggestedQuestions,
    initialMessages,
    setInitialMessages,
    language,
    setLanguage,
  } = formData;
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { tokenId } = useContext(AuthContext);
  const [avatarFile, setAvatarFile] = useState(null);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { userId, chatbotId } = chatbotData;

  const handleSwitchChange = (event) => {
    setDarkMode(event.target.checked);
    setChatbotData({ ...chatbotData, darkMode: event.target.checked });
    setIsChanged(true);
  };

  const handleSaveChanges = debounce(async () => {
    setIsSaving(true);
    let url;

    if (avatarFile) {
      setIsUploadingAvatar(true);
      url = await handleUploadAvatar();
      setIsUploadingAvatar(false);
    }

    const updates = {
      suggestedQuestions: suggestedQuestions,
      primaryColor: primaryColor,
      textColor: textColor,
      avatarUrl: url,
      darkMode: darkMode,
      initialMessages: initialMessages,
      language: language,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot`,
        {
          chatbotId: chatbotId,
          chatbotUpdates: updates,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        ...updates,
      });

      setIsChanged(false);
      showAlert("Changes saved successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error saving changes", "error");
    }
    setIsSaving(false);
  }, 300);

  const handleInitialMessagesChange = (event) => {
    const messages = event.target.value.split(/\n+/);
    setInitialMessages(messages);
    setChatbotData({
      ...chatbotData,
      initialMessages: messages,
    });
    setIsChanged(true);
  };

  const handleSuggestedQuestionsChange = (event) => {
    const questions = event.target.value.split(/\n+/);
    setSuggestedQuestions(questions);
    setChatbotData({
      ...chatbotData,
      suggestedQuestions: questions,
    });
    setIsChanged(true);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    setChatbotData({
      ...chatbotData,
      language: event.target.value,
    });
    setIsChanged(true);
  };

  const handleAvatarFileChange = (e) => {
    if (e.target.files[0]?.size > 1000000) {
      showAlert("Image size must be less than 1MB", "error");
      return;
    }
    setAvatarFile(e.target.files[0]);
    setIsChanged(true);
  };

  const handleUploadAvatar = debounce(async () => {
    const formData = new FormData();
    formData.append("avatar", avatarFile);
    formData.append("chatbotId", chatbotId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot/avatar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setAvatarUrl(response.data.url);
      setIsChanged(true);
      return response.data.url;
    } catch (error) {
      console.error(error);
    }
  }, 300);

  return (
    <>
      <InputLabel sx={{ mb: 0.5, fontSize: "13px" }}>
        Initial Messages
      </InputLabel>
      <TextField
        InputProps={{
          autoComplete: "off",
        }}
        sx={{
          "&.MuiTextField-root": { marginBottom: 2 },
        }}
        size="small"
        fullWidth
        type="text"
        multiline
        rows={3}
        variant="outlined"
        value={initialMessages?.join("\n")}
        name="initialMessages"
        placeholder="Enter initial messages, separated by line breaks"
        onChange={handleInitialMessagesChange}
      />
      <InputLabel sx={{ mb: 0.5, fontSize: "13px" }}>
        Suggested Questions
      </InputLabel>
      <TextField
        InputProps={{
          autoComplete: "off",
        }}
        sx={{
          "&.MuiTextField-root": { marginBottom: 2 },
        }}
        size="small"
        fullWidth
        type="text"
        multiline
        rows={3}
        variant="outlined"
        value={suggestedQuestions?.join("\n")}
        name="suggestedQuestions"
        placeholder="Enter suggested questions, separated by line breaks"
        onChange={handleSuggestedQuestionsChange}
      />
      <InputLabel sx={{ fontSize: "13px" }}>Language</InputLabel>
      <LanguageSelect
        language={language}
        handleLanguageChange={handleLanguageChange}
      />
      <br />
      <InputLabel sx={{ my: 0.5, fontSize: "13px" }}>Primary Color</InputLabel>
      <TextField
        InputProps={{
          autoComplete: "off",
        }}
        sx={{
          "&.MuiTextField-root": { marginBottom: 3 },
          "& .MuiInputBase-input": {
            padding: "7px",
            cursor: "pointer",
            width: "30px",
            height: "30px",
          },
        }}
        size="small"
        type="color"
        variant="outlined"
        value={primaryColor}
        name="primaryColor"
        onChange={(e) => {
          setPrimaryColor(e.target.value);
          setIsChanged(true);
        }}
      />
      <br />
      <InputLabel sx={{ my: 0.5, fontSize: "13px" }}>Text Color</InputLabel>
      <TextField
        InputProps={{
          autoComplete: "off",
        }}
        sx={{
          "&.MuiTextField-root": { marginBottom: 3 },
          "& .MuiInputBase-input": {
            padding: "7px",
            cursor: "pointer",
            width: "30px",
            height: "30px",
          },
        }}
        size="small"
        type="color"
        variant="outlined"
        value={textColor}
        name="textColor"
        onChange={(e) => {
          setTextColor(e.target.value);
          setIsChanged(true);
        }}
      />

      <InputLabel sx={{ mb: 0.5, fontSize: "13px" }}>Dark Mode</InputLabel>
      <FormControlLabel
        control={
          <Switch
            checked={darkMode}
            onChange={handleSwitchChange}
            color="secondary"
          />
        }
        label={darkMode ? "On" : "Off"}
      />
      <br />
      <InputLabel sx={{ mt: 2, fontSize: "13px" }}>Avatar</InputLabel>
      <Fab
        color="primary"
        disablefocusripple="true"
        disableRipple
        disableTouchRipple
        sx={{
          mt: 1,
          mb: 2,
          backgroundColor: primaryColor,
          outline: "none",
          cursor: "default",
          ":hover": {
            backgroundColor: primaryColor,
          },
        }}
      >
        {avatarUrl && (
          <img
            src={avatarFile ? URL.createObjectURL(avatarFile) : avatarUrl}
            alt="avatar"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
            }}
          />
        )}
      </Fab>
      <br />
      <Button
        variant="outlined"
        component="label"
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        disabled={isUploadingAvatar}
        endIcon={
          isUploadingAvatar && <CircularProgress size={20} color="inherit" />
        }
      >
        Upload Image
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          hidden
          onChange={handleAvatarFileChange}
          disabled={isUploadingAvatar}
        />
        {isUploadingAvatar && <CircularProgress size={20} color="inherit" />}
      </Button>
      <br />
      <Button
        onClick={handleSaveChanges}
        variant="contained"
        color="secondary"
        size="small"
        fullWidth
        disableRipple
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        sx={{ mb: 1, mt: 5, width: "200px" }}
        disabled={!isChanged || isSaving}
        endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
      >
        Save
      </Button>
    </>
  );
};

export default ChatInterfaceForm;
