import React, { useEffect } from "react";
import * as PIXI from "pixi.js";
import { KawaseBlurFilter } from "@pixi/filter-kawase-blur";
import { createNoise2D } from "simplex-noise";
import hsl from "hsl-to-hex";
import { Container } from "@mui/material";

const canvasStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100%",
  pointerEvents: "none",
  zIndex: -1,
};

function Orb() {
  useEffect(() => {
    const random = (min, max) => Math.random() * (max - min) + min;
    const map = (n, start1, end1, start2, end2) =>
      ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    const noise2D = new createNoise2D();

    class ColorPalette {
      constructor() {
        this.setColors();
      }
      setColors() {
        this.hue = 261;
        this.saturation = 40;
        this.lightness = 30;
        this.baseColor = hsl(this.hue, this.saturation, this.lightness);
        this.colorChoices = [this.baseColor];
      }

      randomColor() {
        return this.colorChoices[0].replace("#", "0x");
      }
    }

    class Orb {
      constructor(fill = 0x000000) {
        this.bounds = this.setBounds();
        this.x = random(this.bounds["x"].min, this.bounds["x"].max);
        this.y = random(this.bounds["y"].min, this.bounds["y"].max);
        this.scale = 1;
        this.fill = fill;
        this.radius = random(window.innerHeight / 6, window.innerHeight / 3);
        this.xOff = random(0, 1000);
        this.yOff = random(0, 1000);
        this.inc = 0.001;
        this.graphics = new PIXI.Graphics();
        this.graphics.alpha = 0.825;
      }
      setBounds() {
        const maxDist = window.innerWidth / 4;
        const originX = window.innerWidth / 2;
        const originY = window.innerHeight / 1.375;
        return {
          x: { min: originX - maxDist, max: originX + maxDist },
          y: { min: originY - maxDist, max: originY + maxDist },
        };
      }
      update() {
        const xNoise = noise2D(this.xOff, this.xOff);
        const yNoise = noise2D(this.yOff, this.yOff);
        const scaleNoise = noise2D(this.xOff, this.yOff);
        this.x = map(xNoise, -1, 1, this.bounds["x"].min, this.bounds["x"].max);
        this.y = map(yNoise, -1, 1, this.bounds["y"].min, this.bounds["y"].max);
        this.scale = map(scaleNoise, -1, 1, 0.5, 1);
        this.xOff += this.inc;
        this.yOff += this.inc;
      }
      render() {
        this.graphics.x = this.x;
        this.graphics.y = this.y;
        this.graphics.scale.set(this.scale);
        this.graphics.clear();
        this.graphics.beginFill(this.fill);
        this.graphics.drawCircle(0, 0, this.radius);
        this.graphics.endFill();
      }
    }

    const app = new PIXI.Application({
      view: document.querySelector(".orb-canvas"),
      resizeTo: window,
      transparent: true,
    });

    app.stage.filters = [new KawaseBlurFilter(30, 10, true)];

    const colorPalette = new ColorPalette();
    const orbs = [];

    for (let i = 0; i < 10; i++) {
      const orb = new Orb(colorPalette.randomColor());
      app.stage.addChild(orb.graphics);
      orbs.push(orb);
    }

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      app.ticker.add(() => {
        orbs.forEach((orb) => {
          orb.update();
          orb.render();
        });
      });
    } else {
      orbs.forEach((orb) => {
        orb.update();
        orb.render();
      });
    }

    // return () => {
    //   app.destroy();
    // };
  }, []);

  return (
    <Container style={{ backgroundColor: "transparent" }}>
      <canvas className="orb-canvas" style={canvasStyle}></canvas>
    </Container>
  );
}

export default Orb;
