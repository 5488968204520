import React, { useState, useContext } from "react";
import { TextField, Stack, Grid } from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import axios from "axios";
import CharacterCounts from "./CharacterCounts";

const GithubSource = () => {
  const { tokenId } = useContext(AuthContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { showAlert } = useContext(SnackbarContext);
  const { gitHubRepoSource, chatbotId } = chatbotData;
  const [newGithubRepo, setNewGithubRepo] = useState(gitHubRepoSource || {});
  const [isSaving, setIsSaving] = useState(false);
  const [changed, setChanged] = useState(false);

  const updateGithubSource = async () => {
    if (!newGithubRepo.link) {
      showAlert("Please enter a repository link", "error");
      return;
    }
    setIsSaving(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/github`,
        {
          newGitHubRepoSource: newGithubRepo,
          chatbotId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );

      setChatbotData({
        ...chatbotData,
        gitHubRepo: newGithubRepo,
      });
      showAlert("GitHub Source updated successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error updating GitHub Source", "error");
    }
    setIsSaving(false);
  };

  return (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} sm={12} md={12}>
        <Stack direction="column" spacing={2}>
          <TextField
            placeholder="Repository Link"
            size="small"
            variant="outlined"
            value={newGithubRepo?.link}
            onChange={(e) => {
              setChanged(true);
              setNewGithubRepo({ ...newGithubRepo, link: e.target.value });
            }}
          />
          <TextField
            placeholder="Branch (default: main)"
            size="small"
            variant="outlined"
            value={newGithubRepo?.branch || ""}
            onChange={(e) => {
              setChanged(true);
              setNewGithubRepo({ ...newGithubRepo, branch: e.target.value });
            }}
          />
          <TextField
            placeholder="Access Token (optional)"
            size="small"
            variant="outlined"
            type="password"
            value={newGithubRepo?.accessToken || ""}
            onChange={(e) => {
              setChanged(true);
              setNewGithubRepo({
                ...newGithubRepo,
                accessToken: e.target.value,
              });
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <CharacterCounts
          handleClick={updateGithubSource}
          isSaving={isSaving}
          changed={changed}
        />
      </Grid>
    </Grid>
  );
};

export default GithubSource;
