import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import ReactMarkdown from "react-markdown";

const BlogPost = () => {
  const { blogName } = useParams();
  const [markdownFile, setMarkdownFile] = useState("");

  useEffect(() => {
    fetch(`/blogs/${blogName}.md`)
      .then((response) => response.text())
      .then((text) => setMarkdownFile(text));
  }, [blogName]);

  return (
    <Container maxWidth="sm" sx={{ pt: 5, pb: 3 }}>
      <img
        src={`/blogs/${blogName}.png`}
        alt={blogName}
        width="100%"
        style={{ borderRadius: "6px" }}
      />
      <div className="markdown-container">
        <ReactMarkdown>{markdownFile}</ReactMarkdown>
      </div>
    </Container>
  );
};

export default BlogPost;
