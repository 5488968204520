// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCk0j7WiVDNWSxuwrlCpNZakTprMiod9AM",
  authDomain: "dittobot-ai.firebaseapp.com",
  projectId: "dittobot-ai",
  storageBucket: "dittobot-ai.appspot.com",
  messagingSenderId: "421122077976",
  appId: "1:421122077976:web:67ecc8a8f3e7b30a1bda6d",
  measurementId: "G-TSEFTLZZEE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

const firebase = {
  auth,
  functions,
  analytics,
};

export default firebase;
