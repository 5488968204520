import React, { useContext, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  FaDiscord,
  FaFacebookMessenger,
  FaSlack,
  FaShopify,
  FaTelegram,
} from "react-icons/fa";
import { SiZapier } from "react-icons/si";
import ZapierModal from "./ZapierModal";
import DiscordModal from "./DiscordModal";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import UpgradeButton from "../../../components/buttons/UpgradeButton";

const integrations = [
  {
    name: "Zapier",
    icon: <SiZapier fontSize={25} color="#ff4a00" />,
    active: true,
  },
  {
    name: "Shopify",
    icon: <FaShopify fontSize={25} />,
    active: false,
  },
  {
    name: "Slack",
    icon: <FaSlack fontSize={25} />,
    active: false,
  },
  {
    name: "Discord",
    icon: <FaDiscord fontSize={25} color="#7289DA" />,
    active: true,
  },
  {
    name: "Telegram",
    icon: <FaTelegram fontSize={25} />,
    active: false,
  },
  {
    name: "Messenger",
    icon: <FaFacebookMessenger fontSize={25} />,
    active: false,
  },
];

export default function Integrations() {
  const { subscriptionSettings } = useContext(ChatbotContext) || {};
  const { canUseIntegrations } = subscriptionSettings || {};
  const [openModal, setOpenModel] = useState("");

  const handleCloseModal = () => setOpenModel("");

  return (
    <>
      {!canUseIntegrations && <UpgradeButton />}
      <Grid container spacing={3}>
        {integrations
          .sort((a, b) => b.active - a.active)
          .map((integration, index) => (
            <Grid key={index} item xs={12} md={3}>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                fullWidth
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                disabled={!integration.active}
                sx={{
                  mt: 2,
                  py: 2,
                  px: 3,
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                onClick={() => {
                  canUseIntegrations &&
                    integration.active &&
                    setOpenModel(integration.name);
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  {integration.icon}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {integration.name}
                  </Typography>
                </Box>
                {!integration.active && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Coming Soon
                  </Typography>
                )}
              </Button>
            </Grid>
          ))}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openModal.length > 0}
          onClose={() => setOpenModel("")}
        >
          <DialogTitle>{openModal}</DialogTitle>
          <DialogContent dividers>
            {openModal === "Zapier" && (
              <ZapierModal handleCloseModal={handleCloseModal} />
            )}
            {openModal === "Discord" && (
              <DiscordModal handleCloseModal={handleCloseModal} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
