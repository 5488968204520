import React from "react";
import HeaderText from "../../components/layout/HeaderText";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaHandshake, FaRegClock } from "react-icons/fa";
import Icon from "../../components/layout/Icon";
import { Moving, People, Savings, Settings } from "@mui/icons-material";

export default function Cta() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ mt: 10, mb: 10 }}>
      <HeaderText
        title="AI Magic"
        topLine="Why Choose"
        bottomLine="Diddo?"
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<Moving size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Enhance User Engagement
            </Typography>
            <Typography>
              Create interactive experiences that captivate and convert visitors into customers.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<FaRegClock size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Streamline Business Processes
            </Typography>
            <Typography>
              Automate routine tasks and inquiries, allowing your team to focus on complex issues.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<People size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Personalized Experience
            </Typography>
            <Typography>
              Deliver tailored assistance that caters to your unique business needs and customer preferences.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<FaHandshake size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Build Customer Loyalty
            </Typography>
            <Typography>
              Provide instant, personalized support that strengthens customer relationships.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<Savings size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Cost-Effective Solution
            </Typography>
            <Typography>Reduce customer support expenses while improving efficiency.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box className="reason">
            <Icon icon={<Settings size={20} />} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              Gain Competitive Edge
            </Typography>
            <Typography>
              Stay ahead of the curve with cutting-edge AI technology tailored to your business.
            </Typography>
          </Box>
        </Grid>
        {/* call to action */}
        <Grid item xs={12} sm={12} md={12} justifyContent="center">
          <Box className="reason">
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
              gutterBottom
            >
              Create Your Custom AI Agent in Minutes
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              Experience the Diddo difference and revolutionize your website with AI.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              width="100%"
            >
              <Button
                size="medium"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/pricing");
                }}
                sx={{ mt: 3 }}
              >
                Start Your Free Trial
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
