import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

export default function LanguageSelect({ language, handleLanguageChange }) {
  return (
    <FormControl sx={{ my: 0.5, mb: 2, minWidth: 120 }}>
      <Select
        labelId="language-label"
        id="language"
        value={language}
        size="small"
        name="language"
        onChange={handleLanguageChange}
      >
        <MenuItem value="English">🇺🇸 English</MenuItem>
        <MenuItem value="Español">🇪🇸 Español</MenuItem>
        <MenuItem value="Français">🇫🇷 Français</MenuItem>
        <MenuItem value="Deutsch">🇩🇪 Deutsch</MenuItem>
        <MenuItem value="Italiano">🇮🇹 Italiano</MenuItem>
        <MenuItem value="Português">🇵🇹 Português</MenuItem>
        <MenuItem value="Русский">🇷🇺 Русский</MenuItem>
        <MenuItem value="中文">🇨🇳 中文</MenuItem>
        <MenuItem value="日本語">🇯🇵 日本語</MenuItem>
        <MenuItem value="한국어">🇰🇷 한국어</MenuItem>
        <MenuItem value="العربية">🇸🇦 العربية</MenuItem>
        <MenuItem value="हिन्दी">🇮🇳 हिन्दी</MenuItem>
        <MenuItem value="Nederlands">🇳🇱 Nederlands</MenuItem>
        <MenuItem value="Svenska">🇸🇪 Svenska</MenuItem>
        <MenuItem value="Norsk">🇳🇴 Norsk</MenuItem>
        <MenuItem value="Dansk">🇩🇰 Dansk</MenuItem>
        <MenuItem value="Suomi">🇫🇮 Suomi</MenuItem>
        <MenuItem value="Polski">🇵🇱 Polski</MenuItem>
        <MenuItem value="Čeština">🇨🇿 Čeština</MenuItem>
        <MenuItem value="Ελληνικά">🇬🇷 Ελληνικά</MenuItem>
        <MenuItem value="Türkçe">🇹🇷 Türkçe</MenuItem>
        <MenuItem value="Română">🇷🇴 Română</MenuItem>
        <MenuItem value="Magyar">🇭🇺 Magyar</MenuItem>
        <MenuItem value="Українська">🇺🇦 Українська</MenuItem>
        <MenuItem value="ไทย">🇹🇭 ไทย</MenuItem>
        <MenuItem value="Tiếng Việt">🇻🇳 Tiếng Việt</MenuItem>
        <MenuItem value="Afrikaans">🇿🇦 Afrikaans</MenuItem>
        <MenuItem value="Shqip">🇦🇱 Shqip</MenuItem>
        <MenuItem value="አማርኛ">🇪🇹 አማርኛ</MenuItem>
        <MenuItem value="Հայերեն">🇦🇲 Հայերեն</MenuItem>
        <MenuItem value="Azərbaycan">🇦🇿 Azərbaycan</MenuItem>
        <MenuItem value="Euskara">🇪🇸 Euskara</MenuItem>
        <MenuItem value="Беларуская">🇧🇾 Беларуская</MenuItem>
        <MenuItem value="বাংলা">🇧🇩 বাংলা</MenuItem>
        <MenuItem value="Bosanski">🇧🇦 Bosanski</MenuItem>
        <MenuItem value="Български">🇧🇬 Български</MenuItem>
        <MenuItem value="Català">🇪🇸 Català</MenuItem>
        <MenuItem value="Cebuano">🇵🇭 Cebuano</MenuItem>
        <MenuItem value="Chichewa">🇲🇼 Chichewa</MenuItem>
        <MenuItem value="Corsu">🇫🇷 Corsu</MenuItem>
        <MenuItem value="Hrvatski">🇭🇷 Hrvatski</MenuItem>
        <MenuItem value="Esperanto">🌍 Esperanto</MenuItem>
        <MenuItem value="Eesti">🇪🇪 Eesti</MenuItem>
        <MenuItem value="Filipino">🇵🇭 Filipino</MenuItem>
        <MenuItem value="Suomi">🇫🇮 Suomi</MenuItem>
        <MenuItem value="Frysk">🇳🇱 Frysk</MenuItem>
        <MenuItem value="Galego">🇪🇸 Galego</MenuItem>
        <MenuItem value="ქართული">🇬🇪 ქართული</MenuItem>
        <MenuItem value="ગુજરાતી">🇮🇳 ગુજરાતી</MenuItem>
        <MenuItem value="Kreyòl Ayisyen">🇭🇹 Kreyòl Ayisyen</MenuItem>
        <MenuItem value="Hausa">🇳🇬 Hausa</MenuItem>
        <MenuItem value="Ōlelo Hawaiʻi">🇺🇸 Ōlelo Hawaiʻi</MenuItem>
        <MenuItem value="Íslenska">🇮🇸 Íslenska</MenuItem>
        <MenuItem value="Igbo">🇳🇬 Igbo</MenuItem>
        <MenuItem value="Bahasa Indonesia">🇮🇩 Bahasa Indonesia</MenuItem>
        <MenuItem value="Gaeilge">🇮🇪 Gaeilge</MenuItem>
        <MenuItem value="Jawa">🇮🇩 Jawa</MenuItem>
        <MenuItem value="ಕನ್ನಡ">🇮🇳 ಕನ್ನಡ</MenuItem>
        <MenuItem value="Қазақ Тілі">🇰🇿 Қазақ Тілі</MenuItem>
        <MenuItem value="ភាសាខ្មែរ">🇰🇭 ភាសាខ្មែរ</MenuItem>
        <MenuItem value="Kurmanji">🇮🇶 Kurmanji</MenuItem>
        <MenuItem value="Кыргызча">🇰🇬 Кыргызча</MenuItem>
        <MenuItem value="ພາສາລາວ">🇱🇦 ພາສາລາວ</MenuItem>
        <MenuItem value="Latviešu">🇱🇻 Latviešu</MenuItem>
        <MenuItem value="Lietuvių">🇱🇹 Lietuvių</MenuItem>
        <MenuItem value="Lëtzebuergesch">🇱🇺 Lëtzebuergesch</MenuItem>
        <MenuItem value="Македонски">🇲🇰 Македонски</MenuItem>
        <MenuItem value="Malagasy">🇲🇬 Malagasy</MenuItem>
        <MenuItem value="Bahasa Melayu">🇲🇾 Bahasa Melayu</MenuItem>
        <MenuItem value="മലയാളം">🇮🇳 മലയാളം</MenuItem>
        <MenuItem value="Malti">🇲🇹 Malti</MenuItem>
        <MenuItem value="Te Reo Māori">🇳🇿 Te Reo Māori</MenuItem>
        <MenuItem value="मराठी">🇮🇳 मराठी</MenuItem>
        <MenuItem value="Монгол">🇲🇳 Монгол</MenuItem>
        <MenuItem value="မြန်မာစာ">🇲🇲 မြန်မာစာ</MenuItem>
        <MenuItem value="नेपाली">🇳🇵 नेपाली</MenuItem>
        <MenuItem value="پښتو">🇦🇫 پښتو</MenuItem>
        <MenuItem value="فارسی">🇮🇷 فارسی</MenuItem>
        <MenuItem value="Punjabi">🇮🇳 Punjabi</MenuItem>
        <MenuItem value="Samoan">🇼🇸 Samoan</MenuItem>
        <MenuItem value="Gàidhlig">🏴󠁧󠁢󠁳󠁣󠁴󠁿 Gàidhlig</MenuItem>
        <MenuItem value="Српски">🇷🇸 Српски</MenuItem>
        <MenuItem value="Sesotho">🇱🇸 Sesotho</MenuItem>
        <MenuItem value="Shona">🇿🇼 Shona</MenuItem>
        <MenuItem value="سنڌي">🇵🇰 سنڌي</MenuItem>
        <MenuItem value="සිංහල">🇱🇰 සිංහල</MenuItem>
        <MenuItem value="Slovenčina">🇸🇰 Slovenčina</MenuItem>
        <MenuItem value="Slovenščina">🇸🇮 Slovenščina</MenuItem>
        <MenuItem value="Afsoomaali">🇸🇴 Afsoomaali</MenuItem>
        <MenuItem value="Basa Sunda">🇮🇩 Basa Sunda</MenuItem>
        <MenuItem value="Kiswahili">🇹🇿 Kiswahili</MenuItem>
        <MenuItem value="Тоҷикӣ">🇹🇯 Тоҷикӣ</MenuItem>
        <MenuItem value="தமிழ்">🇮🇳 தமிழ்</MenuItem>
        <MenuItem value="తెలుగు">🇮🇳 తెలుగు</MenuItem>
        <MenuItem value="O‘zbek">🇺🇿 O‘zbek</MenuItem>
        <MenuItem value="Cymraeg">🏴󠁧󠁢󠁷󠁬󠁳󠁿 Cymraeg</MenuItem>
        <MenuItem value="Xhosa">🇿🇦 Xhosa</MenuItem>
        <MenuItem value="Yorùbá">🇳🇬 Yorùbá</MenuItem>
        <MenuItem value="Zulu">🇿🇦 Zulu</MenuItem>
      </Select>
    </FormControl>
  );
}
