import { Box, Grid, Typography } from "@mui/material";
import React, { useRef, useEffect } from "react";

const FeatureCard = ({ features }) => {
  const featuresRef = useRef(null);

  useEffect(() => {
    const featuresEl = featuresRef.current;
    const featureEls = featuresEl.querySelectorAll(".feature");

    const handlePointerMove = (ev) => {
      featureEls.forEach((featureEl) => {
        const rect = featureEl.getBoundingClientRect();
        featureEl.style.setProperty("--x", ev.clientX - rect.left);
        featureEl.style.setProperty("--y", ev.clientY - rect.top);
      });
    };

    featuresEl.addEventListener("pointermove", handlePointerMove);

    return () => {
      featuresEl.removeEventListener("pointermove", handlePointerMove);
    };
  }, []);

  return (
    <Grid container spacing={1} ref={featuresRef} sx={{ m: "0 auto" }}>
      {features.map(({ title, description, icon }) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={title}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box className="feature">
            {icon}
            <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
              {title}
            </Typography>
            <Typography>{description}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureCard;
