import React, { useContext, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Paper,
  StepContent,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";

const getSteps = () => [
  "Step 1: Create Discord Bot",
  "Step 2: Add To Your Server",
  "Step 3: Enable Messages",
  "Step 4: Allow Diddo",
];

const DiscordModal = () => {
  const { showAlert } = useContext(SnackbarContext);
  const { tokenId } = useContext(AuthContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { chatbotId, discordSettings } = chatbotData || {
    chatbotId: "",
    discordSettings: {},
  };
  const { botToken, allowedChannels } = discordSettings || {
    botToken: "",
    allowedChannels: "",
  };
  const [activeStep, setActiveStep] = useState(botToken ? 2 : 0);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const steps = getSteps();

  const handleInputChange = (e) => {
    setIsChanged(true);
    const { name, value } = e.target;
    setChatbotData({
      ...chatbotData,
      discordSettings: {
        ...discordSettings,
        [name]: value.replaceAll(" ", ""),
      },
    });
  };

  const handleNext = () => {
    if (isChanged && activeStep === steps.length - 1) {
      handleSaveChanges();
    }
    if (activeStep <= steps.length - 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Create a Discord Bot in the Discord Developer Portal.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              onClick={() =>
                window.open(
                  "https://discord.com/developers/applications",
                  "_blank"
                )
              }
              style={{
                backgroundColor: "#7289DA",
              }}
            >
              Discord Developer Portal
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Add your Discord Bot to your Discord Server by goign to the
              <a
                href="https://discord.com/developers/applications"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#7289DA",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Discord Developer Portal
              </a>{" "}
              and clicking the "OAuth2" tab. Then select the "bot" scope and the
              "Administrator" permission. Copy the link and paste it into your
              browser. Select the server you would like to add the bot to and
              click "Authorize".
            </Typography>
          </>
        );
      case 2:
        return (
          <Typography variant="body2">
            In the{" "}
            <a
              href="https://discord.com/developers/applications"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#7289DA",
                fontWeight: "bold",
              }}
            >
              {" "}
              Discord Developer Portal
            </a>
            , click the "Bot" tab and scroll down to the "Privileged Gateway
            Intents" section. Enable "MESSAGE CONTENT INTENT".
          </Typography>
        );
      case 3:
        return (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter your Discord Bot's Bot Token. You can find the Bot Token in
              the
              <a
                href="https://discord.com/developers/applications"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#7289DA",
                  fontWeight: "bold",
                }}
              >
                {" "}
                Discord Developer Portal
              </a>
              .
            </Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Bot Token"
              value={botToken}
              name="botToken"
              onChange={handleInputChange}
            />
            <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
              Enter the Channel IDs you would like your Discord Bot to post
              messages to. Seperate Channel IDs by commas. You can find the
              Channel ID by right clicking on the channel and selecting "Copy
              ID".
            </Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="97373737373737,94848484848484,..."
              value={allowedChannels}
              name="allowedChannels"
              onChange={handleInputChange}
            />
          </>
        );

      default:
        return "";
    }
  };

  const handleSaveChanges = debounce(async () => {
    setIsSaving(true);

    const updates = {
      discordSettings: {
        botToken: botToken,
        allowedChannels: allowedChannels,
      },
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot`,
        {
          chatbotId: chatbotId,
          chatbotUpdates: updates,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        ...updates,
      });

      setIsChanged(false);
      showAlert("Changes saved successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error saving changes", "error");
    }
    setIsSaving(false);
  }, 1000);

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Paper sx={{ p: 3, bgcolor: "transparent", my: 3 }}>
                {getStepContent(activeStep)}
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={handleNext}
          disabled={isSaving || (!isChanged && activeStep === steps.length - 1)}
          endIcon={isSaving && <CircularProgress size={20} color="inherit" />}
        >
          {activeStep >= steps.length - 1 ? "Save" : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default DiscordModal;
