import React from "react";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import { SiInstagram, SiTiktok } from "@icons-pack/react-simple-icons";

export default function Footer() {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column-reverse"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mt: 10,
          py: 2,
        }}
      >
        <Box textAlign="center">
          <Stack direction="row" spacing={2} mb={1}>
            <Typography variant="body2">
              &copy; diddo.chat. All rights reserved.
            </Typography>
            <Typography variant="body2">
              <a
                href="https://diddo.chat/privacy"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  textTransform: "none",
                  color: "inherit",
                }}
              >
                Privacy
              </a>
            </Typography>
            <Typography variant="body2">
              <a
                href="https://diddo.chat/terms"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  textTransform: "none",
                  color: "inherit",
                }}
              >
                Terms of Service
              </a>
            </Typography>
          </Stack>

          <Typography variant="body2">
            <a
              href="mailto:hello@diddo.chat"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                textTransform: "none",
                color: "inherit",
                fontWeight: 600,
              }}
            >
              hello@diddo.chat
            </a>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 2, md: 0 }}>
          <IconButton
            href="https://x.com/DiddoChat"
            color="inherit"
            aria-label="Twitter"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 300.25 300.25"
              style={{ padding: 5 }}
            >
              <path
                fill="currentColor"
                d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"
              />
            </svg>
          </IconButton>
          <IconButton
            href="https://tiktok.com/@DiddoChat"
            target="_blank"
            color="inherit"
            aria-label="Tiktok"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <SiTiktok size={28} style={{ padding: 5 }} />
          </IconButton>
          <IconButton
            href="https://instagram.com/diddo.chat"
            target="_blank"
            color="inherit"
            aria-label="Instagram"
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <SiInstagram size={28} style={{ padding: 5 }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
