import React, { useContext, useState } from "react";
import { Box, Container, Typography, Button, TextField } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../contexts/SnackbarContext";

const ForgotPasswordPage = () => {
  const { showAlert } = useContext(SnackbarContext);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async () => {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent!");
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("errorCode:", errorCode);
        console.log("errorMessage:", errorMessage);
        showAlert(errorMessage, "error");
      });
  };

  return (
    <Container
      maxWidth={"xs"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box width={"100%"}>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          Reset Your Password
        </Typography>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          placeholder="Email"
          type="text"
          variant="outlined"
          sx={{ mb: 1 }}
          value={email}
          onChange={handleEmailChange}
        />

        <Button
          onClick={handleResetPassword}
          variant="contained"
          color="primary"
          fullWidth
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mb: 1, mt: 2 }}
        >
          Reset Password
        </Button>
        <Typography
          variant="body1"
          component="p"
          sx={{
            my: ".5rem",
            textAlign: "center",
            width: "100%",
          }}
        >
          Remembered your password?{" "}
          <Typography
            variant="body1"
            component="span"
            color='primary'
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            Log In
          </Typography>
        </Typography>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
