import { Box } from "@mui/material";
import React from "react";

export default function Icon({ icon }) {
  return (
    <Box
      sx={{
        color: "#fafafa",
        background:
          "linear-gradient(rgba(20, 20, 20, 0.539) 0%, rgb(44 44 44 / 80%) 100%)",
        fill: "white",
        borderBottomWidth: "0px",
        borderColor: "rgba(255, 255, 255, 0.2)",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderStyle: "solid",
        borderTopWidth: "1px",
        borderRadius: "50%",
        boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
        // p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        mb: 2,
      }}
    >
      {icon}
    </Box>
  );
}
