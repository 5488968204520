import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Slider,
  Typography,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  InputLabel,
  Stack,
  Alert,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { debounce } from "lodash";
import chatbotPrompts from "../../../../utils/prompts.json";
import { AuthContext } from "../../../../contexts/AuthContext";
import { SnackbarContext } from "../../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";

export default function CreateChatbotDialog({
  open,
  handleClose,
  setIsCreating,
}) {
  const { showAlert } = useContext(SnackbarContext);
  const { tokenId } = useContext(AuthContext);
  const { chatbots, setChatbots, subscriptionSettings } =
    useContext(ChatbotContext);
  const { maxChatbots } = subscriptionSettings || {};
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [temperature, setTemperature] = useState(0);
  const [initialMessages, setInitialMessages] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (open) {
      setTitle("");
      setTemperature(0);
      setInitialMessages([]);
      setSelectedPrompt(null);
      setSelectedCategory(null);
      setSearchResults([]);
    }
  }, [open]);

  const handleSearch = (term) => {
    const results = [];
    Object.keys(chatbotPrompts).forEach((category) => {
      chatbotPrompts[category].forEach((job) => {
        if (
          job.job_title.toLowerCase().includes(term.toLowerCase()) ||
          job.description.toLowerCase().includes(term.toLowerCase())
        ) {
          results.push({
            category,
            job_title: job.job_title,
          });
        }
      });
    });
    setSearchResults(results);
  };

  const handlePromptClick = (prompt) => {
    setSelectedPrompt(prompt);
    setInitialMessages(prompt.initialMessages);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const createChatbot = debounce(async () => {
    if (chatbots?.length >= maxChatbots) {
      setErrorMessage(
        "You have reached the maximum number of chatbots",
        "error"
      );
      return;
    }
    if (!title) {
      setErrorMessage("Please enter a chatbot name", "error");
      return;
    }
    // if (!selectedPrompt?.job_title) {
    //   setErrorMessage("Please select a chatbot type", "error");
    //   return;
    // }
    setIsCreating(true);
    try {
      const data = {
        chatbotName: title,
        basePrompt: selectedPrompt?.description,
        temperature,
        initialMessages,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot`,
        data,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      showAlert("Chatbot created successfully", "success");
      setIsCreating(false);
      setChatbots(res.data.chatbots);
      handleClose();
    } catch (error) {
      console.error(error);
      setErrorMessage("Error creating chatbot", "error");
      setIsCreating(false);
    }
  }, 300);

  const filteredPrompts =
    selectedCategory && chatbotPrompts[selectedCategory]
      ? chatbotPrompts[selectedCategory]
      : [];

  const filteredCategories = Object.keys(chatbotPrompts);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create a New Chatbot</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <Alert
            onClose={() => setErrorMessage("")}
            severity={"error"}
            sx={{
              width: "100%",
              textTransform: "capitalize",
              color: "#fafafa",
              mb: 2,
            }}
            variant="standard"
            action={null}
          >
            {errorMessage}
          </Alert>
        )}

        <InputLabel sx={{ fontSize: "13px" }}>Chatbot Name</InputLabel>
        <TextField
          autoFocus
          fullWidth
          size="small"
          placeholder="Chatbot Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          margin="normal"
        />
        <InputLabel sx={{ mt: 4, fontSize: "13px" }}>Temperature</InputLabel>
        <Slider
          size="small"
          value={temperature}
          min={0}
          max={10}
          step={1}
          onChange={(_, newValue) => setTemperature(newValue)}
          valueLabelDisplay="auto"
          style={{ paddingBottom: "0px" }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="caption">0</Typography>
          <Typography variant="caption">10</Typography>
        </Stack>
        <Typography variant="caption" sx={{ mt: 1 }}>
          Temperature controls the creativity of the chatbot. Higher values
          result in more creative responses.
        </Typography>
        <InputLabel sx={{ mt: 4, fontSize: "13px" }}>
          Chatbot Templates
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          placeholder="Search by department or job title"
          variant="outlined"
          margin="normal"
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon
                    sx={{
                      fontSize: "20px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Paper
          sx={{
            mt: 1,
            p: "16px",
            textAlign: "center",
            cursor: "pointer",
            height: 55,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: !selectedPrompt?.job_title && `.5px solid #8a6bea`,
          }}
          onClick={() => {
            setSelectedPrompt(null);
            setSelectedCategory(null);
          }}
        >
          Start From Scratch
        </Paper>
        {searchResults.length > 0 && (
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            {searchResults.map((result, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper
                  style={{
                    padding: "16px",
                    textAlign: "center",
                    cursor: "pointer",
                    height: 100,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setSelectedCategory(result.category);
                    setSelectedPrompt(result);
                  }}
                >
                  {result.job_title}
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
        {selectedCategory && (
          <Button
            onClick={() => {
              setSelectedPrompt(null);
              setSelectedCategory(null);
            }}
            variant="text"
            color="primary"
            sx={{ color: "#fafafa", mt: 2 }}
          >
            ← Select Category
          </Button>
        )}
        <Grid container spacing={2} sx={{ mt: 0.2 }}>
          {selectedCategory
            ? filteredPrompts.map((prompt, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      cursor: "pointer",
                      height: 100,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border:
                        selectedPrompt?.job_title === prompt.job_title &&
                        `.5px solid #8a6bea`,
                    }}
                    onClick={() => handlePromptClick(prompt)}
                  >
                    {prompt.job_title}
                  </Paper>
                </Grid>
              ))
            : filteredCategories.map((category, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Paper
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      cursor: "pointer",
                      height: 100,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={createChatbot}
        >
          Create Chatbot
        </Button>
      </DialogActions>
    </Dialog>
  );
}
