import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function HeaderText({ title, topLine, bottomLine }) {
  return (
    <Box textAlign="center" mb={4}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        disableRipple
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        sx={{
          mb: 2,
          cursor: "default",
          background: `radial-gradient(69.5% 58.5% at 52.9% 109.7%, #3a3a3a 0%, #111111 100%)`,
          borderColor: "#acacac7a",
          textTransform: "capitalize",
        }}
      >
        {title}
      </Button>
      <Typography
        variant="h3"
        sx={{
          fontWeight: "700",
          fontSize: {
            xs: "1.8rem",
            sm: "1.8rem",
            md: "2.5rem",
            lg: "2.5rem",
            xl: "4rem",
          },
        }}
      >
        {topLine}
      </Typography>
      <Box
        component="h3"
        sx={{
          m: 0,
          background: "linear-gradient(45deg, #ff70ac, #8a6bea)",
          WebkitBackgroundClip: "text",
          color: "transparent",
          fontWeight: "700",
          fontSize: {
            xs: "1.8rem",
            sm: "1.8rem",
            md: "2.5rem",
            lg: "2.5rem",
            xl: "4rem",
          },
          position: "relative",
          top: "-.5rem",
        }}
      >
        {bottomLine}
      </Box>
    </Box>
  );
}
