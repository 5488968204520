import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Container, Card, CardContent } from "@mui/material";

const blogFileNames = [
  "chatbots-for-automated-lead-generation",
  "the-ultimate-guide-to-building-your-first-chatbot",
  "how-chatbots-are-revolutionizing-customer-service",
  "why-every-e-commerce-business-needs-a-chatbot",
  "chatbots-in-healthcare",
  "how-chatbots-boost-sales",
  "how-chatbots-are-transforming-education",
];

const Blogs = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ mb: 2, mt: 4 }}>
      <Grid container spacing={4}>
        {blogFileNames.map((fileName, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Card>
              <CardContent sx={{ p: 0 }}>
                <img
                  src={`/blogs/${fileName}.png`}
                  alt={fileName}
                  style={{ borderRadius: "6px", marginBottom: "8px" }}
                  width="100%"
                />
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    textTransform: "capitalize",
                    textDecoration: "none",
                    cursor: "pointer",
                    px: 2,
                    pb: 1,
                  }}
                  onClick={() => navigate(`/blog/${fileName}`)}
                >
                  {fileName.replace(/-/g, " ")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    px: 2,
                    textAlign: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/blog/${fileName}`)}
                >
                  Read more
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs;
