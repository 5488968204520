import React, { useContext, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Stack,
  IconButton,
  InputLabel,
  Paper,
  StepContent,
} from "@mui/material";
import { CheckRounded, FileCopyRounded } from "@mui/icons-material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";

const shortenString = (str) => str.slice(0, 7) + "..." + str.slice(-7);

const getSteps = () => ["Step 1: Copy API key", "Step 2: Create a Zap"];

const ZapierModal = ({ handleCloseModal }) => {
  const { apiKey } = useContext(ChatbotContext);
  const [activeStep, setActiveStep] = useState(0);
  const [apiKeyCopied, setApiKeyCopied] = useState(false);
  const steps = getSteps();

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    setApiKeyCopied(true);
    setTimeout(() => {
      setApiKeyCopied(false);
    }, 2000);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleCloseModal();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Copy the API key below and paste it into the API key field in
              Zapier.
            </Typography>
            <InputLabel sx={{ fontSize: "13px" }}>API Key</InputLabel>
            <Stack direction="row" spacing={2}>
              <Typography>{shortenString(apiKey)}</Typography>
              <IconButton
                variant="outlined"
                size="small"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                sx={{ p: 0, width: "10px" }}
                onClick={handleCopyApiKey}
              >
                {!apiKeyCopied ? (
                  <FileCopyRounded fontSize="small" />
                ) : (
                  <CheckRounded fontSize="small" />
                )}
              </IconButton>
            </Stack>
          </>
        );
      case 1:
        return (
          <Typography variant="body2">
            Install the{" "}
            <a
              href="https://zapier.com/apps/dittobot/integrations"
              target="_blank"
              rel="noopener noreferrer"
            >
              Diddo Integration
            </a>{" "}
            and create a new Zap.
          </Typography>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Paper sx={{ p: 3, bgcolor: "transparent", my: 3 }}>
                {getStepContent(activeStep)}
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        <Button
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={handleNext}
        >
          {activeStep >= steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default ZapierModal;
