import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../../assets/brand/logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Container, Tab, Button, Menu, MenuItem } from "@mui/material";
import { MenuRounded } from "@mui/icons-material";

const loggedInTabs = [
  { name: "Pricing", path: "/pricing" },
  // { name: "Affiliate", path: "https://diddo.getrewardful.com/signup" },
  // { name: "Blog", path: "/blog" },
  { name: "My Chatbots", path: "/chatbots" },
];

const guestTabs = [
  { name: "Pricing", path: "/pricing" },
  // { name: "Affiliate", path: "https://diddo.getrewardful.com/signup" },
  // { name: "Blog", path: "/blog" },
];

function TopNavBar() {
  const navigate = useNavigate();
  const { authState } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    if (path.includes("http")) {
      window.open(path, "_blank");
    } else {
      navigate(path);
      handleMenuClose();
    }
  };

  return (
    <Container maxWidth="md" sx={{ pb: 2, pt: 1 }}>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          border: "none",
          px: 2,
          background:
            "linear-gradient(rgba(20, 20, 20, 1) 0%,rgba(14, 14, 14, 1) 100%)",
          borderRadius: "50px",
          borderBottomWidth: ".2px",
          borderColor: "rgba(255, 255, 255, 0.2)",
          borderLeftWidth: "1px",
          borderRightWidth: "1px",
          borderStyle: "solid",
          borderTopWidth: "1px",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "unset !important",
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
            }}
          >
            <img
              className="jump"
              src={logo}
              alt="diddo-logo"
              width={"40px"}
              style={{ cursor: "pointer", padding: "10px" }}
              onClick={() => navigate("/")}
            />
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                px: 0.25,
                display: { xs: "flex", md: "flex" },
                fontWeight: 700,
                textDecoration: "none",
                cursor: "pointer",
                fontSize: "1.125rem",
              }}
              onClick={() => navigate("/")}
            >
              Diddo
            </Typography>
          </Box>
          {/* Mobile View */}
          <Box
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuRounded sx={{ color: "white" }} />
          </Box>

          {/* Dropdown Menu for Mobile */}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {(authState.isAuth ? loggedInTabs : guestTabs).map(
              ({ name, path }) => (
                <MenuItem key={name} onClick={() => handleNavigate(path)}>
                  {name}
                </MenuItem>
              )
            )}
            {!authState.isAuth && (
              <MenuItem onClick={() => handleNavigate("/signin")}>
                <Tab
                  disableelevation="true"
                  disablefocusripple="true"
                  disableRipple
                  disableTouchRipple
                  label="Sign In"
                  sx={{
                    md: { display: "none" },
                    fontSize: "1rem",
                    fontWeight: 500,
                    alignItems: "flex-start",
                  }}
                />
              </MenuItem>
            )}
            {!authState.isAuth && (
              <MenuItem onClick={() => handleNavigate("/signup")}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disableRipple
                  disableelevation="true"
                  disablefocusripple="true"
                  disableTouchRipple
                  sx={{
                    md: { display: "none" },
                    p: "8px 16px",
                    height: "40px",
                    fontWeight: 500,
                  }}
                >
                  Build Your Bot →
                </Button>
              </MenuItem>
            )}
            {authState.isAuth && (
              <MenuItem onClick={() => handleNavigate("/account")}>
                <Button
                  variant="contained"
                  // color="secondary"
                  size="small"
                  disableRipple
                  disableelevation="true"
                  disablefocusripple="true"
                  disableTouchRipple
                  sx={{
                    md: { display: "none" },
                    p: "8px 16px",
                    height: "40px",
                    fontWeight: 500,
                  }}
                >
                  Account
                </Button>
              </MenuItem>
            )}
          </Menu>

          {/* Desktop View */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              // justifyContent: "center",
            }}
          >
            {(authState.isAuth ? loggedInTabs : guestTabs).map(
              ({ name, path }) => (
                <Tab
                  key={name}
                  label={name}
                  disableelevation="true"
                  disablefocusripple="true"
                  disableRipple
                  disableTouchRipple
                  onClick={() => handleNavigate(path)}
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 400,
                    color: "white",
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              )
            )}
          </Box>

          {/* Account/Sign In options based on authentication status */}
          {authState.isAuth ? (
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <Tab
                disableelevation="true"
                disablefocusripple="true"
                disableRipple
                disableTouchRipple
                label="Account"
                sx={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => handleNavigate("/account")}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
                flex: 1,
                alignItems: "center",
              }}
            >
              <Tab
                disableelevation="true"
                disablefocusripple="true"
                disableRipple
                disableTouchRipple
                label="Sign In"
                sx={{
                  fontSize: "1rem",
                  fontWeight: 400,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={() => navigate("/signin")}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                // sx={{ p: "8px 16px", height: "40px", fontWeight: 500 }}
                onClick={() => navigate("/signup")}
              >
                Build Your Bot →
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Container>
  );
}
export default TopNavBar;
