import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  CircularProgress,
} from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { SnackbarContext } from "../../../contexts/SnackbarContext";

export default function CharacterCounts({ handleClick, changed, isSaving }) {
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, subscriptionSettings } = useContext(ChatbotContext);
  const { maxCharacters } = subscriptionSettings || {};
  const {
    files,
    filesCharacterCount,
    textCharacterCount,
    questionsCharacterCount,
    websitesCharacterCount,
    audioCharacterCount,
  } = chatbotData || {};

  const handleRetrain = () => {
    if (
      textCharacterCount + questionsCharacterCount + filesCharacterCount >
      maxCharacters
    ) {
      showAlert(`You have reached ${maxCharacters} max characters`, "error");
      return;
    }
    handleClick();
  };

  if (!chatbotData) {
    return null;
  }

  return (
    <Paper sx={{ p: 3, my: 5 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Includes Sources:
      </Typography>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body2">
            Text: <br />
            {new Intl.NumberFormat("en-US").format(
              textCharacterCount || 0
            )}{" "}
            characters
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          sx={{ borderLeft: "1px solid lightgray" }}
        >
          <Typography variant="body2">
            Files ({files?.length || 0} files): <br />
            {new Intl.NumberFormat("en-US").format(
              filesCharacterCount || 0
            )}{" "}
            characters
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          sx={{ borderLeft: "1px solid lightgray" }}
        >
          <Typography variant="body2">
            Websites: <br />
            {new Intl.NumberFormat("en-US").format(
              websitesCharacterCount || 0
            )}{" "}
            characters
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          sx={{ borderLeft: "1px solid lightgray" }}
        >
          <Typography variant="body2">
            Q&A: <br />
            {new Intl.NumberFormat("en-US").format(
              questionsCharacterCount || 0
            )}{" "}
            characters
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          sx={{ borderLeft: "1px solid lightgray" }}
        >
          <Typography variant="body2">
            Audio: <br />
            {new Intl.NumberFormat("en-US").format(
              audioCharacterCount || 0
            )}{" "}
            characters
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Total detected characters:{" "}
            <b>
              {new Intl.NumberFormat("en-US").format(
                (textCharacterCount || 0) +
                  (questionsCharacterCount || 0) +
                  (filesCharacterCount || 0) +
                  (websitesCharacterCount || 0) +
                  (audioCharacterCount || 0)
              )}
            </b>{" "}
            / {new Intl.NumberFormat("en-US").format(maxCharacters)} character
            limit
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ width: "200px", mt: 1 }}
            onClick={handleRetrain}
            disabled={!changed || isSaving}
            endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
          >
            Retrain Chatbot
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
