// router
import { createBrowserRouter } from "react-router-dom";
import Root, { loader as rootLoader } from "./routes/root";

// pages
import ErrorPage from "./pages/error";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import Main from "./pages/main";
import ForgotPassword from "./pages/forgot-password";
import Bot from "./pages/main/Bot";
import AccountSettings from "./pages/account";
import Pricing from "./pages/pricing";
import About from "./pages/about";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Blog from "./pages/blog";
import BlogPost from "./pages/blog/BlogPost";
// import Solana from "./pages/solana";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      {
        path: "",
        element: <About />,
      },
      {
        path: "/chatbots",
        element: <Main />,
      },
      {
        path: "/chatbots/:chatbotId/:tab",
        element: <Bot />,
      },
      {
        path: "/account",
        element: <AccountSettings />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/signup",
        element: <RegisterPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:blogName",
        element: <BlogPost />,
      },
      {
        path: "/",
        element: <About />,
      },
      // {
      // path: "/solana",
      // element: <Solana />,
      // },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "*",
        element: <Main />,
      },
    ],
  },
]);
