import React, { useState, useContext } from "react";
import {
  TextField,
  IconButton,
  Stack,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { useCSVReader } from "react-papaparse";
import { AuthContext } from "../../../contexts/AuthContext";
import CharacterCounts from "./CharacterCounts";

const QuestionsSource = () => {
  const { CSVReader } = useCSVReader();
  const { tokenId } = useContext(AuthContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { showAlert } = useContext(SnackbarContext);
  const { userId, questionsSource, chatbotId } = chatbotData;
  const [questions, setQuestions] = useState(questionsSource || []);
  const [newQuestion, setNewQuestion] = useState({ question: "", answer: "" });
  const [changed, setChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateQuestions = debounce(async () => {
    setIsSaving(true);
    const filteredQuestions = questions.filter(
      (question) => question.question !== "" && question.answer !== ""
    );

    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/questions`,
        {
          newQuestionsSource: filteredQuestions,
          chatbotId,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        questionsSource: filteredQuestions,
        questionsCharacterCount: filteredQuestions.reduce(
          (acc, curr) => acc + curr.question.length + curr.answer.length,
          0
        ),
      });
      showAlert("Q&A updated successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error updating questions", "error");
    }
    setIsSaving(false);
  }, 1000);

  const handleChange = (index, type, event) => {
    const newQuestionsSource = [...questions];
    newQuestionsSource[index][type] = event.target.value;
    setQuestions(newQuestionsSource);
    setChatbotData({ ...chatbotData, questionsSource: newQuestionsSource });
    setChanged(true);
  };

  const handleAddField = () => {
    if (newQuestion.question === "" || newQuestion.answer === "") return;

    setQuestions([...questions, newQuestion]);
    setNewQuestion({ question: "", answer: "" });
    setChatbotData({
      ...chatbotData,
      questionsSource: [...questions, newQuestion],
    });
    setChanged(true);
  };

  const handleRemoveField = (index) => {
    const newQuestionsSource = [...questions];
    newQuestionsSource.splice(index, 1);
    setQuestions(newQuestionsSource);
    setChatbotData({ ...chatbotData, questionsSource: newQuestionsSource });
    setChanged(true);
  };

  const handleOnUploadAccepted = (results) => {
    setChanged(true);
    const parsedQuestionsAndAnswers = results.data.map((row) => {
      const question = row[0]; // Assuming the first column is the question
      const answer = row[1]; // Assuming the second column is the answer

      return { question, answer };
    });

    // Append the parsed questions and answers to existing ones
    setQuestions([...questions, ...parsedQuestionsAndAnswers]);
    setChatbotData({
      ...chatbotData,
      questionsSource: [...questions, ...parsedQuestionsAndAnswers],
    });
  };

  return (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <CSVReader onUploadAccepted={handleOnUploadAccepted}>
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            // getRemoveFileProps,
          }) => (
            <>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                InputProps={{
                  autoComplete: "off",
                }}
                // fullWidth
                disableRipple
                disableelevation="true"
                disablefocusripple
                disableTouchRipple
                sx={{ mb: 2, mt: 1 }}
                {...getRootProps()}
              >
                Upload CSV
              </Button>
              <div>{acceptedFile && acceptedFile.name}</div>
              {/* <Button
                variant="outlined"
                color="primary"
                size="small"
                autoComplete='off'
                fullWidth
                disableRipple
                disableelevation="true"
                disablefocusripple
                disableTouchRipple
                sx={{ mb: 1 }}
                {...getRemoveFileProps()}
              >
                Remove File
              </Button> */}
              <ProgressBar />
            </>
          )}
        </CSVReader>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {questionsSource?.map((questionSource, index) => (
          <Stack key={index} direction="column" spacing={2} sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <TextField
                size="small"
                InputProps={{
                  autoComplete: "off",
                }}
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Question"
                value={questionSource.question}
                onChange={(event) => handleChange(index, "question", event)}
              />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                InputProps={{
                  autoComplete: "off",
                }}
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                onClick={() => handleRemoveField(index)}
                sx={{
                  "& .MuiButton-endIcon": {
                    ml: 0,
                  },
                }}
                endIcon={
                  <RemoveRounded
                    fontSize="small"
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                }
                disabled={isSaving}
              />
            </Stack>
            <TextField
              size="small"
              InputProps={{
                autoComplete: "off",
              }}
              fullWidth
              type="text"
              multiline
              rows={4}
              variant="outlined"
              placeholder="Answer"
              value={questionSource.answer}
              onChange={(event) => handleChange(index, "answer", event)}
            />
          </Stack>
        ))}
        <Divider sx={{ my: 4 }} />
        <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
          <TextField
            size="small"
            InputProps={{
              autoComplete: "off",
            }}
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Question"
            value={newQuestion.question}
            onChange={(event) =>
              setNewQuestion({ ...newQuestion, question: event.target.value })
            }
          />
          <TextField
            size="small"
            InputProps={{
              autoComplete: "off",
            }}
            fullWidth
            type="text"
            multiline
            rows={4}
            variant="outlined"
            placeholder="Answer"
            value={newQuestion.answer}
            onChange={(event) =>
              setNewQuestion({ ...newQuestion, answer: event.target.value })
            }
          />
          <IconButton
            disablefocusripple="true"
            disableRipple
            disableTouchRipple
            variant="outlined"
            color="primary"
            onClick={handleAddField}
            sx={{ outline: "none", p: 0 }}
          >
            <AddRounded
              fontSize="small"
              InputProps={{
                autoComplete: "off",
              }}
            />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <CharacterCounts
          handleClick={updateQuestions}
          isSaving={isSaving}
          changed={changed}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionsSource;
