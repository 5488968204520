import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Hidden,
  IconButton,
  Menu,
  Stack,
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import TextSource from "./Sources";
import Conversations from "./Conversations";
import LinksMap from "./Links";
import Contacts from "./Contacts";
import Integrations from "./Integrations";
import Chatbot from "./Chatbot";
import { ArrowDropDownRounded } from "@mui/icons-material";

const MAIN_TABS = [
  "Chatbot",
  "Sources",
  "Conversations",
  "Links",
  "Integrations",
  "Contacts",
];

function TabPanel({ children, tabValue, title }) {
  return (
    <div role="tabpanel" hidden={tabValue !== title}>
      {tabValue === title && <Box>{children}</Box>}
    </div>
  );
}

export default function Bot() {
  const { chatbotId, tab } = useParams();
  const {
    chatbots,
    chatbotData,
    setChatbotData,
    isLoadingChatbotData,
    setIsLoadingChatbotData,
  } = useContext(ChatbotContext);
  const { chatbotName } = chatbotData || {
    chatbotName: "",
  };
  const navigate = useNavigate();
  const anchorRef = useRef(null);

  const [tabValue, setTabValue] = useState(tab || "Chatbot");
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (tab) {
      setTabValue(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (chatbotId && chatbots.length > 0) {
      const chatbot = chatbots.find(
        (chatbot) => chatbot.chatbotId === chatbotId
      );
      if (chatbot) {
        setChatbotData(chatbot);
        setIsLoadingChatbotData(false);
      } else {
        navigate("/chatbots");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatbotId, chatbots]);

  const TabsComponent = () => {
    const handleChange = (event) => {
      const value = event.target.value;
      if (value === "Chatbots") {
        navigate("/chatbots");
        setChatbotData(null);
      } else {
        navigate(`/chatbots/${chatbotId}/${value}`);
        setTabValue(value);
      }
    };

    return (
      <Stack
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        width="100%"
        sx={{
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          pr: 2,
        }}
      >
        <Button
          disablefocusripple="true"
          disableRipple
          disableTouchRipple
          onClick={() => {
            navigate("/chatbots");
            setChatbotData(null);
          }}
          sx={{
            justifyContent: "flex-start",
            mb: 1.4,
            color: "#F9FAFB",
          }}
          startIcon={<Box>←</Box>}
        >
          Chatbots
        </Button>
        <FormControl sx={{ mb: 3 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tabValue}
            onChange={handleChange}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
              },
              color: "#F9FAFB",
            }}
            size="small"
            variant="outlined"
            disableUnderline
            IconComponent={() => (
              <ArrowDropDownRounded sx={{ color: "#F9FAFB", mr: 1 }} />
            )}
          >
            {MAIN_TABS.map((tab) => (
              <MenuItem
                key={tab}
                value={tab}
                sx={{
                  color: "#F9FAFB",
                  pr: 0,
                }}
              >
                {tab}
              </MenuItem>
            ))}
          </Select>
          {MAIN_TABS.map((tab) => (
            <MenuItem
              key={tab}
              value={tab}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                },
                color: "#F9FAFB",
                pr: 0,
              }}
              onClick={() => {
                navigate(`/chatbots/${chatbotId}/${tab}`);
                setTabValue(tab);
                // close mobile menu
                setMobileOpen(false);
              }}
            >
              {tab}
            </MenuItem>
          ))}
        </FormControl>
      </Stack>
    );
  };

  if (isLoadingChatbotData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold", mb: 3 }} gutterBottom>
        {chatbotName}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ mt: "0px !important" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ mt: "0px !important" }}
          >
            <Hidden mdUp>
              <Stack
                direction="row"
                alignItems="center"
                onClick={handleDrawerToggle}
                ref={anchorRef}
              >
                <Typography variant="subtitle1">{tabValue}</Typography>
                <IconButton color="inherit" aria-label="open drawer">
                  <ArrowDropDownRounded />
                </IconButton>
              </Stack>
              <Menu
                open={mobileOpen}
                onClose={handleDrawerToggle}
                anchorEl={anchorRef.current} // The menu will be positioned relative to this element
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {<TabsComponent />}
              </Menu>
            </Hidden>
            <Hidden mdDown>{<TabsComponent />}</Hidden>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ mt: "0px !important" }}
          maxWidth="100%"
        >
          <TabPanel tabValue={tabValue} title={"Chatbot"}>
            {tabValue === "Chatbot" && <Chatbot />}
          </TabPanel>
          <TabPanel tabValue={tabValue} title={"Sources"}>
            {tabValue === "Sources" && <TextSource />}
          </TabPanel>
          <TabPanel tabValue={tabValue} title={"Conversations"}>
            {tabValue === "Conversations" && <Conversations />}
          </TabPanel>
          <TabPanel tabValue={tabValue} title={"Links"}>
            {tabValue === "Links" && <LinksMap />}
          </TabPanel>
          <TabPanel tabValue={tabValue} title={"Integrations"}>
            {tabValue === "Integrations" && <Integrations />}
          </TabPanel>
          <TabPanel tabValue={tabValue} title={"Contacts"}>
            {tabValue === "Contacts" && <Contacts />}
          </TabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
