import { useContext, useState } from "react";
import {
  Paper,
  FormControlLabel,
  FormGroup,
  Switch,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { AuthContext } from "../../../contexts/AuthContext";

export default function ContactSettings() {
  const { tokenId } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, subscriptionSettings } = useContext(ChatbotContext);
  const { canCaptureContacts } = subscriptionSettings || {};
  const { userId, chatbotId, contactCollectionSettings, isCollectingContacts } =
    chatbotData || {};

  const [settings, setSettings] = useState({
    isCollectingContacts: isCollectingContacts,
    collectName: contactCollectionSettings?.collectName,
    collectEmail: contactCollectionSettings?.collectEmail,
    collectPhone: contactCollectionSettings?.collectPhone,
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleSettingsChange = async (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    const newSettings = {
      ...settings,
      [name]: checked,
    };

    setSettings(newSettings);
  };

  const handleSaveChanges = debounce(async () => {
    if (!canCaptureContacts) {
      showAlert("Upgrade to capture contacts", "error");
      return;
    }
    setIsSaving(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/contacts/settings`,
        {
          settings,
          userId,
          chatbotId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      showAlert("Settings saved successfully");
    } catch (error) {
      console.error(error);
      showAlert("Error saving settings", "error");
    }
    setIsSaving(false);
  }, 1000);

  return (
    <>
      <Paper
        sx={{
          p: 3,
          bgcolor: "transparent",
          mb: 3,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={canCaptureContacts && settings?.isCollectingContacts}
                onChange={handleSettingsChange}
                name="isCollectingContacts"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disabled={!canCaptureContacts}
              />
            }
            label="Collect Contacts"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={settings?.collectName}
                onChange={handleSettingsChange}
                name="collectName"
                disabled={!settings?.isCollectingContacts}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            }
            label="Collect Names"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={settings?.collectEmail}
                onChange={handleSettingsChange}
                name="collectEmail"
                disabled={!settings?.isCollectingContacts}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            }
            label="Collect Emails"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="secondary"
                checked={settings?.collectPhone}
                onChange={handleSettingsChange}
                name="collectPhone"
                disabled={!settings?.isCollectingContacts}
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            }
            label="Collect Phone Numbers"
          />
        </FormGroup>
        <Button
          onClick={handleSaveChanges}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mb: 1, mt: 3 }}
          disabled={isSaving || !canCaptureContacts}
          endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
        >
          Save
        </Button>
      </Paper>
    </>
  );
}
