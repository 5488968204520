import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  updateEmail,
} from "firebase/auth";
import firebase from "../firebase";
import { SnackbarContext } from "./SnackbarContext";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { showAlert } = useContext(SnackbarContext);
  const [authState, setAuthState] = useState({
    isAuth: false,
    isLoading: true,
    tokenId: "",
    userId: "",
    email: "",
    subscriptionSettings: {},
  });

  useEffect(() => {
    onAuthStateChanged(firebase.auth, async (user) => {
      if (user) {
        const newState = {
          isAuth: true,
          isLoading: false,
          tokenId: await user.getIdToken(),
          userId: user.uid,
          email: user.email,
        };
        setAuthState(newState);
        localStorage.setItem("authState", JSON.stringify(newState));
      } else if (localStorage.getItem("solanaAuthState")) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuth: true,
          isLoading: false,
          tokenId: localStorage.getItem("solanaAuthState"),
          userId: localStorage.getItem("solanaPublicKey"),
          email: "",
        }));
      } else {
        const newState = {
          isAuth: false,
          isLoading: false,
          tokenId: "",
          userId: "",
          email: "",
          subscriptionSettings: {},
        };
        setAuthState(newState);
        localStorage.removeItem("authState");
      }
    });
  }, []);

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/user/logout`
      );
      setAuthState({
        isAuth: false,
        isLoading: false,
        userId: "",
        email: "",
        subscriptionSettings: {},
        tokenId: "",
      });
      localStorage.removeItem("authState");
      localStorage.removeItem("solanaAuthState");
      localStorage.removeItem("solanaPublicKey");
      console.log("User signed out successfully");
      showAlert("You have been logged out successfully!");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccountDatabase();
      await deleteAccountFirebase();
      setAuthState({
        isAuth: false,
        isLoading: false,
        userId: "",
        email: "",
        subscriptionSettings: {},
        tokenId: "",
      });
      localStorage.removeItem("authState");
    } catch (error) {
      console.error(error);
      showAlert("Error deleting account", "error");
    }
  };

  const deleteAccountFirebase = async () => {
    const auth = getAuth();
    try {
      await auth.currentUser.delete();
    } catch (error) {
      console.error(error);
      showAlert("Error deleting account from Firebase", "error");
      return false;
    }
  };

  const deleteAccountDatabase = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_VERCEL_API_BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${authState.tokenId}`,
        },
        data: {
          userId: authState.userId,
        },
      });
      showAlert("Account deleted successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error deleting account from database", "error");
    }
  };

  const handleChangeEmail = async (newEmail) => {
    try {
      await changeEmailFirebase(newEmail);
      await changeEmailDatabase(newEmail);
    } catch (error) {
      console.error(error);
      showAlert("Error saving email", "error");
    }
  };

  const changeEmailFirebase = async (newEmail) => {
    const auth = getAuth();
    try {
      await updateEmail(auth.currentUser, newEmail);
    } catch (error) {
      console.error(error);
      showAlert("Error saving email to Firebase", "error");
      return false;
    }
  };

  const changeEmailDatabase = async (newEmail) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/user/email`,
        {
          userEmail: newEmail,
          userId: authState.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${authState.tokenId}`,
          },
        }
      );
      showAlert("Email saved successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error saving email to database", "error");
    }
  };

  if (authState.isLoading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        handleLogout,
        tokenId: authState.tokenId,
        email: authState.email,
        userId: authState.userId,
        handleChangeEmail,
        handleDeleteAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
