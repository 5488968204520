import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";

export default function EmbedScriptDialog({ open, setOpen }) {
  const { chatbotData } = useContext(ChatbotContext);
  const { chatbotId } = chatbotData || {
    chatbotId: "",
  };

  const iFrameTag = `<iframe
  src="https://demo.diddo.chat/chatbot/${chatbotId}"
  id="${chatbotId}"
  style="width: 350px; height: 500px; border: none;"
  ></iframe>`;

  const scriptTag = `<script>
  window.dittobotConfig = {
      chatbotId: "${chatbotId}"
  };
  </script>
  <script
  src="https://demo.diddo.chat/widget/main.js"
  id="${chatbotId}"
  defer
  ></script>`;

  const [tab, setTab] = useState(0);
  const [copied, setCopied] = useState(false);
  const [copiedIFrame, setCopiedIFrame] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(scriptTag);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleCopyIFrame = () => {
    navigator.clipboard.writeText(iFrameTag);
    setCopiedIFrame(true);
    setTimeout(() => {
      setCopiedIFrame(false);
    }, 1000);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          padding: 0,
        },
      }}
    >
      <DialogTitle>{"Embed On Website"}</DialogTitle>
      <DialogContent>
        <Tabs
          value={tab}
          onChange={(e, val) => setTab(val)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label="Chat Bubble"
            sx={{ textTransform: "none" }}
            disablefocusripple="true"
            disableRipple
            disableTouchRipple
            disableElevation
          />
          <Tab
            label="iFrame"
            sx={{ textTransform: "none" }}
            disablefocusripple="true"
            disableRipple
            disableTouchRipple
            disableElevation
          />
        </Tabs>
      </DialogContent>
      {tab === 0 ? (
        <DialogContent>
          <DialogContentText>
            To add a chat bubble to the bottom right of your website, add this
            script tag to your html code
          </DialogContentText>
          <Paper sx={{ my: 2, px: 2 }}>
            <Box
              component="pre"
              style={{
                overflowY: "auto",
                whiteSpace: "pre-wrap",
                p: 2,
              }}
            >
              {scriptTag}
            </Box>
          </Paper>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ mt: 1 }}
            endIcon={!copied && <FileCopyIcon fontSize="small" />}
            onClick={handleCopy}
          >
            {copied ? "Copied!" : "Copy Script"}
          </Button>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            To add this chatbot anywhere on your website, add this iframe to
            your HTML code.
          </DialogContentText>
          <Paper sx={{ my: 2, px: 2 }}>
            <Box
              component="pre"
              style={{
                overflowY: "auto",
                whiteSpace: "pre-wrap",
                p: 2,
              }}
            >
              {iFrameTag}
            </Box>
          </Paper>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ mt: 1, textTransform: "none" }}
            endIcon={!copied && <FileCopyIcon fontSize="small" />}
            onClick={handleCopyIFrame}
          >
            {copiedIFrame ? "Copied!" : "Copy iFrame"}
          </Button>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
