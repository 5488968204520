/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";

const isMobile = window.innerWidth <= 768;

const BotMessage = ({ message }) => {
  return (
    <ListItem>
      <ListItemText
        primary={message}
        style={{
          flex: "unset",
          backgroundColor: "#e9e9e9",
          color: "black",
          borderRadius: 6,
          padding: "14px",
        }}
      />
    </ListItem>
  );
};

const UserMessage = ({ message, primaryColor }) => (
  <ListItem style={{ display: "flex", justifyContent: "flex-end" }}>
    <ListItemText
      primary={message}
      style={{
        flex: "unset",
        backgroundColor: "#8a6bea",
        color: "white",
        borderRadius: 6,
        padding: "14px",
      }}
    />
  </ListItem>
);

const ConversationWidget = ({ conversation }) => {
  const { chatbotData } = useContext(ChatbotContext);
  const { primaryColor, darkMode } = chatbotData;
  const [chatHistory, setChatHistory] = useState([]);

  useEffect(() => {
    setChatHistory(
      conversation.messages.map((message) => ({
        message: message.text,
        sender: message.sender === "User" ? "User" : "Bot",
      }))
    );
  }, [conversation]);

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobile ? window.innerHeight : "750px",
        bgcolor: "#F7F7F7",
        borderRadius: isMobile ? 0 : 3,
        p: isMobile ? 0 : 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: 1,
        borderColor: "#d3d3d3",
      }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          "-webkit-overflow-scrolling": "touch",
          flexGrow: 1,
          height: "calc(100% - 80px)",
          "::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none" /* IE and Edge */,
          "scrollbar-width": "none",
        }}
      >
        <List>
          {chatHistory.map((chat, index) =>
            chat.sender === "User" ? (
              <UserMessage
                key={index}
                message={chat.message}
                primaryColor={primaryColor}
              />
            ) : (
              <BotMessage
                key={index}
                message={chat.message}
                darkMode={darkMode}
              />
            )
          )}
        </List>
      </Box>
    </Box>
  );
};

export default ConversationWidget;
