import React from "react";
import { Box, Container } from "@mui/material";
import HeaderText from "../../components/layout/HeaderText";
import FeatureCard from "./FeatureCard";
import { FaGlobe, FaPaintBrush, FaRocket } from "react-icons/fa";
import Icon from "../../components/layout/Icon";
import {
  Article,
  Contacts,
  EditNote,
  Security,
  SettingsSuggest,
  SmartToy,
} from "@mui/icons-material";

const features = [
  {
    title: "Efficient AI Training",
    description:
      "Simplify AI training by importing documents, scraping websites, or uploading audio files. Let Diddo handle the complexities.",
    icon: <Icon icon={<SmartToy size={20} />} />,
  },
  {
    title: "Flexible Deployment Options",
    description:
      "Integrate your AI agent using chat interfaces, iframes, or full-screen options. No coding required.",
    icon: <Icon icon={<FaRocket size={20} />} />,
  },
  {
    title: "Multilingual Support",
    description:
      "Your AI agent automatically detects and communicates in users' preferred languages.",
    icon: <Icon icon={<FaGlobe size={20} />} />,
  },
  {
    title: "Extensive Customization",
    description:
      "Tailor your AI agent to embody your brand's personality and expertise.",
    icon: <Icon icon={<FaPaintBrush size={20} />} />,
  },
  {
    title: "Automated Workflow Integration",
    description:
      "Streamline business processes with customizable workflows and integrations.",
    icon: <Icon icon={<SettingsSuggest size={20} />} />,
  },
  {
    title: "Advanced Reasoning Capabilities",
    description:
      "Equip your AI agent to tackle complex queries and provide insightful solutions.",
    icon: <Icon icon={<Article size={20} />} />,
  },
  {
    title: "Enterprise-Grade Security",
    description:
      "Keep your data and AI agent's knowledge secure and confidential.",
    icon: <Icon icon={<Security size={20} />} />,
  },
  {
    title: "Behavioral Adaptation",
    description:
      "Enable your AI agent to learn from interactions and adapt its responses.",
    icon: <Icon icon={<EditNote size={20} />} />,
  },
  {
    title: "Seamless Integrations",
    description:
      "Integrate with over 3,000 applications using Diddo's API and Zapier integration.",
    icon: <Icon icon={<Contacts size={20} />} />,
  },
  // ... (keep the remaining features if needed)
];

export default function FeaturesHome() {
  return (
    <Container maxWidth="md" py={3}>
      <Box textAlign="center" pb={4}>
        <Box
          sx={{
            width: {
              xs: "100%",
              md: "75%",
            },
            margin: "0 auto",
          }}
        >
          <iframe
            title="Diddo Live Demo"
            src="https://demo.diddo.chat/chatbot/7f207868-3d14-4692-92d4-fedf7547c836"
            id="7f207868-3d14-4692-92d4-fedf7547c836"
            width={"100%"}
            height={600}
            style={{
              margin: "0 auto",
              borderRadius: 10,
              border: "1px solid rgb(255 255 255 / 30%)",
              marginTop: 40,
              marginBottom: 40,
            }}
          ></iframe>
        </Box>
      </Box>

      <HeaderText
        title="Features"
        topLine="Everything you need"
        bottomLine="all in one place"
      />
      <FeatureCard features={features} />
    </Container>
  );
}
