import React, { useState } from "react";
import SecondaryTabs from "../../../components/tabs/SecondaryTabs";
import TextSource from "./TextSource";
import QuestionsSource from "./QuestionsSource";
import FilesSource from "./FilesSource";
import WebsitesSource from "./WebsitesSource";
import AudioSource from "./AudioSource";
import Instructions from "../../../components/alerts/Instructions";
import GitHubSource from "./GitHubSource";
// import YouTubeSource from "./YouTubeSource";
// import BlockchainSource from "./BlockchainSource";

const description = `Specify data sources for training your chatbot. Changes can be saved by clicking on the "Retrain Chatbot" button.`;

export default function Sources() {
  const [tabValue, setTabValue] = useState("Websites");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Instructions description={description} id="sources" />
      <SecondaryTabs
        tabs={[
          "Websites",
          "Documents",
          // "YouTube",
          "Text",
          "Q&A",
          "Audio",
          "GitHub",
          // "Blockchain",
        ]}
        tabValue={tabValue}
        handleChange={handleTabChange}
      />
      {tabValue === "Documents" && <FilesSource />}
      {tabValue === "Text" && <TextSource />}
      {/* {tabValue === "YouTube" && <YouTubeSource />} */}
      {tabValue === "Q&A" && <QuestionsSource />}
      {tabValue === "Websites" && <WebsitesSource />}
      {tabValue === "Audio" && <AudioSource />}
      {tabValue === "GitHub" && <GitHubSource />}
      {/* {tabValue === "Blockchain" && <BlockchainSource />} */}
    </div>
  );
}
