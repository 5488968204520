import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import UpgradeButton from "../../../../components/buttons/UpgradeButton";
import { FileCopyOutlined } from "@mui/icons-material";

const CustomDomainManager = () => {
  const { chatbotData, subscriptionSettings, setChatbotData } =
    useContext(ChatbotContext);
  const { chatbotId, customDomains } = chatbotData;
  const { canAddCustomDomain } = subscriptionSettings || {
    canAddCustomDomain: false,
  };
  const { tokenId } = useContext(AuthContext);
  const [domainToAdd, setDomainToAdd] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [records, setRecords] = useState([]);

  const handleLinkDomain = debounce(async () => {
    if (!canAddCustomDomain) return;
    setIsSaving(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot/custom-domain`,
        {
          chatbotId,
          customDomain: domainToAdd,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        customDomains: [...customDomains, domainToAdd],
      });
      setIsSaving(false);
    } catch (error) {
      console.log("Error linking domain:", error);
      setIsSaving(false);
    }
  }, 300);

  const handleUnlinkDomain = debounce(async (domain) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot/custom-domain`,
        {
          data: {
            chatbotId,
            customDomain: domain,
          },
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        customDomains: customDomains.filter((d) => d !== domain),
      });
    } catch (error) {
      console.log("Error linking domain:", error);
    }
  }, 300);

  const getDomainStatus = useCallback(
    async (domain) => {
      console.log("Getting domain status for:", domain);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot/custom-domain/${chatbotId}/${domain}`,
          {
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        );

        setIsSaving(false);
        return data;
      } catch (error) {
        console.log("Error getting domain:", error);
        setIsSaving(false);
      }
    },
    [chatbotId, tokenId]
  );

  useEffect(() => {
    // if (customDomains.length === 0) return;
    console.log("Getting domain status for:", customDomains);
    const allRecords = customDomains?.map((domain) => {
      return getDomainStatus(domain);
    });
    Promise.all(allRecords).then((values) => {
      console.log("All records:", values);
      setRecords(values);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDomains]);

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Stack direction="row" spacing={2}>
          <TextField
            InputProps={{
              autoComplete: "off",
            }}
            size="small"
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Domain"
            value={domainToAdd}
            name="domain"
            onChange={(e) => setDomainToAdd(e.target.value)}
          />
          {canAddCustomDomain ? (
            <Button
              onClick={handleLinkDomain}
              variant="contained"
              color="secondary"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              disabled={!domainToAdd || isSaving}
              endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
            >
              Save
            </Button>
          ) : (
            <UpgradeButton />
          )}
        </Stack>
      </Paper>
      {records?.map((record, i) => {
        const {
          name,
          configured,
          verified,
          verification,
          verificationResponse,
        } = record || {};

        console.log("Record:", record);
        const isSubdomain = name?.split(".").length > 2;
        const recordType = isSubdomain ? "CNAME" : "A";
        const nameField = isSubdomain ? name?.split(".")[0] : "@";
        const valueField = isSubdomain ? "demo.diddo.chat." : "76.76.21.21";
        return (
          <Paper
            key={i}
            sx={{
              p: 3,
              bgcolor: "transparent",
              mb: 3,
            }}
          >
            <Typography variant="h6">{name}</Typography>
            {!configured ? (
              <Typography variant="body2" color="error">
                {verificationResponse?.error?.message || "Not Configured"}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ color: "success.main" }}>
                Verified
              </Typography>
            )}
            <TableContainer component={Paper} sx={{ my: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {verified ? recordType : verification?.[0].type}
                    </TableCell>
                    <TableCell>{verified ? nameField : "_vercel"}</TableCell>
                    <TableCell>
                      {verified ? valueField : verification?.[0].value}
                    </TableCell>
                    <TableCell>
                      <FileCopyOutlined
                        onClick={() =>
                          handleCopy(
                            configured ? valueField : verification?.[0].value
                          )
                        }
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px",
                          float: "right",
                          mr: 2,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="outlined"
              color="error"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              sx={{ color: "error.main" }}
              onClick={() => handleUnlinkDomain(name)}
            >
              Unlink Domain
            </Button>
          </Paper>
        );
      })}
    </>
  );
};

export default CustomDomainManager;
