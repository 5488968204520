import React from "react";
import { Box, Container, Grid } from "@mui/material";
import HeaderText from "../../components/layout/HeaderText";

// const flagImages = Array.from({ length: 24 }, (_, i) =>
//   require(`../../assets/flags/${i + 1}.svg`)
// );

export default function Languages() {
  return (
    <Box my={7}>
      <Container maxWidth="md">
        <HeaderText
          title="Global Reach"
          topLine="Available in"
          bottomLine="100+ languages"
        />
        <Grid container justifyContent="center" spacing={2}>
          {/* {flagImages.map((flag, index) => (
            <Grid item key={index}>
              <img
                src={flag}
                alt={`Flag ${index + 1}`}
                width={55}
                height={55}
              />
            </Grid>
          ))} */}
          <div class="worldmap">
            <svg
              width="100%"
              height={window.innerWidth < 600 ? 300 : 450}
              viewBox="0 0 750 450"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Translate 100+ Languages</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <path
                  d="M379.952,169.646 C377.947,169.646 376.322,171.271 376.322,173.275 C376.322,175.28 377.947,176.905 379.952,176.905 C381.956,176.905 383.581,175.28 383.581,173.275 C383.581,171.271 381.956,169.646 379.952,169.646"
                  id="Fill-1"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,179.063 C407.657,179.063 406.032,180.688 406.032,182.693 C406.032,184.697 407.657,186.322 409.661,186.322 C411.666,186.322 413.291,184.697 413.291,182.693 C413.291,180.688 411.666,179.063 409.661,179.063"
                  id="Fill-2"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,179.063 C377.947,179.063 376.322,180.688 376.322,182.693 C376.322,184.697 377.947,186.322 379.952,186.322 C381.956,186.322 383.581,184.697 383.581,182.693 C383.581,180.688 381.956,179.063 379.952,179.063"
                  id="Fill-3"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,160.229 C397.754,160.229 396.129,161.854 396.129,163.859 C396.129,165.863 397.754,167.488 399.759,167.488 C401.763,167.488 403.388,165.863 403.388,163.859 C403.388,161.854 401.763,160.229 399.759,160.229"
                  id="Fill-4"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,113.144 C397.754,113.144 396.129,114.769 396.129,116.773 C396.129,118.777 397.754,120.403 399.759,120.403 C401.763,120.403 403.388,118.777 403.388,116.773 C403.388,114.769 401.763,113.144 399.759,113.144"
                  id="Fill-5"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,141.395 C397.754,141.395 396.129,143.02 396.129,145.024 C396.129,147.029 397.754,148.654 399.759,148.654 C401.763,148.654 403.388,147.029 403.388,145.024 C403.388,143.02 401.763,141.395 399.759,141.395"
                  id="Fill-6"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,150.812 C397.754,150.812 396.129,152.437 396.129,154.441 C396.129,156.446 397.754,158.071 399.759,158.071 C401.763,158.071 403.388,156.446 403.388,154.441 C403.388,152.437 401.763,150.812 399.759,150.812"
                  id="Fill-7"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,103.727 C377.947,103.727 376.322,105.352 376.322,107.356 C376.322,109.361 377.947,110.986 379.952,110.986 C381.956,110.986 383.581,109.361 383.581,107.356 C383.581,105.352 381.956,103.727 379.952,103.727"
                  id="Fill-8"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,122.561 C377.947,122.561 376.322,124.186 376.322,126.19 C376.322,128.195 377.947,129.82 379.952,129.82 C381.956,129.82 383.581,128.195 383.581,126.19 C383.581,124.186 381.956,122.561 379.952,122.561"
                  id="Fill-9"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,113.144 C377.947,113.144 376.322,114.769 376.322,116.773 C376.322,118.777 377.947,120.403 379.952,120.403 C381.956,120.403 383.581,118.777 383.581,116.773 C383.581,114.769 381.956,113.144 379.952,113.144"
                  id="Fill-10"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,160.229 C377.947,160.229 376.322,161.854 376.322,163.859 C376.322,165.863 377.947,167.488 379.952,167.488 C381.956,167.488 383.581,165.863 383.581,163.859 C383.581,161.854 381.956,160.229 379.952,160.229"
                  id="Fill-11"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,103.727 C397.754,103.727 396.129,105.352 396.129,107.356 C396.129,109.361 397.754,110.986 399.759,110.986 C401.763,110.986 403.388,109.361 403.388,107.356 C403.388,105.352 401.763,103.727 399.759,103.727"
                  id="Fill-12"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,141.395 C377.947,141.395 376.322,143.02 376.322,145.024 C376.322,147.029 377.947,148.654 379.952,148.654 C381.956,148.654 383.581,147.029 383.581,145.024 C383.581,143.02 381.956,141.395 379.952,141.395"
                  id="Fill-13"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,150.812 C377.947,150.812 376.322,152.437 376.322,154.441 C376.322,156.446 377.947,158.071 379.952,158.071 C381.956,158.071 383.581,156.446 383.581,154.441 C383.581,152.437 381.956,150.812 379.952,150.812"
                  id="Fill-14"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,169.646 C397.754,169.646 396.129,171.271 396.129,173.275 C396.129,175.28 397.754,176.905 399.759,176.905 C401.763,176.905 403.388,175.28 403.388,173.275 C403.388,171.271 401.763,169.646 399.759,169.646"
                  id="Fill-15"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M370.048,113.144 C368.044,113.144 366.419,114.769 366.419,116.773 C366.419,118.777 368.044,120.403 370.048,120.403 C372.054,120.403 373.678,118.777 373.678,116.773 C373.678,114.769 372.054,113.144 370.048,113.144"
                  id="Fill-16"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,141.395 C368.044,141.395 366.419,143.02 366.419,145.024 C366.419,147.029 368.044,148.654 370.048,148.654 C372.054,148.654 373.678,147.029 373.678,145.024 C373.678,143.02 372.054,141.395 370.048,141.395"
                  id="Fill-17"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,122.561 C368.044,122.561 366.419,124.186 366.419,126.19 C366.419,128.195 368.044,129.82 370.048,129.82 C372.054,129.82 373.678,128.195 373.678,126.19 C373.678,124.186 372.054,122.561 370.048,122.561"
                  id="Fill-18"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,150.812 C368.044,150.812 366.419,152.437 366.419,154.441 C366.419,156.446 368.044,158.071 370.048,158.071 C372.054,158.071 373.678,156.446 373.678,154.441 C373.678,152.437 372.054,150.812 370.048,150.812"
                  id="Fill-19"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,94.309 C397.754,94.309 396.129,95.935 396.129,97.939 C396.129,99.944 397.754,101.569 399.759,101.569 C401.763,101.569 403.388,99.944 403.388,97.939 C403.388,95.935 401.763,94.309 399.759,94.309"
                  id="Fill-20"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,169.646 C368.044,169.646 366.419,171.271 366.419,173.275 C366.419,175.28 368.044,176.905 370.048,176.905 C372.054,176.905 373.678,175.28 373.678,173.275 C373.678,171.271 372.054,169.646 370.048,169.646"
                  id="Fill-21"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,160.229 C368.044,160.229 366.419,161.854 366.419,163.859 C366.419,165.863 368.044,167.488 370.048,167.488 C372.054,167.488 373.678,165.863 373.678,163.859 C373.678,161.854 372.054,160.229 370.048,160.229"
                  id="Fill-22"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,66.058 C397.754,66.058 396.129,67.683 396.129,69.688 C396.129,71.693 397.754,73.318 399.759,73.318 C401.763,73.318 403.388,71.693 403.388,69.688 C403.388,67.683 401.763,66.058 399.759,66.058"
                  id="Fill-23"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,75.476 C397.754,75.476 396.129,77.1 396.129,79.105 C396.129,81.109 397.754,82.735 399.759,82.735 C401.763,82.735 403.388,81.109 403.388,79.105 C403.388,77.1 401.763,75.476 399.759,75.476"
                  id="Fill-24"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,188.48 C427.464,188.48 425.839,190.106 425.839,192.11 C425.839,194.114 427.464,195.739 429.468,195.739 C431.473,195.739 433.098,194.114 433.098,192.11 C433.098,190.106 431.473,188.48 429.468,188.48"
                  id="Fill-25"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,84.892 C397.754,84.892 396.129,86.517 396.129,88.522 C396.129,90.527 397.754,92.152 399.759,92.152 C401.763,92.152 403.388,90.527 403.388,88.522 C403.388,86.517 401.763,84.892 399.759,84.892"
                  id="Fill-26"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,28.39 C397.754,28.39 396.129,30.016 396.129,32.02 C396.129,34.025 397.754,35.649 399.759,35.649 C401.763,35.649 403.388,34.025 403.388,32.02 C403.388,30.016 401.763,28.39 399.759,28.39"
                  id="Fill-27"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,37.808 C397.754,37.808 396.129,39.432 396.129,41.436 C396.129,43.441 397.754,45.066 399.759,45.066 C401.763,45.066 403.388,43.441 403.388,41.436 C403.388,39.432 401.763,37.808 399.759,37.808"
                  id="Fill-28"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,37.808 C417.561,37.808 415.935,39.432 415.935,41.436 C415.935,43.441 417.561,45.066 419.566,45.066 C421.569,45.066 423.195,43.441 423.195,41.436 C423.195,39.432 421.569,37.808 419.566,37.808"
                  id="Fill-29"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,150.812 C387.851,150.812 386.225,152.437 386.225,154.441 C386.225,156.446 387.851,158.071 389.855,158.071 C391.859,158.071 393.485,156.446 393.485,154.441 C393.485,152.437 391.859,150.812 389.855,150.812"
                  id="Fill-30"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,141.395 C387.851,141.395 386.225,143.02 386.225,145.024 C386.225,147.029 387.851,148.654 389.855,148.654 C391.859,148.654 393.485,147.029 393.485,145.024 C393.485,143.02 391.859,141.395 389.855,141.395"
                  id="Fill-31"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,122.561 C387.851,122.561 386.225,124.186 386.225,126.19 C386.225,128.195 387.851,129.82 389.855,129.82 C391.859,129.82 393.485,128.195 393.485,126.19 C393.485,124.186 391.859,122.561 389.855,122.561"
                  id="Fill-32"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,179.063 C387.851,179.063 386.225,180.688 386.225,182.693 C386.225,184.697 387.851,186.322 389.855,186.322 C391.859,186.322 393.485,184.697 393.485,182.693 C393.485,180.688 391.859,179.063 389.855,179.063"
                  id="Fill-33"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,113.144 C387.851,113.144 386.225,114.769 386.225,116.773 C386.225,118.777 387.851,120.403 389.855,120.403 C391.859,120.403 393.485,118.777 393.485,116.773 C393.485,114.769 391.859,113.144 389.855,113.144"
                  id="Fill-34"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,160.229 C387.851,160.229 386.225,161.854 386.225,163.859 C386.225,165.863 387.851,167.488 389.855,167.488 C391.859,167.488 393.485,165.863 393.485,163.859 C393.485,161.854 391.859,160.229 389.855,160.229"
                  id="Fill-35"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,188.48 C387.851,188.48 386.225,190.106 386.225,192.11 C386.225,194.114 387.851,195.739 389.855,195.739 C391.859,195.739 393.485,194.114 393.485,192.11 C393.485,190.106 391.859,188.48 389.855,188.48"
                  id="Fill-36"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,169.646 C387.851,169.646 386.225,171.271 386.225,173.275 C386.225,175.28 387.851,176.905 389.855,176.905 C391.859,176.905 393.485,175.28 393.485,173.275 C393.485,171.271 391.859,169.646 389.855,169.646"
                  id="Fill-37"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,179.063 C417.561,179.063 415.935,180.688 415.935,182.693 C415.935,184.697 417.561,186.322 419.566,186.322 C421.569,186.322 423.195,184.697 423.195,182.693 C423.195,180.688 421.569,179.063 419.566,179.063"
                  id="Fill-38"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,9.557 C417.561,9.557 415.935,11.181 415.935,13.185 C415.935,15.19 417.561,16.815 419.566,16.815 C421.569,16.815 423.195,15.19 423.195,13.185 C423.195,11.181 421.569,9.557 419.566,9.557"
                  id="Fill-39"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,169.646 C417.561,169.646 415.935,171.271 415.935,173.275 C415.935,175.28 417.561,176.905 419.566,176.905 C421.569,176.905 423.195,175.28 423.195,173.275 C423.195,171.271 421.569,169.646 419.566,169.646"
                  id="Fill-40"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,188.48 C397.754,188.48 396.129,190.106 396.129,192.11 C396.129,194.114 397.754,195.739 399.759,195.739 C401.763,195.739 403.388,194.114 403.388,192.11 C403.388,190.106 401.763,188.48 399.759,188.48"
                  id="Fill-41"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,84.892 C387.851,84.892 386.225,86.517 386.225,88.522 C386.225,90.527 387.851,92.152 389.855,92.152 C391.859,92.152 393.485,90.527 393.485,88.522 C393.485,86.517 391.859,84.892 389.855,84.892"
                  id="Fill-42"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,94.309 C387.851,94.309 386.225,95.935 386.225,97.939 C386.225,99.944 387.851,101.569 389.855,101.569 C391.859,101.569 393.485,99.944 393.485,97.939 C393.485,95.935 391.859,94.309 389.855,94.309"
                  id="Fill-43"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,103.727 C387.851,103.727 386.225,105.352 386.225,107.356 C386.225,109.361 387.851,110.986 389.855,110.986 C391.859,110.986 393.485,109.361 393.485,107.356 C393.485,105.352 391.859,103.727 389.855,103.727"
                  id="Fill-44"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,94.309 C417.561,94.309 415.935,95.935 415.935,97.939 C415.935,99.944 417.561,101.569 419.566,101.569 C421.569,101.569 423.195,99.944 423.195,97.939 C423.195,95.935 421.569,94.309 419.566,94.309"
                  id="Fill-45"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,66.058 C417.561,66.058 415.935,67.683 415.935,69.688 C415.935,71.693 417.561,73.318 419.566,73.318 C421.569,73.318 423.195,71.693 423.195,69.688 C423.195,67.683 421.569,66.058 419.566,66.058"
                  id="Fill-46"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,84.892 C417.561,84.892 415.935,86.517 415.935,88.522 C415.935,90.527 417.561,92.152 419.566,92.152 C421.569,92.152 423.195,90.527 423.195,88.522 C423.195,86.517 421.569,84.892 419.566,84.892"
                  id="Fill-47"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,75.476 C417.561,75.476 415.935,77.1 415.935,79.105 C415.935,81.109 417.561,82.735 419.566,82.735 C421.569,82.735 423.195,81.109 423.195,79.105 C423.195,77.1 421.569,75.476 419.566,75.476"
                  id="Fill-48"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,18.973 C417.561,18.973 415.935,20.598 415.935,22.603 C415.935,24.607 417.561,26.232 419.566,26.232 C421.569,26.232 423.195,24.607 423.195,22.603 C423.195,20.598 421.569,18.973 419.566,18.973"
                  id="Fill-49"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,179.063 C368.044,179.063 366.419,180.688 366.419,182.693 C366.419,184.697 368.044,186.322 370.048,186.322 C372.054,186.322 373.678,184.697 373.678,182.693 C373.678,180.688 372.054,179.063 370.048,179.063"
                  id="Fill-50"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,150.812 C417.561,150.812 415.935,152.437 415.935,154.441 C415.935,156.446 417.561,158.071 419.566,158.071 C421.569,158.071 423.195,156.446 423.195,154.441 C423.195,152.437 421.569,150.812 419.566,150.812"
                  id="Fill-51"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,131.978 C417.561,131.978 415.935,133.603 415.935,135.607 C415.935,137.612 417.561,139.237 419.566,139.237 C421.569,139.237 423.195,137.612 423.195,135.607 C423.195,133.603 421.569,131.978 419.566,131.978"
                  id="Fill-52"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,141.395 C417.561,141.395 415.935,143.02 415.935,145.024 C415.935,147.029 417.561,148.654 419.566,148.654 C421.569,148.654 423.195,147.029 423.195,145.024 C423.195,143.02 421.569,141.395 419.566,141.395"
                  id="Fill-53"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,160.229 C417.561,160.229 415.935,161.854 415.935,163.859 C415.935,165.863 417.561,167.488 419.566,167.488 C421.569,167.488 423.195,165.863 423.195,163.859 C423.195,161.854 421.569,160.229 419.566,160.229"
                  id="Fill-54"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,103.727 C417.561,103.727 415.935,105.352 415.935,107.356 C415.935,109.361 417.561,110.986 419.566,110.986 C421.569,110.986 423.195,109.361 423.195,107.356 C423.195,105.352 421.569,103.727 419.566,103.727"
                  id="Fill-55"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,113.144 C417.561,113.144 415.935,114.769 415.935,116.773 C415.935,118.777 417.561,120.403 419.566,120.403 C421.569,120.403 423.195,118.777 423.195,116.773 C423.195,114.769 421.569,113.144 419.566,113.144"
                  id="Fill-56"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,122.561 C417.561,122.561 415.935,124.186 415.935,126.19 C415.935,128.195 417.561,129.82 419.566,129.82 C421.569,129.82 423.195,128.195 423.195,126.19 C423.195,124.186 421.569,122.561 419.566,122.561"
                  id="Fill-57"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,188.48 C417.561,188.48 415.935,190.106 415.935,192.11 C415.935,194.114 417.561,195.739 419.566,195.739 C421.569,195.739 423.195,194.114 423.195,192.11 C423.195,190.106 421.569,188.48 419.566,188.48"
                  id="Fill-58"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,179.063 C338.335,179.063 336.71,180.688 336.71,182.693 C336.71,184.697 338.335,186.322 340.339,186.322 C342.343,186.322 343.969,184.697 343.969,182.693 C343.969,180.688 342.343,179.063 340.339,179.063"
                  id="Fill-59"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M340.339,188.48 C338.335,188.48 336.71,190.106 336.71,192.11 C336.71,194.114 338.335,195.739 340.339,195.739 C342.343,195.739 343.969,194.114 343.969,192.11 C343.969,190.106 342.343,188.48 340.339,188.48"
                  id="Fill-60"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,122.561 C437.366,122.561 435.742,124.186 435.742,126.19 C435.742,128.195 437.366,129.82 439.371,129.82 C441.376,129.82 443,128.195 443,126.19 C443,124.186 441.376,122.561 439.371,122.561"
                  id="Fill-61"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,131.978 C437.366,131.978 435.742,133.603 435.742,135.607 C435.742,137.612 437.366,139.237 439.371,139.237 C441.376,139.237 443,137.612 443,135.607 C443,133.603 441.376,131.978 439.371,131.978"
                  id="Fill-62"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,94.309 C437.366,94.309 435.742,95.935 435.742,97.939 C435.742,99.944 437.366,101.569 439.371,101.569 C441.376,101.569 443,99.944 443,97.939 C443,95.935 441.376,94.309 439.371,94.309"
                  id="Fill-63"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,113.144 C437.366,113.144 435.742,114.769 435.742,116.773 C435.742,118.777 437.366,120.403 439.371,120.403 C441.376,120.403 443,118.777 443,116.773 C443,114.769 441.376,113.144 439.371,113.144"
                  id="Fill-64"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,103.727 C437.366,103.727 435.742,105.352 435.742,107.356 C435.742,109.361 437.366,110.986 439.371,110.986 C441.376,110.986 443,109.361 443,107.356 C443,105.352 441.376,103.727 439.371,103.727"
                  id="Fill-65"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M340.339,141.395 C338.335,141.395 336.71,143.02 336.71,145.024 C336.71,147.029 338.335,148.654 340.339,148.654 C342.343,148.654 343.969,147.029 343.969,145.024 C343.969,143.02 342.343,141.395 340.339,141.395"
                  id="Fill-66"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,169.646 C338.335,169.646 336.71,171.271 336.71,173.275 C336.71,175.28 338.335,176.905 340.339,176.905 C342.343,176.905 343.969,175.28 343.969,173.275 C343.969,171.271 342.343,169.646 340.339,169.646"
                  id="Fill-67"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,141.395 C437.366,141.395 435.742,143.02 435.742,145.024 C435.742,147.029 437.366,148.654 439.371,148.654 C441.376,148.654 443,147.029 443,145.024 C443,143.02 441.376,141.395 439.371,141.395"
                  id="Fill-68"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,160.229 C437.366,160.229 435.742,161.854 435.742,163.859 C435.742,165.863 437.366,167.488 439.371,167.488 C441.376,167.488 443,165.863 443,163.859 C443,161.854 441.376,160.229 439.371,160.229"
                  id="Fill-69"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,150.812 C437.366,150.812 435.742,152.437 435.742,154.441 C435.742,156.446 437.366,158.071 439.371,158.071 C441.376,158.071 443,156.446 443,154.441 C443,152.437 441.376,150.812 439.371,150.812"
                  id="Fill-70"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,84.892 C437.366,84.892 435.742,86.517 435.742,88.522 C435.742,90.527 437.366,92.152 439.371,92.152 C441.376,92.152 443,90.527 443,88.522 C443,86.517 441.376,84.892 439.371,84.892"
                  id="Fill-71"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,150.812 C338.335,150.812 336.71,152.437 336.71,154.441 C336.71,156.446 338.335,158.071 340.339,158.071 C342.343,158.071 343.969,156.446 343.969,154.441 C343.969,152.437 342.343,150.812 340.339,150.812"
                  id="Fill-72"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M300.726,94.309 C298.721,94.309 297.097,95.935 297.097,97.939 C297.097,99.944 298.721,101.569 300.726,101.569 C302.73,101.569 304.355,99.944 304.355,97.939 C304.355,95.935 302.73,94.309 300.726,94.309"
                  id="Fill-73"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M310.63,94.309 C308.625,94.309 307,95.935 307,97.939 C307,99.944 308.625,101.569 310.63,101.569 C312.634,101.569 314.259,99.944 314.259,97.939 C314.259,95.935 312.634,94.309 310.63,94.309"
                  id="Fill-74"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M310.63,103.727 C308.625,103.727 307,105.352 307,107.356 C307,109.361 308.625,110.986 310.63,110.986 C312.634,110.986 314.259,109.361 314.259,107.356 C314.259,105.352 312.634,103.727 310.63,103.727"
                  id="Fill-75"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,169.646 C407.657,169.646 406.032,171.271 406.032,173.275 C406.032,175.28 407.657,176.905 409.661,176.905 C411.666,176.905 413.291,175.28 413.291,173.275 C413.291,171.271 411.666,169.646 409.661,169.646"
                  id="Fill-76"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M290.823,103.727 C288.818,103.727 287.193,105.352 287.193,107.356 C287.193,109.361 288.818,110.986 290.823,110.986 C292.827,110.986 294.453,109.361 294.453,107.356 C294.453,105.352 292.827,103.727 290.823,103.727"
                  id="Fill-77"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,169.646 C437.366,169.646 435.742,171.271 435.742,173.275 C435.742,175.28 437.366,176.905 439.371,176.905 C441.376,176.905 443,175.28 443,173.275 C443,171.271 441.376,169.646 439.371,169.646"
                  id="Fill-78"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,160.229 C407.657,160.229 406.032,161.854 406.032,163.859 C406.032,165.863 407.657,167.488 409.661,167.488 C411.666,167.488 413.291,165.863 413.291,163.859 C413.291,161.854 411.666,160.229 409.661,160.229"
                  id="Fill-79"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M300.726,103.727 C298.721,103.727 297.097,105.352 297.097,107.356 C297.097,109.361 298.721,110.986 300.726,110.986 C302.73,110.986 304.355,109.361 304.355,107.356 C304.355,105.352 302.73,103.727 300.726,103.727"
                  id="Fill-80"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,141.395 C407.657,141.395 406.032,143.02 406.032,145.024 C406.032,147.029 407.657,148.654 409.661,148.654 C411.666,148.654 413.291,147.029 413.291,145.024 C413.291,143.02 411.666,141.395 409.661,141.395"
                  id="Fill-81"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,84.892 C407.657,84.892 406.032,86.517 406.032,88.522 C406.032,90.527 407.657,92.152 409.661,92.152 C411.666,92.152 413.291,90.527 413.291,88.522 C413.291,86.517 411.666,84.892 409.661,84.892"
                  id="Fill-82"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M330.436,179.063 C328.431,179.063 326.806,180.688 326.806,182.693 C326.806,184.697 328.431,186.322 330.436,186.322 C332.441,186.322 334.065,184.697 334.065,182.693 C334.065,180.688 332.441,179.063 330.436,179.063"
                  id="Fill-83"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,94.309 C407.657,94.309 406.032,95.935 406.032,97.939 C406.032,99.944 407.657,101.569 409.661,101.569 C411.666,101.569 413.291,99.944 413.291,97.939 C413.291,95.935 411.666,94.309 409.661,94.309"
                  id="Fill-84"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,75.476 C437.366,75.476 435.742,77.1 435.742,79.105 C435.742,81.109 437.366,82.735 439.371,82.735 C441.376,82.735 443,81.109 443,79.105 C443,77.1 441.376,75.476 439.371,75.476"
                  id="Fill-85"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,131.978 C407.657,131.978 406.032,133.603 406.032,135.607 C406.032,137.612 407.657,139.237 409.661,139.237 C411.666,139.237 413.291,137.612 413.291,135.607 C413.291,133.603 411.666,131.978 409.661,131.978"
                  id="Fill-86"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,150.812 C407.657,150.812 406.032,152.437 406.032,154.441 C406.032,156.446 407.657,158.071 409.661,158.071 C411.666,158.071 413.291,156.446 413.291,154.441 C413.291,152.437 411.666,150.812 409.661,150.812"
                  id="Fill-87"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,131.978 C348.238,131.978 346.613,133.603 346.613,135.607 C346.613,137.612 348.238,139.237 350.242,139.237 C352.247,139.237 353.871,137.612 353.871,135.607 C353.871,133.603 352.247,131.978 350.242,131.978"
                  id="Fill-88"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,179.063 C358.141,179.063 356.516,180.688 356.516,182.693 C356.516,184.697 358.141,186.322 360.145,186.322 C362.15,186.322 363.775,184.697 363.775,182.693 C363.775,180.688 362.15,179.063 360.145,179.063"
                  id="Fill-89"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,84.892 C427.464,84.892 425.839,86.517 425.839,88.522 C425.839,90.527 427.464,92.152 429.468,92.152 C431.473,92.152 433.098,90.527 433.098,88.522 C433.098,86.517 431.473,84.892 429.468,84.892"
                  id="Fill-90"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,18.973 C397.754,18.973 396.129,20.598 396.129,22.603 C396.129,24.607 397.754,26.232 399.759,26.232 C401.763,26.232 403.388,24.607 403.388,22.603 C403.388,20.598 401.763,18.973 399.759,18.973"
                  id="Fill-91"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,169.646 C358.141,169.646 356.516,171.271 356.516,173.275 C356.516,175.28 358.141,176.905 360.145,176.905 C362.15,176.905 363.775,175.28 363.775,173.275 C363.775,171.271 362.15,169.646 360.145,169.646"
                  id="Fill-92"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,75.476 C427.464,75.476 425.839,77.1 425.839,79.105 C425.839,81.109 427.464,82.735 429.468,82.735 C431.473,82.735 433.098,81.109 433.098,79.105 C433.098,77.1 431.473,75.476 429.468,75.476"
                  id="Fill-93"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M429.468,66.058 C427.464,66.058 425.839,67.683 425.839,69.688 C425.839,71.693 427.464,73.318 429.468,73.318 C431.473,73.318 433.098,71.693 433.098,69.688 C433.098,67.683 431.473,66.058 429.468,66.058"
                  id="Fill-94"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,160.229 C358.141,160.229 356.516,161.854 356.516,163.859 C356.516,165.863 358.141,167.488 360.145,167.488 C362.15,167.488 363.775,165.863 363.775,163.859 C363.775,161.854 362.15,160.229 360.145,160.229"
                  id="Fill-95"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,75.476 C407.657,75.476 406.032,77.1 406.032,79.105 C406.032,81.109 407.657,82.735 409.661,82.735 C411.666,82.735 413.291,81.109 413.291,79.105 C413.291,77.1 411.666,75.476 409.661,75.476"
                  id="Fill-96"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,141.395 C427.464,141.395 425.839,143.02 425.839,145.024 C425.839,147.029 427.464,148.654 429.468,148.654 C431.473,148.654 433.098,147.029 433.098,145.024 C433.098,143.02 431.473,141.395 429.468,141.395"
                  id="Fill-97"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,131.978 C427.464,131.978 425.839,133.603 425.839,135.607 C425.839,137.612 427.464,139.237 429.468,139.237 C431.473,139.237 433.098,137.612 433.098,135.607 C433.098,133.603 431.473,131.978 429.468,131.978"
                  id="Fill-98"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M429.468,94.309 C427.464,94.309 425.839,95.935 425.839,97.939 C425.839,99.944 427.464,101.569 429.468,101.569 C431.473,101.569 433.098,99.944 433.098,97.939 C433.098,95.935 431.473,94.309 429.468,94.309"
                  id="Fill-99"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,113.144 C427.464,113.144 425.839,114.769 425.839,116.773 C425.839,118.777 427.464,120.403 429.468,120.403 C431.473,120.403 433.098,118.777 433.098,116.773 C433.098,114.769 431.473,113.144 429.468,113.144"
                  id="Fill-100"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M429.468,103.727 C427.464,103.727 425.839,105.352 425.839,107.356 C425.839,109.361 427.464,110.986 429.468,110.986 C431.473,110.986 433.098,109.361 433.098,107.356 C433.098,105.352 431.473,103.727 429.468,103.727"
                  id="Fill-101"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,122.561 C427.464,122.561 425.839,124.186 425.839,126.19 C425.839,128.195 427.464,129.82 429.468,129.82 C431.473,129.82 433.098,128.195 433.098,126.19 C433.098,124.186 431.473,122.561 429.468,122.561"
                  id="Fill-102"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,28.39 C407.657,28.39 406.032,30.016 406.032,32.02 C406.032,34.025 407.657,35.649 409.661,35.649 C411.666,35.649 413.291,34.025 413.291,32.02 C413.291,30.016 411.666,28.39 409.661,28.39"
                  id="Fill-103"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M350.242,141.395 C348.238,141.395 346.613,143.02 346.613,145.024 C346.613,147.029 348.238,148.654 350.242,148.654 C352.247,148.654 353.871,147.029 353.871,145.024 C353.871,143.02 352.247,141.395 350.242,141.395"
                  id="Fill-104"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M350.242,150.812 C348.238,150.812 346.613,152.437 346.613,154.441 C346.613,156.446 348.238,158.071 350.242,158.071 C352.247,158.071 353.871,156.446 353.871,154.441 C353.871,152.437 352.247,150.812 350.242,150.812"
                  id="Fill-105"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,179.063 C348.238,179.063 346.613,180.688 346.613,182.693 C346.613,184.697 348.238,186.322 350.242,186.322 C352.247,186.322 353.871,184.697 353.871,182.693 C353.871,180.688 352.247,179.063 350.242,179.063"
                  id="Fill-106"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,66.058 C407.657,66.058 406.032,67.683 406.032,69.688 C406.032,71.693 407.657,73.318 409.661,73.318 C411.666,73.318 413.291,71.693 413.291,69.688 C413.291,67.683 411.666,66.058 409.661,66.058"
                  id="Fill-107"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,37.808 C407.657,37.808 406.032,39.432 406.032,41.436 C406.032,43.441 407.657,45.066 409.661,45.066 C411.666,45.066 413.291,43.441 413.291,41.436 C413.291,39.432 411.666,37.808 409.661,37.808"
                  id="Fill-108"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,169.646 C348.238,169.646 346.613,171.271 346.613,173.275 C346.613,175.28 348.238,176.905 350.242,176.905 C352.247,176.905 353.871,175.28 353.871,173.275 C353.871,171.271 352.247,169.646 350.242,169.646"
                  id="Fill-109"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,9.557 C407.657,9.557 406.032,11.181 406.032,13.185 C406.032,15.19 407.657,16.815 409.661,16.815 C411.666,16.815 413.291,15.19 413.291,13.185 C413.291,11.181 411.666,9.557 409.661,9.557"
                  id="Fill-110"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,160.229 C427.464,160.229 425.839,161.854 425.839,163.859 C425.839,165.863 427.464,167.488 429.468,167.488 C431.473,167.488 433.098,165.863 433.098,163.859 C433.098,161.854 431.473,160.229 429.468,160.229"
                  id="Fill-111"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,18.973 C407.657,18.973 406.032,20.598 406.032,22.603 C406.032,24.607 407.657,26.232 409.661,26.232 C411.666,26.232 413.291,24.607 413.291,22.603 C413.291,20.598 411.666,18.973 409.661,18.973"
                  id="Fill-112"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,150.812 C427.464,150.812 425.839,152.437 425.839,154.441 C425.839,156.446 427.464,158.071 429.468,158.071 C431.473,158.071 433.098,156.446 433.098,154.441 C433.098,152.437 431.473,150.812 429.468,150.812"
                  id="Fill-113"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,122.561 C348.238,122.561 346.613,124.186 346.613,126.19 C346.613,128.195 348.238,129.82 350.242,129.82 C352.247,129.82 353.871,128.195 353.871,126.19 C353.871,124.186 352.247,122.561 350.242,122.561"
                  id="Fill-114"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,310.902 C417.561,310.902 415.935,312.527 415.935,314.531 C415.935,316.536 417.561,318.161 419.566,318.161 C421.569,318.161 423.195,316.536 423.195,314.531 C423.195,312.527 421.569,310.902 419.566,310.902"
                  id="Fill-115"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,301.485 C417.561,301.485 415.935,303.11 415.935,305.115 C415.935,307.119 417.561,308.744 419.566,308.744 C421.569,308.744 423.195,307.119 423.195,305.115 C423.195,303.11 421.569,301.485 419.566,301.485"
                  id="Fill-116"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,292.068 C417.561,292.068 415.935,293.693 415.935,295.697 C415.935,297.702 417.561,299.327 419.566,299.327 C421.569,299.327 423.195,297.702 423.195,295.697 C423.195,293.693 421.569,292.068 419.566,292.068"
                  id="Fill-117"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,282.651 C417.561,282.651 415.935,284.276 415.935,286.28 C415.935,288.285 417.561,289.909 419.566,289.909 C421.569,289.909 423.195,288.285 423.195,286.28 C423.195,284.276 421.569,282.651 419.566,282.651"
                  id="Fill-118"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,339.153 C417.561,339.153 415.935,340.778 415.935,342.782 C415.935,344.787 417.561,346.412 419.566,346.412 C421.569,346.412 423.195,344.787 423.195,342.782 C423.195,340.778 421.569,339.153 419.566,339.153"
                  id="Fill-119"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,348.57 C417.561,348.57 415.935,350.195 415.935,352.199 C415.935,354.204 417.561,355.829 419.566,355.829 C421.569,355.829 423.195,354.204 423.195,352.199 C423.195,350.195 421.569,348.57 419.566,348.57"
                  id="Fill-120"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,329.736 C417.561,329.736 415.935,331.361 415.935,333.365 C415.935,335.37 417.561,336.995 419.566,336.995 C421.569,336.995 423.195,335.37 423.195,333.365 C423.195,331.361 421.569,329.736 419.566,329.736"
                  id="Fill-121"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,320.319 C417.561,320.319 415.935,321.944 415.935,323.949 C415.935,325.953 417.561,327.577 419.566,327.577 C421.569,327.577 423.195,325.953 423.195,323.949 C423.195,321.944 421.569,320.319 419.566,320.319"
                  id="Fill-122"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M429.468,320.319 C427.464,320.319 425.839,321.944 425.839,323.949 C425.839,325.953 427.464,327.577 429.468,327.577 C431.473,327.577 433.098,325.953 433.098,323.949 C433.098,321.944 431.473,320.319 429.468,320.319"
                  id="Fill-123"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,273.234 C437.366,273.234 435.742,274.858 435.742,276.863 C435.742,278.867 437.366,280.493 439.371,280.493 C441.376,280.493 443,278.867 443,276.863 C443,274.858 441.376,273.234 439.371,273.234"
                  id="Fill-124"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,263.817 C437.366,263.817 435.742,265.442 435.742,267.447 C435.742,269.451 437.366,271.076 439.371,271.076 C441.376,271.076 443,269.451 443,267.447 C443,265.442 441.376,263.817 439.371,263.817"
                  id="Fill-125"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,244.982 C437.366,244.982 435.742,246.607 435.742,248.612 C435.742,250.616 437.366,252.242 439.371,252.242 C441.376,252.242 443,250.616 443,248.612 C443,246.607 441.376,244.982 439.371,244.982"
                  id="Fill-126"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M439.371,282.651 C437.366,282.651 435.742,284.276 435.742,286.28 C435.742,288.285 437.366,289.909 439.371,289.909 C441.376,289.909 443,288.285 443,286.28 C443,284.276 441.376,282.651 439.371,282.651"
                  id="Fill-127"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,254.399 C437.366,254.399 435.742,256.025 435.742,258.029 C435.742,260.033 437.366,261.658 439.371,261.658 C441.376,261.658 443,260.033 443,258.029 C443,256.025 441.376,254.399 439.371,254.399"
                  id="Fill-128"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,292.068 C437.366,292.068 435.742,293.693 435.742,295.697 C435.742,297.702 437.366,299.327 439.371,299.327 C441.376,299.327 443,297.702 443,295.697 C443,293.693 441.376,292.068 439.371,292.068"
                  id="Fill-129"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,310.902 C437.366,310.902 435.742,312.527 435.742,314.531 C435.742,316.536 437.366,318.161 439.371,318.161 C441.376,318.161 443,316.536 443,314.531 C443,312.527 441.376,310.902 439.371,310.902"
                  id="Fill-130"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,301.485 C437.366,301.485 435.742,303.11 435.742,305.115 C435.742,307.119 437.366,308.744 439.371,308.744 C441.376,308.744 443,307.119 443,305.115 C443,303.11 441.376,301.485 439.371,301.485"
                  id="Fill-131"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M449.275,329.736 C447.271,329.736 445.645,331.361 445.645,333.365 C445.645,335.37 447.271,336.995 449.275,336.995 C451.279,336.995 452.903,335.37 452.903,333.365 C452.903,331.361 451.279,329.736 449.275,329.736"
                  id="Fill-132"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,329.736 C457.173,329.736 455.549,331.361 455.549,333.365 C455.549,335.37 457.173,336.995 459.178,336.995 C461.183,336.995 462.807,335.37 462.807,333.365 C462.807,331.361 461.183,329.736 459.178,329.736"
                  id="Fill-133"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,310.902 C457.173,310.902 455.549,312.527 455.549,314.531 C455.549,316.536 457.173,318.161 459.178,318.161 C461.183,318.161 462.807,316.536 462.807,314.531 C462.807,312.527 461.183,310.902 459.178,310.902"
                  id="Fill-134"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,235.565 C437.366,235.565 435.742,237.191 435.742,239.195 C435.742,241.199 437.366,242.824 439.371,242.824 C441.376,242.824 443,241.199 443,239.195 C443,237.191 441.376,235.565 439.371,235.565"
                  id="Fill-135"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,263.817 C457.173,263.817 455.549,265.442 455.549,267.447 C455.549,269.451 457.173,271.076 459.178,271.076 C461.183,271.076 462.807,269.451 462.807,267.447 C462.807,265.442 461.183,263.817 459.178,263.817"
                  id="Fill-136"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,320.319 C457.173,320.319 455.549,321.944 455.549,323.949 C455.549,325.953 457.173,327.577 459.178,327.577 C461.183,327.577 462.807,325.953 462.807,323.949 C462.807,321.944 461.183,320.319 459.178,320.319"
                  id="Fill-137"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,216.731 C427.464,216.731 425.839,218.357 425.839,220.361 C425.839,222.365 427.464,223.99 429.468,223.99 C431.473,223.99 433.098,222.365 433.098,220.361 C433.098,218.357 431.473,216.731 429.468,216.731"
                  id="Fill-138"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,263.817 C447.271,263.817 445.645,265.442 445.645,267.447 C445.645,269.451 447.271,271.076 449.275,271.076 C451.279,271.076 452.903,269.451 452.903,267.447 C452.903,265.442 451.279,263.817 449.275,263.817"
                  id="Fill-139"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M449.275,273.234 C447.271,273.234 445.645,274.858 445.645,276.863 C445.645,278.867 447.271,280.493 449.275,280.493 C451.279,280.493 452.903,278.867 452.903,276.863 C452.903,274.858 451.279,273.234 449.275,273.234"
                  id="Fill-140"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,244.982 C427.464,244.982 425.839,246.607 425.839,248.612 C425.839,250.616 427.464,252.242 429.468,252.242 C431.473,252.242 433.098,250.616 433.098,248.612 C433.098,246.607 431.473,244.982 429.468,244.982"
                  id="Fill-141"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,216.731 C417.561,216.731 415.935,218.357 415.935,220.361 C415.935,222.365 417.561,223.99 419.566,223.99 C421.569,223.99 423.195,222.365 423.195,220.361 C423.195,218.357 421.569,216.731 419.566,216.731"
                  id="Fill-142"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,310.902 C427.464,310.902 425.839,312.527 425.839,314.531 C425.839,316.536 427.464,318.161 429.468,318.161 C431.473,318.161 433.098,316.536 433.098,314.531 C433.098,312.527 431.473,310.902 429.468,310.902"
                  id="Fill-143"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,329.736 C427.464,329.736 425.839,331.361 425.839,333.365 C425.839,335.37 427.464,336.995 429.468,336.995 C431.473,336.995 433.098,335.37 433.098,333.365 C433.098,331.361 431.473,329.736 429.468,329.736"
                  id="Fill-144"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,235.565 C417.561,235.565 415.935,237.191 415.935,239.195 C415.935,241.199 417.561,242.824 419.566,242.824 C421.569,242.824 423.195,241.199 423.195,239.195 C423.195,237.191 421.569,235.565 419.566,235.565"
                  id="Fill-145"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,226.148 C417.561,226.148 415.935,227.773 415.935,229.778 C415.935,231.782 417.561,233.407 419.566,233.407 C421.569,233.407 423.195,231.782 423.195,229.778 C423.195,227.773 421.569,226.148 419.566,226.148"
                  id="Fill-146"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M419.566,244.982 C417.561,244.982 415.935,246.607 415.935,248.612 C415.935,250.616 417.561,252.242 419.566,252.242 C421.569,252.242 423.195,250.616 423.195,248.612 C423.195,246.607 421.569,244.982 419.566,244.982"
                  id="Fill-147"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,263.817 C417.561,263.817 415.935,265.442 415.935,267.447 C415.935,269.451 417.561,271.076 419.566,271.076 C421.569,271.076 423.195,269.451 423.195,267.447 C423.195,265.442 421.569,263.817 419.566,263.817"
                  id="Fill-148"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,254.399 C417.561,254.399 415.935,256.025 415.935,258.029 C415.935,260.033 417.561,261.658 419.566,261.658 C421.569,261.658 423.195,260.033 423.195,258.029 C423.195,256.025 421.569,254.399 419.566,254.399"
                  id="Fill-149"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M429.468,226.148 C427.464,226.148 425.839,227.773 425.839,229.778 C425.839,231.782 427.464,233.407 429.468,233.407 C431.473,233.407 433.098,231.782 433.098,229.778 C433.098,227.773 431.473,226.148 429.468,226.148"
                  id="Fill-150"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M419.566,273.234 C417.561,273.234 415.935,274.858 415.935,276.863 C415.935,278.867 417.561,280.493 419.566,280.493 C421.569,280.493 423.195,278.867 423.195,276.863 C423.195,274.858 421.569,273.234 419.566,273.234"
                  id="Fill-151"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,254.399 C427.464,254.399 425.839,256.025 425.839,258.029 C425.839,260.033 427.464,261.658 429.468,261.658 C431.473,261.658 433.098,260.033 433.098,258.029 C433.098,256.025 431.473,254.399 429.468,254.399"
                  id="Fill-152"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,235.565 C427.464,235.565 425.839,237.191 425.839,239.195 C425.839,241.199 427.464,242.824 429.468,242.824 C431.473,242.824 433.098,241.199 433.098,239.195 C433.098,237.191 431.473,235.565 429.468,235.565"
                  id="Fill-153"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,263.817 C427.464,263.817 425.839,265.442 425.839,267.447 C425.839,269.451 427.464,271.076 429.468,271.076 C431.473,271.076 433.098,269.451 433.098,267.447 C433.098,265.442 431.473,263.817 429.468,263.817"
                  id="Fill-154"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,292.068 C427.464,292.068 425.839,293.693 425.839,295.697 C425.839,297.702 427.464,299.327 429.468,299.327 C431.473,299.327 433.098,297.702 433.098,295.697 C433.098,293.693 431.473,292.068 429.468,292.068"
                  id="Fill-155"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,301.485 C427.464,301.485 425.839,303.11 425.839,305.115 C425.839,307.119 427.464,308.744 429.468,308.744 C431.473,308.744 433.098,307.119 433.098,305.115 C433.098,303.11 431.473,301.485 429.468,301.485"
                  id="Fill-156"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,282.651 C427.464,282.651 425.839,284.276 425.839,286.28 C425.839,288.285 427.464,289.909 429.468,289.909 C431.473,289.909 433.098,288.285 433.098,286.28 C433.098,284.276 431.473,282.651 429.468,282.651"
                  id="Fill-157"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M429.468,273.234 C427.464,273.234 425.839,274.858 425.839,276.863 C425.839,278.867 427.464,280.493 429.468,280.493 C431.473,280.493 433.098,278.867 433.098,276.863 C433.098,274.858 431.473,273.234 429.468,273.234"
                  id="Fill-158"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,254.399 C358.141,254.399 356.516,256.025 356.516,258.029 C356.516,260.033 358.141,261.658 360.145,261.658 C362.15,261.658 363.775,260.033 363.775,258.029 C363.775,256.025 362.15,254.399 360.145,254.399"
                  id="Fill-159"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,244.982 C368.044,244.982 366.419,246.607 366.419,248.612 C366.419,250.616 368.044,252.242 370.048,252.242 C372.054,252.242 373.678,250.616 373.678,248.612 C373.678,246.607 372.054,244.982 370.048,244.982"
                  id="Fill-160"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,235.565 C368.044,235.565 366.419,237.191 366.419,239.195 C366.419,241.199 368.044,242.824 370.048,242.824 C372.054,242.824 373.678,241.199 373.678,239.195 C373.678,237.191 372.054,235.565 370.048,235.565"
                  id="Fill-161"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,254.399 C368.044,254.399 366.419,256.025 366.419,258.029 C366.419,260.033 368.044,261.658 370.048,261.658 C372.054,261.658 373.678,260.033 373.678,258.029 C373.678,256.025 372.054,254.399 370.048,254.399"
                  id="Fill-162"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,263.817 C368.044,263.817 366.419,265.442 366.419,267.447 C366.419,269.451 368.044,271.076 370.048,271.076 C372.054,271.076 373.678,269.451 373.678,267.447 C373.678,265.442 372.054,263.817 370.048,263.817"
                  id="Fill-163"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,216.731 C368.044,216.731 366.419,218.357 366.419,220.361 C366.419,222.365 368.044,223.99 370.048,223.99 C372.054,223.99 373.678,222.365 373.678,220.361 C373.678,218.357 372.054,216.731 370.048,216.731"
                  id="Fill-164"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,226.148 C368.044,226.148 366.419,227.773 366.419,229.778 C366.419,231.782 368.044,233.407 370.048,233.407 C372.054,233.407 373.678,231.782 373.678,229.778 C373.678,227.773 372.054,226.148 370.048,226.148"
                  id="Fill-165"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,197.897 C358.141,197.897 356.516,199.523 356.516,201.527 C356.516,203.531 358.141,205.156 360.145,205.156 C362.15,205.156 363.775,203.531 363.775,201.527 C363.775,199.523 362.15,197.897 360.145,197.897"
                  id="Fill-166"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,226.148 C358.141,226.148 356.516,227.773 356.516,229.778 C356.516,231.782 358.141,233.407 360.145,233.407 C362.15,233.407 363.775,231.782 363.775,229.778 C363.775,227.773 362.15,226.148 360.145,226.148"
                  id="Fill-167"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,235.565 C358.141,235.565 356.516,237.191 356.516,239.195 C356.516,241.199 358.141,242.824 360.145,242.824 C362.15,242.824 363.775,241.199 363.775,239.195 C363.775,237.191 362.15,235.565 360.145,235.565"
                  id="Fill-168"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,207.314 C358.141,207.314 356.516,208.939 356.516,210.944 C356.516,212.948 358.141,214.574 360.145,214.574 C362.15,214.574 363.775,212.948 363.775,210.944 C363.775,208.939 362.15,207.314 360.145,207.314"
                  id="Fill-169"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,244.982 C358.141,244.982 356.516,246.607 356.516,248.612 C356.516,250.616 358.141,252.242 360.145,252.242 C362.15,252.242 363.775,250.616 363.775,248.612 C363.775,246.607 362.15,244.982 360.145,244.982"
                  id="Fill-170"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M360.145,216.731 C358.141,216.731 356.516,218.357 356.516,220.361 C356.516,222.365 358.141,223.99 360.145,223.99 C362.15,223.99 363.775,222.365 363.775,220.361 C363.775,218.357 362.15,216.731 360.145,216.731"
                  id="Fill-171"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M370.048,207.314 C368.044,207.314 366.419,208.939 366.419,210.944 C366.419,212.948 368.044,214.574 370.048,214.574 C372.054,214.574 373.678,212.948 373.678,210.944 C373.678,208.939 372.054,207.314 370.048,207.314"
                  id="Fill-172"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,273.234 C377.947,273.234 376.322,274.858 376.322,276.863 C376.322,278.867 377.947,280.493 379.952,280.493 C381.956,280.493 383.581,278.867 383.581,276.863 C383.581,274.858 381.956,273.234 379.952,273.234"
                  id="Fill-173"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,254.399 C377.947,254.399 376.322,256.025 376.322,258.029 C376.322,260.033 377.947,261.658 379.952,261.658 C381.956,261.658 383.581,260.033 383.581,258.029 C383.581,256.025 381.956,254.399 379.952,254.399"
                  id="Fill-174"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,263.817 C377.947,263.817 376.322,265.442 376.322,267.447 C376.322,269.451 377.947,271.076 379.952,271.076 C381.956,271.076 383.581,269.451 383.581,267.447 C383.581,265.442 381.956,263.817 379.952,263.817"
                  id="Fill-175"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,235.565 C377.947,235.565 376.322,237.191 376.322,239.195 C376.322,241.199 377.947,242.824 379.952,242.824 C381.956,242.824 383.581,241.199 383.581,239.195 C383.581,237.191 381.956,235.565 379.952,235.565"
                  id="Fill-176"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M370.048,197.897 C368.044,197.897 366.419,199.523 366.419,201.527 C366.419,203.531 368.044,205.156 370.048,205.156 C372.054,205.156 373.678,203.531 373.678,201.527 C373.678,199.523 372.054,197.897 370.048,197.897"
                  id="Fill-177"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,244.982 C377.947,244.982 376.322,246.607 376.322,248.612 C376.322,250.616 377.947,252.242 379.952,252.242 C381.956,252.242 383.581,250.616 383.581,248.612 C383.581,246.607 381.956,244.982 379.952,244.982"
                  id="Fill-178"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,310.902 C377.947,310.902 376.322,312.527 376.322,314.531 C376.322,316.536 377.947,318.161 379.952,318.161 C381.956,318.161 383.581,316.536 383.581,314.531 C383.581,312.527 381.956,310.902 379.952,310.902"
                  id="Fill-179"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,320.319 C377.947,320.319 376.322,321.944 376.322,323.949 C376.322,325.953 377.947,327.577 379.952,327.577 C381.956,327.577 383.581,325.953 383.581,323.949 C383.581,321.944 381.956,320.319 379.952,320.319"
                  id="Fill-180"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,282.651 C377.947,282.651 376.322,284.276 376.322,286.28 C376.322,288.285 377.947,289.909 379.952,289.909 C381.956,289.909 383.581,288.285 383.581,286.28 C383.581,284.276 381.956,282.651 379.952,282.651"
                  id="Fill-181"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,301.485 C377.947,301.485 376.322,303.11 376.322,305.115 C376.322,307.119 377.947,308.744 379.952,308.744 C381.956,308.744 383.581,307.119 383.581,305.115 C383.581,303.11 381.956,301.485 379.952,301.485"
                  id="Fill-182"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,292.068 C377.947,292.068 376.322,293.693 376.322,295.697 C376.322,297.702 377.947,299.327 379.952,299.327 C381.956,299.327 383.581,297.702 383.581,295.697 C383.581,293.693 381.956,292.068 379.952,292.068"
                  id="Fill-183"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M330.436,244.982 C328.431,244.982 326.806,246.607 326.806,248.612 C326.806,250.616 328.431,252.242 330.436,252.242 C332.441,252.242 334.065,250.616 334.065,248.612 C334.065,246.607 332.441,244.982 330.436,244.982"
                  id="Fill-184"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,235.565 C338.335,235.565 336.71,237.191 336.71,239.195 C336.71,241.199 338.335,242.824 340.339,242.824 C342.343,242.824 343.969,241.199 343.969,239.195 C343.969,237.191 342.343,235.565 340.339,235.565"
                  id="Fill-185"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M330.436,226.148 C328.431,226.148 326.806,227.773 326.806,229.778 C326.806,231.782 328.431,233.407 330.436,233.407 C332.441,233.407 334.065,231.782 334.065,229.778 C334.065,227.773 332.441,226.148 330.436,226.148"
                  id="Fill-186"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M360.145,263.817 C358.141,263.817 356.516,265.442 356.516,267.447 C356.516,269.451 358.141,271.076 360.145,271.076 C362.15,271.076 363.775,269.451 363.775,267.447 C363.775,265.442 362.15,263.817 360.145,263.817"
                  id="Fill-187"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,254.399 C338.335,254.399 336.71,256.025 336.71,258.029 C336.71,260.033 338.335,261.658 340.339,261.658 C342.343,261.658 343.969,260.033 343.969,258.029 C343.969,256.025 342.343,254.399 340.339,254.399"
                  id="Fill-188"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,244.982 C338.335,244.982 336.71,246.607 336.71,248.612 C336.71,250.616 338.335,252.242 340.339,252.242 C342.343,252.242 343.969,250.616 343.969,248.612 C343.969,246.607 342.343,244.982 340.339,244.982"
                  id="Fill-189"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M340.339,263.817 C338.335,263.817 336.71,265.442 336.71,267.447 C336.71,269.451 338.335,271.076 340.339,271.076 C342.343,271.076 343.969,269.451 343.969,267.447 C343.969,265.442 342.343,263.817 340.339,263.817"
                  id="Fill-190"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M320.532,244.982 C318.528,244.982 316.903,246.607 316.903,248.612 C316.903,250.616 318.528,252.242 320.532,252.242 C322.537,252.242 324.162,250.616 324.162,248.612 C324.162,246.607 322.537,244.982 320.532,244.982"
                  id="Fill-191"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M330.436,235.565 C328.431,235.565 326.806,237.191 326.806,239.195 C326.806,241.199 328.431,242.824 330.436,242.824 C332.441,242.824 334.065,241.199 334.065,239.195 C334.065,237.191 332.441,235.565 330.436,235.565"
                  id="Fill-192"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M320.532,235.565 C318.528,235.565 316.903,237.191 316.903,239.195 C316.903,241.199 318.528,242.824 320.532,242.824 C322.537,242.824 324.162,241.199 324.162,239.195 C324.162,237.191 322.537,235.565 320.532,235.565"
                  id="Fill-193"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M379.952,226.148 C377.947,226.148 376.322,227.773 376.322,229.778 C376.322,231.782 377.947,233.407 379.952,233.407 C381.956,233.407 383.581,231.782 383.581,229.778 C383.581,227.773 381.956,226.148 379.952,226.148"
                  id="Fill-194"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M330.436,254.399 C328.431,254.399 326.806,256.025 326.806,258.029 C326.806,260.033 328.431,261.658 330.436,261.658 C332.441,261.658 334.065,260.033 334.065,258.029 C334.065,256.025 332.441,254.399 330.436,254.399"
                  id="Fill-195"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M330.436,216.731 C328.431,216.731 326.806,218.357 326.806,220.361 C326.806,222.365 328.431,223.99 330.436,223.99 C332.441,223.99 334.065,222.365 334.065,220.361 C334.065,218.357 332.441,216.731 330.436,216.731"
                  id="Fill-196"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,216.731 C348.238,216.731 346.613,218.357 346.613,220.361 C346.613,222.365 348.238,223.99 350.242,223.99 C352.247,223.99 353.871,222.365 353.871,220.361 C353.871,218.357 352.247,216.731 350.242,216.731"
                  id="Fill-197"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,226.148 C348.238,226.148 346.613,227.773 346.613,229.778 C346.613,231.782 348.238,233.407 350.242,233.407 C352.247,233.407 353.871,231.782 353.871,229.778 C353.871,227.773 352.247,226.148 350.242,226.148"
                  id="Fill-198"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M350.242,235.565 C348.238,235.565 346.613,237.191 346.613,239.195 C346.613,241.199 348.238,242.824 350.242,242.824 C352.247,242.824 353.871,241.199 353.871,239.195 C353.871,237.191 352.247,235.565 350.242,235.565"
                  id="Fill-199"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,197.897 C348.238,197.897 346.613,199.523 346.613,201.527 C346.613,203.531 348.238,205.156 350.242,205.156 C352.247,205.156 353.871,203.531 353.871,201.527 C353.871,199.523 352.247,197.897 350.242,197.897"
                  id="Fill-200"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,207.314 C348.238,207.314 346.613,208.939 346.613,210.944 C346.613,212.948 348.238,214.574 350.242,214.574 C352.247,214.574 353.871,212.948 353.871,210.944 C353.871,208.939 352.247,207.314 350.242,207.314"
                  id="Fill-201"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M340.339,226.148 C338.335,226.148 336.71,227.773 336.71,229.778 C336.71,231.782 338.335,233.407 340.339,233.407 C342.343,233.407 343.969,231.782 343.969,229.778 C343.969,227.773 342.343,226.148 340.339,226.148"
                  id="Fill-202"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,244.982 C348.238,244.982 346.613,246.607 346.613,248.612 C346.613,250.616 348.238,252.242 350.242,252.242 C352.247,252.242 353.871,250.616 353.871,248.612 C353.871,246.607 352.247,244.982 350.242,244.982"
                  id="Fill-203"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M340.339,216.731 C338.335,216.731 336.71,218.357 336.71,220.361 C336.71,222.365 338.335,223.99 340.339,223.99 C342.343,223.99 343.969,222.365 343.969,220.361 C343.969,218.357 342.343,216.731 340.339,216.731"
                  id="Fill-204"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M340.339,207.314 C338.335,207.314 336.71,208.939 336.71,210.944 C336.71,212.948 338.335,214.574 340.339,214.574 C342.343,214.574 343.969,212.948 343.969,210.944 C343.969,208.939 342.343,207.314 340.339,207.314"
                  id="Fill-205"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,216.731 C407.657,216.731 406.032,218.357 406.032,220.361 C406.032,222.365 407.657,223.99 409.661,223.99 C411.666,223.99 413.291,222.365 413.291,220.361 C413.291,218.357 411.666,216.731 409.661,216.731"
                  id="Fill-206"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M350.242,254.399 C348.238,254.399 346.613,256.025 346.613,258.029 C346.613,260.033 348.238,261.658 350.242,261.658 C352.247,261.658 353.871,260.033 353.871,258.029 C353.871,256.025 352.247,254.399 350.242,254.399"
                  id="Fill-207"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M350.242,263.817 C348.238,263.817 346.613,265.442 346.613,267.447 C346.613,269.451 348.238,271.076 350.242,271.076 C352.247,271.076 353.871,269.451 353.871,267.447 C353.871,265.442 352.247,263.817 350.242,263.817"
                  id="Fill-208"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,357.987 C407.657,357.987 406.032,359.611 406.032,361.616 C406.032,363.621 407.657,365.247 409.661,365.247 C411.666,365.247 413.291,363.621 413.291,361.616 C413.291,359.611 411.666,357.987 409.661,357.987"
                  id="Fill-209"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,216.731 C397.754,216.731 396.129,218.357 396.129,220.361 C396.129,222.365 397.754,223.99 399.759,223.99 C401.763,223.99 403.388,222.365 403.388,220.361 C403.388,218.357 401.763,216.731 399.759,216.731"
                  id="Fill-210"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,329.736 C407.657,329.736 406.032,331.361 406.032,333.365 C406.032,335.37 407.657,336.995 409.661,336.995 C411.666,336.995 413.291,335.37 413.291,333.365 C413.291,331.361 411.666,329.736 409.661,329.736"
                  id="Fill-211"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,348.57 C407.657,348.57 406.032,350.195 406.032,352.199 C406.032,354.204 407.657,355.829 409.661,355.829 C411.666,355.829 413.291,354.204 413.291,352.199 C413.291,350.195 411.666,348.57 409.661,348.57"
                  id="Fill-212"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,235.565 C397.754,235.565 396.129,237.191 396.129,239.195 C396.129,241.199 397.754,242.824 399.759,242.824 C401.763,242.824 403.388,241.199 403.388,239.195 C403.388,237.191 401.763,235.565 399.759,235.565"
                  id="Fill-213"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,339.153 C407.657,339.153 406.032,340.778 406.032,342.782 C406.032,344.787 407.657,346.412 409.661,346.412 C411.666,346.412 413.291,344.787 413.291,342.782 C413.291,340.778 411.666,339.153 409.661,339.153"
                  id="Fill-214"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,226.148 C397.754,226.148 396.129,227.773 396.129,229.778 C396.129,231.782 397.754,233.407 399.759,233.407 C401.763,233.407 403.388,231.782 403.388,229.778 C403.388,227.773 401.763,226.148 399.759,226.148"
                  id="Fill-215"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,273.234 C397.754,273.234 396.129,274.858 396.129,276.863 C396.129,278.867 397.754,280.493 399.759,280.493 C401.763,280.493 403.388,278.867 403.388,276.863 C403.388,274.858 401.763,273.234 399.759,273.234"
                  id="Fill-216"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,244.982 C397.754,244.982 396.129,246.607 396.129,248.612 C396.129,250.616 397.754,252.242 399.759,252.242 C401.763,252.242 403.388,250.616 403.388,248.612 C403.388,246.607 401.763,244.982 399.759,244.982"
                  id="Fill-217"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,282.651 C397.754,282.651 396.129,284.276 396.129,286.28 C396.129,288.285 397.754,289.909 399.759,289.909 C401.763,289.909 403.388,288.285 403.388,286.28 C403.388,284.276 401.763,282.651 399.759,282.651"
                  id="Fill-218"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,263.817 C397.754,263.817 396.129,265.442 396.129,267.447 C396.129,269.451 397.754,271.076 399.759,271.076 C401.763,271.076 403.388,269.451 403.388,267.447 C403.388,265.442 401.763,263.817 399.759,263.817"
                  id="Fill-219"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,254.399 C397.754,254.399 396.129,256.025 396.129,258.029 C396.129,260.033 397.754,261.658 399.759,261.658 C401.763,261.658 403.388,260.033 403.388,258.029 C403.388,256.025 401.763,254.399 399.759,254.399"
                  id="Fill-220"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,226.148 C407.657,226.148 406.032,227.773 406.032,229.778 C406.032,231.782 407.657,233.407 409.661,233.407 C411.666,233.407 413.291,231.782 413.291,229.778 C413.291,227.773 411.666,226.148 409.661,226.148"
                  id="Fill-221"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,254.399 C407.657,254.399 406.032,256.025 406.032,258.029 C406.032,260.033 407.657,261.658 409.661,261.658 C411.666,261.658 413.291,260.033 413.291,258.029 C413.291,256.025 411.666,254.399 409.661,254.399"
                  id="Fill-222"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,244.982 C407.657,244.982 406.032,246.607 406.032,248.612 C406.032,250.616 407.657,252.242 409.661,252.242 C411.666,252.242 413.291,250.616 413.291,248.612 C413.291,246.607 411.666,244.982 409.661,244.982"
                  id="Fill-223"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,292.068 C397.754,292.068 396.129,293.693 396.129,295.697 C396.129,297.702 397.754,299.327 399.759,299.327 C401.763,299.327 403.388,297.702 403.388,295.697 C403.388,293.693 401.763,292.068 399.759,292.068"
                  id="Fill-224"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,263.817 C407.657,263.817 406.032,265.442 406.032,267.447 C406.032,269.451 407.657,271.076 409.661,271.076 C411.666,271.076 413.291,269.451 413.291,267.447 C413.291,265.442 411.666,263.817 409.661,263.817"
                  id="Fill-225"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,320.319 C407.657,320.319 406.032,321.944 406.032,323.949 C406.032,325.953 407.657,327.577 409.661,327.577 C411.666,327.577 413.291,325.953 413.291,323.949 C413.291,321.944 411.666,320.319 409.661,320.319"
                  id="Fill-226"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,235.565 C407.657,235.565 406.032,237.191 406.032,239.195 C406.032,241.199 407.657,242.824 409.661,242.824 C411.666,242.824 413.291,241.199 413.291,239.195 C413.291,237.191 411.666,235.565 409.661,235.565"
                  id="Fill-227"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,310.902 C407.657,310.902 406.032,312.527 406.032,314.531 C406.032,316.536 407.657,318.161 409.661,318.161 C411.666,318.161 413.291,316.536 413.291,314.531 C413.291,312.527 411.666,310.902 409.661,310.902"
                  id="Fill-228"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,301.485 C407.657,301.485 406.032,303.11 406.032,305.115 C406.032,307.119 407.657,308.744 409.661,308.744 C411.666,308.744 413.291,307.119 413.291,305.115 C413.291,303.11 411.666,301.485 409.661,301.485"
                  id="Fill-229"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M409.661,282.651 C407.657,282.651 406.032,284.276 406.032,286.28 C406.032,288.285 407.657,289.909 409.661,289.909 C411.666,289.909 413.291,288.285 413.291,286.28 C413.291,284.276 411.666,282.651 409.661,282.651"
                  id="Fill-230"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,273.234 C407.657,273.234 406.032,274.858 406.032,276.863 C406.032,278.867 407.657,280.493 409.661,280.493 C411.666,280.493 413.291,278.867 413.291,276.863 C413.291,274.858 411.666,273.234 409.661,273.234"
                  id="Fill-231"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M409.661,292.068 C407.657,292.068 406.032,293.693 406.032,295.697 C406.032,297.702 407.657,299.327 409.661,299.327 C411.666,299.327 413.291,297.702 413.291,295.697 C413.291,293.693 411.666,292.068 409.661,292.068"
                  id="Fill-232"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,357.987 C397.754,357.987 396.129,359.611 396.129,361.616 C396.129,363.621 397.754,365.247 399.759,365.247 C401.763,365.247 403.388,363.621 403.388,361.616 C403.388,359.611 401.763,357.987 399.759,357.987"
                  id="Fill-233"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,273.234 C387.851,273.234 386.225,274.858 386.225,276.863 C386.225,278.867 387.851,280.493 389.855,280.493 C391.859,280.493 393.485,278.867 393.485,276.863 C393.485,274.858 391.859,273.234 389.855,273.234"
                  id="Fill-234"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,282.651 C387.851,282.651 386.225,284.276 386.225,286.28 C386.225,288.285 387.851,289.909 389.855,289.909 C391.859,289.909 393.485,288.285 393.485,286.28 C393.485,284.276 391.859,282.651 389.855,282.651"
                  id="Fill-235"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,263.817 C387.851,263.817 386.225,265.442 386.225,267.447 C386.225,269.451 387.851,271.076 389.855,271.076 C391.859,271.076 393.485,269.451 393.485,267.447 C393.485,265.442 391.859,263.817 389.855,263.817"
                  id="Fill-236"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,254.399 C387.851,254.399 386.225,256.025 386.225,258.029 C386.225,260.033 387.851,261.658 389.855,261.658 C391.859,261.658 393.485,260.033 393.485,258.029 C393.485,256.025 391.859,254.399 389.855,254.399"
                  id="Fill-237"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,292.068 C387.851,292.068 386.225,293.693 386.225,295.697 C386.225,297.702 387.851,299.327 389.855,299.327 C391.859,299.327 393.485,297.702 393.485,295.697 C393.485,293.693 391.859,292.068 389.855,292.068"
                  id="Fill-238"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,301.485 C387.851,301.485 386.225,303.11 386.225,305.115 C386.225,307.119 387.851,308.744 389.855,308.744 C391.859,308.744 393.485,307.119 393.485,305.115 C393.485,303.11 391.859,301.485 389.855,301.485"
                  id="Fill-239"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,207.314 C377.947,207.314 376.322,208.939 376.322,210.944 C376.322,212.948 377.947,214.574 379.952,214.574 C381.956,214.574 383.581,212.948 383.581,210.944 C383.581,208.939 381.956,207.314 379.952,207.314"
                  id="Fill-240"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,339.153 C387.851,339.153 386.225,340.778 386.225,342.782 C386.225,344.787 387.851,346.412 389.855,346.412 C391.859,346.412 393.485,344.787 393.485,342.782 C393.485,340.778 391.859,339.153 389.855,339.153"
                  id="Fill-241"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,310.902 C387.851,310.902 386.225,312.527 386.225,314.531 C386.225,316.536 387.851,318.161 389.855,318.161 C391.859,318.161 393.485,316.536 393.485,314.531 C393.485,312.527 391.859,310.902 389.855,310.902"
                  id="Fill-242"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,244.982 C387.851,244.982 386.225,246.607 386.225,248.612 C386.225,250.616 387.851,252.242 389.855,252.242 C391.859,252.242 393.485,250.616 393.485,248.612 C393.485,246.607 391.859,244.982 389.855,244.982"
                  id="Fill-243"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,320.319 C387.851,320.319 386.225,321.944 386.225,323.949 C386.225,325.953 387.851,327.577 389.855,327.577 C391.859,327.577 393.485,325.953 393.485,323.949 C393.485,321.944 391.859,320.319 389.855,320.319"
                  id="Fill-244"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,329.736 C387.851,329.736 386.225,331.361 386.225,333.365 C386.225,335.37 387.851,336.995 389.855,336.995 C391.859,336.995 393.485,335.37 393.485,333.365 C393.485,331.361 391.859,329.736 389.855,329.736"
                  id="Fill-245"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,339.153 C397.754,339.153 396.129,340.778 396.129,342.782 C396.129,344.787 397.754,346.412 399.759,346.412 C401.763,346.412 403.388,344.787 403.388,342.782 C403.388,340.778 401.763,339.153 399.759,339.153"
                  id="Fill-246"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,320.319 C397.754,320.319 396.129,321.944 396.129,323.949 C396.129,325.953 397.754,327.577 399.759,327.577 C401.763,327.577 403.388,325.953 403.388,323.949 C403.388,321.944 401.763,320.319 399.759,320.319"
                  id="Fill-247"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M399.759,329.736 C397.754,329.736 396.129,331.361 396.129,333.365 C396.129,335.37 397.754,336.995 399.759,336.995 C401.763,336.995 403.388,335.37 403.388,333.365 C403.388,331.361 401.763,329.736 399.759,329.736"
                  id="Fill-248"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,301.485 C397.754,301.485 396.129,303.11 396.129,305.115 C396.129,307.119 397.754,308.744 399.759,308.744 C401.763,308.744 403.388,307.119 403.388,305.115 C403.388,303.11 401.763,301.485 399.759,301.485"
                  id="Fill-249"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,310.902 C397.754,310.902 396.129,312.527 396.129,314.531 C396.129,316.536 397.754,318.161 399.759,318.161 C401.763,318.161 403.388,316.536 403.388,314.531 C403.388,312.527 401.763,310.902 399.759,310.902"
                  id="Fill-250"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,216.731 C387.851,216.731 386.225,218.357 386.225,220.361 C386.225,222.365 387.851,223.99 389.855,223.99 C391.859,223.99 393.485,222.365 393.485,220.361 C393.485,218.357 391.859,216.731 389.855,216.731"
                  id="Fill-251"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M399.759,348.57 C397.754,348.57 396.129,350.195 396.129,352.199 C396.129,354.204 397.754,355.829 399.759,355.829 C401.763,355.829 403.388,354.204 403.388,352.199 C403.388,350.195 401.763,348.57 399.759,348.57"
                  id="Fill-252"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,235.565 C387.851,235.565 386.225,237.191 386.225,239.195 C386.225,241.199 387.851,242.824 389.855,242.824 C391.859,242.824 393.485,241.199 393.485,239.195 C393.485,237.191 391.859,235.565 389.855,235.565"
                  id="Fill-253"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M389.855,226.148 C387.851,226.148 386.225,227.773 386.225,229.778 C386.225,231.782 387.851,233.407 389.855,233.407 C391.859,233.407 393.485,231.782 393.485,229.778 C393.485,227.773 391.859,226.148 389.855,226.148"
                  id="Fill-254"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M379.952,216.731 C377.947,216.731 376.322,218.357 376.322,220.361 C376.322,222.365 377.947,223.99 379.952,223.99 C381.956,223.99 383.581,222.365 383.581,220.361 C383.581,218.357 381.956,216.731 379.952,216.731"
                  id="Fill-255"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M389.855,207.314 C387.851,207.314 386.225,208.939 386.225,210.944 C386.225,212.948 387.851,214.574 389.855,214.574 C391.859,214.574 393.485,212.948 393.485,210.944 C393.485,208.939 391.859,207.314 389.855,207.314"
                  id="Fill-256"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,292.068 C576.012,292.068 574.387,293.693 574.387,295.697 C574.387,297.702 576.012,299.327 578.017,299.327 C580.02,299.327 581.646,297.702 581.646,295.697 C581.646,293.693 580.02,292.068 578.017,292.068"
                  id="Fill-257"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,282.651 C576.012,282.651 574.387,284.276 574.387,286.28 C574.387,288.285 576.012,289.909 578.017,289.909 C580.02,289.909 581.646,288.285 581.646,286.28 C581.646,284.276 580.02,282.651 578.017,282.651"
                  id="Fill-258"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,263.817 C566.108,263.817 564.483,265.442 564.483,267.447 C564.483,269.451 566.108,271.076 568.113,271.076 C570.117,271.076 571.743,269.451 571.743,267.447 C571.743,265.442 570.117,263.817 568.113,263.817"
                  id="Fill-259"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,273.234 C576.012,273.234 574.387,274.858 574.387,276.863 C574.387,278.867 576.012,280.493 578.017,280.493 C580.02,280.493 581.646,278.867 581.646,276.863 C581.646,274.858 580.02,273.234 578.017,273.234"
                  id="Fill-260"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,301.485 C585.915,301.485 584.29,303.11 584.29,305.115 C584.29,307.119 585.915,308.744 587.919,308.744 C589.924,308.744 591.549,307.119 591.549,305.115 C591.549,303.11 589.924,301.485 587.919,301.485"
                  id="Fill-261"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,273.234 C566.108,273.234 564.483,274.858 564.483,276.863 C564.483,278.867 566.108,280.493 568.113,280.493 C570.117,280.493 571.743,278.867 571.743,276.863 C571.743,274.858 570.117,273.234 568.113,273.234"
                  id="Fill-262"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,282.651 C566.108,282.651 564.483,284.276 564.483,286.28 C564.483,288.285 566.108,289.909 568.113,289.909 C570.117,289.909 571.743,288.285 571.743,286.28 C571.743,284.276 570.117,282.651 568.113,282.651"
                  id="Fill-263"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,357.987 C645.334,357.987 643.709,359.611 643.709,361.616 C643.709,363.621 645.334,365.247 647.339,365.247 C649.344,365.247 650.968,363.621 650.968,361.616 C650.968,359.611 649.344,357.987 647.339,357.987"
                  id="Fill-264"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,310.902 C655.238,310.902 653.612,312.527 653.612,314.531 C653.612,316.536 655.238,318.161 657.242,318.161 C659.246,318.161 660.871,316.536 660.871,314.531 C660.871,312.527 659.246,310.902 657.242,310.902"
                  id="Fill-265"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,282.651 C655.238,282.651 653.612,284.276 653.612,286.28 C653.612,288.285 655.238,289.909 657.242,289.909 C659.246,289.909 660.871,288.285 660.871,286.28 C660.871,284.276 659.246,282.651 657.242,282.651"
                  id="Fill-266"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,263.817 C655.238,263.817 653.612,265.442 653.612,267.447 C653.612,269.451 655.238,271.076 657.242,271.076 C659.246,271.076 660.871,269.451 660.871,267.447 C660.871,265.442 659.246,263.817 657.242,263.817"
                  id="Fill-267"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,292.068 C655.238,292.068 653.612,293.693 653.612,295.697 C653.612,297.702 655.238,299.327 657.242,299.327 C659.246,299.327 660.871,297.702 660.871,295.697 C660.871,293.693 659.246,292.068 657.242,292.068"
                  id="Fill-268"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,386.238 C665.141,386.238 663.515,387.864 663.515,389.867 C663.515,391.872 665.141,393.497 667.146,393.497 C669.15,393.497 670.775,391.872 670.775,389.867 C670.775,387.864 669.15,386.238 667.146,386.238"
                  id="Fill-269"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,367.404 C665.141,367.404 663.515,369.028 663.515,371.033 C663.515,373.038 665.141,374.662 667.146,374.662 C669.15,374.662 670.775,373.038 670.775,371.033 C670.775,369.028 669.15,367.404 667.146,367.404"
                  id="Fill-270"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,339.153 C665.141,339.153 663.515,340.778 663.515,342.782 C663.515,344.787 665.141,346.412 667.146,346.412 C669.15,346.412 670.775,344.787 670.775,342.782 C670.775,340.778 669.15,339.153 667.146,339.153"
                  id="Fill-271"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,348.57 C665.141,348.57 663.515,350.195 663.515,352.199 C663.515,354.204 665.141,355.829 667.146,355.829 C669.15,355.829 670.775,354.204 670.775,352.199 C670.775,350.195 669.15,348.57 667.146,348.57"
                  id="Fill-272"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,357.987 C665.141,357.987 663.515,359.611 663.515,361.616 C663.515,363.621 665.141,365.247 667.146,365.247 C669.15,365.247 670.775,363.621 670.775,361.616 C670.775,359.611 669.15,357.987 667.146,357.987"
                  id="Fill-273"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,348.57 C655.238,348.57 653.612,350.195 653.612,352.199 C653.612,354.204 655.238,355.829 657.242,355.829 C659.246,355.829 660.871,354.204 660.871,352.199 C660.871,350.195 659.246,348.57 657.242,348.57"
                  id="Fill-274"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,367.404 C655.238,367.404 653.612,369.028 653.612,371.033 C653.612,373.038 655.238,374.662 657.242,374.662 C659.246,374.662 660.871,373.038 660.871,371.033 C660.871,369.028 659.246,367.404 657.242,367.404"
                  id="Fill-275"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,292.068 C645.334,292.068 643.709,293.693 643.709,295.697 C643.709,297.702 645.334,299.327 647.339,299.327 C649.344,299.327 650.968,297.702 650.968,295.697 C650.968,293.693 649.344,292.068 647.339,292.068"
                  id="Fill-276"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M736.468,320.319 C734.463,320.319 732.839,321.944 732.839,323.949 C732.839,325.953 734.463,327.577 736.468,327.577 C738.473,327.577 740.097,325.953 740.097,323.949 C740.097,321.944 738.473,320.319 736.468,320.319"
                  id="Fill-277"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,329.736 C645.334,329.736 643.709,331.361 643.709,333.365 C643.709,335.37 645.334,336.995 647.339,336.995 C649.344,336.995 650.968,335.37 650.968,333.365 C650.968,331.361 649.344,329.736 647.339,329.736"
                  id="Fill-278"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,282.651 C645.334,282.651 643.709,284.276 643.709,286.28 C643.709,288.285 645.334,289.909 647.339,289.909 C649.344,289.909 650.968,288.285 650.968,286.28 C650.968,284.276 649.344,282.651 647.339,282.651"
                  id="Fill-279"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,339.153 C655.238,339.153 653.612,340.778 653.612,342.782 C653.612,344.787 655.238,346.412 657.242,346.412 C659.246,346.412 660.871,344.787 660.871,342.782 C660.871,340.778 659.246,339.153 657.242,339.153"
                  id="Fill-280"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,339.153 C645.334,339.153 643.709,340.778 643.709,342.782 C643.709,344.787 645.334,346.412 647.339,346.412 C649.344,346.412 650.968,344.787 650.968,342.782 C650.968,340.778 649.344,339.153 647.339,339.153"
                  id="Fill-281"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,329.736 C655.238,329.736 653.612,331.361 653.612,333.365 C653.612,335.37 655.238,336.995 657.242,336.995 C659.246,336.995 660.871,335.37 660.871,333.365 C660.871,331.361 659.246,329.736 657.242,329.736"
                  id="Fill-282"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M667.146,329.736 C665.141,329.736 663.515,331.361 663.515,333.365 C663.515,335.37 665.141,336.995 667.146,336.995 C669.15,336.995 670.775,335.37 670.775,333.365 C670.775,331.361 669.15,329.736 667.146,329.736"
                  id="Fill-283"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,357.987 C655.238,357.987 653.612,359.611 653.612,361.616 C653.612,363.621 655.238,365.247 657.242,365.247 C659.246,365.247 660.871,363.621 660.871,361.616 C660.871,359.611 659.246,357.987 657.242,357.987"
                  id="Fill-284"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,320.319 C704.753,320.319 703.129,321.944 703.129,323.949 C703.129,325.953 704.753,327.577 706.758,327.577 C708.763,327.577 710.388,325.953 710.388,323.949 C710.388,321.944 708.763,320.319 706.758,320.319"
                  id="Fill-285"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,376.821 C704.753,376.821 703.129,378.445 703.129,380.45 C703.129,382.455 704.753,384.081 706.758,384.081 C708.763,384.081 710.388,382.455 710.388,380.45 C710.388,378.445 708.763,376.821 706.758,376.821"
                  id="Fill-286"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,301.485 C704.753,301.485 703.129,303.11 703.129,305.115 C703.129,307.119 704.753,308.744 706.758,308.744 C708.763,308.744 710.388,307.119 710.388,305.115 C710.388,303.11 708.763,301.485 706.758,301.485"
                  id="Fill-287"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,386.238 C704.753,386.238 703.129,387.864 703.129,389.867 C703.129,391.872 704.753,393.497 706.758,393.497 C708.763,393.497 710.388,391.872 710.388,389.867 C710.388,387.864 708.763,386.238 706.758,386.238"
                  id="Fill-288"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,395.655 C704.753,395.655 703.129,397.281 703.129,399.284 C703.129,401.289 704.753,402.915 706.758,402.915 C708.763,402.915 710.388,401.289 710.388,399.284 C710.388,397.281 708.763,395.655 706.758,395.655"
                  id="Fill-289"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,320.319 C714.656,320.319 713.032,321.944 713.032,323.949 C713.032,325.953 714.656,327.577 716.661,327.577 C718.666,327.577 720.292,325.953 720.292,323.949 C720.292,321.944 718.666,320.319 716.661,320.319"
                  id="Fill-290"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,386.238 C714.656,386.238 713.032,387.864 713.032,389.867 C713.032,391.872 714.656,393.497 716.661,393.497 C718.666,393.497 720.292,391.872 720.292,389.867 C720.292,387.864 718.666,386.238 716.661,386.238"
                  id="Fill-291"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,254.399 C704.753,254.399 703.129,256.025 703.129,258.029 C703.129,260.033 704.753,261.658 706.758,261.658 C708.763,261.658 710.388,260.033 710.388,258.029 C710.388,256.025 708.763,254.399 706.758,254.399"
                  id="Fill-292"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M667.146,320.319 C665.141,320.319 663.515,321.944 663.515,323.949 C663.515,325.953 665.141,327.577 667.146,327.577 C669.15,327.577 670.775,325.953 670.775,323.949 C670.775,321.944 669.15,320.319 667.146,320.319"
                  id="Fill-293"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,395.655 C714.656,395.655 713.032,397.281 713.032,399.284 C713.032,401.289 714.656,402.915 716.661,402.915 C718.666,402.915 720.292,401.289 720.292,399.284 C720.292,397.281 718.666,395.655 716.661,395.655"
                  id="Fill-294"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,348.57 C675.044,348.57 673.419,350.195 673.419,352.199 C673.419,354.204 675.044,355.829 677.048,355.829 C679.053,355.829 680.678,354.204 680.678,352.199 C680.678,350.195 679.053,348.57 677.048,348.57"
                  id="Fill-295"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,292.068 C665.141,292.068 663.515,293.693 663.515,295.697 C663.515,297.702 665.141,299.327 667.146,299.327 C669.15,299.327 670.775,297.702 670.775,295.697 C670.775,293.693 669.15,292.068 667.146,292.068"
                  id="Fill-296"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,357.987 C675.044,357.987 673.419,359.611 673.419,361.616 C673.419,363.621 675.044,365.247 677.048,365.247 C679.053,365.247 680.678,363.621 680.678,361.616 C680.678,359.611 679.053,357.987 677.048,357.987"
                  id="Fill-297"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,301.485 C665.141,301.485 663.515,303.11 663.515,305.115 C663.515,307.119 665.141,308.744 667.146,308.744 C669.15,308.744 670.775,307.119 670.775,305.115 C670.775,303.11 669.15,301.485 667.146,301.485"
                  id="Fill-298"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,301.485 C675.044,301.485 673.419,303.11 673.419,305.115 C673.419,307.119 675.044,308.744 677.048,308.744 C679.053,308.744 680.678,307.119 680.678,305.115 C680.678,303.11 679.053,301.485 677.048,301.485"
                  id="Fill-299"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,339.153 C675.044,339.153 673.419,340.778 673.419,342.782 C673.419,344.787 675.044,346.412 677.048,346.412 C679.053,346.412 680.678,344.787 680.678,342.782 C680.678,340.778 679.053,339.153 677.048,339.153"
                  id="Fill-300"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,292.068 C675.044,292.068 673.419,293.693 673.419,295.697 C673.419,297.702 675.044,299.327 677.048,299.327 C679.053,299.327 680.678,297.702 680.678,295.697 C680.678,293.693 679.053,292.068 677.048,292.068"
                  id="Fill-301"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,405.072 C704.753,405.072 703.129,406.698 703.129,408.703 C703.129,410.706 704.753,412.331 706.758,412.331 C708.763,412.331 710.388,410.706 710.388,408.703 C710.388,406.698 708.763,405.072 706.758,405.072"
                  id="Fill-302"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,320.319 C655.238,320.319 653.612,321.944 653.612,323.949 C653.612,325.953 655.238,327.577 657.242,327.577 C659.246,327.577 660.871,325.953 660.871,323.949 C660.871,321.944 659.246,320.319 657.242,320.319"
                  id="Fill-303"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M617.629,320.319 C615.624,320.319 614,321.944 614,323.949 C614,325.953 615.624,327.577 617.629,327.577 C619.634,327.577 621.259,325.953 621.259,323.949 C621.259,321.944 619.634,320.319 617.629,320.319"
                  id="Fill-304"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M617.629,357.987 C615.624,357.987 614,359.611 614,361.616 C614,363.621 615.624,365.247 617.629,365.247 C619.634,365.247 621.259,363.621 621.259,361.616 C621.259,359.611 619.634,357.987 617.629,357.987"
                  id="Fill-305"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,329.736 C615.624,329.736 614,331.361 614,333.365 C614,335.37 615.624,336.995 617.629,336.995 C619.634,336.995 621.259,335.37 621.259,333.365 C621.259,331.361 619.634,329.736 617.629,329.736"
                  id="Fill-306"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,348.57 C615.624,348.57 614,350.195 614,352.199 C614,354.204 615.624,355.829 617.629,355.829 C619.634,355.829 621.259,354.204 621.259,352.199 C621.259,350.195 619.634,348.57 617.629,348.57"
                  id="Fill-307"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,339.153 C615.624,339.153 614,340.778 614,342.782 C614,344.787 615.624,346.412 617.629,346.412 C619.634,346.412 621.259,344.787 621.259,342.782 C621.259,340.778 619.634,339.153 617.629,339.153"
                  id="Fill-308"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,301.485 C615.624,301.485 614,303.11 614,305.115 C614,307.119 615.624,308.744 617.629,308.744 C619.634,308.744 621.259,307.119 621.259,305.115 C621.259,303.11 619.634,301.485 617.629,301.485"
                  id="Fill-309"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,282.651 C595.819,282.651 594.193,284.276 594.193,286.28 C594.193,288.285 595.819,289.909 597.822,289.909 C599.827,289.909 601.453,288.285 601.453,286.28 C601.453,284.276 599.827,282.651 597.822,282.651"
                  id="Fill-310"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,273.234 C595.819,273.234 594.193,274.858 594.193,276.863 C594.193,278.867 595.819,280.493 597.822,280.493 C599.827,280.493 601.453,278.867 601.453,276.863 C601.453,274.858 599.827,273.234 597.822,273.234"
                  id="Fill-311"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,292.068 C615.624,292.068 614,293.693 614,295.697 C614,297.702 615.624,299.327 617.629,299.327 C619.634,299.327 621.259,297.702 621.259,295.697 C621.259,293.693 619.634,292.068 617.629,292.068"
                  id="Fill-312"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,254.399 C605.722,254.399 604.097,256.025 604.097,258.029 C604.097,260.033 605.722,261.658 607.726,261.658 C609.73,261.658 611.356,260.033 611.356,258.029 C611.356,256.025 609.73,254.399 607.726,254.399"
                  id="Fill-313"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,282.651 C605.722,282.651 604.097,284.276 604.097,286.28 C604.097,288.285 605.722,289.909 607.726,289.909 C609.73,289.909 611.356,288.285 611.356,286.28 C611.356,284.276 609.73,282.651 607.726,282.651"
                  id="Fill-314"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,263.817 C605.722,263.817 604.097,265.442 604.097,267.447 C604.097,269.451 605.722,271.076 607.726,271.076 C609.73,271.076 611.356,269.451 611.356,267.447 C611.356,265.442 609.73,263.817 607.726,263.817"
                  id="Fill-315"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,348.57 C645.334,348.57 643.709,350.195 643.709,352.199 C643.709,354.204 645.334,355.829 647.339,355.829 C649.344,355.829 650.968,354.204 650.968,352.199 C650.968,350.195 649.344,348.57 647.339,348.57"
                  id="Fill-316"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,301.485 C595.819,301.485 594.193,303.11 594.193,305.115 C594.193,307.119 595.819,308.744 597.822,308.744 C599.827,308.744 601.453,307.119 601.453,305.115 C601.453,303.11 599.827,301.485 597.822,301.485"
                  id="Fill-317"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,357.987 C605.722,357.987 604.097,359.611 604.097,361.616 C604.097,363.621 605.722,365.247 607.726,365.247 C609.73,365.247 611.356,363.621 611.356,361.616 C611.356,359.611 609.73,357.987 607.726,357.987"
                  id="Fill-318"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,367.404 C605.722,367.404 604.097,369.028 604.097,371.033 C604.097,373.038 605.722,374.662 607.726,374.662 C609.73,374.662 611.356,373.038 611.356,371.033 C611.356,369.028 609.73,367.404 607.726,367.404"
                  id="Fill-319"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,348.57 C605.722,348.57 604.097,350.195 604.097,352.199 C604.097,354.204 605.722,355.829 607.726,355.829 C609.73,355.829 611.356,354.204 611.356,352.199 C611.356,350.195 609.73,348.57 607.726,348.57"
                  id="Fill-320"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,339.153 C605.722,339.153 604.097,340.778 604.097,342.782 C604.097,344.787 605.722,346.412 607.726,346.412 C609.73,346.412 611.356,344.787 611.356,342.782 C611.356,340.778 609.73,339.153 607.726,339.153"
                  id="Fill-321"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,329.736 C605.722,329.736 604.097,331.361 604.097,333.365 C604.097,335.37 605.722,336.995 607.726,336.995 C609.73,336.995 611.356,335.37 611.356,333.365 C611.356,331.361 609.73,329.736 607.726,329.736"
                  id="Fill-322"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,273.234 C605.722,273.234 604.097,274.858 604.097,276.863 C604.097,278.867 605.722,280.493 607.726,280.493 C609.73,280.493 611.356,278.867 611.356,276.863 C611.356,274.858 609.73,273.234 607.726,273.234"
                  id="Fill-323"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,348.57 C635.431,348.57 633.805,350.195 633.805,352.199 C633.805,354.204 635.431,355.829 637.436,355.829 C639.441,355.829 641.065,354.204 641.065,352.199 C641.065,350.195 639.441,348.57 637.436,348.57"
                  id="Fill-324"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,339.153 C595.819,339.153 594.193,340.778 594.193,342.782 C594.193,344.787 595.819,346.412 597.822,346.412 C599.827,346.412 601.453,344.787 601.453,342.782 C601.453,340.778 599.827,339.153 597.822,339.153"
                  id="Fill-325"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,339.153 C635.431,339.153 633.805,340.778 633.805,342.782 C633.805,344.787 635.431,346.412 637.436,346.412 C639.441,346.412 641.065,344.787 641.065,342.782 C641.065,340.778 639.441,339.153 637.436,339.153"
                  id="Fill-326"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,357.987 C635.431,357.987 633.805,359.611 633.805,361.616 C633.805,363.621 635.431,365.247 637.436,365.247 C639.441,365.247 641.065,363.621 641.065,361.616 C641.065,359.611 639.441,357.987 637.436,357.987"
                  id="Fill-327"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,329.736 C635.431,329.736 633.805,331.361 633.805,333.365 C633.805,335.37 635.431,336.995 637.436,336.995 C639.441,336.995 641.065,335.37 641.065,333.365 C641.065,331.361 639.441,329.736 637.436,329.736"
                  id="Fill-328"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,263.817 C635.431,263.817 633.805,265.442 633.805,267.447 C633.805,269.451 635.431,271.076 637.436,271.076 C639.441,271.076 641.065,269.451 641.065,267.447 C641.065,265.442 639.441,263.817 637.436,263.817"
                  id="Fill-329"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,282.651 C635.431,282.651 633.805,284.276 633.805,286.28 C633.805,288.285 635.431,289.909 637.436,289.909 C639.441,289.909 641.065,288.285 641.065,286.28 C641.065,284.276 639.441,282.651 637.436,282.651"
                  id="Fill-330"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M637.436,310.902 C635.431,310.902 633.805,312.527 633.805,314.531 C633.805,316.536 635.431,318.161 637.436,318.161 C639.441,318.161 641.065,316.536 641.065,314.531 C641.065,312.527 639.441,310.902 637.436,310.902"
                  id="Fill-331"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,320.319 C635.431,320.319 633.805,321.944 633.805,323.949 C633.805,325.953 635.431,327.577 637.436,327.577 C639.441,327.577 641.065,325.953 641.065,323.949 C641.065,321.944 639.441,320.319 637.436,320.319"
                  id="Fill-332"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,301.485 C625.529,301.485 623.903,303.11 623.903,305.115 C623.903,307.119 625.529,308.744 627.532,308.744 C629.537,308.744 631.161,307.119 631.161,305.115 C631.161,303.11 629.537,301.485 627.532,301.485"
                  id="Fill-333"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,320.319 C625.529,320.319 623.903,321.944 623.903,323.949 C623.903,325.953 625.529,327.577 627.532,327.577 C629.537,327.577 631.161,325.953 631.161,323.949 C631.161,321.944 629.537,320.319 627.532,320.319"
                  id="Fill-334"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,244.982 C615.624,244.982 614,246.607 614,248.612 C614,250.616 615.624,252.242 617.629,252.242 C619.634,252.242 621.259,250.616 621.259,248.612 C621.259,246.607 619.634,244.982 617.629,244.982"
                  id="Fill-335"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,357.987 C625.529,357.987 623.903,359.611 623.903,361.616 C623.903,363.621 625.529,365.247 627.532,365.247 C629.537,365.247 631.161,363.621 631.161,361.616 C631.161,359.611 629.537,357.987 627.532,357.987"
                  id="Fill-336"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,357.987 C595.819,357.987 594.193,359.611 594.193,361.616 C594.193,363.621 595.819,365.247 597.822,365.247 C599.827,365.247 601.453,363.621 601.453,361.616 C601.453,359.611 599.827,357.987 597.822,357.987"
                  id="Fill-337"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,254.399 C615.624,254.399 614,256.025 614,258.029 C614,260.033 615.624,261.658 617.629,261.658 C619.634,261.658 621.259,260.033 621.259,258.029 C621.259,256.025 619.634,254.399 617.629,254.399"
                  id="Fill-338"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,348.57 C625.529,348.57 623.903,350.195 623.903,352.199 C623.903,354.204 625.529,355.829 627.532,355.829 C629.537,355.829 631.161,354.204 631.161,352.199 C631.161,350.195 629.537,348.57 627.532,348.57"
                  id="Fill-339"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M627.532,329.736 C625.529,329.736 623.903,331.361 623.903,333.365 C623.903,335.37 625.529,336.995 627.532,336.995 C629.537,336.995 631.161,335.37 631.161,333.365 C631.161,331.361 629.537,329.736 627.532,329.736"
                  id="Fill-340"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,339.153 C625.529,339.153 623.903,340.778 623.903,342.782 C623.903,344.787 625.529,346.412 627.532,346.412 C629.537,346.412 631.161,344.787 631.161,342.782 C631.161,340.778 629.537,339.153 627.532,339.153"
                  id="Fill-341"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,348.57 C595.819,348.57 594.193,350.195 594.193,352.199 C594.193,354.204 595.819,355.829 597.822,355.829 C599.827,355.829 601.453,354.204 601.453,352.199 C601.453,350.195 599.827,348.57 597.822,348.57"
                  id="Fill-342"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,292.068 C209.593,292.068 207.967,293.693 207.967,295.697 C207.967,297.702 209.593,299.327 211.597,299.327 C213.602,299.327 215.226,297.702 215.226,295.697 C215.226,293.693 213.602,292.068 211.597,292.068"
                  id="Fill-343"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M152.178,216.731 C150.173,216.731 148.548,218.357 148.548,220.361 C148.548,222.365 150.173,223.99 152.178,223.99 C154.183,223.99 155.808,222.365 155.808,220.361 C155.808,218.357 154.183,216.731 152.178,216.731"
                  id="Fill-344"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,273.234 C189.786,273.234 188.162,274.858 188.162,276.863 C188.162,278.867 189.786,280.493 191.79,280.493 C193.795,280.493 195.42,278.867 195.42,276.863 C195.42,274.858 193.795,273.234 191.79,273.234"
                  id="Fill-345"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,282.651 C209.593,282.651 207.967,284.276 207.967,286.28 C207.967,288.285 209.593,289.909 211.597,289.909 C213.602,289.909 215.226,288.285 215.226,286.28 C215.226,284.276 213.602,282.651 211.597,282.651"
                  id="Fill-346"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,263.817 C189.786,263.817 188.162,265.442 188.162,267.447 C188.162,269.451 189.786,271.076 191.79,271.076 C193.795,271.076 195.42,269.451 195.42,267.447 C195.42,265.442 193.795,263.817 191.79,263.817"
                  id="Fill-347"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,301.485 C189.786,301.485 188.162,303.11 188.162,305.115 C188.162,307.119 189.786,308.744 191.79,308.744 C193.795,308.744 195.42,307.119 195.42,305.115 C195.42,303.11 193.795,301.485 191.79,301.485"
                  id="Fill-348"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,292.068 C189.786,292.068 188.162,293.693 188.162,295.697 C188.162,297.702 189.786,299.327 191.79,299.327 C193.795,299.327 195.42,297.702 195.42,295.697 C195.42,293.693 193.795,292.068 191.79,292.068"
                  id="Fill-349"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,282.651 C189.786,282.651 188.162,284.276 188.162,286.28 C188.162,288.285 189.786,289.909 191.79,289.909 C193.795,289.909 195.42,288.285 195.42,286.28 C195.42,284.276 193.795,282.651 191.79,282.651"
                  id="Fill-350"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,310.902 C209.593,310.902 207.967,312.527 207.967,314.531 C207.967,316.536 209.593,318.161 211.597,318.161 C213.602,318.161 215.226,316.536 215.226,314.531 C215.226,312.527 213.602,310.902 211.597,310.902"
                  id="Fill-351"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,348.57 C209.593,348.57 207.967,350.195 207.967,352.199 C207.967,354.204 209.593,355.829 211.597,355.829 C213.602,355.829 215.226,354.204 215.226,352.199 C215.226,350.195 213.602,348.57 211.597,348.57"
                  id="Fill-352"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,357.987 C209.593,357.987 207.967,359.611 207.967,361.616 C207.967,363.621 209.593,365.247 211.597,365.247 C213.602,365.247 215.226,363.621 215.226,361.616 C215.226,359.611 213.602,357.987 211.597,357.987"
                  id="Fill-353"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,339.153 C209.593,339.153 207.967,340.778 207.967,342.782 C207.967,344.787 209.593,346.412 211.597,346.412 C213.602,346.412 215.226,344.787 215.226,342.782 C215.226,340.778 213.602,339.153 211.597,339.153"
                  id="Fill-354"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,320.319 C209.593,320.319 207.967,321.944 207.967,323.949 C207.967,325.953 209.593,327.577 211.597,327.577 C213.602,327.577 215.226,325.953 215.226,323.949 C215.226,321.944 213.602,320.319 211.597,320.319"
                  id="Fill-355"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,329.736 C209.593,329.736 207.967,331.361 207.967,333.365 C207.967,335.37 209.593,336.995 211.597,336.995 C213.602,336.995 215.226,335.37 215.226,333.365 C215.226,331.361 213.602,329.736 211.597,329.736"
                  id="Fill-356"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,254.399 C179.883,254.399 178.258,256.025 178.258,258.029 C178.258,260.033 179.883,261.658 181.887,261.658 C183.892,261.658 185.517,260.033 185.517,258.029 C185.517,256.025 183.892,254.399 181.887,254.399"
                  id="Fill-357"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,301.485 C209.593,301.485 207.967,303.11 207.967,305.115 C207.967,307.119 209.593,308.744 211.597,308.744 C213.602,308.744 215.226,307.119 215.226,305.115 C215.226,303.11 213.602,301.485 211.597,301.485"
                  id="Fill-358"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,357.987 C219.496,357.987 217.871,359.611 217.871,361.616 C217.871,363.621 219.496,365.247 221.501,365.247 C223.505,365.247 225.13,363.621 225.13,361.616 C225.13,359.611 223.505,357.987 221.501,357.987"
                  id="Fill-359"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,273.234 C209.593,273.234 207.967,274.858 207.967,276.863 C207.967,278.867 209.593,280.493 211.597,280.493 C213.602,280.493 215.226,278.867 215.226,276.863 C215.226,274.858 213.602,273.234 211.597,273.234"
                  id="Fill-360"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,216.731 C110.561,216.731 108.935,218.357 108.935,220.361 C108.935,222.365 110.561,223.99 112.565,223.99 C114.569,223.99 116.195,222.365 116.195,220.361 C116.195,218.357 114.569,216.731 112.565,216.731"
                  id="Fill-361"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,254.399 C209.593,254.399 207.967,256.025 207.967,258.029 C207.967,260.033 209.593,261.658 211.597,261.658 C213.602,261.658 215.226,260.033 215.226,258.029 C215.226,256.025 213.602,254.399 211.597,254.399"
                  id="Fill-362"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,386.238 C219.496,386.238 217.871,387.864 217.871,389.867 C217.871,391.872 219.496,393.497 221.501,393.497 C223.505,393.497 225.13,391.872 225.13,389.867 C225.13,387.864 223.505,386.238 221.501,386.238"
                  id="Fill-363"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,442.74 C219.496,442.74 217.871,444.366 217.871,446.369 C217.871,448.374 219.496,450 221.501,450 C223.505,450 225.13,448.374 225.13,446.369 C225.13,444.366 223.505,442.74 221.501,442.74"
                  id="Fill-365"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,367.404 C209.593,367.404 207.967,369.028 207.967,371.033 C207.967,373.038 209.593,374.662 211.597,374.662 C213.602,374.662 215.226,373.038 215.226,371.033 C215.226,369.028 213.602,367.404 211.597,367.404"
                  id="Fill-367"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,367.404 C219.496,367.404 217.871,369.028 217.871,371.033 C217.871,373.038 219.496,374.662 221.501,374.662 C223.505,374.662 225.13,373.038 225.13,371.033 C225.13,369.028 223.505,367.404 221.501,367.404"
                  id="Fill-368"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,263.817 C179.883,263.817 178.258,265.442 178.258,267.447 C178.258,269.451 179.883,271.076 181.887,271.076 C183.892,271.076 185.517,269.451 185.517,267.447 C185.517,265.442 183.892,263.817 181.887,263.817"
                  id="Fill-369"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,376.821 C219.496,376.821 217.871,378.445 217.871,380.45 C217.871,382.455 219.496,384.081 221.501,384.081 C223.505,384.081 225.13,382.455 225.13,380.45 C225.13,378.445 223.505,376.821 221.501,376.821"
                  id="Fill-370"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,292.068 C179.883,292.068 178.258,293.693 178.258,295.697 C178.258,297.702 179.883,299.327 181.887,299.327 C183.892,299.327 185.517,297.702 185.517,295.697 C185.517,293.693 183.892,292.068 181.887,292.068"
                  id="Fill-371"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,235.565 C130.367,235.565 128.742,237.191 128.742,239.195 C128.742,241.199 130.367,242.824 132.371,242.824 C134.376,242.824 136.001,241.199 136.001,239.195 C136.001,237.191 134.376,235.565 132.371,235.565"
                  id="Fill-372"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,282.651 C179.883,282.651 178.258,284.276 178.258,286.28 C178.258,288.285 179.883,289.909 181.887,289.909 C183.892,289.909 185.517,288.285 185.517,286.28 C185.517,284.276 183.892,282.651 181.887,282.651"
                  id="Fill-373"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,235.565 C169.98,235.565 168.355,237.191 168.355,239.195 C168.355,241.199 169.98,242.824 171.985,242.824 C173.989,242.824 175.613,241.199 175.613,239.195 C175.613,237.191 173.989,235.565 171.985,235.565"
                  id="Fill-374"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,226.148 C130.367,226.148 128.742,227.773 128.742,229.778 C128.742,231.782 130.367,233.407 132.371,233.407 C134.376,233.407 136.001,231.782 136.001,229.778 C136.001,227.773 134.376,226.148 132.371,226.148"
                  id="Fill-375"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,244.982 C169.98,244.982 168.355,246.607 168.355,248.612 C168.355,250.616 169.98,252.242 171.985,252.242 C173.989,252.242 175.613,250.616 175.613,248.612 C175.613,246.607 173.989,244.982 171.985,244.982"
                  id="Fill-376"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,216.731 C130.367,216.731 128.742,218.357 128.742,220.361 C128.742,222.365 130.367,223.99 132.371,223.99 C134.376,223.99 136.001,222.365 136.001,220.361 C136.001,218.357 134.376,216.731 132.371,216.731"
                  id="Fill-377"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,357.987 C199.69,357.987 198.064,359.611 198.064,361.616 C198.064,363.621 199.69,365.247 201.694,365.247 C203.698,365.247 205.324,363.621 205.324,361.616 C205.324,359.611 203.698,357.987 201.694,357.987"
                  id="Fill-378"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,339.153 C199.69,339.153 198.064,340.778 198.064,342.782 C198.064,344.787 199.69,346.412 201.694,346.412 C203.698,346.412 205.324,344.787 205.324,342.782 C205.324,340.778 203.698,339.153 201.694,339.153"
                  id="Fill-379"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,320.319 C199.69,320.319 198.064,321.944 198.064,323.949 C198.064,325.953 199.69,327.577 201.694,327.577 C203.698,327.577 205.324,325.953 205.324,323.949 C205.324,321.944 203.698,320.319 201.694,320.319"
                  id="Fill-380"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,329.736 C199.69,329.736 198.064,331.361 198.064,333.365 C198.064,335.37 199.69,336.995 201.694,336.995 C203.698,336.995 205.324,335.37 205.324,333.365 C205.324,331.361 203.698,329.736 201.694,329.736"
                  id="Fill-381"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,301.485 C199.69,301.485 198.064,303.11 198.064,305.115 C198.064,307.119 199.69,308.744 201.694,308.744 C203.698,308.744 205.324,307.119 205.324,305.115 C205.324,303.11 203.698,301.485 201.694,301.485"
                  id="Fill-382"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,310.902 C199.69,310.902 198.064,312.527 198.064,314.531 C198.064,316.536 199.69,318.161 201.694,318.161 C203.698,318.161 205.324,316.536 205.324,314.531 C205.324,312.527 203.698,310.902 201.694,310.902"
                  id="Fill-383"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,414.489 C199.69,414.489 198.064,416.115 198.064,418.118 C198.064,420.123 199.69,421.749 201.694,421.749 C203.698,421.749 205.324,420.123 205.324,418.118 C205.324,416.115 203.698,414.489 201.694,414.489"
                  id="Fill-384"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,292.068 C199.69,292.068 198.064,293.693 198.064,295.697 C198.064,297.702 199.69,299.327 201.694,299.327 C203.698,299.327 205.324,297.702 205.324,295.697 C205.324,293.693 203.698,292.068 201.694,292.068"
                  id="Fill-385"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,329.736 C219.496,329.736 217.871,331.361 217.871,333.365 C217.871,335.37 219.496,336.995 221.501,336.995 C223.505,336.995 225.13,335.37 225.13,333.365 C225.13,331.361 223.505,329.736 221.501,329.736"
                  id="Fill-386"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M221.501,348.57 C219.496,348.57 217.871,350.195 217.871,352.199 C217.871,354.204 219.496,355.829 221.501,355.829 C223.505,355.829 225.13,354.204 225.13,352.199 C225.13,350.195 223.505,348.57 221.501,348.57"
                  id="Fill-387"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,367.404 C199.69,367.404 198.064,369.028 198.064,371.033 C198.064,373.038 199.69,374.662 201.694,374.662 C203.698,374.662 205.324,373.038 205.324,371.033 C205.324,369.028 203.698,367.404 201.694,367.404"
                  id="Fill-388"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,376.821 C209.593,376.821 207.967,378.445 207.967,380.45 C207.967,382.455 209.593,384.081 211.597,384.081 C213.602,384.081 215.226,382.455 215.226,380.45 C215.226,378.445 213.602,376.821 211.597,376.821"
                  id="Fill-389"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,348.57 C199.69,348.57 198.064,350.195 198.064,352.199 C198.064,354.204 199.69,355.829 201.694,355.829 C203.698,355.829 205.324,354.204 205.324,352.199 C205.324,350.195 203.698,348.57 201.694,348.57"
                  id="Fill-390"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,405.072 C199.69,405.072 198.064,406.698 198.064,408.703 C198.064,410.706 199.69,412.331 201.694,412.331 C203.698,412.331 205.324,410.706 205.324,408.703 C205.324,406.698 203.698,405.072 201.694,405.072"
                  id="Fill-391"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,395.655 C199.69,395.655 198.064,397.281 198.064,399.284 C198.064,401.289 199.69,402.915 201.694,402.915 C203.698,402.915 205.324,401.289 205.324,399.284 C205.324,397.281 203.698,395.655 201.694,395.655"
                  id="Fill-392"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,376.821 C199.69,376.821 198.064,378.445 198.064,380.45 C198.064,382.455 199.69,384.081 201.694,384.081 C203.698,384.081 205.324,382.455 205.324,380.45 C205.324,378.445 203.698,376.821 201.694,376.821"
                  id="Fill-393"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,386.238 C199.69,386.238 198.064,387.864 198.064,389.867 C198.064,391.872 199.69,393.497 201.694,393.497 C203.698,393.497 205.324,391.872 205.324,389.867 C205.324,387.864 203.698,386.238 201.694,386.238"
                  id="Fill-394"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,282.651 C199.69,282.651 198.064,284.276 198.064,286.28 C198.064,288.285 199.69,289.909 201.694,289.909 C203.698,289.909 205.324,288.285 205.324,286.28 C205.324,284.276 203.698,282.651 201.694,282.651"
                  id="Fill-395"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,235.565 C189.786,235.565 188.162,237.191 188.162,239.195 C188.162,241.199 189.786,242.824 191.79,242.824 C193.795,242.824 195.42,241.199 195.42,239.195 C195.42,237.191 193.795,235.565 191.79,235.565"
                  id="Fill-396"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,226.148 C120.463,226.148 118.839,227.773 118.839,229.778 C118.839,231.782 120.463,233.407 122.468,233.407 C124.472,233.407 126.098,231.782 126.098,229.778 C126.098,227.773 124.472,226.148 122.468,226.148"
                  id="Fill-397"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,216.731 C120.463,216.731 118.839,218.357 118.839,220.361 C118.839,222.365 120.463,223.99 122.468,223.99 C124.472,223.99 126.098,222.365 126.098,220.361 C126.098,218.357 124.472,216.731 122.468,216.731"
                  id="Fill-398"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,433.323 C199.69,433.323 198.064,434.949 198.064,436.954 C198.064,438.957 199.69,440.582 201.694,440.582 C203.698,440.582 205.324,438.957 205.324,436.954 C205.324,434.949 203.698,433.323 201.694,433.323"
                  id="Fill-399"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,386.238 C209.593,386.238 207.967,387.864 207.967,389.867 C207.967,391.872 209.593,393.497 211.597,393.497 C213.602,393.497 215.226,391.872 215.226,389.867 C215.226,387.864 213.602,386.238 211.597,386.238"
                  id="Fill-400"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,405.072 C209.593,405.072 207.967,406.698 207.967,408.703 C207.967,410.706 209.593,412.331 211.597,412.331 C213.602,412.331 215.226,410.706 215.226,408.703 C215.226,406.698 213.602,405.072 211.597,405.072"
                  id="Fill-401"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,423.906 C199.69,423.906 198.064,425.532 198.064,427.535 C198.064,429.54 199.69,431.166 201.694,431.166 C203.698,431.166 205.324,429.54 205.324,427.535 C205.324,425.532 203.698,423.906 201.694,423.906"
                  id="Fill-402"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,395.655 C209.593,395.655 207.967,397.281 207.967,399.284 C207.967,401.289 209.593,402.915 211.597,402.915 C213.602,402.915 215.226,401.289 215.226,399.284 C215.226,397.281 213.602,395.655 211.597,395.655"
                  id="Fill-403"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,235.565 C199.69,235.565 198.064,237.191 198.064,239.195 C198.064,241.199 199.69,242.824 201.694,242.824 C203.698,242.824 205.324,241.199 205.324,239.195 C205.324,237.191 203.698,235.565 201.694,235.565"
                  id="Fill-404"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,273.234 C199.69,273.234 198.064,274.858 198.064,276.863 C198.064,278.867 199.69,280.493 201.694,280.493 C203.698,280.493 205.324,278.867 205.324,276.863 C205.324,274.858 203.698,273.234 201.694,273.234"
                  id="Fill-405"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,254.399 C199.69,254.399 198.064,256.025 198.064,258.029 C198.064,260.033 199.69,261.658 201.694,261.658 C203.698,261.658 205.324,260.033 205.324,258.029 C205.324,256.025 203.698,254.399 201.694,254.399"
                  id="Fill-406"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,263.817 C199.69,263.817 198.064,265.442 198.064,267.447 C198.064,269.451 199.69,271.076 201.694,271.076 C203.698,271.076 205.324,269.451 205.324,267.447 C205.324,265.442 203.698,263.817 201.694,263.817"
                  id="Fill-407"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,423.906 C209.593,423.906 207.967,425.532 207.967,427.535 C207.967,429.54 209.593,431.166 211.597,431.166 C213.602,431.166 215.226,429.54 215.226,427.535 C215.226,425.532 213.602,423.906 211.597,423.906"
                  id="Fill-412"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,433.323 C209.593,433.323 207.967,434.949 207.967,436.954 C207.967,438.957 209.593,440.582 211.597,440.582 C213.602,440.582 215.226,438.957 215.226,436.954 C215.226,434.949 213.602,433.323 211.597,433.323"
                  id="Fill-413"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,263.817 C209.593,263.817 207.967,265.442 207.967,267.447 C207.967,269.451 209.593,271.076 211.597,271.076 C213.602,271.076 215.226,269.451 215.226,267.447 C215.226,265.442 213.602,263.817 211.597,263.817"
                  id="Fill-414"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M241.307,292.068 C239.303,292.068 237.677,293.693 237.677,295.697 C237.677,297.702 239.303,299.327 241.307,299.327 C243.312,299.327 244.936,297.702 244.936,295.697 C244.936,293.693 243.312,292.068 241.307,292.068"
                  id="Fill-415"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,301.485 C239.303,301.485 237.677,303.11 237.677,305.115 C237.677,307.119 239.303,308.744 241.307,308.744 C243.312,308.744 244.936,307.119 244.936,305.115 C244.936,303.11 243.312,301.485 241.307,301.485"
                  id="Fill-416"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,282.651 C239.303,282.651 237.677,284.276 237.677,286.28 C237.677,288.285 239.303,289.909 241.307,289.909 C243.312,289.909 244.936,288.285 244.936,286.28 C244.936,284.276 243.312,282.651 241.307,282.651"
                  id="Fill-417"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M251.21,348.57 C249.206,348.57 247.58,350.195 247.58,352.199 C247.58,354.204 249.206,355.829 251.21,355.829 C253.215,355.829 254.84,354.204 254.84,352.199 C254.84,350.195 253.215,348.57 251.21,348.57"
                  id="Fill-418"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,273.234 C239.303,273.234 237.677,274.858 237.677,276.863 C237.677,278.867 239.303,280.493 241.307,280.493 C243.312,280.493 244.936,278.867 244.936,276.863 C244.936,274.858 243.312,273.234 241.307,273.234"
                  id="Fill-419"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,357.987 C239.303,357.987 237.677,359.611 237.677,361.616 C237.677,363.621 239.303,365.247 241.307,365.247 C243.312,365.247 244.936,363.621 244.936,361.616 C244.936,359.611 243.312,357.987 241.307,357.987"
                  id="Fill-420"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,339.153 C249.206,339.153 247.58,340.778 247.58,342.782 C247.58,344.787 249.206,346.412 251.21,346.412 C253.215,346.412 254.84,344.787 254.84,342.782 C254.84,340.778 253.215,339.153 251.21,339.153"
                  id="Fill-421"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,339.153 C239.303,339.153 237.677,340.778 237.677,342.782 C237.677,344.787 239.303,346.412 241.307,346.412 C243.312,346.412 244.936,344.787 244.936,342.782 C244.936,340.778 243.312,339.153 241.307,339.153"
                  id="Fill-422"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,348.57 C239.303,348.57 237.677,350.195 237.677,352.199 C237.677,354.204 239.303,355.829 241.307,355.829 C243.312,355.829 244.936,354.204 244.936,352.199 C244.936,350.195 243.312,348.57 241.307,348.57"
                  id="Fill-423"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,329.736 C239.303,329.736 237.677,331.361 237.677,333.365 C237.677,335.37 239.303,336.995 241.307,336.995 C243.312,336.995 244.936,335.37 244.936,333.365 C244.936,331.361 243.312,329.736 241.307,329.736"
                  id="Fill-424"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,320.319 C239.303,320.319 237.677,321.944 237.677,323.949 C237.677,325.953 239.303,327.577 241.307,327.577 C243.312,327.577 244.936,325.953 244.936,323.949 C244.936,321.944 243.312,320.319 241.307,320.319"
                  id="Fill-425"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,310.902 C239.303,310.902 237.677,312.527 237.677,314.531 C237.677,316.536 239.303,318.161 241.307,318.161 C243.312,318.161 244.936,316.536 244.936,314.531 C244.936,312.527 243.312,310.902 241.307,310.902"
                  id="Fill-426"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,273.234 C249.206,273.234 247.58,274.858 247.58,276.863 C247.58,278.867 249.206,280.493 251.21,280.493 C253.215,280.493 254.84,278.867 254.84,276.863 C254.84,274.858 253.215,273.234 251.21,273.234"
                  id="Fill-427"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,282.651 C249.206,282.651 247.58,284.276 247.58,286.28 C247.58,288.285 249.206,289.909 251.21,289.909 C253.215,289.909 254.84,288.285 254.84,286.28 C254.84,284.276 253.215,282.651 251.21,282.651"
                  id="Fill-428"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,339.153 C259.108,339.153 257.484,340.778 257.484,342.782 C257.484,344.787 259.108,346.412 261.113,346.412 C263.118,346.412 264.743,344.787 264.743,342.782 C264.743,340.778 263.118,339.153 261.113,339.153"
                  id="Fill-429"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,329.736 C259.108,329.736 257.484,331.361 257.484,333.365 C257.484,335.37 259.108,336.995 261.113,336.995 C263.118,336.995 264.743,335.37 264.743,333.365 C264.743,331.361 263.118,329.736 261.113,329.736"
                  id="Fill-430"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,320.319 C259.108,320.319 257.484,321.944 257.484,323.949 C257.484,325.953 259.108,327.577 261.113,327.577 C263.118,327.577 264.743,325.953 264.743,323.949 C264.743,321.944 263.118,320.319 261.113,320.319"
                  id="Fill-431"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,310.902 C259.108,310.902 257.484,312.527 257.484,314.531 C257.484,316.536 259.108,318.161 261.113,318.161 C263.118,318.161 264.743,316.536 264.743,314.531 C264.743,312.527 263.118,310.902 261.113,310.902"
                  id="Fill-432"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M251.21,320.319 C249.206,320.319 247.58,321.944 247.58,323.949 C247.58,325.953 249.206,327.577 251.21,327.577 C253.215,327.577 254.84,325.953 254.84,323.949 C254.84,321.944 253.215,320.319 251.21,320.319"
                  id="Fill-433"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,310.902 C249.206,310.902 247.58,312.527 247.58,314.531 C247.58,316.536 249.206,318.161 251.21,318.161 C253.215,318.161 254.84,316.536 254.84,314.531 C254.84,312.527 253.215,310.902 251.21,310.902"
                  id="Fill-434"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,301.485 C249.206,301.485 247.58,303.11 247.58,305.115 C247.58,307.119 249.206,308.744 251.21,308.744 C253.215,308.744 254.84,307.119 254.84,305.115 C254.84,303.11 253.215,301.485 251.21,301.485"
                  id="Fill-435"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,292.068 C249.206,292.068 247.58,293.693 247.58,295.697 C247.58,297.702 249.206,299.327 251.21,299.327 C253.215,299.327 254.84,297.702 254.84,295.697 C254.84,293.693 253.215,292.068 251.21,292.068"
                  id="Fill-436"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,329.736 C249.206,329.736 247.58,331.361 247.58,333.365 C247.58,335.37 249.206,336.995 251.21,336.995 C253.215,336.995 254.84,335.37 254.84,333.365 C254.84,331.361 253.215,329.736 251.21,329.736"
                  id="Fill-437"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,357.987 C229.399,357.987 227.774,359.611 227.774,361.616 C227.774,363.621 229.399,365.247 231.403,365.247 C233.408,365.247 235.033,363.621 235.033,361.616 C235.033,359.611 233.408,357.987 231.403,357.987"
                  id="Fill-438"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,263.817 C219.496,263.817 217.871,265.442 217.871,267.447 C217.871,269.451 219.496,271.076 221.501,271.076 C223.505,271.076 225.13,269.451 225.13,267.447 C225.13,265.442 223.505,263.817 221.501,263.817"
                  id="Fill-439"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,282.651 C219.496,282.651 217.871,284.276 217.871,286.28 C217.871,288.285 219.496,289.909 221.501,289.909 C223.505,289.909 225.13,288.285 225.13,286.28 C225.13,284.276 223.505,282.651 221.501,282.651"
                  id="Fill-440"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,367.404 C239.303,367.404 237.677,369.028 237.677,371.033 C237.677,373.038 239.303,374.662 241.307,374.662 C243.312,374.662 244.936,373.038 244.936,371.033 C244.936,369.028 243.312,367.404 241.307,367.404"
                  id="Fill-441"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,367.404 C229.399,367.404 227.774,369.028 227.774,371.033 C227.774,373.038 229.399,374.662 231.403,374.662 C233.408,374.662 235.033,373.038 235.033,371.033 C235.033,369.028 233.408,367.404 231.403,367.404"
                  id="Fill-442"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,273.234 C219.496,273.234 217.871,274.858 217.871,276.863 C217.871,278.867 219.496,280.493 221.501,280.493 C223.505,280.493 225.13,278.867 225.13,276.863 C225.13,274.858 223.505,273.234 221.501,273.234"
                  id="Fill-443"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,292.068 C219.496,292.068 217.871,293.693 217.871,295.697 C217.871,297.702 219.496,299.327 221.501,299.327 C223.505,299.327 225.13,297.702 225.13,295.697 C225.13,293.693 223.505,292.068 221.501,292.068"
                  id="Fill-444"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,310.902 C219.496,310.902 217.871,312.527 217.871,314.531 C217.871,316.536 219.496,318.161 221.501,318.161 C223.505,318.161 225.13,316.536 225.13,314.531 C225.13,312.527 223.505,310.902 221.501,310.902"
                  id="Fill-445"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M221.501,320.319 C219.496,320.319 217.871,321.944 217.871,323.949 C217.871,325.953 219.496,327.577 221.501,327.577 C223.505,327.577 225.13,325.953 225.13,323.949 C225.13,321.944 223.505,320.319 221.501,320.319"
                  id="Fill-446"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M221.501,301.485 C219.496,301.485 217.871,303.11 217.871,305.115 C217.871,307.119 219.496,308.744 221.501,308.744 C223.505,308.744 225.13,307.119 225.13,305.115 C225.13,303.11 223.505,301.485 221.501,301.485"
                  id="Fill-447"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,376.821 C229.399,376.821 227.774,378.445 227.774,380.45 C227.774,382.455 229.399,384.081 231.403,384.081 C233.408,384.081 235.033,382.455 235.033,380.45 C235.033,378.445 233.408,376.821 231.403,376.821"
                  id="Fill-448"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,348.57 C229.399,348.57 227.774,350.195 227.774,352.199 C227.774,354.204 229.399,355.829 231.403,355.829 C233.408,355.829 235.033,354.204 235.033,352.199 C235.033,350.195 233.408,348.57 231.403,348.57"
                  id="Fill-449"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,282.651 C229.399,282.651 227.774,284.276 227.774,286.28 C227.774,288.285 229.399,289.909 231.403,289.909 C233.408,289.909 235.033,288.285 235.033,286.28 C235.033,284.276 233.408,282.651 231.403,282.651"
                  id="Fill-450"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,273.234 C229.399,273.234 227.774,274.858 227.774,276.863 C227.774,278.867 229.399,280.493 231.403,280.493 C233.408,280.493 235.033,278.867 235.033,276.863 C235.033,274.858 233.408,273.234 231.403,273.234"
                  id="Fill-451"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,301.485 C229.399,301.485 227.774,303.11 227.774,305.115 C227.774,307.119 229.399,308.744 231.403,308.744 C233.408,308.744 235.033,307.119 235.033,305.115 C235.033,303.11 233.408,301.485 231.403,301.485"
                  id="Fill-452"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,263.817 C229.399,263.817 227.774,265.442 227.774,267.447 C227.774,269.451 229.399,271.076 231.403,271.076 C233.408,271.076 235.033,269.451 235.033,267.447 C235.033,265.442 233.408,263.817 231.403,263.817"
                  id="Fill-453"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,292.068 C229.399,292.068 227.774,293.693 227.774,295.697 C227.774,297.702 229.399,299.327 231.403,299.327 C233.408,299.327 235.033,297.702 235.033,295.697 C235.033,293.693 233.408,292.068 231.403,292.068"
                  id="Fill-454"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,329.736 C229.399,329.736 227.774,331.361 227.774,333.365 C227.774,335.37 229.399,336.995 231.403,336.995 C233.408,336.995 235.033,335.37 235.033,333.365 C235.033,331.361 233.408,329.736 231.403,329.736"
                  id="Fill-455"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,310.902 C229.399,310.902 227.774,312.527 227.774,314.531 C227.774,316.536 229.399,318.161 231.403,318.161 C233.408,318.161 235.033,316.536 235.033,314.531 C235.033,312.527 233.408,310.902 231.403,310.902"
                  id="Fill-456"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M231.403,339.153 C229.399,339.153 227.774,340.778 227.774,342.782 C227.774,344.787 229.399,346.412 231.403,346.412 C233.408,346.412 235.033,344.787 235.033,342.782 C235.033,340.778 233.408,339.153 231.403,339.153"
                  id="Fill-457"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,320.319 C229.399,320.319 227.774,321.944 227.774,323.949 C227.774,325.953 229.399,327.577 231.403,327.577 C233.408,327.577 235.033,325.953 235.033,323.949 C235.033,321.944 233.408,320.319 231.403,320.319"
                  id="Fill-458"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M280.92,292.068 C278.915,292.068 277.29,293.693 277.29,295.697 C277.29,297.702 278.915,299.327 280.92,299.327 C282.925,299.327 284.549,297.702 284.549,295.697 C284.549,293.693 282.925,292.068 280.92,292.068"
                  id="Fill-459"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,310.902 C269.012,310.902 267.387,312.527 267.387,314.531 C267.387,316.536 269.012,318.161 271.016,318.161 C273.021,318.161 274.646,316.536 274.646,314.531 C274.646,312.527 273.021,310.902 271.016,310.902"
                  id="Fill-460"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M271.016,320.319 C269.012,320.319 267.387,321.944 267.387,323.949 C267.387,325.953 269.012,327.577 271.016,327.577 C273.021,327.577 274.646,325.953 274.646,323.949 C274.646,321.944 273.021,320.319 271.016,320.319"
                  id="Fill-461"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,282.651 C259.108,282.651 257.484,284.276 257.484,286.28 C257.484,288.285 259.108,289.909 261.113,289.909 C263.118,289.909 264.743,288.285 264.743,286.28 C264.743,284.276 263.118,282.651 261.113,282.651"
                  id="Fill-462"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,329.736 C269.012,329.736 267.387,331.361 267.387,333.365 C267.387,335.37 269.012,336.995 271.016,336.995 C273.021,336.995 274.646,335.37 274.646,333.365 C274.646,331.361 273.021,329.736 271.016,329.736"
                  id="Fill-463"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,244.982 C140.27,244.982 138.645,246.607 138.645,248.612 C138.645,250.616 140.27,252.242 142.275,252.242 C144.28,252.242 145.904,250.616 145.904,248.612 C145.904,246.607 144.28,244.982 142.275,244.982"
                  id="Fill-464"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,292.068 C269.012,292.068 267.387,293.693 267.387,295.697 C267.387,297.702 269.012,299.327 271.016,299.327 C273.021,299.327 274.646,297.702 274.646,295.697 C274.646,293.693 273.021,292.068 271.016,292.068"
                  id="Fill-465"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,235.565 C140.27,235.565 138.645,237.191 138.645,239.195 C138.645,241.199 140.27,242.824 142.275,242.824 C144.28,242.824 145.904,241.199 145.904,239.195 C145.904,237.191 144.28,235.565 142.275,235.565"
                  id="Fill-466"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,226.148 C150.173,226.148 148.548,227.773 148.548,229.778 C148.548,231.782 150.173,233.407 152.178,233.407 C154.183,233.407 155.808,231.782 155.808,229.778 C155.808,227.773 154.183,226.148 152.178,226.148"
                  id="Fill-467"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,310.902 C278.915,310.902 277.29,312.527 277.29,314.531 C277.29,316.536 278.915,318.161 280.92,318.161 C282.925,318.161 284.549,316.536 284.549,314.531 C284.549,312.527 282.925,310.902 280.92,310.902"
                  id="Fill-468"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,235.565 C150.173,235.565 148.548,237.191 148.548,239.195 C148.548,241.199 150.173,242.824 152.178,242.824 C154.183,242.824 155.808,241.199 155.808,239.195 C155.808,237.191 154.183,235.565 152.178,235.565"
                  id="Fill-469"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,301.485 C278.915,301.485 277.29,303.11 277.29,305.115 C277.29,307.119 278.915,308.744 280.92,308.744 C282.925,308.744 284.549,307.119 284.549,305.115 C284.549,303.11 282.925,301.485 280.92,301.485"
                  id="Fill-470"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,244.982 C150.173,244.982 148.548,246.607 148.548,248.612 C148.548,250.616 150.173,252.242 152.178,252.242 C154.183,252.242 155.808,250.616 155.808,248.612 C155.808,246.607 154.183,244.982 152.178,244.982"
                  id="Fill-471"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M271.016,301.485 C269.012,301.485 267.387,303.11 267.387,305.115 C267.387,307.119 269.012,308.744 271.016,308.744 C273.021,308.744 274.646,307.119 274.646,305.115 C274.646,303.11 273.021,301.485 271.016,301.485"
                  id="Fill-472"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,301.485 C259.108,301.485 257.484,303.11 257.484,305.115 C257.484,307.119 259.108,308.744 261.113,308.744 C263.118,308.744 264.743,307.119 264.743,305.115 C264.743,303.11 263.118,301.485 261.113,301.485"
                  id="Fill-473"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,216.731 C140.27,216.731 138.645,218.357 138.645,220.361 C138.645,222.365 140.27,223.99 142.275,223.99 C144.28,223.99 145.904,222.365 145.904,220.361 C145.904,218.357 144.28,216.731 142.275,216.731"
                  id="Fill-474"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M261.113,292.068 C259.108,292.068 257.484,293.693 257.484,295.697 C257.484,297.702 259.108,299.327 261.113,299.327 C263.118,299.327 264.743,297.702 264.743,295.697 C264.743,293.693 263.118,292.068 261.113,292.068"
                  id="Fill-475"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,254.399 C169.98,254.399 168.355,256.025 168.355,258.029 C168.355,260.033 169.98,261.658 171.985,261.658 C173.989,261.658 175.613,260.033 175.613,258.029 C175.613,256.025 173.989,254.399 171.985,254.399"
                  id="Fill-476"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,226.148 C140.27,226.148 138.645,227.773 138.645,229.778 C138.645,231.782 140.27,233.407 142.275,233.407 C144.28,233.407 145.904,231.782 145.904,229.778 C145.904,227.773 144.28,226.148 142.275,226.148"
                  id="Fill-477"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,244.982 C160.076,244.982 158.452,246.607 158.452,248.612 C158.452,250.616 160.076,252.242 162.081,252.242 C164.085,252.242 165.711,250.616 165.711,248.612 C165.711,246.607 164.085,244.982 162.081,244.982"
                  id="Fill-478"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,339.153 C219.496,339.153 217.871,340.778 217.871,342.782 C217.871,344.787 219.496,346.412 221.501,346.412 C223.505,346.412 225.13,344.787 225.13,342.782 C225.13,340.778 223.505,339.153 221.501,339.153"
                  id="Fill-479"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,141.395 C625.529,141.395 623.903,143.02 623.903,145.024 C623.903,147.029 625.529,148.654 627.532,148.654 C629.537,148.654 631.161,147.029 631.161,145.024 C631.161,143.02 629.537,141.395 627.532,141.395"
                  id="Fill-480"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M627.532,150.812 C625.529,150.812 623.903,152.437 623.903,154.441 C623.903,156.446 625.529,158.071 627.532,158.071 C629.537,158.071 631.161,156.446 631.161,154.441 C631.161,152.437 629.537,150.812 627.532,150.812"
                  id="Fill-481"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M627.532,66.058 C625.529,66.058 623.903,67.683 623.903,69.688 C623.903,71.693 625.529,73.318 627.532,73.318 C629.537,73.318 631.161,71.693 631.161,69.688 C631.161,67.683 629.537,66.058 627.532,66.058"
                  id="Fill-482"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,75.476 C625.529,75.476 623.903,77.1 623.903,79.105 C623.903,81.109 625.529,82.735 627.532,82.735 C629.537,82.735 631.161,81.109 631.161,79.105 C631.161,77.1 629.537,75.476 627.532,75.476"
                  id="Fill-483"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,84.892 C625.529,84.892 623.903,86.517 623.903,88.522 C623.903,90.527 625.529,92.152 627.532,92.152 C629.537,92.152 631.161,90.527 631.161,88.522 C631.161,86.517 629.537,84.892 627.532,84.892"
                  id="Fill-484"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,94.309 C625.529,94.309 623.903,95.935 623.903,97.939 C623.903,99.944 625.529,101.569 627.532,101.569 C629.537,101.569 631.161,99.944 631.161,97.939 C631.161,95.935 629.537,94.309 627.532,94.309"
                  id="Fill-485"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,103.727 C625.529,103.727 623.903,105.352 623.903,107.356 C623.903,109.361 625.529,110.986 627.532,110.986 C629.537,110.986 631.161,109.361 631.161,107.356 C631.161,105.352 629.537,103.727 627.532,103.727"
                  id="Fill-486"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,122.561 C625.529,122.561 623.903,124.186 623.903,126.19 C623.903,128.195 625.529,129.82 627.532,129.82 C629.537,129.82 631.161,128.195 631.161,126.19 C631.161,124.186 629.537,122.561 627.532,122.561"
                  id="Fill-487"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,131.978 C625.529,131.978 623.903,133.603 623.903,135.607 C623.903,137.612 625.529,139.237 627.532,139.237 C629.537,139.237 631.161,137.612 631.161,135.607 C631.161,133.603 629.537,131.978 627.532,131.978"
                  id="Fill-488"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,113.144 C625.529,113.144 623.903,114.769 623.903,116.773 C623.903,118.777 625.529,120.403 627.532,120.403 C629.537,120.403 631.161,118.777 631.161,116.773 C631.161,114.769 629.537,113.144 627.532,113.144"
                  id="Fill-489"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,160.229 C605.722,160.229 604.097,161.854 604.097,163.859 C604.097,165.863 605.722,167.488 607.726,167.488 C609.73,167.488 611.356,165.863 611.356,163.859 C611.356,161.854 609.73,160.229 607.726,160.229"
                  id="Fill-490"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,169.646 C605.722,169.646 604.097,171.271 604.097,173.275 C604.097,175.28 605.722,176.905 607.726,176.905 C609.73,176.905 611.356,175.28 611.356,173.275 C611.356,171.271 609.73,169.646 607.726,169.646"
                  id="Fill-491"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,160.229 C615.624,160.229 614,161.854 614,163.859 C614,165.863 615.624,167.488 617.629,167.488 C619.634,167.488 621.259,165.863 621.259,163.859 C621.259,161.854 619.634,160.229 617.629,160.229"
                  id="Fill-492"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,169.646 C635.431,169.646 633.805,171.271 633.805,173.275 C633.805,175.28 635.431,176.905 637.436,176.905 C639.441,176.905 641.065,175.28 641.065,173.275 C641.065,171.271 639.441,169.646 637.436,169.646"
                  id="Fill-493"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,207.314 C605.722,207.314 604.097,208.939 604.097,210.944 C604.097,212.948 605.722,214.574 607.726,214.574 C609.73,214.574 611.356,212.948 611.356,210.944 C611.356,208.939 609.73,207.314 607.726,207.314"
                  id="Fill-494"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,179.063 C605.722,179.063 604.097,180.688 604.097,182.693 C604.097,184.697 605.722,186.322 607.726,186.322 C609.73,186.322 611.356,184.697 611.356,182.693 C611.356,180.688 609.73,179.063 607.726,179.063"
                  id="Fill-495"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M637.436,207.314 C635.431,207.314 633.805,208.939 633.805,210.944 C633.805,212.948 635.431,214.574 637.436,214.574 C639.441,214.574 641.065,212.948 641.065,210.944 C641.065,208.939 639.441,207.314 637.436,207.314"
                  id="Fill-496"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,47.225 C625.529,47.225 623.903,48.849 623.903,50.854 C623.903,52.858 625.529,54.483 627.532,54.483 C629.537,54.483 631.161,52.858 631.161,50.854 C631.161,48.849 629.537,47.225 627.532,47.225"
                  id="Fill-497"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,122.561 C605.722,122.561 604.097,124.186 604.097,126.19 C604.097,128.195 605.722,129.82 607.726,129.82 C609.73,129.82 611.356,128.195 611.356,126.19 C611.356,124.186 609.73,122.561 607.726,122.561"
                  id="Fill-498"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,113.144 C605.722,113.144 604.097,114.769 604.097,116.773 C604.097,118.777 605.722,120.403 607.726,120.403 C609.73,120.403 611.356,118.777 611.356,116.773 C611.356,114.769 609.73,113.144 607.726,113.144"
                  id="Fill-499"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,103.727 C605.722,103.727 604.097,105.352 604.097,107.356 C604.097,109.361 605.722,110.986 607.726,110.986 C609.73,110.986 611.356,109.361 611.356,107.356 C611.356,105.352 609.73,103.727 607.726,103.727"
                  id="Fill-500"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,141.395 C605.722,141.395 604.097,143.02 604.097,145.024 C604.097,147.029 605.722,148.654 607.726,148.654 C609.73,148.654 611.356,147.029 611.356,145.024 C611.356,143.02 609.73,141.395 607.726,141.395"
                  id="Fill-501"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,150.812 C605.722,150.812 604.097,152.437 604.097,154.441 C604.097,156.446 605.722,158.071 607.726,158.071 C609.73,158.071 611.356,156.446 611.356,154.441 C611.356,152.437 609.73,150.812 607.726,150.812"
                  id="Fill-502"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,131.978 C605.722,131.978 604.097,133.603 604.097,135.607 C604.097,137.612 605.722,139.237 607.726,139.237 C609.73,139.237 611.356,137.612 611.356,135.607 C611.356,133.603 609.73,131.978 607.726,131.978"
                  id="Fill-503"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,197.897 C605.722,197.897 604.097,199.523 604.097,201.527 C604.097,203.531 605.722,205.156 607.726,205.156 C609.73,205.156 611.356,203.531 611.356,201.527 C611.356,199.523 609.73,197.897 607.726,197.897"
                  id="Fill-504"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,84.892 C615.624,84.892 614,86.517 614,88.522 C614,90.527 615.624,92.152 617.629,92.152 C619.634,92.152 621.259,90.527 621.259,88.522 C621.259,86.517 619.634,84.892 617.629,84.892"
                  id="Fill-505"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,94.309 C615.624,94.309 614,95.935 614,97.939 C614,99.944 615.624,101.569 617.629,101.569 C619.634,101.569 621.259,99.944 621.259,97.939 C621.259,95.935 619.634,94.309 617.629,94.309"
                  id="Fill-506"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,103.727 C615.624,103.727 614,105.352 614,107.356 C614,109.361 615.624,110.986 617.629,110.986 C619.634,110.986 621.259,109.361 621.259,107.356 C621.259,105.352 619.634,103.727 617.629,103.727"
                  id="Fill-507"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,47.225 C615.624,47.225 614,48.849 614,50.854 C614,52.858 615.624,54.483 617.629,54.483 C619.634,54.483 621.259,52.858 621.259,50.854 C621.259,48.849 619.634,47.225 617.629,47.225"
                  id="Fill-508"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,160.229 C635.431,160.229 633.805,161.854 633.805,163.859 C633.805,165.863 635.431,167.488 637.436,167.488 C639.441,167.488 641.065,165.863 641.065,163.859 C641.065,161.854 639.441,160.229 637.436,160.229"
                  id="Fill-509"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,56.642 C615.624,56.642 614,58.266 614,60.271 C614,62.276 615.624,63.901 617.629,63.901 C619.634,63.901 621.259,62.276 621.259,60.271 C621.259,58.266 619.634,56.642 617.629,56.642"
                  id="Fill-510"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,66.058 C615.624,66.058 614,67.683 614,69.688 C614,71.693 615.624,73.318 617.629,73.318 C619.634,73.318 621.259,71.693 621.259,69.688 C621.259,67.683 619.634,66.058 617.629,66.058"
                  id="Fill-511"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,75.476 C615.624,75.476 614,77.1 614,79.105 C614,81.109 615.624,82.735 617.629,82.735 C619.634,82.735 621.259,81.109 621.259,79.105 C621.259,77.1 619.634,75.476 617.629,75.476"
                  id="Fill-512"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,188.48 C615.624,188.48 614,190.106 614,192.11 C614,194.114 615.624,195.739 617.629,195.739 C619.634,195.739 621.259,194.114 621.259,192.11 C621.259,190.106 619.634,188.48 617.629,188.48"
                  id="Fill-513"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,179.063 C615.624,179.063 614,180.688 614,182.693 C614,184.697 615.624,186.322 617.629,186.322 C619.634,186.322 621.259,184.697 621.259,182.693 C621.259,180.688 619.634,179.063 617.629,179.063"
                  id="Fill-514"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M617.629,169.646 C615.624,169.646 614,171.271 614,173.275 C614,175.28 615.624,176.905 617.629,176.905 C619.634,176.905 621.259,175.28 621.259,173.275 C621.259,171.271 619.634,169.646 617.629,169.646"
                  id="Fill-515"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,122.561 C615.624,122.561 614,124.186 614,126.19 C614,128.195 615.624,129.82 617.629,129.82 C619.634,129.82 621.259,128.195 621.259,126.19 C621.259,124.186 619.634,122.561 617.629,122.561"
                  id="Fill-516"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,141.395 C615.624,141.395 614,143.02 614,145.024 C614,147.029 615.624,148.654 617.629,148.654 C619.634,148.654 621.259,147.029 621.259,145.024 C621.259,143.02 619.634,141.395 617.629,141.395"
                  id="Fill-517"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,131.978 C615.624,131.978 614,133.603 614,135.607 C614,137.612 615.624,139.237 617.629,139.237 C619.634,139.237 621.259,137.612 621.259,135.607 C621.259,133.603 619.634,131.978 617.629,131.978"
                  id="Fill-518"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,113.144 C615.624,113.144 614,114.769 614,116.773 C614,118.777 615.624,120.403 617.629,120.403 C619.634,120.403 621.259,118.777 621.259,116.773 C621.259,114.769 619.634,113.144 617.629,113.144"
                  id="Fill-519"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,56.642 C605.722,56.642 604.097,58.266 604.097,60.271 C604.097,62.276 605.722,63.901 607.726,63.901 C609.73,63.901 611.356,62.276 611.356,60.271 C611.356,58.266 609.73,56.642 607.726,56.642"
                  id="Fill-520"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,169.646 C625.529,169.646 623.903,171.271 623.903,173.275 C623.903,175.28 625.529,176.905 627.532,176.905 C629.537,176.905 631.161,175.28 631.161,173.275 C631.161,171.271 629.537,169.646 627.532,169.646"
                  id="Fill-521"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,160.229 C625.529,160.229 623.903,161.854 623.903,163.859 C623.903,165.863 625.529,167.488 627.532,167.488 C629.537,167.488 631.161,165.863 631.161,163.859 C631.161,161.854 629.537,160.229 627.532,160.229"
                  id="Fill-522"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,66.058 C605.722,66.058 604.097,67.683 604.097,69.688 C604.097,71.693 605.722,73.318 607.726,73.318 C609.73,73.318 611.356,71.693 611.356,69.688 C611.356,67.683 609.73,66.058 607.726,66.058"
                  id="Fill-523"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,75.476 C605.722,75.476 604.097,77.1 604.097,79.105 C604.097,81.109 605.722,82.735 607.726,82.735 C609.73,82.735 611.356,81.109 611.356,79.105 C611.356,77.1 609.73,75.476 607.726,75.476"
                  id="Fill-524"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,94.309 C605.722,94.309 604.097,95.935 604.097,97.939 C604.097,99.944 605.722,101.569 607.726,101.569 C609.73,101.569 611.356,99.944 611.356,97.939 C611.356,95.935 609.73,94.309 607.726,94.309"
                  id="Fill-525"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,84.892 C605.722,84.892 604.097,86.517 604.097,88.522 C604.097,90.527 605.722,92.152 607.726,92.152 C609.73,92.152 611.356,90.527 611.356,88.522 C611.356,86.517 609.73,84.892 607.726,84.892"
                  id="Fill-526"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,47.225 C605.722,47.225 604.097,48.849 604.097,50.854 C604.097,52.858 605.722,54.483 607.726,54.483 C609.73,54.483 611.356,52.858 611.356,50.854 C611.356,48.849 609.73,47.225 607.726,47.225"
                  id="Fill-527"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M587.919,179.063 C585.915,179.063 584.29,180.688 584.29,182.693 C584.29,184.697 585.915,186.322 587.919,186.322 C589.924,186.322 591.549,184.697 591.549,182.693 C591.549,180.688 589.924,179.063 587.919,179.063"
                  id="Fill-528"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,188.48 C605.722,188.48 604.097,190.106 604.097,192.11 C604.097,194.114 605.722,195.739 607.726,195.739 C609.73,195.739 611.356,194.114 611.356,192.11 C611.356,190.106 609.73,188.48 607.726,188.48"
                  id="Fill-529"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M607.726,216.731 C605.722,216.731 604.097,218.357 604.097,220.361 C604.097,222.365 605.722,223.99 607.726,223.99 C609.73,223.99 611.356,222.365 611.356,220.361 C611.356,218.357 609.73,216.731 607.726,216.731"
                  id="Fill-530"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M627.532,197.897 C625.529,197.897 623.903,199.523 623.903,201.527 C623.903,203.531 625.529,205.156 627.532,205.156 C629.537,205.156 631.161,203.531 631.161,201.527 C631.161,199.523 629.537,197.897 627.532,197.897"
                  id="Fill-531"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,179.063 C625.529,179.063 623.903,180.688 623.903,182.693 C623.903,184.697 625.529,186.322 627.532,186.322 C629.537,186.322 631.161,184.697 631.161,182.693 C631.161,180.688 629.537,179.063 627.532,179.063"
                  id="Fill-532"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M627.532,188.48 C625.529,188.48 623.903,190.106 623.903,192.11 C623.903,194.114 625.529,195.739 627.532,195.739 C629.537,195.739 631.161,194.114 631.161,192.11 C631.161,190.106 629.537,188.48 627.532,188.48"
                  id="Fill-533"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M627.532,56.642 C625.529,56.642 623.903,58.266 623.903,60.271 C623.903,62.276 625.529,63.901 627.532,63.901 C629.537,63.901 631.161,62.276 631.161,60.271 C631.161,58.266 629.537,56.642 627.532,56.642"
                  id="Fill-534"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M686.951,94.309 C684.948,94.309 683.322,95.935 683.322,97.939 C683.322,99.944 684.948,101.569 686.951,101.569 C688.956,101.569 690.581,99.944 690.581,97.939 C690.581,95.935 688.956,94.309 686.951,94.309"
                  id="Fill-535"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M696.855,131.978 C694.851,131.978 693.225,133.603 693.225,135.607 C693.225,137.612 694.851,139.237 696.855,139.237 C698.859,139.237 700.485,137.612 700.485,135.607 C700.485,133.603 698.859,131.978 696.855,131.978"
                  id="Fill-536"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M686.951,66.058 C684.948,66.058 683.322,67.683 683.322,69.688 C683.322,71.693 684.948,73.318 686.951,73.318 C688.956,73.318 690.581,71.693 690.581,69.688 C690.581,67.683 688.956,66.058 686.951,66.058"
                  id="Fill-537"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M686.951,75.476 C684.948,75.476 683.322,77.1 683.322,79.105 C683.322,81.109 684.948,82.735 686.951,82.735 C688.956,82.735 690.581,81.109 690.581,79.105 C690.581,77.1 688.956,75.476 686.951,75.476"
                  id="Fill-538"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M686.951,84.892 C684.948,84.892 683.322,86.517 683.322,88.522 C683.322,90.527 684.948,92.152 686.951,92.152 C688.956,92.152 690.581,90.527 690.581,88.522 C690.581,86.517 688.956,84.892 686.951,84.892"
                  id="Fill-539"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,141.395 C694.851,141.395 693.225,143.02 693.225,145.024 C693.225,147.029 694.851,148.654 696.855,148.654 C698.859,148.654 700.485,147.029 700.485,145.024 C700.485,143.02 698.859,141.395 696.855,141.395"
                  id="Fill-540"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,84.892 C694.851,84.892 693.225,86.517 693.225,88.522 C693.225,90.527 694.851,92.152 696.855,92.152 C698.859,92.152 700.485,90.527 700.485,88.522 C700.485,86.517 698.859,84.892 696.855,84.892"
                  id="Fill-541"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,94.309 C694.851,94.309 693.225,95.935 693.225,97.939 C693.225,99.944 694.851,101.569 696.855,101.569 C698.859,101.569 700.485,99.944 700.485,97.939 C700.485,95.935 698.859,94.309 696.855,94.309"
                  id="Fill-542"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M696.855,103.727 C694.851,103.727 693.225,105.352 693.225,107.356 C693.225,109.361 694.851,110.986 696.855,110.986 C698.859,110.986 700.485,109.361 700.485,107.356 C700.485,105.352 698.859,103.727 696.855,103.727"
                  id="Fill-543"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,113.144 C694.851,113.144 693.225,114.769 693.225,116.773 C693.225,118.777 694.851,120.403 696.855,120.403 C698.859,120.403 700.485,118.777 700.485,116.773 C700.485,114.769 698.859,113.144 696.855,113.144"
                  id="Fill-544"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,122.561 C694.851,122.561 693.225,124.186 693.225,126.19 C693.225,128.195 694.851,129.82 696.855,129.82 C698.859,129.82 700.485,128.195 700.485,126.19 C700.485,124.186 698.859,122.561 696.855,122.561"
                  id="Fill-545"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M686.951,131.978 C684.948,131.978 683.322,133.603 683.322,135.607 C683.322,137.612 684.948,139.237 686.951,139.237 C688.956,139.237 690.581,137.612 690.581,135.607 C690.581,133.603 688.956,131.978 686.951,131.978"
                  id="Fill-546"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,84.892 C675.044,84.892 673.419,86.517 673.419,88.522 C673.419,90.527 675.044,92.152 677.048,92.152 C679.053,92.152 680.678,90.527 680.678,88.522 C680.678,86.517 679.053,84.892 677.048,84.892"
                  id="Fill-547"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,66.058 C675.044,66.058 673.419,67.683 673.419,69.688 C673.419,71.693 675.044,73.318 677.048,73.318 C679.053,73.318 680.678,71.693 680.678,69.688 C680.678,67.683 679.053,66.058 677.048,66.058"
                  id="Fill-548"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,75.476 C675.044,75.476 673.419,77.1 673.419,79.105 C673.419,81.109 675.044,82.735 677.048,82.735 C679.053,82.735 680.678,81.109 680.678,79.105 C680.678,77.1 679.053,75.476 677.048,75.476"
                  id="Fill-549"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M686.951,103.727 C684.948,103.727 683.322,105.352 683.322,107.356 C683.322,109.361 684.948,110.986 686.951,110.986 C688.956,110.986 690.581,109.361 690.581,107.356 C690.581,105.352 688.956,103.727 686.951,103.727"
                  id="Fill-550"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M677.048,103.727 C675.044,103.727 673.419,105.352 673.419,107.356 C673.419,109.361 675.044,110.986 677.048,110.986 C679.053,110.986 680.678,109.361 680.678,107.356 C680.678,105.352 679.053,103.727 677.048,103.727"
                  id="Fill-551"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,94.309 C675.044,94.309 673.419,95.935 673.419,97.939 C673.419,99.944 675.044,101.569 677.048,101.569 C679.053,101.569 680.678,99.944 680.678,97.939 C680.678,95.935 679.053,94.309 677.048,94.309"
                  id="Fill-552"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,75.476 C694.851,75.476 693.225,77.1 693.225,79.105 C693.225,81.109 694.851,82.735 696.855,82.735 C698.859,82.735 700.485,81.109 700.485,79.105 C700.485,77.1 698.859,75.476 696.855,75.476"
                  id="Fill-553"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M686.951,113.144 C684.948,113.144 683.322,114.769 683.322,116.773 C683.322,118.777 684.948,120.403 686.951,120.403 C688.956,120.403 690.581,118.777 690.581,116.773 C690.581,114.769 688.956,113.144 686.951,113.144"
                  id="Fill-554"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M686.951,150.812 C684.948,150.812 683.322,152.437 683.322,154.441 C683.322,156.446 684.948,158.071 686.951,158.071 C688.956,158.071 690.581,156.446 690.581,154.441 C690.581,152.437 688.956,150.812 686.951,150.812"
                  id="Fill-555"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,56.642 C675.044,56.642 673.419,58.266 673.419,60.271 C673.419,62.276 675.044,63.901 677.048,63.901 C679.053,63.901 680.678,62.276 680.678,60.271 C680.678,58.266 679.053,56.642 677.048,56.642"
                  id="Fill-556"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M686.951,141.395 C684.948,141.395 683.322,143.02 683.322,145.024 C683.322,147.029 684.948,148.654 686.951,148.654 C688.956,148.654 690.581,147.029 690.581,145.024 C690.581,143.02 688.956,141.395 686.951,141.395"
                  id="Fill-557"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M696.855,66.058 C694.851,66.058 693.225,67.683 693.225,69.688 C693.225,71.693 694.851,73.318 696.855,73.318 C698.859,73.318 700.485,71.693 700.485,69.688 C700.485,67.683 698.859,66.058 696.855,66.058"
                  id="Fill-558"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M736.468,103.727 C734.463,103.727 732.839,105.352 732.839,107.356 C732.839,109.361 734.463,110.986 736.468,110.986 C738.473,110.986 740.097,109.361 740.097,107.356 C740.097,105.352 738.473,103.727 736.468,103.727"
                  id="Fill-559"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M726.565,84.892 C724.56,84.892 722.936,86.517 722.936,88.522 C722.936,90.527 724.56,92.152 726.565,92.152 C728.568,92.152 730.194,90.527 730.194,88.522 C730.194,86.517 728.568,84.892 726.565,84.892"
                  id="Fill-560"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M726.565,75.476 C724.56,75.476 722.936,77.1 722.936,79.105 C722.936,81.109 724.56,82.735 726.565,82.735 C728.568,82.735 730.194,81.109 730.194,79.105 C730.194,77.1 728.568,75.476 726.565,75.476"
                  id="Fill-561"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M726.565,103.727 C724.56,103.727 722.936,105.352 722.936,107.356 C722.936,109.361 724.56,110.986 726.565,110.986 C728.568,110.986 730.194,109.361 730.194,107.356 C730.194,105.352 728.568,103.727 726.565,103.727"
                  id="Fill-562"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M726.565,94.309 C724.56,94.309 722.936,95.935 722.936,97.939 C722.936,99.944 724.56,101.569 726.565,101.569 C728.568,101.569 730.194,99.944 730.194,97.939 C730.194,95.935 728.568,94.309 726.565,94.309"
                  id="Fill-563"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,75.476 C714.656,75.476 713.032,77.1 713.032,79.105 C713.032,81.109 714.656,82.735 716.661,82.735 C718.666,82.735 720.292,81.109 720.292,79.105 C720.292,77.1 718.666,75.476 716.661,75.476"
                  id="Fill-564"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,150.812 C635.431,150.812 633.805,152.437 633.805,154.441 C633.805,156.446 635.431,158.071 637.436,158.071 C639.441,158.071 641.065,156.446 641.065,154.441 C641.065,152.437 639.441,150.812 637.436,150.812"
                  id="Fill-565"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M746.371,94.309 C744.366,94.309 742.741,95.935 742.741,97.939 C742.741,99.944 744.366,101.569 746.371,101.569 C748.375,101.569 750,99.944 750,97.939 C750,95.935 748.375,94.309 746.371,94.309"
                  id="Fill-566"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M736.468,75.476 C734.463,75.476 732.839,77.1 732.839,79.105 C732.839,81.109 734.463,82.735 736.468,82.735 C738.473,82.735 740.097,81.109 740.097,79.105 C740.097,77.1 738.473,75.476 736.468,75.476"
                  id="Fill-567"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M736.468,94.309 C734.463,94.309 732.839,95.935 732.839,97.939 C732.839,99.944 734.463,101.569 736.468,101.569 C738.473,101.569 740.097,99.944 740.097,97.939 C740.097,95.935 738.473,94.309 736.468,94.309"
                  id="Fill-568"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M736.468,84.892 C734.463,84.892 732.839,86.517 732.839,88.522 C732.839,90.527 734.463,92.152 736.468,92.152 C738.473,92.152 740.097,90.527 740.097,88.522 C740.097,86.517 738.473,84.892 736.468,84.892"
                  id="Fill-569"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,94.309 C704.753,94.309 703.129,95.935 703.129,97.939 C703.129,99.944 704.753,101.569 706.758,101.569 C708.763,101.569 710.388,99.944 710.388,97.939 C710.388,95.935 708.763,94.309 706.758,94.309"
                  id="Fill-570"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,113.144 C675.044,113.144 673.419,114.769 673.419,116.773 C673.419,118.777 675.044,120.403 677.048,120.403 C679.053,120.403 680.678,118.777 680.678,116.773 C680.678,114.769 679.053,113.144 677.048,113.144"
                  id="Fill-571"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,103.727 C704.753,103.727 703.129,105.352 703.129,107.356 C703.129,109.361 704.753,110.986 706.758,110.986 C708.763,110.986 710.388,109.361 710.388,107.356 C710.388,105.352 708.763,103.727 706.758,103.727"
                  id="Fill-572"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,113.144 C704.753,113.144 703.129,114.769 703.129,116.773 C703.129,118.777 704.753,120.403 706.758,120.403 C708.763,120.403 710.388,118.777 710.388,116.773 C710.388,114.769 708.763,113.144 706.758,113.144"
                  id="Fill-573"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M706.758,122.561 C704.753,122.561 703.129,124.186 703.129,126.19 C703.129,128.195 704.753,129.82 706.758,129.82 C708.763,129.82 710.388,128.195 710.388,126.19 C710.388,124.186 708.763,122.561 706.758,122.561"
                  id="Fill-574"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,84.892 C704.753,84.892 703.129,86.517 703.129,88.522 C703.129,90.527 704.753,92.152 706.758,92.152 C708.763,92.152 710.388,90.527 710.388,88.522 C710.388,86.517 708.763,84.892 706.758,84.892"
                  id="Fill-575"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,84.892 C714.656,84.892 713.032,86.517 713.032,88.522 C713.032,90.527 714.656,92.152 716.661,92.152 C718.666,92.152 720.292,90.527 720.292,88.522 C720.292,86.517 718.666,84.892 716.661,84.892"
                  id="Fill-576"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,94.309 C714.656,94.309 713.032,95.935 713.032,97.939 C713.032,99.944 714.656,101.569 716.661,101.569 C718.666,101.569 720.292,99.944 720.292,97.939 C720.292,95.935 718.666,94.309 716.661,94.309"
                  id="Fill-577"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M706.758,75.476 C704.753,75.476 703.129,77.1 703.129,79.105 C703.129,81.109 704.753,82.735 706.758,82.735 C708.763,82.735 710.388,81.109 710.388,79.105 C710.388,77.1 708.763,75.476 706.758,75.476"
                  id="Fill-578"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,103.727 C714.656,103.727 713.032,105.352 713.032,107.356 C713.032,109.361 714.656,110.986 716.661,110.986 C718.666,110.986 720.292,109.361 720.292,107.356 C720.292,105.352 718.666,103.727 716.661,103.727"
                  id="Fill-579"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M716.661,113.144 C714.656,113.144 713.032,114.769 713.032,116.773 C713.032,118.777 714.656,120.403 716.661,120.403 C718.666,120.403 720.292,118.777 720.292,116.773 C720.292,114.769 718.666,113.144 716.661,113.144"
                  id="Fill-580"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M746.371,92.152 C748.375,92.152 750,90.527 750,88.522 C750,86.517 748.375,84.892 746.371,84.892 C744.366,84.892 742.741,86.517 742.741,88.522 C742.741,90.527 744.366,92.152 746.371,92.152"
                  id="Fill-581"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,131.978 C645.334,131.978 643.709,133.603 643.709,135.607 C643.709,137.612 645.334,139.237 647.339,139.237 C649.344,139.237 650.968,137.612 650.968,135.607 C650.968,133.603 649.344,131.978 647.339,131.978"
                  id="Fill-582"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,141.395 C645.334,141.395 643.709,143.02 643.709,145.024 C643.709,147.029 645.334,148.654 647.339,148.654 C649.344,148.654 650.968,147.029 650.968,145.024 C650.968,143.02 649.344,141.395 647.339,141.395"
                  id="Fill-583"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,150.812 C645.334,150.812 643.709,152.437 643.709,154.441 C643.709,156.446 645.334,158.071 647.339,158.071 C649.344,158.071 650.968,156.446 650.968,154.441 C650.968,152.437 649.344,150.812 647.339,150.812"
                  id="Fill-584"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,160.229 C645.334,160.229 643.709,161.854 643.709,163.859 C643.709,165.863 645.334,167.488 647.339,167.488 C649.344,167.488 650.968,165.863 650.968,163.859 C650.968,161.854 649.344,160.229 647.339,160.229"
                  id="Fill-585"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,122.561 C645.334,122.561 643.709,124.186 643.709,126.19 C643.709,128.195 645.334,129.82 647.339,129.82 C649.344,129.82 650.968,128.195 650.968,126.19 C650.968,124.186 649.344,122.561 647.339,122.561"
                  id="Fill-586"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,103.727 C645.334,103.727 643.709,105.352 643.709,107.356 C643.709,109.361 645.334,110.986 647.339,110.986 C649.344,110.986 650.968,109.361 650.968,107.356 C650.968,105.352 649.344,103.727 647.339,103.727"
                  id="Fill-587"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,66.058 C645.334,66.058 643.709,67.683 643.709,69.688 C643.709,71.693 645.334,73.318 647.339,73.318 C649.344,73.318 650.968,71.693 650.968,69.688 C650.968,67.683 649.344,66.058 647.339,66.058"
                  id="Fill-588"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,75.476 C645.334,75.476 643.709,77.1 643.709,79.105 C643.709,81.109 645.334,82.735 647.339,82.735 C649.344,82.735 650.968,81.109 650.968,79.105 C650.968,77.1 649.344,75.476 647.339,75.476"
                  id="Fill-589"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,94.309 C645.334,94.309 643.709,95.935 643.709,97.939 C643.709,99.944 645.334,101.569 647.339,101.569 C649.344,101.569 650.968,99.944 650.968,97.939 C650.968,95.935 649.344,94.309 647.339,94.309"
                  id="Fill-590"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,113.144 C645.334,113.144 643.709,114.769 643.709,116.773 C643.709,118.777 645.334,120.403 647.339,120.403 C649.344,120.403 650.968,118.777 650.968,116.773 C650.968,114.769 649.344,113.144 647.339,113.144"
                  id="Fill-591"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,188.48 C645.334,188.48 643.709,190.106 643.709,192.11 C643.709,194.114 645.334,195.739 647.339,195.739 C649.344,195.739 650.968,194.114 650.968,192.11 C650.968,190.106 649.344,188.48 647.339,188.48"
                  id="Fill-592"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M647.339,84.892 C645.334,84.892 643.709,86.517 643.709,88.522 C643.709,90.527 645.334,92.152 647.339,92.152 C649.344,92.152 650.968,90.527 650.968,88.522 C650.968,86.517 649.344,84.892 647.339,84.892"
                  id="Fill-593"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,122.561 C635.431,122.561 633.805,124.186 633.805,126.19 C633.805,128.195 635.431,129.82 637.436,129.82 C639.441,129.82 641.065,128.195 641.065,126.19 C641.065,124.186 639.441,122.561 637.436,122.561"
                  id="Fill-594"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,113.144 C635.431,113.144 633.805,114.769 633.805,116.773 C633.805,118.777 635.431,120.403 637.436,120.403 C639.441,120.403 641.065,118.777 641.065,116.773 C641.065,114.769 639.441,113.144 637.436,113.144"
                  id="Fill-595"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M647.339,197.897 C645.334,197.897 643.709,199.523 643.709,201.527 C643.709,203.531 645.334,205.156 647.339,205.156 C649.344,205.156 650.968,203.531 650.968,201.527 C650.968,199.523 649.344,197.897 647.339,197.897"
                  id="Fill-596"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M637.436,103.727 C635.431,103.727 633.805,105.352 633.805,107.356 C633.805,109.361 635.431,110.986 637.436,110.986 C639.441,110.986 641.065,109.361 641.065,107.356 C641.065,105.352 639.441,103.727 637.436,103.727"
                  id="Fill-597"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,141.395 C635.431,141.395 633.805,143.02 633.805,145.024 C633.805,147.029 635.431,148.654 637.436,148.654 C639.441,148.654 641.065,147.029 641.065,145.024 C641.065,143.02 639.441,141.395 637.436,141.395"
                  id="Fill-598"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,131.978 C635.431,131.978 633.805,133.603 633.805,135.607 C633.805,137.612 635.431,139.237 637.436,139.237 C639.441,139.237 641.065,137.612 641.065,135.607 C641.065,133.603 639.441,131.978 637.436,131.978"
                  id="Fill-599"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M637.436,94.309 C635.431,94.309 633.805,95.935 633.805,97.939 C633.805,99.944 635.431,101.569 637.436,101.569 C639.441,101.569 641.065,99.944 641.065,97.939 C641.065,95.935 639.441,94.309 637.436,94.309"
                  id="Fill-600"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,75.476 C635.431,75.476 633.805,77.1 633.805,79.105 C633.805,81.109 635.431,82.735 637.436,82.735 C639.441,82.735 641.065,81.109 641.065,79.105 C641.065,77.1 639.441,75.476 637.436,75.476"
                  id="Fill-601"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,66.058 C635.431,66.058 633.805,67.683 633.805,69.688 C633.805,71.693 635.431,73.318 637.436,73.318 C639.441,73.318 641.065,71.693 641.065,69.688 C641.065,67.683 639.441,66.058 637.436,66.058"
                  id="Fill-602"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M637.436,84.892 C635.431,84.892 633.805,86.517 633.805,88.522 C633.805,90.527 635.431,92.152 637.436,92.152 C639.441,92.152 641.065,90.527 641.065,88.522 C641.065,86.517 639.441,84.892 637.436,84.892"
                  id="Fill-603"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,56.642 C665.141,56.642 663.515,58.266 663.515,60.271 C663.515,62.276 665.141,63.901 667.146,63.901 C669.15,63.901 670.775,62.276 670.775,60.271 C670.775,58.266 669.15,56.642 667.146,56.642"
                  id="Fill-604"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,113.144 C665.141,113.144 663.515,114.769 663.515,116.773 C663.515,118.777 665.141,120.403 667.146,120.403 C669.15,120.403 670.775,118.777 670.775,116.773 C670.775,114.769 669.15,113.144 667.146,113.144"
                  id="Fill-605"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,122.561 C665.141,122.561 663.515,124.186 663.515,126.19 C663.515,128.195 665.141,129.82 667.146,129.82 C669.15,129.82 670.775,128.195 670.775,126.19 C670.775,124.186 669.15,122.561 667.146,122.561"
                  id="Fill-606"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,150.812 C665.141,150.812 663.515,152.437 663.515,154.441 C663.515,156.446 665.141,158.071 667.146,158.071 C669.15,158.071 670.775,156.446 670.775,154.441 C670.775,152.437 669.15,150.812 667.146,150.812"
                  id="Fill-607"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,160.229 C665.141,160.229 663.515,161.854 663.515,163.859 C663.515,165.863 665.141,167.488 667.146,167.488 C669.15,167.488 670.775,165.863 670.775,163.859 C670.775,161.854 669.15,160.229 667.146,160.229"
                  id="Fill-608"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,169.646 C665.141,169.646 663.515,171.271 663.515,173.275 C663.515,175.28 665.141,176.905 667.146,176.905 C669.15,176.905 670.775,175.28 670.775,173.275 C670.775,171.271 669.15,169.646 667.146,169.646"
                  id="Fill-609"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,103.727 C665.141,103.727 663.515,105.352 663.515,107.356 C663.515,109.361 665.141,110.986 667.146,110.986 C669.15,110.986 670.775,109.361 670.775,107.356 C670.775,105.352 669.15,103.727 667.146,103.727"
                  id="Fill-610"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,66.058 C665.141,66.058 663.515,67.683 663.515,69.688 C663.515,71.693 665.141,73.318 667.146,73.318 C669.15,73.318 670.775,71.693 670.775,69.688 C670.775,67.683 669.15,66.058 667.146,66.058"
                  id="Fill-611"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M677.048,122.561 C675.044,122.561 673.419,124.186 673.419,126.19 C673.419,128.195 675.044,129.82 677.048,129.82 C679.053,129.82 680.678,128.195 680.678,126.19 C680.678,124.186 679.053,122.561 677.048,122.561"
                  id="Fill-612"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M667.146,94.309 C665.141,94.309 663.515,95.935 663.515,97.939 C663.515,99.944 665.141,101.569 667.146,101.569 C669.15,101.569 670.775,99.944 670.775,97.939 C670.775,95.935 669.15,94.309 667.146,94.309"
                  id="Fill-613"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M667.146,84.892 C665.141,84.892 663.515,86.517 663.515,88.522 C663.515,90.527 665.141,92.152 667.146,92.152 C669.15,92.152 670.775,90.527 670.775,88.522 C670.775,86.517 669.15,84.892 667.146,84.892"
                  id="Fill-614"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M667.146,75.476 C665.141,75.476 663.515,77.1 663.515,79.105 C663.515,81.109 665.141,82.735 667.146,82.735 C669.15,82.735 670.775,81.109 670.775,79.105 C670.775,77.1 669.15,75.476 667.146,75.476"
                  id="Fill-615"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,179.063 C655.238,179.063 653.612,180.688 653.612,182.693 C653.612,184.697 655.238,186.322 657.242,186.322 C659.246,186.322 660.871,184.697 660.871,182.693 C660.871,180.688 659.246,179.063 657.242,179.063"
                  id="Fill-616"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,122.561 C655.238,122.561 653.612,124.186 653.612,126.19 C653.612,128.195 655.238,129.82 657.242,129.82 C659.246,129.82 660.871,128.195 660.871,126.19 C660.871,124.186 659.246,122.561 657.242,122.561"
                  id="Fill-617"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,113.144 C655.238,113.144 653.612,114.769 653.612,116.773 C653.612,118.777 655.238,120.403 657.242,120.403 C659.246,120.403 660.871,118.777 660.871,116.773 C660.871,114.769 659.246,113.144 657.242,113.144"
                  id="Fill-618"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,56.642 C655.238,56.642 653.612,58.266 653.612,60.271 C653.612,62.276 655.238,63.901 657.242,63.901 C659.246,63.901 660.871,62.276 660.871,60.271 C660.871,58.266 659.246,56.642 657.242,56.642"
                  id="Fill-619"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,188.48 C655.238,188.48 653.612,190.106 653.612,192.11 C653.612,194.114 655.238,195.739 657.242,195.739 C659.246,195.739 660.871,194.114 660.871,192.11 C660.871,190.106 659.246,188.48 657.242,188.48"
                  id="Fill-620"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M657.242,169.646 C655.238,169.646 653.612,171.271 653.612,173.275 C653.612,175.28 655.238,176.905 657.242,176.905 C659.246,176.905 660.871,175.28 660.871,173.275 C660.871,171.271 659.246,169.646 657.242,169.646"
                  id="Fill-621"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,103.727 C655.238,103.727 653.612,105.352 653.612,107.356 C653.612,109.361 655.238,110.986 657.242,110.986 C659.246,110.986 660.871,109.361 660.871,107.356 C660.871,105.352 659.246,103.727 657.242,103.727"
                  id="Fill-622"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,66.058 C655.238,66.058 653.612,67.683 653.612,69.688 C653.612,71.693 655.238,73.318 657.242,73.318 C659.246,73.318 660.871,71.693 660.871,69.688 C660.871,67.683 659.246,66.058 657.242,66.058"
                  id="Fill-623"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,94.309 C655.238,94.309 653.612,95.935 653.612,97.939 C653.612,99.944 655.238,101.569 657.242,101.569 C659.246,101.569 660.871,99.944 660.871,97.939 C660.871,95.935 659.246,94.309 657.242,94.309"
                  id="Fill-624"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,75.476 C655.238,75.476 653.612,77.1 653.612,79.105 C653.612,81.109 655.238,82.735 657.242,82.735 C659.246,82.735 660.871,81.109 660.871,79.105 C660.871,77.1 659.246,75.476 657.242,75.476"
                  id="Fill-625"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M657.242,84.892 C655.238,84.892 653.612,86.517 653.612,88.522 C653.612,90.527 655.238,92.152 657.242,92.152 C659.246,92.152 660.871,90.527 660.871,88.522 C660.871,86.517 659.246,84.892 657.242,84.892"
                  id="Fill-626"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M617.629,150.812 C615.624,150.812 614,152.437 614,154.441 C614,156.446 615.624,158.071 617.629,158.071 C619.634,158.071 621.259,156.446 621.259,154.441 C621.259,152.437 619.634,150.812 617.629,150.812"
                  id="Fill-627"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,75.476 C536.398,75.476 534.773,77.1 534.773,79.105 C534.773,81.109 536.398,82.735 538.403,82.735 C540.408,82.735 542.034,81.109 542.034,79.105 C542.034,77.1 540.408,75.476 538.403,75.476"
                  id="Fill-628"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,66.058 C536.398,66.058 534.773,67.683 534.773,69.688 C534.773,71.693 536.398,73.318 538.403,73.318 C540.408,73.318 542.034,71.693 542.034,69.688 C542.034,67.683 540.408,66.058 538.403,66.058"
                  id="Fill-629"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,56.642 C536.398,56.642 534.773,58.266 534.773,60.271 C534.773,62.276 536.398,63.901 538.403,63.901 C540.408,63.901 542.034,62.276 542.034,60.271 C542.034,58.266 540.408,56.642 538.403,56.642"
                  id="Fill-630"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,226.148 C556.205,226.148 554.58,227.773 554.58,229.778 C554.58,231.782 556.205,233.407 558.21,233.407 C560.215,233.407 561.839,231.782 561.839,229.778 C561.839,227.773 560.215,226.148 558.21,226.148"
                  id="Fill-631"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,84.892 C536.398,84.892 534.773,86.517 534.773,88.522 C534.773,90.527 536.398,92.152 538.403,92.152 C540.408,92.152 542.034,90.527 542.034,88.522 C542.034,86.517 540.408,84.892 538.403,84.892"
                  id="Fill-632"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,94.309 C536.398,94.309 534.773,95.935 534.773,97.939 C534.773,99.944 536.398,101.569 538.403,101.569 C540.408,101.569 542.034,99.944 542.034,97.939 C542.034,95.935 540.408,94.309 538.403,94.309"
                  id="Fill-633"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,216.731 C546.302,216.731 544.678,218.357 544.678,220.361 C544.678,222.365 546.302,223.99 548.307,223.99 C550.31,223.99 551.936,222.365 551.936,220.361 C551.936,218.357 550.31,216.731 548.307,216.731"
                  id="Fill-634"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M538.403,47.225 C536.398,47.225 534.773,48.849 534.773,50.854 C534.773,52.858 536.398,54.483 538.403,54.483 C540.408,54.483 542.034,52.858 542.034,50.854 C542.034,48.849 540.408,47.225 538.403,47.225"
                  id="Fill-635"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,207.314 C546.302,207.314 544.678,208.939 544.678,210.944 C544.678,212.948 546.302,214.574 548.307,214.574 C550.31,214.574 551.936,212.948 551.936,210.944 C551.936,208.939 550.31,207.314 548.307,207.314"
                  id="Fill-636"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,226.148 C546.302,226.148 544.678,227.773 544.678,229.778 C544.678,231.782 546.302,233.407 548.307,233.407 C550.31,233.407 551.936,231.782 551.936,229.778 C551.936,227.773 550.31,226.148 548.307,226.148"
                  id="Fill-637"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,207.314 C536.398,207.314 534.773,208.939 534.773,210.944 C534.773,212.948 536.398,214.574 538.403,214.574 C540.408,214.574 542.034,212.948 542.034,210.944 C542.034,208.939 540.408,207.314 538.403,207.314"
                  id="Fill-638"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,179.063 C536.398,179.063 534.773,180.688 534.773,182.693 C534.773,184.697 536.398,186.322 538.403,186.322 C540.408,186.322 542.034,184.697 542.034,182.693 C542.034,180.688 540.408,179.063 538.403,179.063"
                  id="Fill-639"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,169.646 C536.398,169.646 534.773,171.271 534.773,173.275 C534.773,175.28 536.398,176.905 538.403,176.905 C540.408,176.905 542.034,175.28 542.034,173.275 C542.034,171.271 540.408,169.646 538.403,169.646"
                  id="Fill-640"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,113.144 C536.398,113.144 534.773,114.769 534.773,116.773 C534.773,118.777 536.398,120.403 538.403,120.403 C540.408,120.403 542.034,118.777 542.034,116.773 C542.034,114.769 540.408,113.144 538.403,113.144"
                  id="Fill-641"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,160.229 C536.398,160.229 534.773,161.854 534.773,163.859 C534.773,165.863 536.398,167.488 538.403,167.488 C540.408,167.488 542.034,165.863 542.034,163.859 C542.034,161.854 540.408,160.229 538.403,160.229"
                  id="Fill-642"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,197.897 C546.302,197.897 544.678,199.523 544.678,201.527 C544.678,203.531 546.302,205.156 548.307,205.156 C550.31,205.156 551.936,203.531 551.936,201.527 C551.936,199.523 550.31,197.897 548.307,197.897"
                  id="Fill-643"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,188.48 C536.398,188.48 534.773,190.106 534.773,192.11 C534.773,194.114 536.398,195.739 538.403,195.739 C540.408,195.739 542.034,194.114 542.034,192.11 C542.034,190.106 540.408,188.48 538.403,188.48"
                  id="Fill-644"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,122.561 C536.398,122.561 534.773,124.186 534.773,126.19 C534.773,128.195 536.398,129.82 538.403,129.82 C540.408,129.82 542.034,128.195 542.034,126.19 C542.034,124.186 540.408,122.561 538.403,122.561"
                  id="Fill-645"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,131.978 C536.398,131.978 534.773,133.603 534.773,135.607 C534.773,137.612 536.398,139.237 538.403,139.237 C540.408,139.237 542.034,137.612 542.034,135.607 C542.034,133.603 540.408,131.978 538.403,131.978"
                  id="Fill-646"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,141.395 C536.398,141.395 534.773,143.02 534.773,145.024 C534.773,147.029 536.398,148.654 538.403,148.654 C540.408,148.654 542.034,147.029 542.034,145.024 C542.034,143.02 540.408,141.395 538.403,141.395"
                  id="Fill-647"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M538.403,150.812 C536.398,150.812 534.773,152.437 534.773,154.441 C534.773,156.446 536.398,158.071 538.403,158.071 C540.408,158.071 542.034,156.446 542.034,154.441 C542.034,152.437 540.408,150.812 538.403,150.812"
                  id="Fill-648"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,197.897 C536.398,197.897 534.773,199.523 534.773,201.527 C534.773,203.531 536.398,205.156 538.403,205.156 C540.408,205.156 542.034,203.531 542.034,201.527 C542.034,199.523 540.408,197.897 538.403,197.897"
                  id="Fill-649"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,66.058 C546.302,66.058 544.678,67.683 544.678,69.688 C544.678,71.693 546.302,73.318 548.307,73.318 C550.31,73.318 551.936,71.693 551.936,69.688 C551.936,67.683 550.31,66.058 548.307,66.058"
                  id="Fill-650"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,56.642 C546.302,56.642 544.678,58.266 544.678,60.271 C544.678,62.276 546.302,63.901 548.307,63.901 C550.31,63.901 551.936,62.276 551.936,60.271 C551.936,58.266 550.31,56.642 548.307,56.642"
                  id="Fill-651"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,47.225 C546.302,47.225 544.678,48.849 544.678,50.854 C544.678,52.858 546.302,54.483 548.307,54.483 C550.31,54.483 551.936,52.858 551.936,50.854 C551.936,48.849 550.31,47.225 548.307,47.225"
                  id="Fill-652"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,94.309 C546.302,94.309 544.678,95.935 544.678,97.939 C544.678,99.944 546.302,101.569 548.307,101.569 C550.31,101.569 551.936,99.944 551.936,97.939 C551.936,95.935 550.31,94.309 548.307,94.309"
                  id="Fill-653"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,84.892 C546.302,84.892 544.678,86.517 544.678,88.522 C544.678,90.527 546.302,92.152 548.307,92.152 C550.31,92.152 551.936,90.527 551.936,88.522 C551.936,86.517 550.31,84.892 548.307,84.892"
                  id="Fill-654"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,75.476 C546.302,75.476 544.678,77.1 544.678,79.105 C544.678,81.109 546.302,82.735 548.307,82.735 C550.31,82.735 551.936,81.109 551.936,79.105 C551.936,77.1 550.31,75.476 548.307,75.476"
                  id="Fill-655"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,244.982 C556.205,244.982 554.58,246.607 554.58,248.612 C554.58,250.616 556.205,252.242 558.21,252.242 C560.215,252.242 561.839,250.616 561.839,248.612 C561.839,246.607 560.215,244.982 558.21,244.982"
                  id="Fill-656"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,188.48 C546.302,188.48 544.678,190.106 544.678,192.11 C544.678,194.114 546.302,195.739 548.307,195.739 C550.31,195.739 551.936,194.114 551.936,192.11 C551.936,190.106 550.31,188.48 548.307,188.48"
                  id="Fill-657"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M548.307,28.39 C546.302,28.39 544.678,30.016 544.678,32.02 C544.678,34.025 546.302,35.649 548.307,35.649 C550.31,35.649 551.936,34.025 551.936,32.02 C551.936,30.016 550.31,28.39 548.307,28.39"
                  id="Fill-658"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,37.808 C546.302,37.808 544.678,39.432 544.678,41.436 C544.678,43.441 546.302,45.066 548.307,45.066 C550.31,45.066 551.936,43.441 551.936,41.436 C551.936,39.432 550.31,37.808 548.307,37.808"
                  id="Fill-659"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,235.565 C556.205,235.565 554.58,237.191 554.58,239.195 C554.58,241.199 556.205,242.824 558.21,242.824 C560.215,242.824 561.839,241.199 561.839,239.195 C561.839,237.191 560.215,235.565 558.21,235.565"
                  id="Fill-660"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M548.307,160.229 C546.302,160.229 544.678,161.854 544.678,163.859 C544.678,165.863 546.302,167.488 548.307,167.488 C550.31,167.488 551.936,165.863 551.936,163.859 C551.936,161.854 550.31,160.229 548.307,160.229"
                  id="Fill-661"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,169.646 C546.302,169.646 544.678,171.271 544.678,173.275 C544.678,175.28 546.302,176.905 548.307,176.905 C550.31,176.905 551.936,175.28 551.936,173.275 C551.936,171.271 550.31,169.646 548.307,169.646"
                  id="Fill-662"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M548.307,150.812 C546.302,150.812 544.678,152.437 544.678,154.441 C544.678,156.446 546.302,158.071 548.307,158.071 C550.31,158.071 551.936,156.446 551.936,154.441 C551.936,152.437 550.31,150.812 548.307,150.812"
                  id="Fill-663"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M548.307,179.063 C546.302,179.063 544.678,180.688 544.678,182.693 C544.678,184.697 546.302,186.322 548.307,186.322 C550.31,186.322 551.936,184.697 551.936,182.693 C551.936,180.688 550.31,179.063 548.307,179.063"
                  id="Fill-664"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,103.727 C546.302,103.727 544.678,105.352 544.678,107.356 C544.678,109.361 546.302,110.986 548.307,110.986 C550.31,110.986 551.936,109.361 551.936,107.356 C551.936,105.352 550.31,103.727 548.307,103.727"
                  id="Fill-665"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,122.561 C546.302,122.561 544.678,124.186 544.678,126.19 C544.678,128.195 546.302,129.82 548.307,129.82 C550.31,129.82 551.936,128.195 551.936,126.19 C551.936,124.186 550.31,122.561 548.307,122.561"
                  id="Fill-666"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M548.307,113.144 C546.302,113.144 544.678,114.769 544.678,116.773 C544.678,118.777 546.302,120.403 548.307,120.403 C550.31,120.403 551.936,118.777 551.936,116.773 C551.936,114.769 550.31,113.144 548.307,113.144"
                  id="Fill-667"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,131.978 C546.302,131.978 544.678,133.603 544.678,135.607 C544.678,137.612 546.302,139.237 548.307,139.237 C550.31,139.237 551.936,137.612 551.936,135.607 C551.936,133.603 550.31,131.978 548.307,131.978"
                  id="Fill-668"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M548.307,141.395 C546.302,141.395 544.678,143.02 544.678,145.024 C544.678,147.029 546.302,148.654 548.307,148.654 C550.31,148.654 551.936,147.029 551.936,145.024 C551.936,143.02 550.31,141.395 548.307,141.395"
                  id="Fill-669"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M538.403,103.727 C536.398,103.727 534.773,105.352 534.773,107.356 C534.773,109.361 536.398,110.986 538.403,110.986 C540.408,110.986 542.034,109.361 542.034,107.356 C542.034,105.352 540.408,103.727 538.403,103.727"
                  id="Fill-670"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,66.058 C516.593,66.058 514.968,67.683 514.968,69.688 C514.968,71.693 516.593,73.318 518.597,73.318 C520.602,73.318 522.227,71.693 522.227,69.688 C522.227,67.683 520.602,66.058 518.597,66.058"
                  id="Fill-671"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,84.892 C516.593,84.892 514.968,86.517 514.968,88.522 C514.968,90.527 516.593,92.152 518.597,92.152 C520.602,92.152 522.227,90.527 522.227,88.522 C522.227,86.517 520.602,84.892 518.597,84.892"
                  id="Fill-672"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,75.476 C516.593,75.476 514.968,77.1 514.968,79.105 C514.968,81.109 516.593,82.735 518.597,82.735 C520.602,82.735 522.227,81.109 522.227,79.105 C522.227,77.1 520.602,75.476 518.597,75.476"
                  id="Fill-673"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,113.144 C516.593,113.144 514.968,114.769 514.968,116.773 C514.968,118.777 516.593,120.403 518.597,120.403 C520.602,120.403 522.227,118.777 522.227,116.773 C522.227,114.769 520.602,113.144 518.597,113.144"
                  id="Fill-674"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,94.309 C516.593,94.309 514.968,95.935 514.968,97.939 C514.968,99.944 516.593,101.569 518.597,101.569 C520.602,101.569 522.227,99.944 522.227,97.939 C522.227,95.935 520.602,94.309 518.597,94.309"
                  id="Fill-675"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,103.727 C516.593,103.727 514.968,105.352 514.968,107.356 C514.968,109.361 516.593,110.986 518.597,110.986 C520.602,110.986 522.227,109.361 522.227,107.356 C522.227,105.352 520.602,103.727 518.597,103.727"
                  id="Fill-676"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,216.731 C536.398,216.731 534.773,218.357 534.773,220.361 C534.773,222.365 536.398,223.99 538.403,223.99 C540.408,223.99 542.034,222.365 542.034,220.361 C542.034,218.357 540.408,216.731 538.403,216.731"
                  id="Fill-677"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,56.642 C516.593,56.642 514.968,58.266 514.968,60.271 C514.968,62.276 516.593,63.901 518.597,63.901 C520.602,63.901 522.227,62.276 522.227,60.271 C522.227,58.266 520.602,56.642 518.597,56.642"
                  id="Fill-678"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,235.565 C526.495,235.565 524.871,237.191 524.871,239.195 C524.871,241.199 526.495,242.824 528.5,242.824 C530.505,242.824 532.129,241.199 532.129,239.195 C532.129,237.191 530.505,235.565 528.5,235.565"
                  id="Fill-679"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,47.225 C516.593,47.225 514.968,48.849 514.968,50.854 C514.968,52.858 516.593,54.483 518.597,54.483 C520.602,54.483 522.227,52.858 522.227,50.854 C522.227,48.849 520.602,47.225 518.597,47.225"
                  id="Fill-680"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,160.229 C516.593,160.229 514.968,161.854 514.968,163.859 C514.968,165.863 516.593,167.488 518.597,167.488 C520.602,167.488 522.227,165.863 522.227,163.859 C522.227,161.854 520.602,160.229 518.597,160.229"
                  id="Fill-681"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,188.48 C516.593,188.48 514.968,190.106 514.968,192.11 C514.968,194.114 516.593,195.739 518.597,195.739 C520.602,195.739 522.227,194.114 522.227,192.11 C522.227,190.106 520.602,188.48 518.597,188.48"
                  id="Fill-682"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,179.063 C516.593,179.063 514.968,180.688 514.968,182.693 C514.968,184.697 516.593,186.322 518.597,186.322 C520.602,186.322 522.227,184.697 522.227,182.693 C522.227,180.688 520.602,179.063 518.597,179.063"
                  id="Fill-683"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,169.646 C516.593,169.646 514.968,171.271 514.968,173.275 C514.968,175.28 516.593,176.905 518.597,176.905 C520.602,176.905 522.227,175.28 522.227,173.275 C522.227,171.271 520.602,169.646 518.597,169.646"
                  id="Fill-684"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,197.897 C516.593,197.897 514.968,199.523 514.968,201.527 C514.968,203.531 516.593,205.156 518.597,205.156 C520.602,205.156 522.227,203.531 522.227,201.527 C522.227,199.523 520.602,197.897 518.597,197.897"
                  id="Fill-685"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,207.314 C516.593,207.314 514.968,208.939 514.968,210.944 C514.968,212.948 516.593,214.574 518.597,214.574 C520.602,214.574 522.227,212.948 522.227,210.944 C522.227,208.939 520.602,207.314 518.597,207.314"
                  id="Fill-686"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,141.395 C516.593,141.395 514.968,143.02 514.968,145.024 C514.968,147.029 516.593,148.654 518.597,148.654 C520.602,148.654 522.227,147.029 522.227,145.024 C522.227,143.02 520.602,141.395 518.597,141.395"
                  id="Fill-687"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,122.561 C516.593,122.561 514.968,124.186 514.968,126.19 C514.968,128.195 516.593,129.82 518.597,129.82 C520.602,129.82 522.227,128.195 522.227,126.19 C522.227,124.186 520.602,122.561 518.597,122.561"
                  id="Fill-688"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,131.978 C516.593,131.978 514.968,133.603 514.968,135.607 C514.968,137.612 516.593,139.237 518.597,139.237 C520.602,139.237 522.227,137.612 522.227,135.607 C522.227,133.603 520.602,131.978 518.597,131.978"
                  id="Fill-689"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,226.148 C526.495,226.148 524.871,227.773 524.871,229.778 C524.871,231.782 526.495,233.407 528.5,233.407 C530.505,233.407 532.129,231.782 532.129,229.778 C532.129,227.773 530.505,226.148 528.5,226.148"
                  id="Fill-690"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,150.812 C516.593,150.812 514.968,152.437 514.968,154.441 C514.968,156.446 516.593,158.071 518.597,158.071 C520.602,158.071 522.227,156.446 522.227,154.441 C522.227,152.437 520.602,150.812 518.597,150.812"
                  id="Fill-691"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M528.5,244.982 C526.495,244.982 524.871,246.607 524.871,248.612 C524.871,250.616 526.495,252.242 528.5,252.242 C530.505,252.242 532.129,250.616 532.129,248.612 C532.129,246.607 530.505,244.982 528.5,244.982"
                  id="Fill-692"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,84.892 C526.495,84.892 524.871,86.517 524.871,88.522 C524.871,90.527 526.495,92.152 528.5,92.152 C530.505,92.152 532.129,90.527 532.129,88.522 C532.129,86.517 530.505,84.892 528.5,84.892"
                  id="Fill-693"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,94.309 C526.495,94.309 524.871,95.935 524.871,97.939 C524.871,99.944 526.495,101.569 528.5,101.569 C530.505,101.569 532.129,99.944 532.129,97.939 C532.129,95.935 530.505,94.309 528.5,94.309"
                  id="Fill-694"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,216.731 C526.495,216.731 524.871,218.357 524.871,220.361 C524.871,222.365 526.495,223.99 528.5,223.99 C530.505,223.99 532.129,222.365 532.129,220.361 C532.129,218.357 530.505,216.731 528.5,216.731"
                  id="Fill-695"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M528.5,103.727 C526.495,103.727 524.871,105.352 524.871,107.356 C524.871,109.361 526.495,110.986 528.5,110.986 C530.505,110.986 532.129,109.361 532.129,107.356 C532.129,105.352 530.505,103.727 528.5,103.727"
                  id="Fill-696"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,75.476 C526.495,75.476 524.871,77.1 524.871,79.105 C524.871,81.109 526.495,82.735 528.5,82.735 C530.505,82.735 532.129,81.109 532.129,79.105 C532.129,77.1 530.505,75.476 528.5,75.476"
                  id="Fill-697"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,66.058 C526.495,66.058 524.871,67.683 524.871,69.688 C524.871,71.693 526.495,73.318 528.5,73.318 C530.505,73.318 532.129,71.693 532.129,69.688 C532.129,67.683 530.505,66.058 528.5,66.058"
                  id="Fill-698"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M538.403,226.148 C536.398,226.148 534.773,227.773 534.773,229.778 C534.773,231.782 536.398,233.407 538.403,233.407 C540.408,233.407 542.034,231.782 542.034,229.778 C542.034,227.773 540.408,226.148 538.403,226.148"
                  id="Fill-699"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,122.561 C526.495,122.561 524.871,124.186 524.871,126.19 C524.871,128.195 526.495,129.82 528.5,129.82 C530.505,129.82 532.129,128.195 532.129,126.19 C532.129,124.186 530.505,122.561 528.5,122.561"
                  id="Fill-700"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M538.403,235.565 C536.398,235.565 534.773,237.191 534.773,239.195 C534.773,241.199 536.398,242.824 538.403,242.824 C540.408,242.824 542.034,241.199 542.034,239.195 C542.034,237.191 540.408,235.565 538.403,235.565"
                  id="Fill-701"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,56.642 C526.495,56.642 524.871,58.266 524.871,60.271 C524.871,62.276 526.495,63.901 528.5,63.901 C530.505,63.901 532.129,62.276 532.129,60.271 C532.129,58.266 530.505,56.642 528.5,56.642"
                  id="Fill-702"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,113.144 C526.495,113.144 524.871,114.769 524.871,116.773 C524.871,118.777 526.495,120.403 528.5,120.403 C530.505,120.403 532.129,118.777 532.129,116.773 C532.129,114.769 530.505,113.144 528.5,113.144"
                  id="Fill-703"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M528.5,197.897 C526.495,197.897 524.871,199.523 524.871,201.527 C524.871,203.531 526.495,205.156 528.5,205.156 C530.505,205.156 532.129,203.531 532.129,201.527 C532.129,199.523 530.505,197.897 528.5,197.897"
                  id="Fill-704"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,188.48 C526.495,188.48 524.871,190.106 524.871,192.11 C524.871,194.114 526.495,195.739 528.5,195.739 C530.505,195.739 532.129,194.114 532.129,192.11 C532.129,190.106 530.505,188.48 528.5,188.48"
                  id="Fill-705"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,207.314 C526.495,207.314 524.871,208.939 524.871,210.944 C524.871,212.948 526.495,214.574 528.5,214.574 C530.505,214.574 532.129,212.948 532.129,210.944 C532.129,208.939 530.505,207.314 528.5,207.314"
                  id="Fill-706"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,131.978 C526.495,131.978 524.871,133.603 524.871,135.607 C524.871,137.612 526.495,139.237 528.5,139.237 C530.505,139.237 532.129,137.612 532.129,135.607 C532.129,133.603 530.505,131.978 528.5,131.978"
                  id="Fill-707"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,179.063 C526.495,179.063 524.871,180.688 524.871,182.693 C524.871,184.697 526.495,186.322 528.5,186.322 C530.505,186.322 532.129,184.697 532.129,182.693 C532.129,180.688 530.505,179.063 528.5,179.063"
                  id="Fill-708"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,169.646 C526.495,169.646 524.871,171.271 524.871,173.275 C524.871,175.28 526.495,176.905 528.5,176.905 C530.505,176.905 532.129,175.28 532.129,173.275 C532.129,171.271 530.505,169.646 528.5,169.646"
                  id="Fill-709"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M528.5,150.812 C526.495,150.812 524.871,152.437 524.871,154.441 C524.871,156.446 526.495,158.071 528.5,158.071 C530.505,158.071 532.129,156.446 532.129,154.441 C532.129,152.437 530.505,150.812 528.5,150.812"
                  id="Fill-710"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,141.395 C526.495,141.395 524.871,143.02 524.871,145.024 C524.871,147.029 526.495,148.654 528.5,148.654 C530.505,148.654 532.129,147.029 532.129,145.024 C532.129,143.02 530.505,141.395 528.5,141.395"
                  id="Fill-711"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M528.5,160.229 C526.495,160.229 524.871,161.854 524.871,163.859 C524.871,165.863 526.495,167.488 528.5,167.488 C530.505,167.488 532.129,165.863 532.129,163.859 C532.129,161.854 530.505,160.229 528.5,160.229"
                  id="Fill-712"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,131.978 C556.205,131.978 554.58,133.603 554.58,135.607 C554.58,137.612 556.205,139.237 558.21,139.237 C560.215,139.237 561.839,137.612 561.839,135.607 C561.839,133.603 560.215,131.978 558.21,131.978"
                  id="Fill-713"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,197.897 C576.012,197.897 574.387,199.523 574.387,201.527 C574.387,203.531 576.012,205.156 578.017,205.156 C580.02,205.156 581.646,203.531 581.646,201.527 C581.646,199.523 580.02,197.897 578.017,197.897"
                  id="Fill-714"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,188.48 C576.012,188.48 574.387,190.106 574.387,192.11 C574.387,194.114 576.012,195.739 578.017,195.739 C580.02,195.739 581.646,194.114 581.646,192.11 C581.646,190.106 580.02,188.48 578.017,188.48"
                  id="Fill-715"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,207.314 C576.012,207.314 574.387,208.939 574.387,210.944 C574.387,212.948 576.012,214.574 578.017,214.574 C580.02,214.574 581.646,212.948 581.646,210.944 C581.646,208.939 580.02,207.314 578.017,207.314"
                  id="Fill-716"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M578.017,179.063 C576.012,179.063 574.387,180.688 574.387,182.693 C574.387,184.697 576.012,186.322 578.017,186.322 C580.02,186.322 581.646,184.697 581.646,182.693 C581.646,180.688 580.02,179.063 578.017,179.063"
                  id="Fill-717"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,131.978 C576.012,131.978 574.387,133.603 574.387,135.607 C574.387,137.612 576.012,139.237 578.017,139.237 C580.02,139.237 581.646,137.612 581.646,135.607 C581.646,133.603 580.02,131.978 578.017,131.978"
                  id="Fill-718"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M578.017,160.229 C576.012,160.229 574.387,161.854 574.387,163.859 C574.387,165.863 576.012,167.488 578.017,167.488 C580.02,167.488 581.646,165.863 581.646,163.859 C581.646,161.854 580.02,160.229 578.017,160.229"
                  id="Fill-719"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,141.395 C576.012,141.395 574.387,143.02 574.387,145.024 C574.387,147.029 576.012,148.654 578.017,148.654 C580.02,148.654 581.646,147.029 581.646,145.024 C581.646,143.02 580.02,141.395 578.017,141.395"
                  id="Fill-720"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,150.812 C576.012,150.812 574.387,152.437 574.387,154.441 C574.387,156.446 576.012,158.071 578.017,158.071 C580.02,158.071 581.646,156.446 581.646,154.441 C581.646,152.437 580.02,150.812 578.017,150.812"
                  id="Fill-721"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,169.646 C576.012,169.646 574.387,171.271 574.387,173.275 C574.387,175.28 576.012,176.905 578.017,176.905 C580.02,176.905 581.646,175.28 581.646,173.275 C581.646,171.271 580.02,169.646 578.017,169.646"
                  id="Fill-722"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M578.017,226.148 C576.012,226.148 574.387,227.773 574.387,229.778 C574.387,231.782 576.012,233.407 578.017,233.407 C580.02,233.407 581.646,231.782 581.646,229.778 C581.646,227.773 580.02,226.148 578.017,226.148"
                  id="Fill-723"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,216.731 C576.012,216.731 574.387,218.357 574.387,220.361 C574.387,222.365 576.012,223.99 578.017,223.99 C580.02,223.99 581.646,222.365 581.646,220.361 C581.646,218.357 580.02,216.731 578.017,216.731"
                  id="Fill-724"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,28.39 C566.108,28.39 564.483,30.016 564.483,32.02 C564.483,34.025 566.108,35.649 568.113,35.649 C570.117,35.649 571.743,34.025 571.743,32.02 C571.743,30.016 570.117,28.39 568.113,28.39"
                  id="Fill-725"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,37.808 C566.108,37.808 564.483,39.432 564.483,41.436 C564.483,43.441 566.108,45.066 568.113,45.066 C570.117,45.066 571.743,43.441 571.743,41.436 C571.743,39.432 570.117,37.808 568.113,37.808"
                  id="Fill-726"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,47.225 C566.108,47.225 564.483,48.849 564.483,50.854 C564.483,52.858 566.108,54.483 568.113,54.483 C570.117,54.483 571.743,52.858 571.743,50.854 C571.743,48.849 570.117,47.225 568.113,47.225"
                  id="Fill-727"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,141.395 C585.915,141.395 584.29,143.02 584.29,145.024 C584.29,147.029 585.915,148.654 587.919,148.654 C589.924,148.654 591.549,147.029 591.549,145.024 C591.549,143.02 589.924,141.395 587.919,141.395"
                  id="Fill-728"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,56.642 C566.108,56.642 564.483,58.266 564.483,60.271 C564.483,62.276 566.108,63.901 568.113,63.901 C570.117,63.901 571.743,62.276 571.743,60.271 C571.743,58.266 570.117,56.642 568.113,56.642"
                  id="Fill-729"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,150.812 C585.915,150.812 584.29,152.437 584.29,154.441 C584.29,156.446 585.915,158.071 587.919,158.071 C589.924,158.071 591.549,156.446 591.549,154.441 C591.549,152.437 589.924,150.812 587.919,150.812"
                  id="Fill-730"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,235.565 C576.012,235.565 574.387,237.191 574.387,239.195 C574.387,241.199 576.012,242.824 578.017,242.824 C580.02,242.824 581.646,241.199 581.646,239.195 C581.646,237.191 580.02,235.565 578.017,235.565"
                  id="Fill-731"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,66.058 C566.108,66.058 564.483,67.683 564.483,69.688 C564.483,71.693 566.108,73.318 568.113,73.318 C570.117,73.318 571.743,71.693 571.743,69.688 C571.743,67.683 570.117,66.058 568.113,66.058"
                  id="Fill-732"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,244.982 C576.012,244.982 574.387,246.607 574.387,248.612 C574.387,250.616 576.012,252.242 578.017,252.242 C580.02,252.242 581.646,250.616 581.646,248.612 C581.646,246.607 580.02,244.982 578.017,244.982"
                  id="Fill-733"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,160.229 C585.915,160.229 584.29,161.854 584.29,163.859 C584.29,165.863 585.915,167.488 587.919,167.488 C589.924,167.488 591.549,165.863 591.549,163.859 C591.549,161.854 589.924,160.229 587.919,160.229"
                  id="Fill-734"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,254.399 C576.012,254.399 574.387,256.025 574.387,258.029 C574.387,260.033 576.012,261.658 578.017,261.658 C580.02,261.658 581.646,260.033 581.646,258.029 C581.646,256.025 580.02,254.399 578.017,254.399"
                  id="Fill-735"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,169.646 C585.915,169.646 584.29,171.271 584.29,173.275 C584.29,175.28 585.915,176.905 587.919,176.905 C589.924,176.905 591.549,175.28 591.549,173.275 C591.549,171.271 589.924,169.646 587.919,169.646"
                  id="Fill-736"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,244.982 C585.915,244.982 584.29,246.607 584.29,248.612 C584.29,250.616 585.915,252.242 587.919,252.242 C589.924,252.242 591.549,250.616 591.549,248.612 C591.549,246.607 589.924,244.982 587.919,244.982"
                  id="Fill-737"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,9.557 C576.012,9.557 574.387,11.181 574.387,13.185 C574.387,15.19 576.012,16.815 578.017,16.815 C580.02,16.815 581.646,15.19 581.646,13.185 C581.646,11.181 580.02,9.557 578.017,9.557"
                  id="Fill-738"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,28.39 C576.012,28.39 574.387,30.016 574.387,32.02 C574.387,34.025 576.012,35.649 578.017,35.649 C580.02,35.649 581.646,34.025 581.646,32.02 C581.646,30.016 580.02,28.39 578.017,28.39"
                  id="Fill-739"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,122.561 C576.012,122.561 574.387,124.186 574.387,126.19 C574.387,128.195 576.012,129.82 578.017,129.82 C580.02,129.82 581.646,128.195 581.646,126.19 C581.646,124.186 580.02,122.561 578.017,122.561"
                  id="Fill-740"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,226.148 C585.915,226.148 584.29,227.773 584.29,229.778 C584.29,231.782 585.915,233.407 587.919,233.407 C589.924,233.407 591.549,231.782 591.549,229.778 C591.549,227.773 589.924,226.148 587.919,226.148"
                  id="Fill-741"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,197.897 C585.915,197.897 584.29,199.523 584.29,201.527 C584.29,203.531 585.915,205.156 587.919,205.156 C589.924,205.156 591.549,203.531 591.549,201.527 C591.549,199.523 589.924,197.897 587.919,197.897"
                  id="Fill-742"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,188.48 C585.915,188.48 584.29,190.106 584.29,192.11 C584.29,194.114 585.915,195.739 587.919,195.739 C589.924,195.739 591.549,194.114 591.549,192.11 C591.549,190.106 589.924,188.48 587.919,188.48"
                  id="Fill-743"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,216.731 C585.915,216.731 584.29,218.357 584.29,220.361 C584.29,222.365 585.915,223.99 587.919,223.99 C589.924,223.99 591.549,222.365 591.549,220.361 C591.549,218.357 589.924,216.731 587.919,216.731"
                  id="Fill-744"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,207.314 C585.915,207.314 584.29,208.939 584.29,210.944 C584.29,212.948 585.915,214.574 587.919,214.574 C589.924,214.574 591.549,212.948 591.549,210.944 C591.549,208.939 589.924,207.314 587.919,207.314"
                  id="Fill-745"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,18.973 C576.012,18.973 574.387,20.598 574.387,22.603 C574.387,24.607 576.012,26.232 578.017,26.232 C580.02,26.232 581.646,24.607 581.646,22.603 C581.646,20.598 580.02,18.973 578.017,18.973"
                  id="Fill-746"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,84.892 C576.012,84.892 574.387,86.517 574.387,88.522 C574.387,90.527 576.012,92.152 578.017,92.152 C580.02,92.152 581.646,90.527 581.646,88.522 C581.646,86.517 580.02,84.892 578.017,84.892"
                  id="Fill-747"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,94.309 C576.012,94.309 574.387,95.935 574.387,97.939 C574.387,99.944 576.012,101.569 578.017,101.569 C580.02,101.569 581.646,99.944 581.646,97.939 C581.646,95.935 580.02,94.309 578.017,94.309"
                  id="Fill-748"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,103.727 C576.012,103.727 574.387,105.352 574.387,107.356 C574.387,109.361 576.012,110.986 578.017,110.986 C580.02,110.986 581.646,109.361 581.646,107.356 C581.646,105.352 580.02,103.727 578.017,103.727"
                  id="Fill-749"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,113.144 C576.012,113.144 574.387,114.769 574.387,116.773 C574.387,118.777 576.012,120.403 578.017,120.403 C580.02,120.403 581.646,118.777 581.646,116.773 C581.646,114.769 580.02,113.144 578.017,113.144"
                  id="Fill-750"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,66.058 C576.012,66.058 574.387,67.683 574.387,69.688 C574.387,71.693 576.012,73.318 578.017,73.318 C580.02,73.318 581.646,71.693 581.646,69.688 C581.646,67.683 580.02,66.058 578.017,66.058"
                  id="Fill-751"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,56.642 C576.012,56.642 574.387,58.266 574.387,60.271 C574.387,62.276 576.012,63.901 578.017,63.901 C580.02,63.901 581.646,62.276 581.646,60.271 C581.646,58.266 580.02,56.642 578.017,56.642"
                  id="Fill-752"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,47.225 C576.012,47.225 574.387,48.849 574.387,50.854 C574.387,52.858 576.012,54.483 578.017,54.483 C580.02,54.483 581.646,52.858 581.646,50.854 C581.646,48.849 580.02,47.225 578.017,47.225"
                  id="Fill-753"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,37.808 C576.012,37.808 574.387,39.432 574.387,41.436 C574.387,43.441 576.012,45.066 578.017,45.066 C580.02,45.066 581.646,43.441 581.646,41.436 C581.646,39.432 580.02,37.808 578.017,37.808"
                  id="Fill-754"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M578.017,75.476 C576.012,75.476 574.387,77.1 574.387,79.105 C574.387,81.109 576.012,82.735 578.017,82.735 C580.02,82.735 581.646,81.109 581.646,79.105 C581.646,77.1 580.02,75.476 578.017,75.476"
                  id="Fill-755"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,113.144 C566.108,113.144 564.483,114.769 564.483,116.773 C564.483,118.777 566.108,120.403 568.113,120.403 C570.117,120.403 571.743,118.777 571.743,116.773 C571.743,114.769 570.117,113.144 568.113,113.144"
                  id="Fill-756"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,66.058 C556.205,66.058 554.58,67.683 554.58,69.688 C554.58,71.693 556.205,73.318 558.21,73.318 C560.215,73.318 561.839,71.693 561.839,69.688 C561.839,67.683 560.215,66.058 558.21,66.058"
                  id="Fill-757"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,113.144 C585.915,113.144 584.29,114.769 584.29,116.773 C584.29,118.777 585.915,120.403 587.919,120.403 C589.924,120.403 591.549,118.777 591.549,116.773 C591.549,114.769 589.924,113.144 587.919,113.144"
                  id="Fill-758"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,84.892 C556.205,84.892 554.58,86.517 554.58,88.522 C554.58,90.527 556.205,92.152 558.21,92.152 C560.215,92.152 561.839,90.527 561.839,88.522 C561.839,86.517 560.215,84.892 558.21,84.892"
                  id="Fill-759"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,94.309 C556.205,94.309 554.58,95.935 554.58,97.939 C554.58,99.944 556.205,101.569 558.21,101.569 C560.215,101.569 561.839,99.944 561.839,97.939 C561.839,95.935 560.215,94.309 558.21,94.309"
                  id="Fill-760"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,103.727 C556.205,103.727 554.58,105.352 554.58,107.356 C554.58,109.361 556.205,110.986 558.21,110.986 C560.215,110.986 561.839,109.361 561.839,107.356 C561.839,105.352 560.215,103.727 558.21,103.727"
                  id="Fill-761"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,113.144 C556.205,113.144 554.58,114.769 554.58,116.773 C554.58,118.777 556.205,120.403 558.21,120.403 C560.215,120.403 561.839,118.777 561.839,116.773 C561.839,114.769 560.215,113.144 558.21,113.144"
                  id="Fill-762"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,28.39 C556.205,28.39 554.58,30.016 554.58,32.02 C554.58,34.025 556.205,35.649 558.21,35.649 C560.215,35.649 561.839,34.025 561.839,32.02 C561.839,30.016 560.215,28.39 558.21,28.39"
                  id="Fill-763"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,56.642 C556.205,56.642 554.58,58.266 554.58,60.271 C554.58,62.276 556.205,63.901 558.21,63.901 C560.215,63.901 561.839,62.276 561.839,60.271 C561.839,58.266 560.215,56.642 558.21,56.642"
                  id="Fill-764"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,37.808 C556.205,37.808 554.58,39.432 554.58,41.436 C554.58,43.441 556.205,45.066 558.21,45.066 C560.215,45.066 561.839,43.441 561.839,41.436 C561.839,39.432 560.215,37.808 558.21,37.808"
                  id="Fill-765"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,47.225 C556.205,47.225 554.58,48.849 554.58,50.854 C554.58,52.858 556.205,54.483 558.21,54.483 C560.215,54.483 561.839,52.858 561.839,50.854 C561.839,48.849 560.215,47.225 558.21,47.225"
                  id="Fill-766"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,75.476 C556.205,75.476 554.58,77.1 554.58,79.105 C554.58,81.109 556.205,82.735 558.21,82.735 C560.215,82.735 561.839,81.109 561.839,79.105 C561.839,77.1 560.215,75.476 558.21,75.476"
                  id="Fill-767"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,179.063 C556.205,179.063 554.58,180.688 554.58,182.693 C554.58,184.697 556.205,186.322 558.21,186.322 C560.215,186.322 561.839,184.697 561.839,182.693 C561.839,180.688 560.215,179.063 558.21,179.063"
                  id="Fill-768"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,188.48 C556.205,188.48 554.58,190.106 554.58,192.11 C554.58,194.114 556.205,195.739 558.21,195.739 C560.215,195.739 561.839,194.114 561.839,192.11 C561.839,190.106 560.215,188.48 558.21,188.48"
                  id="Fill-769"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,169.646 C556.205,169.646 554.58,171.271 554.58,173.275 C554.58,175.28 556.205,176.905 558.21,176.905 C560.215,176.905 561.839,175.28 561.839,173.275 C561.839,171.271 560.215,169.646 558.21,169.646"
                  id="Fill-770"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,197.897 C556.205,197.897 554.58,199.523 554.58,201.527 C554.58,203.531 556.205,205.156 558.21,205.156 C560.215,205.156 561.839,203.531 561.839,201.527 C561.839,199.523 560.215,197.897 558.21,197.897"
                  id="Fill-771"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,207.314 C556.205,207.314 554.58,208.939 554.58,210.944 C554.58,212.948 556.205,214.574 558.21,214.574 C560.215,214.574 561.839,212.948 561.839,210.944 C561.839,208.939 560.215,207.314 558.21,207.314"
                  id="Fill-772"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,122.561 C556.205,122.561 554.58,124.186 554.58,126.19 C554.58,128.195 556.205,129.82 558.21,129.82 C560.215,129.82 561.839,128.195 561.839,126.19 C561.839,124.186 560.215,122.561 558.21,122.561"
                  id="Fill-773"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,141.395 C556.205,141.395 554.58,143.02 554.58,145.024 C554.58,147.029 556.205,148.654 558.21,148.654 C560.215,148.654 561.839,147.029 561.839,145.024 C561.839,143.02 560.215,141.395 558.21,141.395"
                  id="Fill-774"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,216.731 C516.593,216.731 514.968,218.357 514.968,220.361 C514.968,222.365 516.593,223.99 518.597,223.99 C520.602,223.99 522.227,222.365 522.227,220.361 C522.227,218.357 520.602,216.731 518.597,216.731"
                  id="Fill-775"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,160.229 C556.205,160.229 554.58,161.854 554.58,163.859 C554.58,165.863 556.205,167.488 558.21,167.488 C560.215,167.488 561.839,165.863 561.839,163.859 C561.839,161.854 560.215,160.229 558.21,160.229"
                  id="Fill-776"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M558.21,150.812 C556.205,150.812 554.58,152.437 554.58,154.441 C554.58,156.446 556.205,158.071 558.21,158.071 C560.215,158.071 561.839,156.446 561.839,154.441 C561.839,152.437 560.215,150.812 558.21,150.812"
                  id="Fill-777"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M587.919,131.978 C585.915,131.978 584.29,133.603 584.29,135.607 C584.29,137.612 585.915,139.237 587.919,139.237 C589.924,139.237 591.549,137.612 591.549,135.607 C591.549,133.603 589.924,131.978 587.919,131.978"
                  id="Fill-778"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,169.646 C566.108,169.646 564.483,171.271 564.483,173.275 C564.483,175.28 566.108,176.905 568.113,176.905 C570.117,176.905 571.743,175.28 571.743,173.275 C571.743,171.271 570.117,169.646 568.113,169.646"
                  id="Fill-779"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,141.395 C566.108,141.395 564.483,143.02 564.483,145.024 C564.483,147.029 566.108,148.654 568.113,148.654 C570.117,148.654 571.743,147.029 571.743,145.024 C571.743,143.02 570.117,141.395 568.113,141.395"
                  id="Fill-780"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,160.229 C566.108,160.229 564.483,161.854 564.483,163.859 C564.483,165.863 566.108,167.488 568.113,167.488 C570.117,167.488 571.743,165.863 571.743,163.859 C571.743,161.854 570.117,160.229 568.113,160.229"
                  id="Fill-781"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M568.113,122.561 C566.108,122.561 564.483,124.186 564.483,126.19 C564.483,128.195 566.108,129.82 568.113,129.82 C570.117,129.82 571.743,128.195 571.743,126.19 C571.743,124.186 570.117,122.561 568.113,122.561"
                  id="Fill-782"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,150.812 C566.108,150.812 564.483,152.437 564.483,154.441 C564.483,156.446 566.108,158.071 568.113,158.071 C570.117,158.071 571.743,156.446 571.743,154.441 C571.743,152.437 570.117,150.812 568.113,150.812"
                  id="Fill-783"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M568.113,131.978 C566.108,131.978 564.483,133.603 564.483,135.607 C564.483,137.612 566.108,139.237 568.113,139.237 C570.117,139.237 571.743,137.612 571.743,135.607 C571.743,133.603 570.117,131.978 568.113,131.978"
                  id="Fill-784"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,84.892 C566.108,84.892 564.483,86.517 564.483,88.522 C564.483,90.527 566.108,92.152 568.113,92.152 C570.117,92.152 571.743,90.527 571.743,88.522 C571.743,86.517 570.117,84.892 568.113,84.892"
                  id="Fill-785"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M568.113,94.309 C566.108,94.309 564.483,95.935 564.483,97.939 C564.483,99.944 566.108,101.569 568.113,101.569 C570.117,101.569 571.743,99.944 571.743,97.939 C571.743,95.935 570.117,94.309 568.113,94.309"
                  id="Fill-786"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,103.727 C566.108,103.727 564.483,105.352 564.483,107.356 C564.483,109.361 566.108,110.986 568.113,110.986 C570.117,110.986 571.743,109.361 571.743,107.356 C571.743,105.352 570.117,103.727 568.113,103.727"
                  id="Fill-787"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M558.21,216.731 C556.205,216.731 554.58,218.357 554.58,220.361 C554.58,222.365 556.205,223.99 558.21,223.99 C560.215,223.99 561.839,222.365 561.839,220.361 C561.839,218.357 560.215,216.731 558.21,216.731"
                  id="Fill-788"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,179.063 C566.108,179.063 564.483,180.688 564.483,182.693 C564.483,184.697 566.108,186.322 568.113,186.322 C570.117,186.322 571.743,184.697 571.743,182.693 C571.743,180.688 570.117,179.063 568.113,179.063"
                  id="Fill-789"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,244.982 C566.108,244.982 564.483,246.607 564.483,248.612 C564.483,250.616 566.108,252.242 568.113,252.242 C570.117,252.242 571.743,250.616 571.743,248.612 C571.743,246.607 570.117,244.982 568.113,244.982"
                  id="Fill-790"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,226.148 C566.108,226.148 564.483,227.773 564.483,229.778 C564.483,231.782 566.108,233.407 568.113,233.407 C570.117,233.407 571.743,231.782 571.743,229.778 C571.743,227.773 570.117,226.148 568.113,226.148"
                  id="Fill-791"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,235.565 C566.108,235.565 564.483,237.191 564.483,239.195 C564.483,241.199 566.108,242.824 568.113,242.824 C570.117,242.824 571.743,241.199 571.743,239.195 C571.743,237.191 570.117,235.565 568.113,235.565"
                  id="Fill-792"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,75.476 C566.108,75.476 564.483,77.1 564.483,79.105 C564.483,81.109 566.108,82.735 568.113,82.735 C570.117,82.735 571.743,81.109 571.743,79.105 C571.743,77.1 570.117,75.476 568.113,75.476"
                  id="Fill-793"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,188.48 C566.108,188.48 564.483,190.106 564.483,192.11 C564.483,194.114 566.108,195.739 568.113,195.739 C570.117,195.739 571.743,194.114 571.743,192.11 C571.743,190.106 570.117,188.48 568.113,188.48"
                  id="Fill-794"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,216.731 C566.108,216.731 564.483,218.357 564.483,220.361 C564.483,222.365 566.108,223.99 568.113,223.99 C570.117,223.99 571.743,222.365 571.743,220.361 C571.743,218.357 570.117,216.731 568.113,216.731"
                  id="Fill-795"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,122.561 C585.915,122.561 584.29,124.186 584.29,126.19 C584.29,128.195 585.915,129.82 587.919,129.82 C589.924,129.82 591.549,128.195 591.549,126.19 C591.549,124.186 589.924,122.561 587.919,122.561"
                  id="Fill-796"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,197.897 C566.108,197.897 564.483,199.523 564.483,201.527 C564.483,203.531 566.108,205.156 568.113,205.156 C570.117,205.156 571.743,203.531 571.743,201.527 C571.743,199.523 570.117,197.897 568.113,197.897"
                  id="Fill-797"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M568.113,207.314 C566.108,207.314 564.483,208.939 564.483,210.944 C564.483,212.948 566.108,214.574 568.113,214.574 C570.117,214.574 571.743,212.948 571.743,210.944 C571.743,208.939 570.117,207.314 568.113,207.314"
                  id="Fill-798"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,254.399 C516.593,254.399 514.968,256.025 514.968,258.029 C514.968,260.033 516.593,261.658 518.597,261.658 C520.602,261.658 522.227,260.033 522.227,258.029 C522.227,256.025 520.602,254.399 518.597,254.399"
                  id="Fill-799"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,18.973 C467.076,18.973 465.451,20.598 465.451,22.603 C465.451,24.607 467.076,26.232 469.081,26.232 C471.086,26.232 472.71,24.607 472.71,22.603 C472.71,20.598 471.086,18.973 469.081,18.973"
                  id="Fill-800"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,179.063 C486.883,179.063 485.257,180.688 485.257,182.693 C485.257,184.697 486.883,186.322 488.888,186.322 C490.892,186.322 492.517,184.697 492.517,182.693 C492.517,180.688 490.892,179.063 488.888,179.063"
                  id="Fill-801"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,84.892 C467.076,84.892 465.451,86.517 465.451,88.522 C465.451,90.527 467.076,92.152 469.081,92.152 C471.086,92.152 472.71,90.527 472.71,88.522 C472.71,86.517 471.086,84.892 469.081,84.892"
                  id="Fill-802"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,94.309 C467.076,94.309 465.451,95.935 465.451,97.939 C465.451,99.944 467.076,101.569 469.081,101.569 C471.086,101.569 472.71,99.944 472.71,97.939 C472.71,95.935 471.086,94.309 469.081,94.309"
                  id="Fill-803"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,103.727 C467.076,103.727 465.451,105.352 465.451,107.356 C465.451,109.361 467.076,110.986 469.081,110.986 C471.086,110.986 472.71,109.361 472.71,107.356 C472.71,105.352 471.086,103.727 469.081,103.727"
                  id="Fill-804"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,197.897 C595.819,197.897 594.193,199.523 594.193,201.527 C594.193,203.531 595.819,205.156 597.822,205.156 C599.827,205.156 601.453,203.531 601.453,201.527 C601.453,199.523 599.827,197.897 597.822,197.897"
                  id="Fill-805"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,188.48 C595.819,188.48 594.193,190.106 594.193,192.11 C594.193,194.114 595.819,195.739 597.822,195.739 C599.827,195.739 601.453,194.114 601.453,192.11 C601.453,190.106 599.827,188.48 597.822,188.48"
                  id="Fill-806"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,216.731 C476.98,216.731 475.354,218.357 475.354,220.361 C475.354,222.365 476.98,223.99 478.984,223.99 C480.988,223.99 482.613,222.365 482.613,220.361 C482.613,218.357 480.988,216.731 478.984,216.731"
                  id="Fill-807"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,207.314 C476.98,207.314 475.354,208.939 475.354,210.944 C475.354,212.948 476.98,214.574 478.984,214.574 C480.988,214.574 482.613,212.948 482.613,210.944 C482.613,208.939 480.988,207.314 478.984,207.314"
                  id="Fill-808"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,197.897 C476.98,197.897 475.354,199.523 475.354,201.527 C475.354,203.531 476.98,205.156 478.984,205.156 C480.988,205.156 482.613,203.531 482.613,201.527 C482.613,199.523 480.988,197.897 478.984,197.897"
                  id="Fill-809"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,113.144 C467.076,113.144 465.451,114.769 465.451,116.773 C465.451,118.777 467.076,120.403 469.081,120.403 C471.086,120.403 472.71,118.777 472.71,116.773 C472.71,114.769 471.086,113.144 469.081,113.144"
                  id="Fill-810"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,235.565 C476.98,235.565 475.354,237.191 475.354,239.195 C475.354,241.199 476.98,242.824 478.984,242.824 C480.988,242.824 482.613,241.199 482.613,239.195 C482.613,237.191 480.988,235.565 478.984,235.565"
                  id="Fill-811"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,9.557 C467.076,9.557 465.451,11.181 465.451,13.185 C465.451,15.19 467.076,16.815 469.081,16.815 C471.086,16.815 472.71,15.19 472.71,13.185 C472.71,11.181 471.086,9.557 469.081,9.557"
                  id="Fill-812"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,226.148 C467.076,226.148 465.451,227.773 465.451,229.778 C465.451,231.782 467.076,233.407 469.081,233.407 C471.086,233.407 472.71,231.782 472.71,229.778 C472.71,227.773 471.086,226.148 469.081,226.148"
                  id="Fill-813"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,207.314 C467.076,207.314 465.451,208.939 465.451,210.944 C465.451,212.948 467.076,214.574 469.081,214.574 C471.086,214.574 472.71,212.948 472.71,210.944 C472.71,208.939 471.086,207.314 469.081,207.314"
                  id="Fill-814"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,122.561 C467.076,122.561 465.451,124.186 465.451,126.19 C465.451,128.195 467.076,129.82 469.081,129.82 C471.086,129.82 472.71,128.195 472.71,126.19 C472.71,124.186 471.086,122.561 469.081,122.561"
                  id="Fill-815"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,207.314 C595.819,207.314 594.193,208.939 594.193,210.944 C594.193,212.948 595.819,214.574 597.822,214.574 C599.827,214.574 601.453,212.948 601.453,210.944 C601.453,208.939 599.827,207.314 597.822,207.314"
                  id="Fill-816"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,235.565 C467.076,235.565 465.451,237.191 465.451,239.195 C465.451,241.199 467.076,242.824 469.081,242.824 C471.086,242.824 472.71,241.199 472.71,239.195 C472.71,237.191 471.086,235.565 469.081,235.565"
                  id="Fill-817"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,197.897 C467.076,197.897 465.451,199.523 465.451,201.527 C465.451,203.531 467.076,205.156 469.081,205.156 C471.086,205.156 472.71,203.531 472.71,201.527 C472.71,199.523 471.086,197.897 469.081,197.897"
                  id="Fill-818"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,188.48 C467.076,188.48 465.451,190.106 465.451,192.11 C465.451,194.114 467.076,195.739 469.081,195.739 C471.086,195.739 472.71,194.114 472.71,192.11 C472.71,190.106 471.086,188.48 469.081,188.48"
                  id="Fill-819"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,141.395 C467.076,141.395 465.451,143.02 465.451,145.024 C465.451,147.029 467.076,148.654 469.081,148.654 C471.086,148.654 472.71,147.029 472.71,145.024 C472.71,143.02 471.086,141.395 469.081,141.395"
                  id="Fill-820"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,131.978 C467.076,131.978 465.451,133.603 465.451,135.607 C465.451,137.612 467.076,139.237 469.081,139.237 C471.086,139.237 472.71,137.612 472.71,135.607 C472.71,133.603 471.086,131.978 469.081,131.978"
                  id="Fill-821"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M469.081,150.812 C467.076,150.812 465.451,152.437 465.451,154.441 C465.451,156.446 467.076,158.071 469.081,158.071 C471.086,158.071 472.71,156.446 472.71,154.441 C472.71,152.437 471.086,150.812 469.081,150.812"
                  id="Fill-822"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M469.081,160.229 C467.076,160.229 465.451,161.854 465.451,163.859 C465.451,165.863 467.076,167.488 469.081,167.488 C471.086,167.488 472.71,165.863 472.71,163.859 C472.71,161.854 471.086,160.229 469.081,160.229"
                  id="Fill-823"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,113.144 C476.98,113.144 475.354,114.769 475.354,116.773 C475.354,118.777 476.98,120.403 478.984,120.403 C480.988,120.403 482.613,118.777 482.613,116.773 C482.613,114.769 480.988,113.144 478.984,113.144"
                  id="Fill-824"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,94.309 C476.98,94.309 475.354,95.935 475.354,97.939 C475.354,99.944 476.98,101.569 478.984,101.569 C480.988,101.569 482.613,99.944 482.613,97.939 C482.613,95.935 480.988,94.309 478.984,94.309"
                  id="Fill-825"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,84.892 C476.98,84.892 475.354,86.517 475.354,88.522 C475.354,90.527 476.98,92.152 478.984,92.152 C480.988,92.152 482.613,90.527 482.613,88.522 C482.613,86.517 480.988,84.892 478.984,84.892"
                  id="Fill-826"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,141.395 C595.819,141.395 594.193,143.02 594.193,145.024 C594.193,147.029 595.819,148.654 597.822,148.654 C599.827,148.654 601.453,147.029 601.453,145.024 C601.453,143.02 599.827,141.395 597.822,141.395"
                  id="Fill-827"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,103.727 C476.98,103.727 475.354,105.352 475.354,107.356 C475.354,109.361 476.98,110.986 478.984,110.986 C480.988,110.986 482.613,109.361 482.613,107.356 C482.613,105.352 480.988,103.727 478.984,103.727"
                  id="Fill-828"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,216.731 C437.366,216.731 435.742,218.357 435.742,220.361 C435.742,222.365 437.366,223.99 439.371,223.99 C441.376,223.99 443,222.365 443,220.361 C443,218.357 441.376,216.731 439.371,216.731"
                  id="Fill-829"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,18.973 C476.98,18.973 475.354,20.598 475.354,22.603 C475.354,24.607 476.98,26.232 478.984,26.232 C480.988,26.232 482.613,24.607 482.613,22.603 C482.613,20.598 480.988,18.973 478.984,18.973"
                  id="Fill-830"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,75.476 C476.98,75.476 475.354,77.1 475.354,79.105 C475.354,81.109 476.98,82.735 478.984,82.735 C480.988,82.735 482.613,81.109 482.613,79.105 C482.613,77.1 480.988,75.476 478.984,75.476"
                  id="Fill-831"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,188.48 C486.883,188.48 485.257,190.106 485.257,192.11 C485.257,194.114 486.883,195.739 488.888,195.739 C490.892,195.739 492.517,194.114 492.517,192.11 C492.517,190.106 490.892,188.48 488.888,188.48"
                  id="Fill-832"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,197.897 C486.883,197.897 485.257,199.523 485.257,201.527 C485.257,203.531 486.883,205.156 488.888,205.156 C490.892,205.156 492.517,203.531 492.517,201.527 C492.517,199.523 490.892,197.897 488.888,197.897"
                  id="Fill-833"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,216.731 C486.883,216.731 485.257,218.357 485.257,220.361 C485.257,222.365 486.883,223.99 488.888,223.99 C490.892,223.99 492.517,222.365 492.517,220.361 C492.517,218.357 490.892,216.731 488.888,216.731"
                  id="Fill-834"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,207.314 C486.883,207.314 485.257,208.939 485.257,210.944 C485.257,212.948 486.883,214.574 488.888,214.574 C490.892,214.574 492.517,212.948 492.517,210.944 C492.517,208.939 490.892,207.314 488.888,207.314"
                  id="Fill-835"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,9.557 C476.98,9.557 475.354,11.181 475.354,13.185 C475.354,15.19 476.98,16.815 478.984,16.815 C480.988,16.815 482.613,15.19 482.613,13.185 C482.613,11.181 480.988,9.557 478.984,9.557"
                  id="Fill-836"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,179.063 C595.819,179.063 594.193,180.688 594.193,182.693 C594.193,184.697 595.819,186.322 597.822,186.322 C599.827,186.322 601.453,184.697 601.453,182.693 C601.453,180.688 599.827,179.063 597.822,179.063"
                  id="Fill-837"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,160.229 C476.98,160.229 475.354,161.854 475.354,163.859 C475.354,165.863 476.98,167.488 478.984,167.488 C480.988,167.488 482.613,165.863 482.613,163.859 C482.613,161.854 480.988,160.229 478.984,160.229"
                  id="Fill-838"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,141.395 C476.98,141.395 475.354,143.02 475.354,145.024 C475.354,147.029 476.98,148.654 478.984,148.654 C480.988,148.654 482.613,147.029 482.613,145.024 C482.613,143.02 480.988,141.395 478.984,141.395"
                  id="Fill-839"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,150.812 C476.98,150.812 475.354,152.437 475.354,154.441 C475.354,156.446 476.98,158.071 478.984,158.071 C480.988,158.071 482.613,156.446 482.613,154.441 C482.613,152.437 480.988,150.812 478.984,150.812"
                  id="Fill-840"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,169.646 C476.98,169.646 475.354,171.271 475.354,173.275 C475.354,175.28 476.98,176.905 478.984,176.905 C480.988,176.905 482.613,175.28 482.613,173.275 C482.613,171.271 480.988,169.646 478.984,169.646"
                  id="Fill-841"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M478.984,179.063 C476.98,179.063 475.354,180.688 475.354,182.693 C475.354,184.697 476.98,186.322 478.984,186.322 C480.988,186.322 482.613,184.697 482.613,182.693 C482.613,180.688 480.988,179.063 478.984,179.063"
                  id="Fill-842"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,150.812 C595.819,150.812 594.193,152.437 594.193,154.441 C594.193,156.446 595.819,158.071 597.822,158.071 C599.827,158.071 601.453,156.446 601.453,154.441 C601.453,152.437 599.827,150.812 597.822,150.812"
                  id="Fill-843"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,160.229 C595.819,160.229 594.193,161.854 594.193,163.859 C594.193,165.863 595.819,167.488 597.822,167.488 C599.827,167.488 601.453,165.863 601.453,163.859 C601.453,161.854 599.827,160.229 597.822,160.229"
                  id="Fill-844"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,169.646 C595.819,169.646 594.193,171.271 594.193,173.275 C594.193,175.28 595.819,176.905 597.822,176.905 C599.827,176.905 601.453,175.28 601.453,173.275 C601.453,171.271 599.827,169.646 597.822,169.646"
                  id="Fill-845"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,131.978 C476.98,131.978 475.354,133.603 475.354,135.607 C475.354,137.612 476.98,139.237 478.984,139.237 C480.988,139.237 482.613,137.612 482.613,135.607 C482.613,133.603 480.988,131.978 478.984,131.978"
                  id="Fill-846"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,188.48 C476.98,188.48 475.354,190.106 475.354,192.11 C475.354,194.114 476.98,195.739 478.984,195.739 C480.988,195.739 482.613,194.114 482.613,192.11 C482.613,190.106 480.988,188.48 478.984,188.48"
                  id="Fill-847"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M478.984,122.561 C476.98,122.561 475.354,124.186 475.354,126.19 C475.354,128.195 476.98,129.82 478.984,129.82 C480.988,129.82 482.613,128.195 482.613,126.19 C482.613,124.186 480.988,122.561 478.984,122.561"
                  id="Fill-848"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M518.597,226.148 C516.593,226.148 514.968,227.773 514.968,229.778 C514.968,231.782 516.593,233.407 518.597,233.407 C520.602,233.407 522.227,231.782 522.227,229.778 C522.227,227.773 520.602,226.148 518.597,226.148"
                  id="Fill-849"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,226.148 C447.271,226.148 445.645,227.773 445.645,229.778 C445.645,231.782 447.271,233.407 449.275,233.407 C451.279,233.407 452.903,231.782 452.903,229.778 C452.903,227.773 451.279,226.148 449.275,226.148"
                  id="Fill-850"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,66.058 C585.915,66.058 584.29,67.683 584.29,69.688 C584.29,71.693 585.915,73.318 587.919,73.318 C589.924,73.318 591.549,71.693 591.549,69.688 C591.549,67.683 589.924,66.058 587.919,66.058"
                  id="Fill-851"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,216.731 C447.271,216.731 445.645,218.357 445.645,220.361 C445.645,222.365 447.271,223.99 449.275,223.99 C451.279,223.99 452.903,222.365 452.903,220.361 C452.903,218.357 451.279,216.731 449.275,216.731"
                  id="Fill-852"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,56.642 C585.915,56.642 584.29,58.266 584.29,60.271 C584.29,62.276 585.915,63.901 587.919,63.901 C589.924,63.901 591.549,62.276 591.549,60.271 C591.549,58.266 589.924,56.642 587.919,56.642"
                  id="Fill-853"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,84.892 C447.271,84.892 445.645,86.517 445.645,88.522 C445.645,90.527 447.271,92.152 449.275,92.152 C451.279,92.152 452.903,90.527 452.903,88.522 C452.903,86.517 451.279,84.892 449.275,84.892"
                  id="Fill-854"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,207.314 C437.366,207.314 435.742,208.939 435.742,210.944 C435.742,212.948 437.366,214.574 439.371,214.574 C441.376,214.574 443,212.948 443,210.944 C443,208.939 441.376,207.314 439.371,207.314"
                  id="Fill-855"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,47.225 C585.915,47.225 584.29,48.849 584.29,50.854 C584.29,52.858 585.915,54.483 587.919,54.483 C589.924,54.483 591.549,52.858 591.549,50.854 C591.549,48.849 589.924,47.225 587.919,47.225"
                  id="Fill-856"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,18.973 C585.915,18.973 584.29,20.598 584.29,22.603 C584.29,24.607 585.915,26.232 587.919,26.232 C589.924,26.232 591.549,24.607 591.549,22.603 C591.549,20.598 589.924,18.973 587.919,18.973"
                  id="Fill-857"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,226.148 C595.819,226.148 594.193,227.773 594.193,229.778 C594.193,231.782 595.819,233.407 597.822,233.407 C599.827,233.407 601.453,231.782 601.453,229.778 C601.453,227.773 599.827,226.148 597.822,226.148"
                  id="Fill-858"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,28.39 C585.915,28.39 584.29,30.016 584.29,32.02 C584.29,34.025 585.915,35.649 587.919,35.649 C589.924,35.649 591.549,34.025 591.549,32.02 C591.549,30.016 589.924,28.39 587.919,28.39"
                  id="Fill-859"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,37.808 C585.915,37.808 584.29,39.432 584.29,41.436 C584.29,43.441 585.915,45.066 587.919,45.066 C589.924,45.066 591.549,43.441 591.549,41.436 C591.549,39.432 589.924,37.808 587.919,37.808"
                  id="Fill-860"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M449.275,94.309 C447.271,94.309 445.645,95.935 445.645,97.939 C445.645,99.944 447.271,101.569 449.275,101.569 C451.279,101.569 452.903,99.944 452.903,97.939 C452.903,95.935 451.279,94.309 449.275,94.309"
                  id="Fill-861"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,197.897 C447.271,197.897 445.645,199.523 445.645,201.527 C445.645,203.531 447.271,205.156 449.275,205.156 C451.279,205.156 452.903,203.531 452.903,201.527 C452.903,199.523 451.279,197.897 449.275,197.897"
                  id="Fill-862"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,179.063 C447.271,179.063 445.645,180.688 445.645,182.693 C445.645,184.697 447.271,186.322 449.275,186.322 C451.279,186.322 452.903,184.697 452.903,182.693 C452.903,180.688 451.279,179.063 449.275,179.063"
                  id="Fill-863"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M449.275,169.646 C447.271,169.646 445.645,171.271 445.645,173.275 C445.645,175.28 447.271,176.905 449.275,176.905 C451.279,176.905 452.903,175.28 452.903,173.275 C452.903,171.271 451.279,169.646 449.275,169.646"
                  id="Fill-864"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,188.48 C447.271,188.48 445.645,190.106 445.645,192.11 C445.645,194.114 447.271,195.739 449.275,195.739 C451.279,195.739 452.903,194.114 452.903,192.11 C452.903,190.106 451.279,188.48 449.275,188.48"
                  id="Fill-865"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,84.892 C585.915,84.892 584.29,86.517 584.29,88.522 C584.29,90.527 585.915,92.152 587.919,92.152 C589.924,92.152 591.549,90.527 591.549,88.522 C591.549,86.517 589.924,84.892 587.919,84.892"
                  id="Fill-866"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,160.229 C447.271,160.229 445.645,161.854 445.645,163.859 C445.645,165.863 447.271,167.488 449.275,167.488 C451.279,167.488 452.903,165.863 452.903,163.859 C452.903,161.854 451.279,160.229 449.275,160.229"
                  id="Fill-867"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M449.275,141.395 C447.271,141.395 445.645,143.02 445.645,145.024 C445.645,147.029 447.271,148.654 449.275,148.654 C451.279,148.654 452.903,147.029 452.903,145.024 C452.903,143.02 451.279,141.395 449.275,141.395"
                  id="Fill-868"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,113.144 C447.271,113.144 445.645,114.769 445.645,116.773 C445.645,118.777 447.271,120.403 449.275,120.403 C451.279,120.403 452.903,118.777 452.903,116.773 C452.903,114.769 451.279,113.144 449.275,113.144"
                  id="Fill-869"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,122.561 C447.271,122.561 445.645,124.186 445.645,126.19 C445.645,128.195 447.271,129.82 449.275,129.82 C451.279,129.82 452.903,128.195 452.903,126.19 C452.903,124.186 451.279,122.561 449.275,122.561"
                  id="Fill-870"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,150.812 C447.271,150.812 445.645,152.437 445.645,154.441 C445.645,156.446 447.271,158.071 449.275,158.071 C451.279,158.071 452.903,156.446 452.903,154.441 C452.903,152.437 451.279,150.812 449.275,150.812"
                  id="Fill-871"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,103.727 C447.271,103.727 445.645,105.352 445.645,107.356 C445.645,109.361 447.271,110.986 449.275,110.986 C451.279,110.986 452.903,109.361 452.903,107.356 C452.903,105.352 451.279,103.727 449.275,103.727"
                  id="Fill-872"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,131.978 C447.271,131.978 445.645,133.603 445.645,135.607 C445.645,137.612 447.271,139.237 449.275,139.237 C451.279,139.237 452.903,137.612 452.903,135.607 C452.903,133.603 451.279,131.978 449.275,131.978"
                  id="Fill-873"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,75.476 C585.915,75.476 584.29,77.1 584.29,79.105 C584.29,81.109 585.915,82.735 587.919,82.735 C589.924,82.735 591.549,81.109 591.549,79.105 C591.549,77.1 589.924,75.476 587.919,75.476"
                  id="Fill-874"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,103.727 C457.173,103.727 455.549,105.352 455.549,107.356 C455.549,109.361 457.173,110.986 459.178,110.986 C461.183,110.986 462.807,109.361 462.807,107.356 C462.807,105.352 461.183,103.727 459.178,103.727"
                  id="Fill-875"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,131.978 C457.173,131.978 455.549,133.603 455.549,135.607 C455.549,137.612 457.173,139.237 459.178,139.237 C461.183,139.237 462.807,137.612 462.807,135.607 C462.807,133.603 461.183,131.978 459.178,131.978"
                  id="Fill-876"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,84.892 C457.173,84.892 455.549,86.517 455.549,88.522 C455.549,90.527 457.173,92.152 459.178,92.152 C461.183,92.152 462.807,90.527 462.807,88.522 C462.807,86.517 461.183,84.892 459.178,84.892"
                  id="Fill-877"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,94.309 C457.173,94.309 455.549,95.935 455.549,97.939 C455.549,99.944 457.173,101.569 459.178,101.569 C461.183,101.569 462.807,99.944 462.807,97.939 C462.807,95.935 461.183,94.309 459.178,94.309"
                  id="Fill-878"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,122.561 C457.173,122.561 455.549,124.186 455.549,126.19 C455.549,128.195 457.173,129.82 459.178,129.82 C461.183,129.82 462.807,128.195 462.807,126.19 C462.807,124.186 461.183,122.561 459.178,122.561"
                  id="Fill-879"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,113.144 C457.173,113.144 455.549,114.769 455.549,116.773 C455.549,118.777 457.173,120.403 459.178,120.403 C461.183,120.403 462.807,118.777 462.807,116.773 C462.807,114.769 461.183,113.144 459.178,113.144"
                  id="Fill-880"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,75.476 C457.173,75.476 455.549,77.1 455.549,79.105 C455.549,81.109 457.173,82.735 459.178,82.735 C461.183,82.735 462.807,81.109 462.807,79.105 C462.807,77.1 461.183,75.476 459.178,75.476"
                  id="Fill-881"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,18.973 C457.173,18.973 455.549,20.598 455.549,22.603 C455.549,24.607 457.173,26.232 459.178,26.232 C461.183,26.232 462.807,24.607 462.807,22.603 C462.807,20.598 461.183,18.973 459.178,18.973"
                  id="Fill-882"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,188.48 C437.366,188.48 435.742,190.106 435.742,192.11 C435.742,194.114 437.366,195.739 439.371,195.739 C441.376,195.739 443,194.114 443,192.11 C443,190.106 441.376,188.48 439.371,188.48"
                  id="Fill-883"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M439.371,197.897 C437.366,197.897 435.742,199.523 435.742,201.527 C435.742,203.531 437.366,205.156 439.371,205.156 C441.376,205.156 443,203.531 443,201.527 C443,199.523 441.376,197.897 439.371,197.897"
                  id="Fill-884"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,216.731 C595.819,216.731 594.193,218.357 594.193,220.361 C594.193,222.365 595.819,223.99 597.822,223.99 C599.827,223.99 601.453,222.365 601.453,220.361 C601.453,218.357 599.827,216.731 597.822,216.731"
                  id="Fill-885"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,9.557 C457.173,9.557 455.549,11.181 455.549,13.185 C455.549,15.19 457.173,16.815 459.178,16.815 C461.183,16.815 462.807,15.19 462.807,13.185 C462.807,11.181 461.183,9.557 459.178,9.557"
                  id="Fill-886"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,216.731 C457.173,216.731 455.549,218.357 455.549,220.361 C455.549,222.365 457.173,223.99 459.178,223.99 C461.183,223.99 462.807,222.365 462.807,220.361 C462.807,218.357 461.183,216.731 459.178,216.731"
                  id="Fill-887"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,94.309 C585.915,94.309 584.29,95.935 584.29,97.939 C584.29,99.944 585.915,101.569 587.919,101.569 C589.924,101.569 591.549,99.944 591.549,97.939 C591.549,95.935 589.924,94.309 587.919,94.309"
                  id="Fill-888"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,235.565 C457.173,235.565 455.549,237.191 455.549,239.195 C455.549,241.199 457.173,242.824 459.178,242.824 C461.183,242.824 462.807,241.199 462.807,239.195 C462.807,237.191 461.183,235.565 459.178,235.565"
                  id="Fill-889"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,141.395 C457.173,141.395 455.549,143.02 455.549,145.024 C455.549,147.029 457.173,148.654 459.178,148.654 C461.183,148.654 462.807,147.029 462.807,145.024 C462.807,143.02 461.183,141.395 459.178,141.395"
                  id="Fill-890"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M587.919,103.727 C585.915,103.727 584.29,105.352 584.29,107.356 C584.29,109.361 585.915,110.986 587.919,110.986 C589.924,110.986 591.549,109.361 591.549,107.356 C591.549,105.352 589.924,103.727 587.919,103.727"
                  id="Fill-891"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,244.982 C457.173,244.982 455.549,246.607 455.549,248.612 C455.549,250.616 457.173,252.242 459.178,252.242 C461.183,252.242 462.807,250.616 462.807,248.612 C462.807,246.607 461.183,244.982 459.178,244.982"
                  id="Fill-892"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,226.148 C457.173,226.148 455.549,227.773 455.549,229.778 C455.549,231.782 457.173,233.407 459.178,233.407 C461.183,233.407 462.807,231.782 462.807,229.778 C462.807,227.773 461.183,226.148 459.178,226.148"
                  id="Fill-893"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,150.812 C457.173,150.812 455.549,152.437 455.549,154.441 C455.549,156.446 457.173,158.071 459.178,158.071 C461.183,158.071 462.807,156.446 462.807,154.441 C462.807,152.437 461.183,150.812 459.178,150.812"
                  id="Fill-894"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,160.229 C457.173,160.229 455.549,161.854 455.549,163.859 C455.549,165.863 457.173,167.488 459.178,167.488 C461.183,167.488 462.807,165.863 462.807,163.859 C462.807,161.854 461.183,160.229 459.178,160.229"
                  id="Fill-895"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,197.897 C457.173,197.897 455.549,199.523 455.549,201.527 C455.549,203.531 457.173,205.156 459.178,205.156 C461.183,205.156 462.807,203.531 462.807,201.527 C462.807,199.523 461.183,197.897 459.178,197.897"
                  id="Fill-896"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M459.178,169.646 C457.173,169.646 455.549,171.271 455.549,173.275 C455.549,175.28 457.173,176.905 459.178,176.905 C461.183,176.905 462.807,175.28 462.807,173.275 C462.807,171.271 461.183,169.646 459.178,169.646"
                  id="Fill-897"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M459.178,207.314 C457.173,207.314 455.549,208.939 455.549,210.944 C455.549,212.948 457.173,214.574 459.178,214.574 C461.183,214.574 462.807,212.948 462.807,210.944 C462.807,208.939 461.183,207.314 459.178,207.314"
                  id="Fill-898"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,47.225 C486.883,47.225 485.257,48.849 485.257,50.854 C485.257,52.858 486.883,54.483 488.888,54.483 C490.892,54.483 492.517,52.858 492.517,50.854 C492.517,48.849 490.892,47.225 488.888,47.225"
                  id="Fill-899"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,103.727 C496.786,103.727 495.161,105.352 495.161,107.356 C495.161,109.361 496.786,110.986 498.79,110.986 C500.795,110.986 502.42,109.361 502.42,107.356 C502.42,105.352 500.795,103.727 498.79,103.727"
                  id="Fill-900"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,131.978 C496.786,131.978 495.161,133.603 495.161,135.607 C495.161,137.612 496.786,139.237 498.79,139.237 C500.795,139.237 502.42,137.612 502.42,135.607 C502.42,133.603 500.795,131.978 498.79,131.978"
                  id="Fill-901"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,9.557 C486.883,9.557 485.257,11.181 485.257,13.185 C485.257,15.19 486.883,16.815 488.888,16.815 C490.892,16.815 492.517,15.19 492.517,13.185 C492.517,11.181 490.892,9.557 488.888,9.557"
                  id="Fill-902"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,122.561 C496.786,122.561 495.161,124.186 495.161,126.19 C495.161,128.195 496.786,129.82 498.79,129.82 C500.795,129.82 502.42,128.195 502.42,126.19 C502.42,124.186 500.795,122.561 498.79,122.561"
                  id="Fill-903"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,150.812 C496.786,150.812 495.161,152.437 495.161,154.441 C495.161,156.446 496.786,158.071 498.79,158.071 C500.795,158.071 502.42,156.446 502.42,154.441 C502.42,152.437 500.795,150.812 498.79,150.812"
                  id="Fill-904"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M498.79,141.395 C496.786,141.395 495.161,143.02 495.161,145.024 C495.161,147.029 496.786,148.654 498.79,148.654 C500.795,148.654 502.42,147.029 502.42,145.024 C502.42,143.02 500.795,141.395 498.79,141.395"
                  id="Fill-905"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,244.982 C506.69,244.982 505.064,246.607 505.064,248.612 C505.064,250.616 506.69,252.242 508.693,252.242 C510.698,252.242 512.324,250.616 512.324,248.612 C512.324,246.607 510.698,244.982 508.693,244.982"
                  id="Fill-906"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,94.309 C496.786,94.309 495.161,95.935 495.161,97.939 C495.161,99.944 496.786,101.569 498.79,101.569 C500.795,101.569 502.42,99.944 502.42,97.939 C502.42,95.935 500.795,94.309 498.79,94.309"
                  id="Fill-907"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,37.808 C496.786,37.808 495.161,39.432 495.161,41.436 C495.161,43.441 496.786,45.066 498.79,45.066 C500.795,45.066 502.42,43.441 502.42,41.436 C502.42,39.432 500.795,37.808 498.79,37.808"
                  id="Fill-908"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,0.139 C496.786,0.139 495.161,1.764 495.161,3.769 C495.161,5.774 496.786,7.398 498.79,7.398 C500.795,7.398 502.42,5.774 502.42,3.769 C502.42,1.764 500.795,0.139 498.79,0.139"
                  id="Fill-909"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,75.476 C496.786,75.476 495.161,77.1 495.161,79.105 C495.161,81.109 496.786,82.735 498.79,82.735 C500.795,82.735 502.42,81.109 502.42,79.105 C502.42,77.1 500.795,75.476 498.79,75.476"
                  id="Fill-910"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M498.79,84.892 C496.786,84.892 495.161,86.517 495.161,88.522 C495.161,90.527 496.786,92.152 498.79,92.152 C500.795,92.152 502.42,90.527 502.42,88.522 C502.42,86.517 500.795,84.892 498.79,84.892"
                  id="Fill-911"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M498.79,113.144 C496.786,113.144 495.161,114.769 495.161,116.773 C495.161,118.777 496.786,120.403 498.79,120.403 C500.795,120.403 502.42,118.777 502.42,116.773 C502.42,114.769 500.795,113.144 498.79,113.144"
                  id="Fill-912"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M498.79,207.314 C496.786,207.314 495.161,208.939 495.161,210.944 C495.161,212.948 496.786,214.574 498.79,214.574 C500.795,214.574 502.42,212.948 502.42,210.944 C502.42,208.939 500.795,207.314 498.79,207.314"
                  id="Fill-913"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,169.646 C486.883,169.646 485.257,171.271 485.257,173.275 C485.257,175.28 486.883,176.905 488.888,176.905 C490.892,176.905 492.517,175.28 492.517,173.275 C492.517,171.271 490.892,169.646 488.888,169.646"
                  id="Fill-914"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M498.79,226.148 C496.786,226.148 495.161,227.773 495.161,229.778 C495.161,231.782 496.786,233.407 498.79,233.407 C500.795,233.407 502.42,231.782 502.42,229.778 C502.42,227.773 500.795,226.148 498.79,226.148"
                  id="Fill-915"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,216.731 C496.786,216.731 495.161,218.357 495.161,220.361 C495.161,222.365 496.786,223.99 498.79,223.99 C500.795,223.99 502.42,222.365 502.42,220.361 C502.42,218.357 500.795,216.731 498.79,216.731"
                  id="Fill-916"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,66.058 C595.819,66.058 594.193,67.683 594.193,69.688 C594.193,71.693 595.819,73.318 597.822,73.318 C599.827,73.318 601.453,71.693 601.453,69.688 C601.453,67.683 599.827,66.058 597.822,66.058"
                  id="Fill-917"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,179.063 C496.786,179.063 495.161,180.688 495.161,182.693 C495.161,184.697 496.786,186.322 498.79,186.322 C500.795,186.322 502.42,184.697 502.42,182.693 C502.42,180.688 500.795,179.063 498.79,179.063"
                  id="Fill-918"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M597.822,56.642 C595.819,56.642 594.193,58.266 594.193,60.271 C594.193,62.276 595.819,63.901 597.822,63.901 C599.827,63.901 601.453,62.276 601.453,60.271 C601.453,58.266 599.827,56.642 597.822,56.642"
                  id="Fill-919"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,188.48 C496.786,188.48 495.161,190.106 495.161,192.11 C495.161,194.114 496.786,195.739 498.79,195.739 C500.795,195.739 502.42,194.114 502.42,192.11 C502.42,190.106 500.795,188.48 498.79,188.48"
                  id="Fill-920"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,47.225 C595.819,47.225 594.193,48.849 594.193,50.854 C594.193,52.858 595.819,54.483 597.822,54.483 C599.827,54.483 601.453,52.858 601.453,50.854 C601.453,48.849 599.827,47.225 597.822,47.225"
                  id="Fill-921"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,160.229 C496.786,160.229 495.161,161.854 495.161,163.859 C495.161,165.863 496.786,167.488 498.79,167.488 C500.795,167.488 502.42,165.863 502.42,163.859 C502.42,161.854 500.795,160.229 498.79,160.229"
                  id="Fill-922"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,169.646 C496.786,169.646 495.161,171.271 495.161,173.275 C495.161,175.28 496.786,176.905 498.79,176.905 C500.795,176.905 502.42,175.28 502.42,173.275 C502.42,171.271 500.795,169.646 498.79,169.646"
                  id="Fill-923"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,18.973 C595.819,18.973 594.193,20.598 594.193,22.603 C594.193,24.607 595.819,26.232 597.822,26.232 C599.827,26.232 601.453,24.607 601.453,22.603 C601.453,20.598 599.827,18.973 597.822,18.973"
                  id="Fill-924"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,66.058 C506.69,66.058 505.064,67.683 505.064,69.688 C505.064,71.693 506.69,73.318 508.693,73.318 C510.698,73.318 512.324,71.693 512.324,69.688 C512.324,67.683 510.698,66.058 508.693,66.058"
                  id="Fill-925"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,94.309 C506.69,94.309 505.064,95.935 505.064,97.939 C505.064,99.944 506.69,101.569 508.693,101.569 C510.698,101.569 512.324,99.944 512.324,97.939 C512.324,95.935 510.698,94.309 508.693,94.309"
                  id="Fill-926"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,235.565 C506.69,235.565 505.064,237.191 505.064,239.195 C505.064,241.199 506.69,242.824 508.693,242.824 C510.698,242.824 512.324,241.199 512.324,239.195 C512.324,237.191 510.698,235.565 508.693,235.565"
                  id="Fill-927"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,84.892 C506.69,84.892 505.064,86.517 505.064,88.522 C505.064,90.527 506.69,92.152 508.693,92.152 C510.698,92.152 512.324,90.527 512.324,88.522 C512.324,86.517 510.698,84.892 508.693,84.892"
                  id="Fill-928"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,113.144 C506.69,113.144 505.064,114.769 505.064,116.773 C505.064,118.777 506.69,120.403 508.693,120.403 C510.698,120.403 512.324,118.777 512.324,116.773 C512.324,114.769 510.698,113.144 508.693,113.144"
                  id="Fill-929"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,122.561 C506.69,122.561 505.064,124.186 505.064,126.19 C505.064,128.195 506.69,129.82 508.693,129.82 C510.698,129.82 512.324,128.195 512.324,126.19 C512.324,124.186 510.698,122.561 508.693,122.561"
                  id="Fill-930"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,103.727 C506.69,103.727 505.064,105.352 505.064,107.356 C505.064,109.361 506.69,110.986 508.693,110.986 C510.698,110.986 512.324,109.361 512.324,107.356 C512.324,105.352 510.698,103.727 508.693,103.727"
                  id="Fill-931"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,244.982 C516.593,244.982 514.968,246.607 514.968,248.612 C514.968,250.616 516.593,252.242 518.597,252.242 C520.602,252.242 522.227,250.616 522.227,248.612 C522.227,246.607 520.602,244.982 518.597,244.982"
                  id="Fill-932"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M508.693,56.642 C506.69,56.642 505.064,58.266 505.064,60.271 C505.064,62.276 506.69,63.901 508.693,63.901 C510.698,63.901 512.324,62.276 512.324,60.271 C512.324,58.266 510.698,56.642 508.693,56.642"
                  id="Fill-933"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M518.597,235.565 C516.593,235.565 514.968,237.191 514.968,239.195 C514.968,241.199 516.593,242.824 518.597,242.824 C520.602,242.824 522.227,241.199 522.227,239.195 C522.227,237.191 520.602,235.565 518.597,235.565"
                  id="Fill-934"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M508.693,47.225 C506.69,47.225 505.064,48.849 505.064,50.854 C505.064,52.858 506.69,54.483 508.693,54.483 C510.698,54.483 512.324,52.858 512.324,50.854 C512.324,48.849 510.698,47.225 508.693,47.225"
                  id="Fill-935"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M607.726,226.148 C605.722,226.148 604.097,227.773 604.097,229.778 C604.097,231.782 605.722,233.407 607.726,233.407 C609.73,233.407 611.356,231.782 611.356,229.778 C611.356,227.773 609.73,226.148 607.726,226.148"
                  id="Fill-936"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,75.476 C506.69,75.476 505.064,77.1 505.064,79.105 C505.064,81.109 506.69,82.735 508.693,82.735 C510.698,82.735 512.324,81.109 512.324,79.105 C512.324,77.1 510.698,75.476 508.693,75.476"
                  id="Fill-937"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,188.48 C506.69,188.48 505.064,190.106 505.064,192.11 C505.064,194.114 506.69,195.739 508.693,195.739 C510.698,195.739 512.324,194.114 512.324,192.11 C512.324,190.106 510.698,188.48 508.693,188.48"
                  id="Fill-938"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,207.314 C506.69,207.314 505.064,208.939 505.064,210.944 C505.064,212.948 506.69,214.574 508.693,214.574 C510.698,214.574 512.324,212.948 512.324,210.944 C512.324,208.939 510.698,207.314 508.693,207.314"
                  id="Fill-939"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M508.693,197.897 C506.69,197.897 505.064,199.523 505.064,201.527 C505.064,203.531 506.69,205.156 508.693,205.156 C510.698,205.156 512.324,203.531 512.324,201.527 C512.324,199.523 510.698,197.897 508.693,197.897"
                  id="Fill-940"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M508.693,226.148 C506.69,226.148 505.064,227.773 505.064,229.778 C505.064,231.782 506.69,233.407 508.693,233.407 C510.698,233.407 512.324,231.782 512.324,229.778 C512.324,227.773 510.698,226.148 508.693,226.148"
                  id="Fill-941"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,216.731 C506.69,216.731 505.064,218.357 505.064,220.361 C505.064,222.365 506.69,223.99 508.693,223.99 C510.698,223.99 512.324,222.365 512.324,220.361 C512.324,218.357 510.698,216.731 508.693,216.731"
                  id="Fill-942"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,179.063 C506.69,179.063 505.064,180.688 505.064,182.693 C505.064,184.697 506.69,186.322 508.693,186.322 C510.698,186.322 512.324,184.697 512.324,182.693 C512.324,180.688 510.698,179.063 508.693,179.063"
                  id="Fill-943"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,150.812 C506.69,150.812 505.064,152.437 505.064,154.441 C505.064,156.446 506.69,158.071 508.693,158.071 C510.698,158.071 512.324,156.446 512.324,154.441 C512.324,152.437 510.698,150.812 508.693,150.812"
                  id="Fill-944"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,131.978 C506.69,131.978 505.064,133.603 505.064,135.607 C505.064,137.612 506.69,139.237 508.693,139.237 C510.698,139.237 512.324,137.612 512.324,135.607 C512.324,133.603 510.698,131.978 508.693,131.978"
                  id="Fill-945"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M508.693,141.395 C506.69,141.395 505.064,143.02 505.064,145.024 C505.064,147.029 506.69,148.654 508.693,148.654 C510.698,148.654 512.324,147.029 512.324,145.024 C512.324,143.02 510.698,141.395 508.693,141.395"
                  id="Fill-946"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,160.229 C506.69,160.229 505.064,161.854 505.064,163.859 C505.064,165.863 506.69,167.488 508.693,167.488 C510.698,167.488 512.324,165.863 512.324,163.859 C512.324,161.854 510.698,160.229 508.693,160.229"
                  id="Fill-947"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M508.693,169.646 C506.69,169.646 505.064,171.271 505.064,173.275 C505.064,175.28 506.69,176.905 508.693,176.905 C510.698,176.905 512.324,175.28 512.324,173.275 C512.324,171.271 510.698,169.646 508.693,169.646"
                  id="Fill-948"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M498.79,197.897 C496.786,197.897 495.161,199.523 495.161,201.527 C495.161,203.531 496.786,205.156 498.79,205.156 C500.795,205.156 502.42,203.531 502.42,201.527 C502.42,199.523 500.795,197.897 498.79,197.897"
                  id="Fill-949"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,75.476 C595.819,75.476 594.193,77.1 594.193,79.105 C594.193,81.109 595.819,82.735 597.822,82.735 C599.827,82.735 601.453,81.109 601.453,79.105 C601.453,77.1 599.827,75.476 597.822,75.476"
                  id="Fill-950"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,94.309 C595.819,94.309 594.193,95.935 594.193,97.939 C594.193,99.944 595.819,101.569 597.822,101.569 C599.827,101.569 601.453,99.944 601.453,97.939 C601.453,95.935 599.827,94.309 597.822,94.309"
                  id="Fill-951"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,113.144 C595.819,113.144 594.193,114.769 594.193,116.773 C594.193,118.777 595.819,120.403 597.822,120.403 C599.827,120.403 601.453,118.777 601.453,116.773 C601.453,114.769 599.827,113.144 597.822,113.144"
                  id="Fill-952"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,84.892 C595.819,84.892 594.193,86.517 594.193,88.522 C594.193,90.527 595.819,92.152 597.822,92.152 C599.827,92.152 601.453,90.527 601.453,88.522 C601.453,86.517 599.827,84.892 597.822,84.892"
                  id="Fill-953"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,122.561 C595.819,122.561 594.193,124.186 594.193,126.19 C594.193,128.195 595.819,129.82 597.822,129.82 C599.827,129.82 601.453,128.195 601.453,126.19 C601.453,124.186 599.827,122.561 597.822,122.561"
                  id="Fill-954"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,103.727 C486.883,103.727 485.257,105.352 485.257,107.356 C485.257,109.361 486.883,110.986 488.888,110.986 C490.892,110.986 492.517,109.361 492.517,107.356 C492.517,105.352 490.892,103.727 488.888,103.727"
                  id="Fill-955"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,131.978 C595.819,131.978 594.193,133.603 594.193,135.607 C594.193,137.612 595.819,139.237 597.822,139.237 C599.827,139.237 601.453,137.612 601.453,135.607 C601.453,133.603 599.827,131.978 597.822,131.978"
                  id="Fill-956"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,113.144 C486.883,113.144 485.257,114.769 485.257,116.773 C485.257,118.777 486.883,120.403 488.888,120.403 C490.892,120.403 492.517,118.777 492.517,116.773 C492.517,114.769 490.892,113.144 488.888,113.144"
                  id="Fill-957"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,56.642 C486.883,56.642 485.257,58.266 485.257,60.271 C485.257,62.276 486.883,63.901 488.888,63.901 C490.892,63.901 492.517,62.276 492.517,60.271 C492.517,58.266 490.892,56.642 488.888,56.642"
                  id="Fill-958"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,75.476 C486.883,75.476 485.257,77.1 485.257,79.105 C485.257,81.109 486.883,82.735 488.888,82.735 C490.892,82.735 492.517,81.109 492.517,79.105 C492.517,77.1 490.892,75.476 488.888,75.476"
                  id="Fill-959"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,84.892 C486.883,84.892 485.257,86.517 485.257,88.522 C485.257,90.527 486.883,92.152 488.888,92.152 C490.892,92.152 492.517,90.527 492.517,88.522 C492.517,86.517 490.892,84.892 488.888,84.892"
                  id="Fill-960"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,18.973 C486.883,18.973 485.257,20.598 485.257,22.603 C485.257,24.607 486.883,26.232 488.888,26.232 C490.892,26.232 492.517,24.607 492.517,22.603 C492.517,20.598 490.892,18.973 488.888,18.973"
                  id="Fill-961"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M597.822,103.727 C595.819,103.727 594.193,105.352 594.193,107.356 C594.193,109.361 595.819,110.986 597.822,110.986 C599.827,110.986 601.453,109.361 601.453,107.356 C601.453,105.352 599.827,103.727 597.822,103.727"
                  id="Fill-962"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,37.808 C486.883,37.808 485.257,39.432 485.257,41.436 C485.257,43.441 486.883,45.066 488.888,45.066 C490.892,45.066 492.517,43.441 492.517,41.436 C492.517,39.432 490.892,37.808 488.888,37.808"
                  id="Fill-963"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M449.275,207.314 C447.271,207.314 445.645,208.939 445.645,210.944 C445.645,212.948 447.271,214.574 449.275,214.574 C451.279,214.574 452.903,212.948 452.903,210.944 C452.903,208.939 451.279,207.314 449.275,207.314"
                  id="Fill-964"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,150.812 C486.883,150.812 485.257,152.437 485.257,154.441 C485.257,156.446 486.883,158.071 488.888,158.071 C490.892,158.071 492.517,156.446 492.517,154.441 C492.517,152.437 490.892,150.812 488.888,150.812"
                  id="Fill-965"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,94.309 C486.883,94.309 485.257,95.935 485.257,97.939 C485.257,99.944 486.883,101.569 488.888,101.569 C490.892,101.569 492.517,99.944 492.517,97.939 C492.517,95.935 490.892,94.309 488.888,94.309"
                  id="Fill-966"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M488.888,122.561 C486.883,122.561 485.257,124.186 485.257,126.19 C485.257,128.195 486.883,129.82 488.888,129.82 C490.892,129.82 492.517,128.195 492.517,126.19 C492.517,124.186 490.892,122.561 488.888,122.561"
                  id="Fill-967"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,141.395 C486.883,141.395 485.257,143.02 485.257,145.024 C485.257,147.029 486.883,148.654 488.888,148.654 C490.892,148.654 492.517,147.029 492.517,145.024 C492.517,143.02 490.892,141.395 488.888,141.395"
                  id="Fill-968"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,160.229 C486.883,160.229 485.257,161.854 485.257,163.859 C485.257,165.863 486.883,167.488 488.888,167.488 C490.892,167.488 492.517,165.863 492.517,163.859 C492.517,161.854 490.892,160.229 488.888,160.229"
                  id="Fill-969"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M488.888,131.978 C486.883,131.978 485.257,133.603 485.257,135.607 C485.257,137.612 486.883,139.237 488.888,139.237 C490.892,139.237 492.517,137.612 492.517,135.607 C492.517,133.603 490.892,131.978 488.888,131.978"
                  id="Fill-970"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,56.642 C130.367,56.642 128.742,58.266 128.742,60.271 C128.742,62.276 130.367,63.901 132.371,63.901 C134.376,63.901 136.001,62.276 136.001,60.271 C136.001,58.266 134.376,56.642 132.371,56.642"
                  id="Fill-971"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,179.063 C130.367,179.063 128.742,180.688 128.742,182.693 C128.742,184.697 130.367,186.322 132.371,186.322 C134.376,186.322 136.001,184.697 136.001,182.693 C136.001,180.688 134.376,179.063 132.371,179.063"
                  id="Fill-972"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,47.225 C130.367,47.225 128.742,48.849 128.742,50.854 C128.742,52.858 130.367,54.483 132.371,54.483 C134.376,54.483 136.001,52.858 136.001,50.854 C136.001,48.849 134.376,47.225 132.371,47.225"
                  id="Fill-973"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,188.48 C130.367,188.48 128.742,190.106 128.742,192.11 C128.742,194.114 130.367,195.739 132.371,195.739 C134.376,195.739 136.001,194.114 136.001,192.11 C136.001,190.106 134.376,188.48 132.371,188.48"
                  id="Fill-974"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,66.058 C130.367,66.058 128.742,67.683 128.742,69.688 C128.742,71.693 130.367,73.318 132.371,73.318 C134.376,73.318 136.001,71.693 136.001,69.688 C136.001,67.683 134.376,66.058 132.371,66.058"
                  id="Fill-975"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,150.812 C130.367,150.812 128.742,152.437 128.742,154.441 C128.742,156.446 130.367,158.071 132.371,158.071 C134.376,158.071 136.001,156.446 136.001,154.441 C136.001,152.437 134.376,150.812 132.371,150.812"
                  id="Fill-976"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,169.646 C130.367,169.646 128.742,171.271 128.742,173.275 C128.742,175.28 130.367,176.905 132.371,176.905 C134.376,176.905 136.001,175.28 136.001,173.275 C136.001,171.271 134.376,169.646 132.371,169.646"
                  id="Fill-977"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,141.395 C130.367,141.395 128.742,143.02 128.742,145.024 C128.742,147.029 130.367,148.654 132.371,148.654 C134.376,148.654 136.001,147.029 136.001,145.024 C136.001,143.02 134.376,141.395 132.371,141.395"
                  id="Fill-978"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,122.561 C130.367,122.561 128.742,124.186 128.742,126.19 C128.742,128.195 130.367,129.82 132.371,129.82 C134.376,129.82 136.001,128.195 136.001,126.19 C136.001,124.186 134.376,122.561 132.371,122.561"
                  id="Fill-979"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,131.978 C130.367,131.978 128.742,133.603 128.742,135.607 C128.742,137.612 130.367,139.237 132.371,139.237 C134.376,139.237 136.001,137.612 136.001,135.607 C136.001,133.603 134.376,131.978 132.371,131.978"
                  id="Fill-980"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,160.229 C130.367,160.229 128.742,161.854 128.742,163.859 C128.742,165.863 130.367,167.488 132.371,167.488 C134.376,167.488 136.001,165.863 136.001,163.859 C136.001,161.854 134.376,160.229 132.371,160.229"
                  id="Fill-981"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,75.476 C130.367,75.476 128.742,77.1 128.742,79.105 C128.742,81.109 130.367,82.735 132.371,82.735 C134.376,82.735 136.001,81.109 136.001,79.105 C136.001,77.1 134.376,75.476 132.371,75.476"
                  id="Fill-982"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,179.063 C120.463,179.063 118.839,180.688 118.839,182.693 C118.839,184.697 120.463,186.322 122.468,186.322 C124.472,186.322 126.098,184.697 126.098,182.693 C126.098,180.688 124.472,179.063 122.468,179.063"
                  id="Fill-983"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,47.225 C110.561,47.225 108.935,48.849 108.935,50.854 C108.935,52.858 110.561,54.483 112.565,54.483 C114.569,54.483 116.195,52.858 116.195,50.854 C116.195,48.849 114.569,47.225 112.565,47.225"
                  id="Fill-984"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,18.973 C110.561,18.973 108.935,20.598 108.935,22.603 C108.935,24.607 110.561,26.232 112.565,26.232 C114.569,26.232 116.195,24.607 116.195,22.603 C116.195,20.598 114.569,18.973 112.565,18.973"
                  id="Fill-985"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,84.892 C120.463,84.892 118.839,86.517 118.839,88.522 C118.839,90.527 120.463,92.152 122.468,92.152 C124.472,92.152 126.098,90.527 126.098,88.522 C126.098,86.517 124.472,84.892 122.468,84.892"
                  id="Fill-986"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,113.144 C130.367,113.144 128.742,114.769 128.742,116.773 C128.742,118.777 130.367,120.403 132.371,120.403 C134.376,120.403 136.001,118.777 136.001,116.773 C136.001,114.769 134.376,113.144 132.371,113.144"
                  id="Fill-987"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,9.557 C120.463,9.557 118.839,11.181 118.839,13.185 C118.839,15.19 120.463,16.815 122.468,16.815 C124.472,16.815 126.098,15.19 126.098,13.185 C126.098,11.181 124.472,9.557 122.468,9.557"
                  id="Fill-988"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,103.727 C130.367,103.727 128.742,105.352 128.742,107.356 C128.742,109.361 130.367,110.986 132.371,110.986 C134.376,110.986 136.001,109.361 136.001,107.356 C136.001,105.352 134.376,103.727 132.371,103.727"
                  id="Fill-989"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,207.314 C130.367,207.314 128.742,208.939 128.742,210.944 C128.742,212.948 130.367,214.574 132.371,214.574 C134.376,214.574 136.001,212.948 136.001,210.944 C136.001,208.939 134.376,207.314 132.371,207.314"
                  id="Fill-990"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,94.309 C130.367,94.309 128.742,95.935 128.742,97.939 C128.742,99.944 130.367,101.569 132.371,101.569 C134.376,101.569 136.001,99.944 136.001,97.939 C136.001,95.935 134.376,94.309 132.371,94.309"
                  id="Fill-991"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,188.48 C120.463,188.48 118.839,190.106 118.839,192.11 C118.839,194.114 120.463,195.739 122.468,195.739 C124.472,195.739 126.098,194.114 126.098,192.11 C126.098,190.106 124.472,188.48 122.468,188.48"
                  id="Fill-992"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,84.892 C130.367,84.892 128.742,86.517 128.742,88.522 C128.742,90.527 130.367,92.152 132.371,92.152 C134.376,92.152 136.001,90.527 136.001,88.522 C136.001,86.517 134.376,84.892 132.371,84.892"
                  id="Fill-993"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,197.897 C120.463,197.897 118.839,199.523 118.839,201.527 C118.839,203.531 120.463,205.156 122.468,205.156 C124.472,205.156 126.098,203.531 126.098,201.527 C126.098,199.523 124.472,197.897 122.468,197.897"
                  id="Fill-994"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,207.314 C120.463,207.314 118.839,208.939 118.839,210.944 C118.839,212.948 120.463,214.574 122.468,214.574 C124.472,214.574 126.098,212.948 126.098,210.944 C126.098,208.939 124.472,207.314 122.468,207.314"
                  id="Fill-995"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,197.897 C130.367,197.897 128.742,199.523 128.742,201.527 C128.742,203.531 130.367,205.156 132.371,205.156 C134.376,205.156 136.001,203.531 136.001,201.527 C136.001,199.523 134.376,197.897 132.371,197.897"
                  id="Fill-996"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M142.275,169.646 C140.27,169.646 138.645,171.271 138.645,173.275 C138.645,175.28 140.27,176.905 142.275,176.905 C144.28,176.905 145.904,175.28 145.904,173.275 C145.904,171.271 144.28,169.646 142.275,169.646"
                  id="Fill-997"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,37.808 C120.463,37.808 118.839,39.432 118.839,41.436 C118.839,43.441 120.463,45.066 122.468,45.066 C124.472,45.066 126.098,43.441 126.098,41.436 C126.098,39.432 124.472,37.808 122.468,37.808"
                  id="Fill-998"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,47.225 C120.463,47.225 118.839,48.849 118.839,50.854 C118.839,52.858 120.463,54.483 122.468,54.483 C124.472,54.483 126.098,52.858 126.098,50.854 C126.098,48.849 124.472,47.225 122.468,47.225"
                  id="Fill-999"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,160.229 C140.27,160.229 138.645,161.854 138.645,163.859 C138.645,165.863 140.27,167.488 142.275,167.488 C144.28,167.488 145.904,165.863 145.904,163.859 C145.904,161.854 144.28,160.229 142.275,160.229"
                  id="Fill-1000"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M142.275,141.395 C140.27,141.395 138.645,143.02 138.645,145.024 C138.645,147.029 140.27,148.654 142.275,148.654 C144.28,148.654 145.904,147.029 145.904,145.024 C145.904,143.02 144.28,141.395 142.275,141.395"
                  id="Fill-1001"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,150.812 C140.27,150.812 138.645,152.437 138.645,154.441 C138.645,156.446 140.27,158.071 142.275,158.071 C144.28,158.071 145.904,156.446 145.904,154.441 C145.904,152.437 144.28,150.812 142.275,150.812"
                  id="Fill-1002"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M132.371,9.557 C130.367,9.557 128.742,11.181 128.742,13.185 C128.742,15.19 130.367,16.815 132.371,16.815 C134.376,16.815 136.001,15.19 136.001,13.185 C136.001,11.181 134.376,9.557 132.371,9.557"
                  id="Fill-1003"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,56.642 C120.463,56.642 118.839,58.266 118.839,60.271 C118.839,62.276 120.463,63.901 122.468,63.901 C124.472,63.901 126.098,62.276 126.098,60.271 C126.098,58.266 124.472,56.642 122.468,56.642"
                  id="Fill-1004"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,66.058 C120.463,66.058 118.839,67.683 118.839,69.688 C118.839,71.693 120.463,73.318 122.468,73.318 C124.472,73.318 126.098,71.693 126.098,69.688 C126.098,67.683 124.472,66.058 122.468,66.058"
                  id="Fill-1005"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M142.275,188.48 C140.27,188.48 138.645,190.106 138.645,192.11 C138.645,194.114 140.27,195.739 142.275,195.739 C144.28,195.739 145.904,194.114 145.904,192.11 C145.904,190.106 144.28,188.48 142.275,188.48"
                  id="Fill-1006"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,103.727 C120.463,103.727 118.839,105.352 118.839,107.356 C118.839,109.361 120.463,110.986 122.468,110.986 C124.472,110.986 126.098,109.361 126.098,107.356 C126.098,105.352 124.472,103.727 122.468,103.727"
                  id="Fill-1007"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,197.897 C140.27,197.897 138.645,199.523 138.645,201.527 C138.645,203.531 140.27,205.156 142.275,205.156 C144.28,205.156 145.904,203.531 145.904,201.527 C145.904,199.523 144.28,197.897 142.275,197.897"
                  id="Fill-1008"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,94.309 C120.463,94.309 118.839,95.935 118.839,97.939 C118.839,99.944 120.463,101.569 122.468,101.569 C124.472,101.569 126.098,99.944 126.098,97.939 C126.098,95.935 124.472,94.309 122.468,94.309"
                  id="Fill-1009"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,179.063 C140.27,179.063 138.645,180.688 138.645,182.693 C138.645,184.697 140.27,186.322 142.275,186.322 C144.28,186.322 145.904,184.697 145.904,182.693 C145.904,180.688 144.28,179.063 142.275,179.063"
                  id="Fill-1010"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,75.476 C120.463,75.476 118.839,77.1 118.839,79.105 C118.839,81.109 120.463,82.735 122.468,82.735 C124.472,82.735 126.098,81.109 126.098,79.105 C126.098,77.1 124.472,75.476 122.468,75.476"
                  id="Fill-1011"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,207.314 C140.27,207.314 138.645,208.939 138.645,210.944 C138.645,212.948 140.27,214.574 142.275,214.574 C144.28,214.574 145.904,212.948 145.904,210.944 C145.904,208.939 144.28,207.314 142.275,207.314"
                  id="Fill-1012"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,122.561 C140.27,122.561 138.645,124.186 138.645,126.19 C138.645,128.195 140.27,129.82 142.275,129.82 C144.28,129.82 145.904,128.195 145.904,126.19 C145.904,124.186 144.28,122.561 142.275,122.561"
                  id="Fill-1013"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,131.978 C140.27,131.978 138.645,133.603 138.645,135.607 C138.645,137.612 140.27,139.237 142.275,139.237 C144.28,139.237 145.904,137.612 145.904,135.607 C145.904,133.603 144.28,131.978 142.275,131.978"
                  id="Fill-1014"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,113.144 C120.463,113.144 118.839,114.769 118.839,116.773 C118.839,118.777 120.463,120.403 122.468,120.403 C124.472,120.403 126.098,118.777 126.098,116.773 C126.098,114.769 124.472,113.144 122.468,113.144"
                  id="Fill-1015"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,18.973 C140.27,18.973 138.645,20.598 138.645,22.603 C138.645,24.607 140.27,26.232 142.275,26.232 C144.28,26.232 145.904,24.607 145.904,22.603 C145.904,20.598 144.28,18.973 142.275,18.973"
                  id="Fill-1016"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,169.646 C120.463,169.646 118.839,171.271 118.839,173.275 C118.839,175.28 120.463,176.905 122.468,176.905 C124.472,176.905 126.098,175.28 126.098,173.275 C126.098,171.271 124.472,169.646 122.468,169.646"
                  id="Fill-1017"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,84.892 C140.27,84.892 138.645,86.517 138.645,88.522 C138.645,90.527 140.27,92.152 142.275,92.152 C144.28,92.152 145.904,90.527 145.904,88.522 C145.904,86.517 144.28,84.892 142.275,84.892"
                  id="Fill-1018"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,0.139 C140.27,0.139 138.645,1.764 138.645,3.769 C138.645,5.774 140.27,7.398 142.275,7.398 C144.28,7.398 145.904,5.774 145.904,3.769 C145.904,1.764 144.28,0.139 142.275,0.139"
                  id="Fill-1019"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M132.371,0.139 C130.367,0.139 128.742,1.764 128.742,3.769 C128.742,5.774 130.367,7.398 132.371,7.398 C134.376,7.398 136.001,5.774 136.001,3.769 C136.001,1.764 134.376,0.139 132.371,0.139"
                  id="Fill-1020"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,160.229 C120.463,160.229 118.839,161.854 118.839,163.859 C118.839,165.863 120.463,167.488 122.468,167.488 C124.472,167.488 126.098,165.863 126.098,163.859 C126.098,161.854 124.472,160.229 122.468,160.229"
                  id="Fill-1021"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M142.275,9.557 C140.27,9.557 138.645,11.181 138.645,13.185 C138.645,15.19 140.27,16.815 142.275,16.815 C144.28,16.815 145.904,15.19 145.904,13.185 C145.904,11.181 144.28,9.557 142.275,9.557"
                  id="Fill-1022"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,66.058 C140.27,66.058 138.645,67.683 138.645,69.688 C138.645,71.693 140.27,73.318 142.275,73.318 C144.28,73.318 145.904,71.693 145.904,69.688 C145.904,67.683 144.28,66.058 142.275,66.058"
                  id="Fill-1023"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,103.727 C140.27,103.727 138.645,105.352 138.645,107.356 C138.645,109.361 140.27,110.986 142.275,110.986 C144.28,110.986 145.904,109.361 145.904,107.356 C145.904,105.352 144.28,103.727 142.275,103.727"
                  id="Fill-1024"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M142.275,94.309 C140.27,94.309 138.645,95.935 138.645,97.939 C138.645,99.944 140.27,101.569 142.275,101.569 C144.28,101.569 145.904,99.944 145.904,97.939 C145.904,95.935 144.28,94.309 142.275,94.309"
                  id="Fill-1025"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,18.973 C120.463,18.973 118.839,20.598 118.839,22.603 C118.839,24.607 120.463,26.232 122.468,26.232 C124.472,26.232 126.098,24.607 126.098,22.603 C126.098,20.598 124.472,18.973 122.468,18.973"
                  id="Fill-1026"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M142.275,113.144 C140.27,113.144 138.645,114.769 138.645,116.773 C138.645,118.777 140.27,120.403 142.275,120.403 C144.28,120.403 145.904,118.777 145.904,116.773 C145.904,114.769 144.28,113.144 142.275,113.144"
                  id="Fill-1027"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M122.468,131.978 C120.463,131.978 118.839,133.603 118.839,135.607 C118.839,137.612 120.463,139.237 122.468,139.237 C124.472,139.237 126.098,137.612 126.098,135.607 C126.098,133.603 124.472,131.978 122.468,131.978"
                  id="Fill-1028"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,141.395 C120.463,141.395 118.839,143.02 118.839,145.024 C118.839,147.029 120.463,148.654 122.468,148.654 C124.472,148.654 126.098,147.029 126.098,145.024 C126.098,143.02 124.472,141.395 122.468,141.395"
                  id="Fill-1029"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,122.561 C120.463,122.561 118.839,124.186 118.839,126.19 C118.839,128.195 120.463,129.82 122.468,129.82 C124.472,129.82 126.098,128.195 126.098,126.19 C126.098,124.186 124.472,122.561 122.468,122.561"
                  id="Fill-1030"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M122.468,150.812 C120.463,150.812 118.839,152.437 118.839,154.441 C118.839,156.446 120.463,158.071 122.468,158.071 C124.472,158.071 126.098,156.446 126.098,154.441 C126.098,152.437 124.472,150.812 122.468,150.812"
                  id="Fill-1031"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,197.897 C100.658,197.897 99.032,199.523 99.032,201.527 C99.032,203.531 100.658,205.156 102.662,205.156 C104.667,205.156 106.291,203.531 106.291,201.527 C106.291,199.523 104.667,197.897 102.662,197.897"
                  id="Fill-1032"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M53.145,103.727 C51.141,103.727 49.517,105.352 49.517,107.356 C49.517,109.361 51.141,110.986 53.145,110.986 C55.15,110.986 56.775,109.361 56.775,107.356 C56.775,105.352 55.15,103.727 53.145,103.727"
                  id="Fill-1033"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M53.145,94.309 C51.141,94.309 49.517,95.935 49.517,97.939 C49.517,99.944 51.141,101.569 53.145,101.569 C55.15,101.569 56.775,99.944 56.775,97.939 C56.775,95.935 55.15,94.309 53.145,94.309"
                  id="Fill-1034"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,113.144 C61.045,113.144 59.419,114.769 59.419,116.773 C59.419,118.777 61.045,120.403 63.049,120.403 C65.053,120.403 66.678,118.777 66.678,116.773 C66.678,114.769 65.053,113.144 63.049,113.144"
                  id="Fill-1035"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,122.561 C61.045,122.561 59.419,124.186 59.419,126.19 C59.419,128.195 61.045,129.82 63.049,129.82 C65.053,129.82 66.678,128.195 66.678,126.19 C66.678,124.186 65.053,122.561 63.049,122.561"
                  id="Fill-1036"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M53.145,75.476 C51.141,75.476 49.517,77.1 49.517,79.105 C49.517,81.109 51.141,82.735 53.145,82.735 C55.15,82.735 56.775,81.109 56.775,79.105 C56.775,77.1 55.15,75.476 53.145,75.476"
                  id="Fill-1037"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M53.145,84.892 C51.141,84.892 49.517,86.517 49.517,88.522 C49.517,90.527 51.141,92.152 53.145,92.152 C55.15,92.152 56.775,90.527 56.775,88.522 C56.775,86.517 55.15,84.892 53.145,84.892"
                  id="Fill-1038"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M53.145,113.144 C51.141,113.144 49.517,114.769 49.517,116.773 C49.517,118.777 51.141,120.403 53.145,120.403 C55.15,120.403 56.775,118.777 56.775,116.773 C56.775,114.769 55.15,113.144 53.145,113.144"
                  id="Fill-1039"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M43.242,94.309 C41.238,94.309 39.613,95.935 39.613,97.939 C39.613,99.944 41.238,101.569 43.242,101.569 C45.247,101.569 46.872,99.944 46.872,97.939 C46.872,95.935 45.247,94.309 43.242,94.309"
                  id="Fill-1040"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M43.242,103.727 C41.238,103.727 39.613,105.352 39.613,107.356 C39.613,109.361 41.238,110.986 43.242,110.986 C45.247,110.986 46.872,109.361 46.872,107.356 C46.872,105.352 45.247,103.727 43.242,103.727"
                  id="Fill-1041"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M43.242,84.892 C41.238,84.892 39.613,86.517 39.613,88.522 C39.613,90.527 41.238,92.152 43.242,92.152 C45.247,92.152 46.872,90.527 46.872,88.522 C46.872,86.517 45.247,84.892 43.242,84.892"
                  id="Fill-1042"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M43.242,113.144 C41.238,113.144 39.613,114.769 39.613,116.773 C39.613,118.777 41.238,120.403 43.242,120.403 C45.247,120.403 46.872,118.777 46.872,116.773 C46.872,114.769 45.247,113.144 43.242,113.144"
                  id="Fill-1043"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M43.242,75.476 C41.238,75.476 39.613,77.1 39.613,79.105 C39.613,81.109 41.238,82.735 43.242,82.735 C45.247,82.735 46.872,81.109 46.872,79.105 C46.872,77.1 45.247,75.476 43.242,75.476"
                  id="Fill-1044"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,84.892 C70.947,84.892 69.323,86.517 69.323,88.522 C69.323,90.527 70.947,92.152 72.952,92.152 C74.957,92.152 76.581,90.527 76.581,88.522 C76.581,86.517 74.957,84.892 72.952,84.892"
                  id="Fill-1045"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,94.309 C70.947,94.309 69.323,95.935 69.323,97.939 C69.323,99.944 70.947,101.569 72.952,101.569 C74.957,101.569 76.581,99.944 76.581,97.939 C76.581,95.935 74.957,94.309 72.952,94.309"
                  id="Fill-1046"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,103.727 C70.947,103.727 69.323,105.352 69.323,107.356 C69.323,109.361 70.947,110.986 72.952,110.986 C74.957,110.986 76.581,109.361 76.581,107.356 C76.581,105.352 74.957,103.727 72.952,103.727"
                  id="Fill-1047"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,75.476 C70.947,75.476 69.323,77.1 69.323,79.105 C69.323,81.109 70.947,82.735 72.952,82.735 C74.957,82.735 76.581,81.109 76.581,79.105 C76.581,77.1 74.957,75.476 72.952,75.476"
                  id="Fill-1048"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,141.395 C80.85,141.395 79.226,143.02 79.226,145.024 C79.226,147.029 80.85,148.654 82.855,148.654 C84.86,148.654 86.485,147.029 86.485,145.024 C86.485,143.02 84.86,141.395 82.855,141.395"
                  id="Fill-1049"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,113.144 C70.947,113.144 69.323,114.769 69.323,116.773 C69.323,118.777 70.947,120.403 72.952,120.403 C74.957,120.403 76.581,118.777 76.581,116.773 C76.581,114.769 74.957,113.144 72.952,113.144"
                  id="Fill-1050"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,84.892 C61.045,84.892 59.419,86.517 59.419,88.522 C59.419,90.527 61.045,92.152 63.049,92.152 C65.053,92.152 66.678,90.527 66.678,88.522 C66.678,86.517 65.053,84.892 63.049,84.892"
                  id="Fill-1051"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,103.727 C61.045,103.727 59.419,105.352 59.419,107.356 C59.419,109.361 61.045,110.986 63.049,110.986 C65.053,110.986 66.678,109.361 66.678,107.356 C66.678,105.352 65.053,103.727 63.049,103.727"
                  id="Fill-1052"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,94.309 C61.045,94.309 59.419,95.935 59.419,97.939 C59.419,99.944 61.045,101.569 63.049,101.569 C65.053,101.569 66.678,99.944 66.678,97.939 C66.678,95.935 65.053,94.309 63.049,94.309"
                  id="Fill-1053"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M72.952,122.561 C70.947,122.561 69.323,124.186 69.323,126.19 C69.323,128.195 70.947,129.82 72.952,129.82 C74.957,129.82 76.581,128.195 76.581,126.19 C76.581,124.186 74.957,122.561 72.952,122.561"
                  id="Fill-1054"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M63.049,75.476 C61.045,75.476 59.419,77.1 59.419,79.105 C59.419,81.109 61.045,82.735 63.049,82.735 C65.053,82.735 66.678,81.109 66.678,79.105 C66.678,77.1 65.053,75.476 63.049,75.476"
                  id="Fill-1055"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M23.436,131.978 C21.432,131.978 19.807,133.603 19.807,135.607 C19.807,137.612 21.432,139.237 23.436,139.237 C25.44,139.237 27.066,137.612 27.066,135.607 C27.066,133.603 25.44,131.978 23.436,131.978"
                  id="Fill-1056"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M13.533,66.058 C11.528,66.058 9.904,67.683 9.904,69.688 C9.904,71.693 11.528,73.318 13.533,73.318 C15.538,73.318 17.163,71.693 17.163,69.688 C17.163,67.683 15.538,66.058 13.533,66.058"
                  id="Fill-1057"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M13.533,75.476 C11.528,75.476 9.904,77.1 9.904,79.105 C9.904,81.109 11.528,82.735 13.533,82.735 C15.538,82.735 17.163,81.109 17.163,79.105 C17.163,77.1 15.538,75.476 13.533,75.476"
                  id="Fill-1058"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,122.561 C21.432,122.561 19.807,124.186 19.807,126.19 C19.807,128.195 21.432,129.82 23.436,129.82 C25.44,129.82 27.066,128.195 27.066,126.19 C27.066,124.186 25.44,122.561 23.436,122.561"
                  id="Fill-1059"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M13.533,84.892 C11.528,84.892 9.904,86.517 9.904,88.522 C9.904,90.527 11.528,92.152 13.533,92.152 C15.538,92.152 17.163,90.527 17.163,88.522 C17.163,86.517 15.538,84.892 13.533,84.892"
                  id="Fill-1060"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,131.978 C80.85,131.978 79.226,133.603 79.226,135.607 C79.226,137.612 80.85,139.237 82.855,139.237 C84.86,139.237 86.485,137.612 86.485,135.607 C86.485,133.603 84.86,131.978 82.855,131.978"
                  id="Fill-1061"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,113.144 C21.432,113.144 19.807,114.769 19.807,116.773 C19.807,118.777 21.432,120.403 23.436,120.403 C25.44,120.403 27.066,118.777 27.066,116.773 C27.066,114.769 25.44,113.144 23.436,113.144"
                  id="Fill-1062"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M13.533,122.561 C11.528,122.561 9.904,124.186 9.904,126.19 C9.904,128.195 11.528,129.82 13.533,129.82 C15.538,129.82 17.163,128.195 17.163,126.19 C17.163,124.186 15.538,122.561 13.533,122.561"
                  id="Fill-1063"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M3.629,94.309 C1.625,94.309 0,95.935 0,97.939 C0,99.944 1.625,101.569 3.629,101.569 C5.634,101.569 7.259,99.944 7.259,97.939 C7.259,95.935 5.634,94.309 3.629,94.309"
                  id="Fill-1064"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M13.533,103.727 C11.528,103.727 9.904,105.352 9.904,107.356 C9.904,109.361 11.528,110.986 13.533,110.986 C15.538,110.986 17.163,109.361 17.163,107.356 C17.163,105.352 15.538,103.727 13.533,103.727"
                  id="Fill-1065"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M13.533,113.144 C11.528,113.144 9.904,114.769 9.904,116.773 C9.904,118.777 11.528,120.403 13.533,120.403 C15.538,120.403 17.163,118.777 17.163,116.773 C17.163,114.769 15.538,113.144 13.533,113.144"
                  id="Fill-1066"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M13.533,94.309 C11.528,94.309 9.904,95.935 9.904,97.939 C9.904,99.944 11.528,101.569 13.533,101.569 C15.538,101.569 17.163,99.944 17.163,97.939 C17.163,95.935 15.538,94.309 13.533,94.309"
                  id="Fill-1067"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,103.727 C31.335,103.727 29.71,105.352 29.71,107.356 C29.71,109.361 31.335,110.986 33.34,110.986 C35.344,110.986 36.968,109.361 36.968,107.356 C36.968,105.352 35.344,103.727 33.34,103.727"
                  id="Fill-1068"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,94.309 C31.335,94.309 29.71,95.935 29.71,97.939 C29.71,99.944 31.335,101.569 33.34,101.569 C35.344,101.569 36.968,99.944 36.968,97.939 C36.968,95.935 35.344,94.309 33.34,94.309"
                  id="Fill-1069"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,66.058 C31.335,66.058 29.71,67.683 29.71,69.688 C29.71,71.693 31.335,73.318 33.34,73.318 C35.344,73.318 36.968,71.693 36.968,69.688 C36.968,67.683 35.344,66.058 33.34,66.058"
                  id="Fill-1070"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,75.476 C31.335,75.476 29.71,77.1 29.71,79.105 C29.71,81.109 31.335,82.735 33.34,82.735 C35.344,82.735 36.968,81.109 36.968,79.105 C36.968,77.1 35.344,75.476 33.34,75.476"
                  id="Fill-1071"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,113.144 C31.335,113.144 29.71,114.769 29.71,116.773 C29.71,118.777 31.335,120.403 33.34,120.403 C35.344,120.403 36.968,118.777 36.968,116.773 C36.968,114.769 35.344,113.144 33.34,113.144"
                  id="Fill-1072"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M33.34,84.892 C31.335,84.892 29.71,86.517 29.71,88.522 C29.71,90.527 31.335,92.152 33.34,92.152 C35.344,92.152 36.968,90.527 36.968,88.522 C36.968,86.517 35.344,84.892 33.34,84.892"
                  id="Fill-1073"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M23.436,75.476 C21.432,75.476 19.807,77.1 19.807,79.105 C19.807,81.109 21.432,82.735 23.436,82.735 C25.44,82.735 27.066,81.109 27.066,79.105 C27.066,77.1 25.44,75.476 23.436,75.476"
                  id="Fill-1074"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,84.892 C21.432,84.892 19.807,86.517 19.807,88.522 C19.807,90.527 21.432,92.152 23.436,92.152 C25.44,92.152 27.066,90.527 27.066,88.522 C27.066,86.517 25.44,84.892 23.436,84.892"
                  id="Fill-1075"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,94.309 C21.432,94.309 19.807,95.935 19.807,97.939 C19.807,99.944 21.432,101.569 23.436,101.569 C25.44,101.569 27.066,99.944 27.066,97.939 C27.066,95.935 25.44,94.309 23.436,94.309"
                  id="Fill-1076"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M33.34,122.561 C31.335,122.561 29.71,124.186 29.71,126.19 C29.71,128.195 31.335,129.82 33.34,129.82 C35.344,129.82 36.968,128.195 36.968,126.19 C36.968,124.186 35.344,122.561 33.34,122.561"
                  id="Fill-1077"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,103.727 C21.432,103.727 19.807,105.352 19.807,107.356 C19.807,109.361 21.432,110.986 23.436,110.986 C25.44,110.986 27.066,109.361 27.066,107.356 C27.066,105.352 25.44,103.727 23.436,103.727"
                  id="Fill-1078"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M23.436,66.058 C21.432,66.058 19.807,67.683 19.807,69.688 C19.807,71.693 21.432,73.318 23.436,73.318 C25.44,73.318 27.066,71.693 27.066,69.688 C27.066,67.683 25.44,66.058 23.436,66.058"
                  id="Fill-1079"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,197.897 C110.561,197.897 108.935,199.523 108.935,201.527 C108.935,203.531 110.561,205.156 112.565,205.156 C114.569,205.156 116.195,203.531 116.195,201.527 C116.195,199.523 114.569,197.897 112.565,197.897"
                  id="Fill-1080"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,122.561 C100.658,122.561 99.032,124.186 99.032,126.19 C99.032,128.195 100.658,129.82 102.662,129.82 C104.667,129.82 106.291,128.195 106.291,126.19 C106.291,124.186 104.667,122.561 102.662,122.561"
                  id="Fill-1081"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,37.808 C100.658,37.808 99.032,39.432 99.032,41.436 C99.032,43.441 100.658,45.066 102.662,45.066 C104.667,45.066 106.291,43.441 106.291,41.436 C106.291,39.432 104.667,37.808 102.662,37.808"
                  id="Fill-1082"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,56.642 C110.561,56.642 108.935,58.266 108.935,60.271 C108.935,62.276 110.561,63.901 112.565,63.901 C114.569,63.901 116.195,62.276 116.195,60.271 C116.195,58.266 114.569,56.642 112.565,56.642"
                  id="Fill-1083"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,188.48 C110.561,188.48 108.935,190.106 108.935,192.11 C108.935,194.114 110.561,195.739 112.565,195.739 C114.569,195.739 116.195,194.114 116.195,192.11 C116.195,190.106 114.569,188.48 112.565,188.48"
                  id="Fill-1084"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,47.225 C100.658,47.225 99.032,48.849 99.032,50.854 C99.032,52.858 100.658,54.483 102.662,54.483 C104.667,54.483 106.291,52.858 106.291,50.854 C106.291,48.849 104.667,47.225 102.662,47.225"
                  id="Fill-1085"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,84.892 C100.658,84.892 99.032,86.517 99.032,88.522 C99.032,90.527 100.658,92.152 102.662,92.152 C104.667,92.152 106.291,90.527 106.291,88.522 C106.291,86.517 104.667,84.892 102.662,84.892"
                  id="Fill-1086"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,103.727 C100.658,103.727 99.032,105.352 99.032,107.356 C99.032,109.361 100.658,110.986 102.662,110.986 C104.667,110.986 106.291,109.361 106.291,107.356 C106.291,105.352 104.667,103.727 102.662,103.727"
                  id="Fill-1087"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,113.144 C100.658,113.144 99.032,114.769 99.032,116.773 C99.032,118.777 100.658,120.403 102.662,120.403 C104.667,120.403 106.291,118.777 106.291,116.773 C106.291,114.769 104.667,113.144 102.662,113.144"
                  id="Fill-1088"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,75.476 C100.658,75.476 99.032,77.1 99.032,79.105 C99.032,81.109 100.658,82.735 102.662,82.735 C104.667,82.735 106.291,81.109 106.291,79.105 C106.291,77.1 104.667,75.476 102.662,75.476"
                  id="Fill-1089"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,179.063 C110.561,179.063 108.935,180.688 108.935,182.693 C108.935,184.697 110.561,186.322 112.565,186.322 C114.569,186.322 116.195,184.697 116.195,182.693 C116.195,180.688 114.569,179.063 112.565,179.063"
                  id="Fill-1090"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,94.309 C100.658,94.309 99.032,95.935 99.032,97.939 C99.032,99.944 100.658,101.569 102.662,101.569 C104.667,101.569 106.291,99.944 106.291,97.939 C106.291,95.935 104.667,94.309 102.662,94.309"
                  id="Fill-1091"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,207.314 C110.561,207.314 108.935,208.939 108.935,210.944 C108.935,212.948 110.561,214.574 112.565,214.574 C114.569,214.574 116.195,212.948 116.195,210.944 C116.195,208.939 114.569,207.314 112.565,207.314"
                  id="Fill-1092"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,94.309 C110.561,94.309 108.935,95.935 108.935,97.939 C108.935,99.944 110.561,101.569 112.565,101.569 C114.569,101.569 116.195,99.944 116.195,97.939 C116.195,95.935 114.569,94.309 112.565,94.309"
                  id="Fill-1093"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,169.646 C110.561,169.646 108.935,171.271 108.935,173.275 C108.935,175.28 110.561,176.905 112.565,176.905 C114.569,176.905 116.195,175.28 116.195,173.275 C116.195,171.271 114.569,169.646 112.565,169.646"
                  id="Fill-1094"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M112.565,84.892 C110.561,84.892 108.935,86.517 108.935,88.522 C108.935,90.527 110.561,92.152 112.565,92.152 C114.569,92.152 116.195,90.527 116.195,88.522 C116.195,86.517 114.569,84.892 112.565,84.892"
                  id="Fill-1095"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,113.144 C110.561,113.144 108.935,114.769 108.935,116.773 C108.935,118.777 110.561,120.403 112.565,120.403 C114.569,120.403 116.195,118.777 116.195,116.773 C116.195,114.769 114.569,113.144 112.565,113.144"
                  id="Fill-1096"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,75.476 C110.561,75.476 108.935,77.1 108.935,79.105 C108.935,81.109 110.561,82.735 112.565,82.735 C114.569,82.735 116.195,81.109 116.195,79.105 C116.195,77.1 114.569,75.476 112.565,75.476"
                  id="Fill-1097"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M112.565,103.727 C110.561,103.727 108.935,105.352 108.935,107.356 C108.935,109.361 110.561,110.986 112.565,110.986 C114.569,110.986 116.195,109.361 116.195,107.356 C116.195,105.352 114.569,103.727 112.565,103.727"
                  id="Fill-1098"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,160.229 C110.561,160.229 108.935,161.854 108.935,163.859 C108.935,165.863 110.561,167.488 112.565,167.488 C114.569,167.488 116.195,165.863 116.195,163.859 C116.195,161.854 114.569,160.229 112.565,160.229"
                  id="Fill-1099"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M112.565,150.812 C110.561,150.812 108.935,152.437 108.935,154.441 C108.935,156.446 110.561,158.071 112.565,158.071 C114.569,158.071 116.195,156.446 116.195,154.441 C116.195,152.437 114.569,150.812 112.565,150.812"
                  id="Fill-1100"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M112.565,131.978 C110.561,131.978 108.935,133.603 108.935,135.607 C108.935,137.612 110.561,139.237 112.565,139.237 C114.569,139.237 116.195,137.612 116.195,135.607 C116.195,133.603 114.569,131.978 112.565,131.978"
                  id="Fill-1101"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M112.565,122.561 C110.561,122.561 108.935,124.186 108.935,126.19 C108.935,128.195 110.561,129.82 112.565,129.82 C114.569,129.82 116.195,128.195 116.195,126.19 C116.195,124.186 114.569,122.561 112.565,122.561"
                  id="Fill-1102"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M112.565,141.395 C110.561,141.395 108.935,143.02 108.935,145.024 C108.935,147.029 110.561,148.654 112.565,148.654 C114.569,148.654 116.195,147.029 116.195,145.024 C116.195,143.02 114.569,141.395 112.565,141.395"
                  id="Fill-1103"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M92.758,150.812 C90.754,150.812 89.129,152.437 89.129,154.441 C89.129,156.446 90.754,158.071 92.758,158.071 C94.763,158.071 96.388,156.446 96.388,154.441 C96.388,152.437 94.763,150.812 92.758,150.812"
                  id="Fill-1104"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,131.978 C90.754,131.978 89.129,133.603 89.129,135.607 C89.129,137.612 90.754,139.237 92.758,139.237 C94.763,139.237 96.388,137.612 96.388,135.607 C96.388,133.603 94.763,131.978 92.758,131.978"
                  id="Fill-1105"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,141.395 C90.754,141.395 89.129,143.02 89.129,145.024 C89.129,147.029 90.754,148.654 92.758,148.654 C94.763,148.654 96.388,147.029 96.388,145.024 C96.388,143.02 94.763,141.395 92.758,141.395"
                  id="Fill-1106"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M92.758,122.561 C90.754,122.561 89.129,124.186 89.129,126.19 C89.129,128.195 90.754,129.82 92.758,129.82 C94.763,129.82 96.388,128.195 96.388,126.19 C96.388,124.186 94.763,122.561 92.758,122.561"
                  id="Fill-1107"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,122.561 C80.85,122.561 79.226,124.186 79.226,126.19 C79.226,128.195 80.85,129.82 82.855,129.82 C84.86,129.82 86.485,128.195 86.485,126.19 C86.485,124.186 84.86,122.561 82.855,122.561"
                  id="Fill-1108"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,113.144 C90.754,113.144 89.129,114.769 89.129,116.773 C89.129,118.777 90.754,120.403 92.758,120.403 C94.763,120.403 96.388,118.777 96.388,116.773 C96.388,114.769 94.763,113.144 92.758,113.144"
                  id="Fill-1109"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M82.855,75.476 C80.85,75.476 79.226,77.1 79.226,79.105 C79.226,81.109 80.85,82.735 82.855,82.735 C84.86,82.735 86.485,81.109 86.485,79.105 C86.485,77.1 84.86,75.476 82.855,75.476"
                  id="Fill-1110"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,103.727 C80.85,103.727 79.226,105.352 79.226,107.356 C79.226,109.361 80.85,110.986 82.855,110.986 C84.86,110.986 86.485,109.361 86.485,107.356 C86.485,105.352 84.86,103.727 82.855,103.727"
                  id="Fill-1111"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M92.758,103.727 C90.754,103.727 89.129,105.352 89.129,107.356 C89.129,109.361 90.754,110.986 92.758,110.986 C94.763,110.986 96.388,109.361 96.388,107.356 C96.388,105.352 94.763,103.727 92.758,103.727"
                  id="Fill-1112"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,113.144 C80.85,113.144 79.226,114.769 79.226,116.773 C79.226,118.777 80.85,120.403 82.855,120.403 C84.86,120.403 86.485,118.777 86.485,116.773 C86.485,114.769 84.86,113.144 82.855,113.144"
                  id="Fill-1113"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M82.855,84.892 C80.85,84.892 79.226,86.517 79.226,88.522 C79.226,90.527 80.85,92.152 82.855,92.152 C84.86,92.152 86.485,90.527 86.485,88.522 C86.485,86.517 84.86,84.892 82.855,84.892"
                  id="Fill-1114"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M82.855,94.309 C80.85,94.309 79.226,95.935 79.226,97.939 C79.226,99.944 80.85,101.569 82.855,101.569 C84.86,101.569 86.485,99.944 86.485,97.939 C86.485,95.935 84.86,94.309 82.855,94.309"
                  id="Fill-1115"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,179.063 C100.658,179.063 99.032,180.688 99.032,182.693 C99.032,184.697 100.658,186.322 102.662,186.322 C104.667,186.322 106.291,184.697 106.291,182.693 C106.291,180.688 104.667,179.063 102.662,179.063"
                  id="Fill-1116"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,169.646 C100.658,169.646 99.032,171.271 99.032,173.275 C99.032,175.28 100.658,176.905 102.662,176.905 C104.667,176.905 106.291,175.28 106.291,173.275 C106.291,171.271 104.667,169.646 102.662,169.646"
                  id="Fill-1117"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M102.662,160.229 C100.658,160.229 99.032,161.854 99.032,163.859 C99.032,165.863 100.658,167.488 102.662,167.488 C104.667,167.488 106.291,165.863 106.291,163.859 C106.291,161.854 104.667,160.229 102.662,160.229"
                  id="Fill-1118"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,131.978 C100.658,131.978 99.032,133.603 99.032,135.607 C99.032,137.612 100.658,139.237 102.662,139.237 C104.667,139.237 106.291,137.612 106.291,135.607 C106.291,133.603 104.667,131.978 102.662,131.978"
                  id="Fill-1119"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,94.309 C90.754,94.309 89.129,95.935 89.129,97.939 C89.129,99.944 90.754,101.569 92.758,101.569 C94.763,101.569 96.388,99.944 96.388,97.939 C96.388,95.935 94.763,94.309 92.758,94.309"
                  id="Fill-1120"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,150.812 C100.658,150.812 99.032,152.437 99.032,154.441 C99.032,156.446 100.658,158.071 102.662,158.071 C104.667,158.071 106.291,156.446 106.291,154.441 C106.291,152.437 104.667,150.812 102.662,150.812"
                  id="Fill-1121"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M102.662,141.395 C100.658,141.395 99.032,143.02 99.032,145.024 C99.032,147.029 100.658,148.654 102.662,148.654 C104.667,148.654 106.291,147.029 106.291,145.024 C106.291,143.02 104.667,141.395 102.662,141.395"
                  id="Fill-1122"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M102.662,188.48 C100.658,188.48 99.032,190.106 99.032,192.11 C99.032,194.114 100.658,195.739 102.662,195.739 C104.667,195.739 106.291,194.114 106.291,192.11 C106.291,190.106 104.667,188.48 102.662,188.48"
                  id="Fill-1123"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,75.476 C90.754,75.476 89.129,77.1 89.129,79.105 C89.129,81.109 90.754,82.735 92.758,82.735 C94.763,82.735 96.388,81.109 96.388,79.105 C96.388,77.1 94.763,75.476 92.758,75.476"
                  id="Fill-1124"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,84.892 C90.754,84.892 89.129,86.517 89.129,88.522 C89.129,90.527 90.754,92.152 92.758,92.152 C94.763,92.152 96.388,90.527 96.388,88.522 C96.388,86.517 94.763,84.892 92.758,84.892"
                  id="Fill-1125"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M92.758,66.058 C90.754,66.058 89.129,67.683 89.129,69.688 C89.129,71.693 90.754,73.318 92.758,73.318 C94.763,73.318 96.388,71.693 96.388,69.688 C96.388,67.683 94.763,66.058 92.758,66.058"
                  id="Fill-1126"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,0.139 C189.786,0.139 188.162,1.764 188.162,3.769 C188.162,5.774 189.786,7.398 191.79,7.398 C193.795,7.398 195.42,5.774 195.42,3.769 C195.42,1.764 193.795,0.139 191.79,0.139"
                  id="Fill-1127"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,0.139 C219.496,0.139 217.871,1.764 217.871,3.769 C217.871,5.774 219.496,7.398 221.501,7.398 C223.505,7.398 225.13,5.774 225.13,3.769 C225.13,1.764 223.505,0.139 221.501,0.139"
                  id="Fill-1128"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,9.557 C219.496,9.557 217.871,11.181 217.871,13.185 C217.871,15.19 219.496,16.815 221.501,16.815 C223.505,16.815 225.13,15.19 225.13,13.185 C225.13,11.181 223.505,9.557 221.501,9.557"
                  id="Fill-1129"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,150.812 C229.399,150.812 227.774,152.437 227.774,154.441 C227.774,156.446 229.399,158.071 231.403,158.071 C233.408,158.071 235.033,156.446 235.033,154.441 C235.033,152.437 233.408,150.812 231.403,150.812"
                  id="Fill-1130"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,47.225 C219.496,47.225 217.871,48.849 217.871,50.854 C217.871,52.858 219.496,54.483 221.501,54.483 C223.505,54.483 225.13,52.858 225.13,50.854 C225.13,48.849 223.505,47.225 221.501,47.225"
                  id="Fill-1131"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,207.314 C150.173,207.314 148.548,208.939 148.548,210.944 C148.548,212.948 150.173,214.574 152.178,214.574 C154.183,214.574 155.808,212.948 155.808,210.944 C155.808,208.939 154.183,207.314 152.178,207.314"
                  id="Fill-1132"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,131.978 C229.399,131.978 227.774,133.603 227.774,135.607 C227.774,137.612 229.399,139.237 231.403,139.237 C233.408,139.237 235.033,137.612 235.033,135.607 C235.033,133.603 233.408,131.978 231.403,131.978"
                  id="Fill-1133"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,56.642 C219.496,56.642 217.871,58.266 217.871,60.271 C217.871,62.276 219.496,63.901 221.501,63.901 C223.505,63.901 225.13,62.276 225.13,60.271 C225.13,58.266 223.505,56.642 221.501,56.642"
                  id="Fill-1134"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,94.309 C219.496,94.309 217.871,95.935 217.871,97.939 C217.871,99.944 219.496,101.569 221.501,101.569 C223.505,101.569 225.13,99.944 225.13,97.939 C225.13,95.935 223.505,94.309 221.501,94.309"
                  id="Fill-1135"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,84.892 C219.496,84.892 217.871,86.517 217.871,88.522 C217.871,90.527 219.496,92.152 221.501,92.152 C223.505,92.152 225.13,90.527 225.13,88.522 C225.13,86.517 223.505,84.892 221.501,84.892"
                  id="Fill-1136"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,75.476 C219.496,75.476 217.871,77.1 217.871,79.105 C217.871,81.109 219.496,82.735 221.501,82.735 C223.505,82.735 225.13,81.109 225.13,79.105 C225.13,77.1 223.505,75.476 221.501,75.476"
                  id="Fill-1137"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,141.395 C229.399,141.395 227.774,143.02 227.774,145.024 C227.774,147.029 229.399,148.654 231.403,148.654 C233.408,148.654 235.033,147.029 235.033,145.024 C235.033,143.02 233.408,141.395 231.403,141.395"
                  id="Fill-1138"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,75.476 C239.303,75.476 237.677,77.1 237.677,79.105 C237.677,81.109 239.303,82.735 241.307,82.735 C243.312,82.735 244.936,81.109 244.936,79.105 C244.936,77.1 243.312,75.476 241.307,75.476"
                  id="Fill-1139"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,84.892 C239.303,84.892 237.677,86.517 237.677,88.522 C237.677,90.527 239.303,92.152 241.307,92.152 C243.312,92.152 244.936,90.527 244.936,88.522 C244.936,86.517 243.312,84.892 241.307,84.892"
                  id="Fill-1140"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,66.058 C239.303,66.058 237.677,67.683 237.677,69.688 C237.677,71.693 239.303,73.318 241.307,73.318 C243.312,73.318 244.936,71.693 244.936,69.688 C244.936,67.683 243.312,66.058 241.307,66.058"
                  id="Fill-1141"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,47.225 C239.303,47.225 237.677,48.849 237.677,50.854 C237.677,52.858 239.303,54.483 241.307,54.483 C243.312,54.483 244.936,52.858 244.936,50.854 C244.936,48.849 243.312,47.225 241.307,47.225"
                  id="Fill-1142"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,56.642 C239.303,56.642 237.677,58.266 237.677,60.271 C237.677,62.276 239.303,63.901 241.307,63.901 C243.312,63.901 244.936,62.276 244.936,60.271 C244.936,58.266 243.312,56.642 241.307,56.642"
                  id="Fill-1143"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,28.39 C229.399,28.39 227.774,30.016 227.774,32.02 C227.774,34.025 229.399,35.649 231.403,35.649 C233.408,35.649 235.033,34.025 235.033,32.02 C235.033,30.016 233.408,28.39 231.403,28.39"
                  id="Fill-1144"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,37.808 C229.399,37.808 227.774,39.432 227.774,41.436 C227.774,43.441 229.399,45.066 231.403,45.066 C233.408,45.066 235.033,43.441 235.033,41.436 C235.033,39.432 233.408,37.808 231.403,37.808"
                  id="Fill-1145"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,94.309 C239.303,94.309 237.677,95.935 237.677,97.939 C237.677,99.944 239.303,101.569 241.307,101.569 C243.312,101.569 244.936,99.944 244.936,97.939 C244.936,95.935 243.312,94.309 241.307,94.309"
                  id="Fill-1146"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,47.225 C229.399,47.225 227.774,48.849 227.774,50.854 C227.774,52.858 229.399,54.483 231.403,54.483 C233.408,54.483 235.033,52.858 235.033,50.854 C235.033,48.849 233.408,47.225 231.403,47.225"
                  id="Fill-1147"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M231.403,56.642 C229.399,56.642 227.774,58.266 227.774,60.271 C227.774,62.276 229.399,63.901 231.403,63.901 C233.408,63.901 235.033,62.276 235.033,60.271 C235.033,58.266 233.408,56.642 231.403,56.642"
                  id="Fill-1148"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,84.892 C209.593,84.892 207.967,86.517 207.967,88.522 C207.967,90.527 209.593,92.152 211.597,92.152 C213.602,92.152 215.226,90.527 215.226,88.522 C215.226,86.517 213.602,84.892 211.597,84.892"
                  id="Fill-1149"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,37.808 C239.303,37.808 237.677,39.432 237.677,41.436 C237.677,43.441 239.303,45.066 241.307,45.066 C243.312,45.066 244.936,43.441 244.936,41.436 C244.936,39.432 243.312,37.808 241.307,37.808"
                  id="Fill-1150"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,141.395 C209.593,141.395 207.967,143.02 207.967,145.024 C207.967,147.029 209.593,148.654 211.597,148.654 C213.602,148.654 215.226,147.029 215.226,145.024 C215.226,143.02 213.602,141.395 211.597,141.395"
                  id="Fill-1151"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,131.978 C209.593,131.978 207.967,133.603 207.967,135.607 C207.967,137.612 209.593,139.237 211.597,139.237 C213.602,139.237 215.226,137.612 215.226,135.607 C215.226,133.603 213.602,131.978 211.597,131.978"
                  id="Fill-1152"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,122.561 C209.593,122.561 207.967,124.186 207.967,126.19 C207.967,128.195 209.593,129.82 211.597,129.82 C213.602,129.82 215.226,128.195 215.226,126.19 C215.226,124.186 213.602,122.561 211.597,122.561"
                  id="Fill-1153"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,94.309 C209.593,94.309 207.967,95.935 207.967,97.939 C207.967,99.944 209.593,101.569 211.597,101.569 C213.602,101.569 215.226,99.944 215.226,97.939 C215.226,95.935 213.602,94.309 211.597,94.309"
                  id="Fill-1154"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,150.812 C209.593,150.812 207.967,152.437 207.967,154.441 C207.967,156.446 209.593,158.071 211.597,158.071 C213.602,158.071 215.226,156.446 215.226,154.441 C215.226,152.437 213.602,150.812 211.597,150.812"
                  id="Fill-1155"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,0.139 C199.69,0.139 198.064,1.764 198.064,3.769 C198.064,5.774 199.69,7.398 201.694,7.398 C203.698,7.398 205.324,5.774 205.324,3.769 C205.324,1.764 203.698,0.139 201.694,0.139"
                  id="Fill-1156"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,160.229 C209.593,160.229 207.967,161.854 207.967,163.859 C207.967,165.863 209.593,167.488 211.597,167.488 C213.602,167.488 215.226,165.863 215.226,163.859 C215.226,161.854 213.602,160.229 211.597,160.229"
                  id="Fill-1157"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,131.978 C219.496,131.978 217.871,133.603 217.871,135.607 C217.871,137.612 219.496,139.237 221.501,139.237 C223.505,139.237 225.13,137.612 225.13,135.607 C225.13,133.603 223.505,131.978 221.501,131.978"
                  id="Fill-1158"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,169.646 C209.593,169.646 207.967,171.271 207.967,173.275 C207.967,175.28 209.593,176.905 211.597,176.905 C213.602,176.905 215.226,175.28 215.226,173.275 C215.226,171.271 213.602,169.646 211.597,169.646"
                  id="Fill-1159"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,9.557 C199.69,9.557 198.064,11.181 198.064,13.185 C198.064,15.19 199.69,16.815 201.694,16.815 C203.698,16.815 205.324,15.19 205.324,13.185 C205.324,11.181 203.698,9.557 201.694,9.557"
                  id="Fill-1160"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M221.501,169.646 C219.496,169.646 217.871,171.271 217.871,173.275 C217.871,175.28 219.496,176.905 221.501,176.905 C223.505,176.905 225.13,175.28 225.13,173.275 C225.13,171.271 223.505,169.646 221.501,169.646"
                  id="Fill-1161"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,0.139 C209.593,0.139 207.967,1.764 207.967,3.769 C207.967,5.774 209.593,7.398 211.597,7.398 C213.602,7.398 215.226,5.774 215.226,3.769 C215.226,1.764 213.602,0.139 211.597,0.139"
                  id="Fill-1162"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,150.812 C219.496,150.812 217.871,152.437 217.871,154.441 C217.871,156.446 219.496,158.071 221.501,158.071 C223.505,158.071 225.13,156.446 225.13,154.441 C225.13,152.437 223.505,150.812 221.501,150.812"
                  id="Fill-1163"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M221.501,141.395 C219.496,141.395 217.871,143.02 217.871,145.024 C217.871,147.029 219.496,148.654 221.501,148.654 C223.505,148.654 225.13,147.029 225.13,145.024 C225.13,143.02 223.505,141.395 221.501,141.395"
                  id="Fill-1164"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,9.557 C209.593,9.557 207.967,11.181 207.967,13.185 C207.967,15.19 209.593,16.815 211.597,16.815 C213.602,16.815 215.226,15.19 215.226,13.185 C215.226,11.181 213.602,9.557 211.597,9.557"
                  id="Fill-1165"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,28.39 C209.593,28.39 207.967,30.016 207.967,32.02 C207.967,34.025 209.593,35.649 211.597,35.649 C213.602,35.649 215.226,34.025 215.226,32.02 C215.226,30.016 213.602,28.39 211.597,28.39"
                  id="Fill-1166"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M211.597,75.476 C209.593,75.476 207.967,77.1 207.967,79.105 C207.967,81.109 209.593,82.735 211.597,82.735 C213.602,82.735 215.226,81.109 215.226,79.105 C215.226,77.1 213.602,75.476 211.597,75.476"
                  id="Fill-1167"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,66.058 C209.593,66.058 207.967,67.683 207.967,69.688 C207.967,71.693 209.593,73.318 211.597,73.318 C213.602,73.318 215.226,71.693 215.226,69.688 C215.226,67.683 213.602,66.058 211.597,66.058"
                  id="Fill-1168"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M211.597,18.973 C209.593,18.973 207.967,20.598 207.967,22.603 C207.967,24.607 209.593,26.232 211.597,26.232 C213.602,26.232 215.226,24.607 215.226,22.603 C215.226,20.598 213.602,18.973 211.597,18.973"
                  id="Fill-1169"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M221.501,122.561 C219.496,122.561 217.871,124.186 217.871,126.19 C217.871,128.195 219.496,129.82 221.501,129.82 C223.505,129.82 225.13,128.195 225.13,126.19 C225.13,124.186 223.505,122.561 221.501,122.561"
                  id="Fill-1170"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,37.808 C269.012,37.808 267.387,39.432 267.387,41.436 C267.387,43.441 269.012,45.066 271.016,45.066 C273.021,45.066 274.646,43.441 274.646,41.436 C274.646,39.432 273.021,37.808 271.016,37.808"
                  id="Fill-1171"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,9.557 C269.012,9.557 267.387,11.181 267.387,13.185 C267.387,15.19 269.012,16.815 271.016,16.815 C273.021,16.815 274.646,15.19 274.646,13.185 C274.646,11.181 273.021,9.557 271.016,9.557"
                  id="Fill-1172"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,28.39 C239.303,28.39 237.677,30.016 237.677,32.02 C237.677,34.025 239.303,35.649 241.307,35.649 C243.312,35.649 244.936,34.025 244.936,32.02 C244.936,30.016 243.312,28.39 241.307,28.39"
                  id="Fill-1173"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,18.973 C269.012,18.973 267.387,20.598 267.387,22.603 C267.387,24.607 269.012,26.232 271.016,26.232 C273.021,26.232 274.646,24.607 274.646,22.603 C274.646,20.598 273.021,18.973 271.016,18.973"
                  id="Fill-1174"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,28.39 C269.012,28.39 267.387,30.016 267.387,32.02 C267.387,34.025 269.012,35.649 271.016,35.649 C273.021,35.649 274.646,34.025 274.646,32.02 C274.646,30.016 273.021,28.39 271.016,28.39"
                  id="Fill-1175"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,56.642 C269.012,56.642 267.387,58.266 267.387,60.271 C267.387,62.276 269.012,63.901 271.016,63.901 C273.021,63.901 274.646,62.276 274.646,60.271 C274.646,58.266 273.021,56.642 271.016,56.642"
                  id="Fill-1176"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,84.892 C269.012,84.892 267.387,86.517 267.387,88.522 C267.387,90.527 269.012,92.152 271.016,92.152 C273.021,92.152 274.646,90.527 274.646,88.522 C274.646,86.517 273.021,84.892 271.016,84.892"
                  id="Fill-1177"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,66.058 C269.012,66.058 267.387,67.683 267.387,69.688 C267.387,71.693 269.012,73.318 271.016,73.318 C273.021,73.318 274.646,71.693 274.646,69.688 C274.646,67.683 273.021,66.058 271.016,66.058"
                  id="Fill-1178"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,9.557 C259.108,9.557 257.484,11.181 257.484,13.185 C257.484,15.19 259.108,16.815 261.113,16.815 C263.118,16.815 264.743,15.19 264.743,13.185 C264.743,11.181 263.118,9.557 261.113,9.557"
                  id="Fill-1179"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,75.476 C269.012,75.476 267.387,77.1 267.387,79.105 C267.387,81.109 269.012,82.735 271.016,82.735 C273.021,82.735 274.646,81.109 274.646,79.105 C274.646,77.1 273.021,75.476 271.016,75.476"
                  id="Fill-1180"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,37.808 C278.915,37.808 277.29,39.432 277.29,41.436 C277.29,43.441 278.915,45.066 280.92,45.066 C282.925,45.066 284.549,43.441 284.549,41.436 C284.549,39.432 282.925,37.808 280.92,37.808"
                  id="Fill-1181"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,18.973 C259.108,18.973 257.484,20.598 257.484,22.603 C257.484,24.607 259.108,26.232 261.113,26.232 C263.118,26.232 264.743,24.607 264.743,22.603 C264.743,20.598 263.118,18.973 261.113,18.973"
                  id="Fill-1182"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,28.39 C278.915,28.39 277.29,30.016 277.29,32.02 C277.29,34.025 278.915,35.649 280.92,35.649 C282.925,35.649 284.549,34.025 284.549,32.02 C284.549,30.016 282.925,28.39 280.92,28.39"
                  id="Fill-1183"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,18.973 C278.915,18.973 277.29,20.598 277.29,22.603 C277.29,24.607 278.915,26.232 280.92,26.232 C282.925,26.232 284.549,24.607 284.549,22.603 C284.549,20.598 282.925,18.973 280.92,18.973"
                  id="Fill-1184"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M290.823,18.973 C288.818,18.973 287.193,20.598 287.193,22.603 C287.193,24.607 288.818,26.232 290.823,26.232 C292.827,26.232 294.453,24.607 294.453,22.603 C294.453,20.598 292.827,18.973 290.823,18.973"
                  id="Fill-1185"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M290.823,28.39 C288.818,28.39 287.193,30.016 287.193,32.02 C287.193,34.025 288.818,35.649 290.823,35.649 C292.827,35.649 294.453,34.025 294.453,32.02 C294.453,30.016 292.827,28.39 290.823,28.39"
                  id="Fill-1186"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,56.642 C278.915,56.642 277.29,58.266 277.29,60.271 C277.29,62.276 278.915,63.901 280.92,63.901 C282.925,63.901 284.549,62.276 284.549,60.271 C284.549,58.266 282.925,56.642 280.92,56.642"
                  id="Fill-1187"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,66.058 C278.915,66.058 277.29,67.683 277.29,69.688 C277.29,71.693 278.915,73.318 280.92,73.318 C282.925,73.318 284.549,71.693 284.549,69.688 C284.549,67.683 282.925,66.058 280.92,66.058"
                  id="Fill-1188"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,75.476 C278.915,75.476 277.29,77.1 277.29,79.105 C277.29,81.109 278.915,82.735 280.92,82.735 C282.925,82.735 284.549,81.109 284.549,79.105 C284.549,77.1 282.925,75.476 280.92,75.476"
                  id="Fill-1189"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,47.225 C278.915,47.225 277.29,48.849 277.29,50.854 C277.29,52.858 278.915,54.483 280.92,54.483 C282.925,54.483 284.549,52.858 284.549,50.854 C284.549,48.849 282.925,47.225 280.92,47.225"
                  id="Fill-1190"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M280.92,84.892 C278.915,84.892 277.29,86.517 277.29,88.522 C277.29,90.527 278.915,92.152 280.92,92.152 C282.925,92.152 284.549,90.527 284.549,88.522 C284.549,86.517 282.925,84.892 280.92,84.892"
                  id="Fill-1191"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M271.016,47.225 C269.012,47.225 267.387,48.849 267.387,50.854 C267.387,52.858 269.012,54.483 271.016,54.483 C273.021,54.483 274.646,52.858 274.646,50.854 C274.646,48.849 273.021,47.225 271.016,47.225"
                  id="Fill-1192"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,84.892 C249.206,84.892 247.58,86.517 247.58,88.522 C247.58,90.527 249.206,92.152 251.21,92.152 C253.215,92.152 254.84,90.527 254.84,88.522 C254.84,86.517 253.215,84.892 251.21,84.892"
                  id="Fill-1193"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,75.476 C249.206,75.476 247.58,77.1 247.58,79.105 C247.58,81.109 249.206,82.735 251.21,82.735 C253.215,82.735 254.84,81.109 254.84,79.105 C254.84,77.1 253.215,75.476 251.21,75.476"
                  id="Fill-1194"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,66.058 C249.206,66.058 247.58,67.683 247.58,69.688 C247.58,71.693 249.206,73.318 251.21,73.318 C253.215,73.318 254.84,71.693 254.84,69.688 C254.84,67.683 253.215,66.058 251.21,66.058"
                  id="Fill-1195"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,56.642 C249.206,56.642 247.58,58.266 247.58,60.271 C247.58,62.276 249.206,63.901 251.21,63.901 C253.215,63.901 254.84,62.276 254.84,60.271 C254.84,58.266 253.215,56.642 251.21,56.642"
                  id="Fill-1196"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,47.225 C249.206,47.225 247.58,48.849 247.58,50.854 C247.58,52.858 249.206,54.483 251.21,54.483 C253.215,54.483 254.84,52.858 254.84,50.854 C254.84,48.849 253.215,47.225 251.21,47.225"
                  id="Fill-1197"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,94.309 C249.206,94.309 247.58,95.935 247.58,97.939 C247.58,99.944 249.206,101.569 251.21,101.569 C253.215,101.569 254.84,99.944 254.84,97.939 C254.84,95.935 253.215,94.309 251.21,94.309"
                  id="Fill-1198"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M241.307,18.973 C239.303,18.973 237.677,20.598 237.677,22.603 C237.677,24.607 239.303,26.232 241.307,26.232 C243.312,26.232 244.936,24.607 244.936,22.603 C244.936,20.598 243.312,18.973 241.307,18.973"
                  id="Fill-1199"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,113.144 C249.206,113.144 247.58,114.769 247.58,116.773 C247.58,118.777 249.206,120.403 251.21,120.403 C253.215,120.403 254.84,118.777 254.84,116.773 C254.84,114.769 253.215,113.144 251.21,113.144"
                  id="Fill-1200"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,37.808 C249.206,37.808 247.58,39.432 247.58,41.436 C247.58,43.441 249.206,45.066 251.21,45.066 C253.215,45.066 254.84,43.441 254.84,41.436 C254.84,39.432 253.215,37.808 251.21,37.808"
                  id="Fill-1201"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,28.39 C259.108,28.39 257.484,30.016 257.484,32.02 C257.484,34.025 259.108,35.649 261.113,35.649 C263.118,35.649 264.743,34.025 264.743,32.02 C264.743,30.016 263.118,28.39 261.113,28.39"
                  id="Fill-1202"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,103.727 C249.206,103.727 247.58,105.352 247.58,107.356 C247.58,109.361 249.206,110.986 251.21,110.986 C253.215,110.986 254.84,109.361 254.84,107.356 C254.84,105.352 253.215,103.727 251.21,103.727"
                  id="Fill-1203"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,47.225 C259.108,47.225 257.484,48.849 257.484,50.854 C257.484,52.858 259.108,54.483 261.113,54.483 C263.118,54.483 264.743,52.858 264.743,50.854 C264.743,48.849 263.118,47.225 261.113,47.225"
                  id="Fill-1204"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M261.113,56.642 C259.108,56.642 257.484,58.266 257.484,60.271 C257.484,62.276 259.108,63.901 261.113,63.901 C263.118,63.901 264.743,62.276 264.743,60.271 C264.743,58.266 263.118,56.642 261.113,56.642"
                  id="Fill-1205"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M251.21,28.39 C249.206,28.39 247.58,30.016 247.58,32.02 C247.58,34.025 249.206,35.649 251.21,35.649 C253.215,35.649 254.84,34.025 254.84,32.02 C254.84,30.016 253.215,28.39 251.21,28.39"
                  id="Fill-1206"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,66.058 C259.108,66.058 257.484,67.683 257.484,69.688 C257.484,71.693 259.108,73.318 261.113,73.318 C263.118,73.318 264.743,71.693 264.743,69.688 C264.743,67.683 263.118,66.058 261.113,66.058"
                  id="Fill-1207"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M261.113,94.309 C259.108,94.309 257.484,95.935 257.484,97.939 C257.484,99.944 259.108,101.569 261.113,101.569 C263.118,101.569 264.743,99.944 264.743,97.939 C264.743,95.935 263.118,94.309 261.113,94.309"
                  id="Fill-1209"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M251.21,18.973 C249.206,18.973 247.58,20.598 247.58,22.603 C247.58,24.607 249.206,26.232 251.21,26.232 C253.215,26.232 254.84,24.607 254.84,22.603 C254.84,20.598 253.215,18.973 251.21,18.973"
                  id="Fill-1210"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M261.113,84.892 C259.108,84.892 257.484,86.517 257.484,88.522 C257.484,90.527 259.108,92.152 261.113,92.152 C263.118,92.152 264.743,90.527 264.743,88.522 C264.743,86.517 263.118,84.892 261.113,84.892"
                  id="Fill-1211"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M261.113,75.476 C259.108,75.476 257.484,77.1 257.484,79.105 C257.484,81.109 259.108,82.735 261.113,82.735 C263.118,82.735 264.743,81.109 264.743,79.105 C264.743,77.1 263.118,75.476 261.113,75.476"
                  id="Fill-1212"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,18.973 C199.69,18.973 198.064,20.598 198.064,22.603 C198.064,24.607 199.69,26.232 201.694,26.232 C203.698,26.232 205.324,24.607 205.324,22.603 C205.324,20.598 203.698,18.973 201.694,18.973"
                  id="Fill-1213"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,94.309 C160.076,94.309 158.452,95.935 158.452,97.939 C158.452,99.944 160.076,101.569 162.081,101.569 C164.085,101.569 165.711,99.944 165.711,97.939 C165.711,95.935 164.085,94.309 162.081,94.309"
                  id="Fill-1214"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,131.978 C160.076,131.978 158.452,133.603 158.452,135.607 C158.452,137.612 160.076,139.237 162.081,139.237 C164.085,139.237 165.711,137.612 165.711,135.607 C165.711,133.603 164.085,131.978 162.081,131.978"
                  id="Fill-1215"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,103.727 C160.076,103.727 158.452,105.352 158.452,107.356 C158.452,109.361 160.076,110.986 162.081,110.986 C164.085,110.986 165.711,109.361 165.711,107.356 C165.711,105.352 164.085,103.727 162.081,103.727"
                  id="Fill-1216"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,75.476 C160.076,75.476 158.452,77.1 158.452,79.105 C158.452,81.109 160.076,82.735 162.081,82.735 C164.085,82.735 165.711,81.109 165.711,79.105 C165.711,77.1 164.085,75.476 162.081,75.476"
                  id="Fill-1217"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,179.063 C160.076,179.063 158.452,180.688 158.452,182.693 C158.452,184.697 160.076,186.322 162.081,186.322 C164.085,186.322 165.711,184.697 165.711,182.693 C165.711,180.688 164.085,179.063 162.081,179.063"
                  id="Fill-1218"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,141.395 C160.076,141.395 158.452,143.02 158.452,145.024 C158.452,147.029 160.076,148.654 162.081,148.654 C164.085,148.654 165.711,147.029 165.711,145.024 C165.711,143.02 164.085,141.395 162.081,141.395"
                  id="Fill-1219"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,169.646 C160.076,169.646 158.452,171.271 158.452,173.275 C158.452,175.28 160.076,176.905 162.081,176.905 C164.085,176.905 165.711,175.28 165.711,173.275 C165.711,171.271 164.085,169.646 162.081,169.646"
                  id="Fill-1220"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,160.229 C160.076,160.229 158.452,161.854 158.452,163.859 C158.452,165.863 160.076,167.488 162.081,167.488 C164.085,167.488 165.711,165.863 165.711,163.859 C165.711,161.854 164.085,160.229 162.081,160.229"
                  id="Fill-1221"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,66.058 C160.076,66.058 158.452,67.683 158.452,69.688 C158.452,71.693 160.076,73.318 162.081,73.318 C164.085,73.318 165.711,71.693 165.711,69.688 C165.711,67.683 164.085,66.058 162.081,66.058"
                  id="Fill-1222"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,150.812 C160.076,150.812 158.452,152.437 158.452,154.441 C158.452,156.446 160.076,158.071 162.081,158.071 C164.085,158.071 165.711,156.446 165.711,154.441 C165.711,152.437 164.085,150.812 162.081,150.812"
                  id="Fill-1223"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,84.892 C160.076,84.892 158.452,86.517 158.452,88.522 C158.452,90.527 160.076,92.152 162.081,92.152 C164.085,92.152 165.711,90.527 165.711,88.522 C165.711,86.517 164.085,84.892 162.081,84.892"
                  id="Fill-1224"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,160.229 C169.98,160.229 168.355,161.854 168.355,163.859 C168.355,165.863 169.98,167.488 171.985,167.488 C173.989,167.488 175.613,165.863 175.613,163.859 C175.613,161.854 173.989,160.229 171.985,160.229"
                  id="Fill-1225"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,188.48 C169.98,188.48 168.355,190.106 168.355,192.11 C168.355,194.114 169.98,195.739 171.985,195.739 C173.989,195.739 175.613,194.114 175.613,192.11 C175.613,190.106 173.989,188.48 171.985,188.48"
                  id="Fill-1226"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,150.812 C169.98,150.812 168.355,152.437 168.355,154.441 C168.355,156.446 169.98,158.071 171.985,158.071 C173.989,158.071 175.613,156.446 175.613,154.441 C175.613,152.437 173.989,150.812 171.985,150.812"
                  id="Fill-1227"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,103.727 C169.98,103.727 168.355,105.352 168.355,107.356 C168.355,109.361 169.98,110.986 171.985,110.986 C173.989,110.986 175.613,109.361 175.613,107.356 C175.613,105.352 173.989,103.727 171.985,103.727"
                  id="Fill-1228"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,28.39 C199.69,28.39 198.064,30.016 198.064,32.02 C198.064,34.025 199.69,35.649 201.694,35.649 C203.698,35.649 205.324,34.025 205.324,32.02 C205.324,30.016 203.698,28.39 201.694,28.39"
                  id="Fill-1229"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,28.39 C160.076,28.39 158.452,30.016 158.452,32.02 C158.452,34.025 160.076,35.649 162.081,35.649 C164.085,35.649 165.711,34.025 165.711,32.02 C165.711,30.016 164.085,28.39 162.081,28.39"
                  id="Fill-1230"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,0.139 C160.076,0.139 158.452,1.764 158.452,3.769 C158.452,5.774 160.076,7.398 162.081,7.398 C164.085,7.398 165.711,5.774 165.711,3.769 C165.711,1.764 164.085,0.139 162.081,0.139"
                  id="Fill-1231"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,47.225 C160.076,47.225 158.452,48.849 158.452,50.854 C158.452,52.858 160.076,54.483 162.081,54.483 C164.085,54.483 165.711,52.858 165.711,50.854 C165.711,48.849 164.085,47.225 162.081,47.225"
                  id="Fill-1232"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,197.897 C169.98,197.897 168.355,199.523 168.355,201.527 C168.355,203.531 169.98,205.156 171.985,205.156 C173.989,205.156 175.613,203.531 175.613,201.527 C175.613,199.523 173.989,197.897 171.985,197.897"
                  id="Fill-1233"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,207.314 C169.98,207.314 168.355,208.939 168.355,210.944 C168.355,212.948 169.98,214.574 171.985,214.574 C173.989,214.574 175.613,212.948 175.613,210.944 C175.613,208.939 173.989,207.314 171.985,207.314"
                  id="Fill-1234"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,150.812 C150.173,150.812 148.548,152.437 148.548,154.441 C148.548,156.446 150.173,158.071 152.178,158.071 C154.183,158.071 155.808,156.446 155.808,154.441 C155.808,152.437 154.183,150.812 152.178,150.812"
                  id="Fill-1235"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,141.395 C150.173,141.395 148.548,143.02 148.548,145.024 C148.548,147.029 150.173,148.654 152.178,148.654 C154.183,148.654 155.808,147.029 155.808,145.024 C155.808,143.02 154.183,141.395 152.178,141.395"
                  id="Fill-1236"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,94.309 C169.98,94.309 168.355,95.935 168.355,97.939 C168.355,99.944 169.98,101.569 171.985,101.569 C173.989,101.569 175.613,99.944 175.613,97.939 C175.613,95.935 173.989,94.309 171.985,94.309"
                  id="Fill-1237"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M152.178,131.978 C150.173,131.978 148.548,133.603 148.548,135.607 C148.548,137.612 150.173,139.237 152.178,139.237 C154.183,139.237 155.808,137.612 155.808,135.607 C155.808,133.603 154.183,131.978 152.178,131.978"
                  id="Fill-1238"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M152.178,160.229 C150.173,160.229 148.548,161.854 148.548,163.859 C148.548,165.863 150.173,167.488 152.178,167.488 C154.183,167.488 155.808,165.863 155.808,163.859 C155.808,161.854 154.183,160.229 152.178,160.229"
                  id="Fill-1239"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,122.561 C150.173,122.561 148.548,124.186 148.548,126.19 C148.548,128.195 150.173,129.82 152.178,129.82 C154.183,129.82 155.808,128.195 155.808,126.19 C155.808,124.186 154.183,122.561 152.178,122.561"
                  id="Fill-1240"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,188.48 C150.173,188.48 148.548,190.106 148.548,192.11 C148.548,194.114 150.173,195.739 152.178,195.739 C154.183,195.739 155.808,194.114 155.808,192.11 C155.808,190.106 154.183,188.48 152.178,188.48"
                  id="Fill-1241"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M152.178,197.897 C150.173,197.897 148.548,199.523 148.548,201.527 C148.548,203.531 150.173,205.156 152.178,205.156 C154.183,205.156 155.808,203.531 155.808,201.527 C155.808,199.523 154.183,197.897 152.178,197.897"
                  id="Fill-1242"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,113.144 C150.173,113.144 148.548,114.769 148.548,116.773 C148.548,118.777 150.173,120.403 152.178,120.403 C154.183,120.403 155.808,118.777 155.808,116.773 C155.808,114.769 154.183,113.144 152.178,113.144"
                  id="Fill-1243"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M152.178,179.063 C150.173,179.063 148.548,180.688 148.548,182.693 C148.548,184.697 150.173,186.322 152.178,186.322 C154.183,186.322 155.808,184.697 155.808,182.693 C155.808,180.688 154.183,179.063 152.178,179.063"
                  id="Fill-1244"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,169.646 C150.173,169.646 148.548,171.271 148.548,173.275 C148.548,175.28 150.173,176.905 152.178,176.905 C154.183,176.905 155.808,175.28 155.808,173.275 C155.808,171.271 154.183,169.646 152.178,169.646"
                  id="Fill-1245"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,75.476 C150.173,75.476 148.548,77.1 148.548,79.105 C148.548,81.109 150.173,82.735 152.178,82.735 C154.183,82.735 155.808,81.109 155.808,79.105 C155.808,77.1 154.183,75.476 152.178,75.476"
                  id="Fill-1246"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,0.139 C150.173,0.139 148.548,1.764 148.548,3.769 C148.548,5.774 150.173,7.398 152.178,7.398 C154.183,7.398 155.808,5.774 155.808,3.769 C155.808,1.764 154.183,0.139 152.178,0.139"
                  id="Fill-1247"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,28.39 C150.173,28.39 148.548,30.016 148.548,32.02 C148.548,34.025 150.173,35.649 152.178,35.649 C154.183,35.649 155.808,34.025 155.808,32.02 C155.808,30.016 154.183,28.39 152.178,28.39"
                  id="Fill-1248"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M162.081,207.314 C160.076,207.314 158.452,208.939 158.452,210.944 C158.452,212.948 160.076,214.574 162.081,214.574 C164.085,214.574 165.711,212.948 165.711,210.944 C165.711,208.939 164.085,207.314 162.081,207.314"
                  id="Fill-1249"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,197.897 C160.076,197.897 158.452,199.523 158.452,201.527 C158.452,203.531 160.076,205.156 162.081,205.156 C164.085,205.156 165.711,203.531 165.711,201.527 C165.711,199.523 164.085,197.897 162.081,197.897"
                  id="Fill-1250"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,47.225 C150.173,47.225 148.548,48.849 148.548,50.854 C148.548,52.858 150.173,54.483 152.178,54.483 C154.183,54.483 155.808,52.858 155.808,50.854 C155.808,48.849 154.183,47.225 152.178,47.225"
                  id="Fill-1251"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,84.892 C150.173,84.892 148.548,86.517 148.548,88.522 C148.548,90.527 150.173,92.152 152.178,92.152 C154.183,92.152 155.808,90.527 155.808,88.522 C155.808,86.517 154.183,84.892 152.178,84.892"
                  id="Fill-1252"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,94.309 C150.173,94.309 148.548,95.935 148.548,97.939 C148.548,99.944 150.173,101.569 152.178,101.569 C154.183,101.569 155.808,99.944 155.808,97.939 C155.808,95.935 154.183,94.309 152.178,94.309"
                  id="Fill-1253"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M152.178,103.727 C150.173,103.727 148.548,105.352 148.548,107.356 C148.548,109.361 150.173,110.986 152.178,110.986 C154.183,110.986 155.808,109.361 155.808,107.356 C155.808,105.352 154.183,103.727 152.178,103.727"
                  id="Fill-1254"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M162.081,188.48 C160.076,188.48 158.452,190.106 158.452,192.11 C158.452,194.114 160.076,195.739 162.081,195.739 C164.085,195.739 165.711,194.114 165.711,192.11 C165.711,190.106 164.085,188.48 162.081,188.48"
                  id="Fill-1255"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,141.395 C169.98,141.395 168.355,143.02 168.355,145.024 C168.355,147.029 169.98,148.654 171.985,148.654 C173.989,148.654 175.613,147.029 175.613,145.024 C175.613,143.02 173.989,141.395 171.985,141.395"
                  id="Fill-1256"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,160.229 C189.786,160.229 188.162,161.854 188.162,163.859 C188.162,165.863 189.786,167.488 191.79,167.488 C193.795,167.488 195.42,165.863 195.42,163.859 C195.42,161.854 193.795,160.229 191.79,160.229"
                  id="Fill-1257"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,56.642 C189.786,56.642 188.162,58.266 188.162,60.271 C188.162,62.276 189.786,63.901 191.79,63.901 C193.795,63.901 195.42,62.276 195.42,60.271 C195.42,58.266 193.795,56.642 191.79,56.642"
                  id="Fill-1258"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,9.557 C189.786,9.557 188.162,11.181 188.162,13.185 C188.162,15.19 189.786,16.815 191.79,16.815 C193.795,16.815 195.42,15.19 195.42,13.185 C195.42,11.181 193.795,9.557 191.79,9.557"
                  id="Fill-1259"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,18.973 C189.786,18.973 188.162,20.598 188.162,22.603 C188.162,24.607 189.786,26.232 191.79,26.232 C193.795,26.232 195.42,24.607 195.42,22.603 C195.42,20.598 193.795,18.973 191.79,18.973"
                  id="Fill-1260"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,113.144 C189.786,113.144 188.162,114.769 188.162,116.773 C188.162,118.777 189.786,120.403 191.79,120.403 C193.795,120.403 195.42,118.777 195.42,116.773 C195.42,114.769 193.795,113.144 191.79,113.144"
                  id="Fill-1261"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,122.561 C189.786,122.561 188.162,124.186 188.162,126.19 C188.162,128.195 189.786,129.82 191.79,129.82 C193.795,129.82 195.42,128.195 195.42,126.19 C195.42,124.186 193.795,122.561 191.79,122.561"
                  id="Fill-1262"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,84.892 C169.98,84.892 168.355,86.517 168.355,88.522 C168.355,90.527 169.98,92.152 171.985,92.152 C173.989,92.152 175.613,90.527 175.613,88.522 C175.613,86.517 173.989,84.892 171.985,84.892"
                  id="Fill-1263"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,179.063 C199.69,179.063 198.064,180.688 198.064,182.693 C198.064,184.697 199.69,186.322 201.694,186.322 C203.698,186.322 205.324,184.697 205.324,182.693 C205.324,180.688 203.698,179.063 201.694,179.063"
                  id="Fill-1264"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,150.812 C189.786,150.812 188.162,152.437 188.162,154.441 C188.162,156.446 189.786,158.071 191.79,158.071 C193.795,158.071 195.42,156.446 195.42,154.441 C195.42,152.437 193.795,150.812 191.79,150.812"
                  id="Fill-1265"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,131.978 C189.786,131.978 188.162,133.603 188.162,135.607 C188.162,137.612 189.786,139.237 191.79,139.237 C193.795,139.237 195.42,137.612 195.42,135.607 C195.42,133.603 193.795,131.978 191.79,131.978"
                  id="Fill-1266"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,66.058 C189.786,66.058 188.162,67.683 188.162,69.688 C188.162,71.693 189.786,73.318 191.79,73.318 C193.795,73.318 195.42,71.693 195.42,69.688 C195.42,67.683 193.795,66.058 191.79,66.058"
                  id="Fill-1267"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,113.144 C199.69,113.144 198.064,114.769 198.064,116.773 C198.064,118.777 199.69,120.403 201.694,120.403 C203.698,120.403 205.324,118.777 205.324,116.773 C205.324,114.769 203.698,113.144 201.694,113.144"
                  id="Fill-1268"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,75.476 C199.69,75.476 198.064,77.1 198.064,79.105 C198.064,81.109 199.69,82.735 201.694,82.735 C203.698,82.735 205.324,81.109 205.324,79.105 C205.324,77.1 203.698,75.476 201.694,75.476"
                  id="Fill-1269"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,66.058 C199.69,66.058 198.064,67.683 198.064,69.688 C198.064,71.693 199.69,73.318 201.694,73.318 C203.698,73.318 205.324,71.693 205.324,69.688 C205.324,67.683 203.698,66.058 201.694,66.058"
                  id="Fill-1270"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,37.808 C199.69,37.808 198.064,39.432 198.064,41.436 C198.064,43.441 199.69,45.066 201.694,45.066 C203.698,45.066 205.324,43.441 205.324,41.436 C205.324,39.432 203.698,37.808 201.694,37.808"
                  id="Fill-1271"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M201.694,122.561 C199.69,122.561 198.064,124.186 198.064,126.19 C198.064,128.195 199.69,129.82 201.694,129.82 C203.698,129.82 205.324,128.195 205.324,126.19 C205.324,124.186 203.698,122.561 201.694,122.561"
                  id="Fill-1272"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,141.395 C199.69,141.395 198.064,143.02 198.064,145.024 C198.064,147.029 199.69,148.654 201.694,148.654 C203.698,148.654 205.324,147.029 205.324,145.024 C205.324,143.02 203.698,141.395 201.694,141.395"
                  id="Fill-1273"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,160.229 C199.69,160.229 198.064,161.854 198.064,163.859 C198.064,165.863 199.69,167.488 201.694,167.488 C203.698,167.488 205.324,165.863 205.324,163.859 C205.324,161.854 203.698,160.229 201.694,160.229"
                  id="Fill-1274"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,150.812 C199.69,150.812 198.064,152.437 198.064,154.441 C198.064,156.446 199.69,158.071 201.694,158.071 C203.698,158.071 205.324,156.446 205.324,154.441 C205.324,152.437 203.698,150.812 201.694,150.812"
                  id="Fill-1275"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,169.646 C199.69,169.646 198.064,171.271 198.064,173.275 C198.064,175.28 199.69,176.905 201.694,176.905 C203.698,176.905 205.324,175.28 205.324,173.275 C205.324,171.271 203.698,169.646 201.694,169.646"
                  id="Fill-1276"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M201.694,131.978 C199.69,131.978 198.064,133.603 198.064,135.607 C198.064,137.612 199.69,139.237 201.694,139.237 C203.698,139.237 205.324,137.612 205.324,135.607 C205.324,133.603 203.698,131.978 201.694,131.978"
                  id="Fill-1277"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="4s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,141.395 C189.786,141.395 188.162,143.02 188.162,145.024 C188.162,147.029 189.786,148.654 191.79,148.654 C193.795,148.654 195.42,147.029 195.42,145.024 C195.42,143.02 193.795,141.395 191.79,141.395"
                  id="Fill-1278"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,9.557 C169.98,9.557 168.355,11.181 168.355,13.185 C168.355,15.19 169.98,16.815 171.985,16.815 C173.989,16.815 175.613,15.19 175.613,13.185 C175.613,11.181 173.989,9.557 171.985,9.557"
                  id="Fill-1279"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,0.139 C169.98,0.139 168.355,1.764 168.355,3.769 C168.355,5.774 169.98,7.398 171.985,7.398 C173.989,7.398 175.613,5.774 175.613,3.769 C175.613,1.764 173.989,0.139 171.985,0.139"
                  id="Fill-1280"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,169.646 C189.786,169.646 188.162,171.271 188.162,173.275 C188.162,175.28 189.786,176.905 191.79,176.905 C193.795,176.905 195.42,175.28 195.42,173.275 C195.42,171.271 193.795,169.646 191.79,169.646"
                  id="Fill-1281"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,207.314 C179.883,207.314 178.258,208.939 178.258,210.944 C178.258,212.948 179.883,214.574 181.887,214.574 C183.892,214.574 185.517,212.948 185.517,210.944 C185.517,208.939 183.892,207.314 181.887,207.314"
                  id="Fill-1282"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,197.897 C179.883,197.897 178.258,199.523 178.258,201.527 C178.258,203.531 179.883,205.156 181.887,205.156 C183.892,205.156 185.517,203.531 185.517,201.527 C185.517,199.523 183.892,197.897 181.887,197.897"
                  id="Fill-1283"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M181.887,216.731 C179.883,216.731 178.258,218.357 178.258,220.361 C178.258,222.365 179.883,223.99 181.887,223.99 C183.892,223.99 185.517,222.365 185.517,220.361 C185.517,218.357 183.892,216.731 181.887,216.731"
                  id="Fill-1284"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="2s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,75.476 C169.98,75.476 168.355,77.1 168.355,79.105 C168.355,81.109 169.98,82.735 171.985,82.735 C173.989,82.735 175.613,81.109 175.613,79.105 C175.613,77.1 173.989,75.476 171.985,75.476"
                  id="Fill-1285"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,28.39 C169.98,28.39 168.355,30.016 168.355,32.02 C168.355,34.025 169.98,35.649 171.985,35.649 C173.989,35.649 175.613,34.025 175.613,32.02 C175.613,30.016 173.989,28.39 171.985,28.39"
                  id="Fill-1286"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M171.985,47.225 C169.98,47.225 168.355,48.849 168.355,50.854 C168.355,52.858 169.98,54.483 171.985,54.483 C173.989,54.483 175.613,52.858 175.613,50.854 C175.613,48.849 173.989,47.225 171.985,47.225"
                  id="Fill-1287"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M171.985,37.808 C169.98,37.808 168.355,39.432 168.355,41.436 C168.355,43.441 169.98,45.066 171.985,45.066 C173.989,45.066 175.613,43.441 175.613,41.436 C175.613,39.432 173.989,37.808 171.985,37.808"
                  id="Fill-1288"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,188.48 C179.883,188.48 178.258,190.106 178.258,192.11 C178.258,194.114 179.883,195.739 181.887,195.739 C183.892,195.739 185.517,194.114 185.517,192.11 C185.517,190.106 183.892,188.48 181.887,188.48"
                  id="Fill-1289"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,188.48 C189.786,188.48 188.162,190.106 188.162,192.11 C188.162,194.114 189.786,195.739 191.79,195.739 C193.795,195.739 195.42,194.114 195.42,192.11 C195.42,190.106 193.795,188.48 191.79,188.48"
                  id="Fill-1290"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M191.79,197.897 C189.786,197.897 188.162,199.523 188.162,201.527 C188.162,203.531 189.786,205.156 191.79,205.156 C193.795,205.156 195.42,203.531 195.42,201.527 C195.42,199.523 193.795,197.897 191.79,197.897"
                  id="Fill-1291"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,37.808 C179.883,37.808 178.258,39.432 178.258,41.436 C178.258,43.441 179.883,45.066 181.887,45.066 C183.892,45.066 185.517,43.441 185.517,41.436 C185.517,39.432 183.892,37.808 181.887,37.808"
                  id="Fill-1292"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,160.229 C179.883,160.229 178.258,161.854 178.258,163.859 C178.258,165.863 179.883,167.488 181.887,167.488 C183.892,167.488 185.517,165.863 185.517,163.859 C185.517,161.854 183.892,160.229 181.887,160.229"
                  id="Fill-1293"
                  fill="#8a6bea"
                >
                  <animate
                    attributeType="CSS"
                    attributeName="fill"
                    values="#f1f1f3;#8a6bea;#f1f1f3"
                    dur="3s"
                    repeatCount="indefinite"
                  />
                </path>
                <path
                  d="M191.79,179.063 C189.786,179.063 188.162,180.688 188.162,182.693 C188.162,184.697 189.786,186.323 191.79,186.323 C193.795,186.323 195.42,184.697 195.42,182.693 C195.42,180.688 193.795,179.063 191.79,179.063"
                  id="Fill-1294"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,75.476 C179.883,75.476 178.258,77.1 178.258,79.105 C178.258,81.109 179.883,82.735 181.887,82.735 C183.892,82.735 185.517,81.109 185.517,79.105 C185.517,77.1 183.892,75.476 181.887,75.476"
                  id="Fill-1295"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,141.395 C179.883,141.395 178.258,143.02 178.258,145.024 C178.258,147.029 179.883,148.654 181.887,148.654 C183.892,148.654 185.517,147.029 185.517,145.024 C185.517,143.02 183.892,141.395 181.887,141.395"
                  id="Fill-1296"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,150.812 C179.883,150.812 178.258,152.437 178.258,154.441 C178.258,156.446 179.883,158.071 181.887,158.071 C183.892,158.071 185.517,156.446 185.517,154.441 C185.517,152.437 183.892,150.812 181.887,150.812"
                  id="Fill-1297"
                  fill="#8a6bea"
                ></path>
                <path
                  d="M181.887,84.892 C179.883,84.892 178.258,86.517 178.258,88.522 C178.258,90.527 179.883,92.152 181.887,92.152 C183.892,92.152 185.517,90.527 185.517,88.522 C185.517,86.517 183.892,84.892 181.887,84.892"
                  id="Fill-1298"
                  fill="#8a6bea"
                ></path>
              </g>
            </svg>
          </div>
        </Grid>
      </Container>
    </Box>
  );
}
