import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";

export default function AddContactDialog({ open, setOpen, handleClick }) {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
  });

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <DialogTitle>{"Create Contact"}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} width={"100%"}>
          <TextField
            InputProps={{
              autoComplete: "off",
            }}
            size="small"
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Name"
            value={contact.name}
            onChange={(e) => setContact({ ...contact, name: e.target.value })}
          />
          <TextField
            InputProps={{
              autoComplete: "off",
            }}
            size="small"
            fullWidth
            type="email"
            variant="outlined"
            placeholder="Email"
            value={contact.email}
            onChange={(e) => setContact({ ...contact, email: e.target.value })}
          />
          <TextField
            InputProps={{
              autoComplete: "off",
            }}
            size="small"
            fullWidth
            type="number"
            variant="outlined"
            placeholder="Phone"
            value={contact.phone}
            onChange={(e) => setContact({ ...contact, phone: e.target.value })}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          onClick={() => handleClick(contact)}
        >
          Add
        </Button>

        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
