import * as React from "react";
import { Box, Grid } from "@mui/material";
import SecondaryTabs from "../../../components/tabs/SecondaryTabs";
import ContactSettings from "./Settings";
import AllContacts from "./AllContacts";
import Instructions from "../../../components/alerts/Instructions";

const description = `View all the contacts that have interacted with your chatbot. To start collecting contacts, simply go to the "Settings" tab and enable the "Collect Contacts" option. Then select which information you want to collect from your contacts.`;

export default function Contacts() {
  const [tabValue, setTabValue] = React.useState("All Contacts");

  const tabs = ["All Contacts", "Settings"];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ pb: 20 }}>
      <Instructions description={description} id="contacts" />
      <SecondaryTabs
        tabValue={tabValue}
        tabs={tabs}
        handleChange={handleChange}
      />
      <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
        {tabValue === "Settings" && (
          <Grid item xs={12} md={12}>
            <ContactSettings />
          </Grid>
        )}
        {tabValue === "All Contacts" && <AllContacts />}
      </Grid>
    </Box>
  );
}
