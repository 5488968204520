import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
} from "@mui/material";
import {
  FileCopy as FileCopyIcon,
  OpenInNewRounded,
} from "@mui/icons-material";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";

export default function ShareDialog({ open, setOpen }) {
  const { chatbotData } = useContext(ChatbotContext);
  const { chatbotId } = chatbotData || {
    chatbotId: "",
  };
  const shareUrl = `https://demo.diddo.chat/chatbot/${chatbotId}`;

  const [copied, setCopied] = useState(false);

  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const goToChatbot = () => {
    window.open(shareUrl, "_blank");
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          padding: 0,
        },
      }}
    >
      <DialogTitle>{"Share Your Chatbot"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Use this link to access the chatbot
        </DialogContentText>
        <Paper sx={{ my: 2, px: 2 }}>
          <Box
            component="pre"
            style={{
              overflowY: "auto",
              whiteSpace: "pre-wrap",
              p: 2,
            }}
          >
            {shareUrl}
          </Box>
        </Paper>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 1, mr: 1 }}
          endIcon={!copied && <FileCopyIcon fontSize="small" />}
          onClick={() => handleCopy(shareUrl)}
        >
          {copied ? "Copied!" : "Copy Link"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 1, ml: 2 }}
          endIcon={<OpenInNewRounded fontSize="small" />}
          onClick={goToChatbot}
        >
          Go to Chatbot
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
