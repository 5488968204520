import {
  SiAsana,
  SiAtlassian,
  SiDiscord,
  SiDropbox,
  SiGithub,
  SiGmail,
  SiGooglesheets,
  SiLinkedin,
  SiLoom,
  SiMailchimp,
  SiMeta,
  SiSalesforce,
  SiShopify,
  SiSlack,
  SiSquarespace,
  SiWordpress,
  SiX,
  SiZoom,
} from "@icons-pack/react-simple-icons";
import React from "react";
import HeaderText from "../../components/layout/HeaderText";
import { Container } from "@mui/material";

const iconStyles = {
  width: "20vw",
  height: "10vh",
  padding: "20px",
  background:
    "linear-gradient(rgba(20, 20, 20, 0.539) 0%, rgba(14, 14, 14, 0.8) 100%)",
  fill: "white",
  display: "grid",
  placeItems: "center",
  borderBottomWidth: "0px",
  borderColor: "rgba(255, 255, 255, 0.2)",
  borderLeftWidth: "1px",
  borderRightWidth: "1px",
  borderStyle: "solid",
  borderTopWidth: "1px",
  borderRadius: "8px",
  boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
};

const Integrations = () => {
  return (
    <Container maxWidth="md" sx={{ my: 10 }}>
      <HeaderText
        title="Integrations"
        topLine="Integrate with"
        bottomLine="3,000+ applications"
      />
      <style>{`
        .marquee {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          gap: var(--gap);
        }
        @media (max-width: 768px) {
          .marquee {
            flex-direction: row;
          }
        }
        .marquee__group {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: flex-start;
          gap: var(--gap);
          min-width: 200%;
          --gap: 20px;
          --duration: 60s;
          --scroll-start: 0;
          --scroll-end: calc(-50% - var(--gap));
        }
        .marquee__group.scroll-left {
          animation: scroll-left var(--duration) linear infinite;
        }
        .marquee__group.scroll-right {
          animation: scroll-right var(--duration) linear infinite;
        }
        @keyframes scroll-left {
          from {
            transform: translateX(var(--scroll-start));
          }
          to {
            transform: translateX(var(--scroll-end));
          }
        }
        @keyframes scroll-right {
          from {
            transform: translateX(var(--scroll-end));
          }
          to {
            transform: translateX(var(--scroll-start));
          }
        }
      `}</style>
      <div className="marquee">
        <div className="marquee__group scroll-left">
          <SiGmail style={iconStyles} />
          <SiShopify style={iconStyles} />
          <SiLinkedin style={iconStyles} />
          <SiZoom style={iconStyles} />
          <SiMailchimp style={iconStyles} />
          <SiMeta style={iconStyles} />
          <SiSquarespace style={iconStyles} />
          <SiDropbox style={iconStyles} />
          <SiAtlassian style={iconStyles} />
          <SiGmail style={iconStyles} />
          <SiShopify style={iconStyles} />
          <SiLinkedin style={iconStyles} />
          <SiZoom style={iconStyles} />
          <SiMailchimp style={iconStyles} />
          <SiMeta style={iconStyles} />
          <SiSquarespace style={iconStyles} />
          <SiDropbox style={iconStyles} />
          <SiAtlassian style={iconStyles} />
        </div>
        <div
          aria-hidden="true"
          className="marquee__group scroll-right"
          style={{ marginTop: "20px" }}
        >
          <SiGooglesheets style={iconStyles} />
          <SiGithub style={iconStyles} />
          <SiX style={iconStyles} />
          <SiWordpress style={iconStyles} />
          <SiDiscord style={iconStyles} />
          <SiLoom style={iconStyles} />
          <SiAsana style={iconStyles} />
          <SiSalesforce style={iconStyles} />
          <SiSlack style={iconStyles} />
          <SiGooglesheets style={iconStyles} />
          <SiGithub style={iconStyles} />
          <SiX style={iconStyles} />
          <SiWordpress style={iconStyles} />
          <SiDiscord style={iconStyles} />
          <SiLoom style={iconStyles} />
          <SiAsana style={iconStyles} />
          <SiSalesforce style={iconStyles} />
          <SiSlack style={iconStyles} />
        </div>
      </div>
    </Container>
  );
};

export default Integrations;
