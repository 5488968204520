import React from "react";
import { Fade, Typography, Button, Box, Chip, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ mt: 7 }}>
      <Box textAlign="center" pb={2}>
        <Fade in timeout={1000}>
          <Chip
            size="small"
            label={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Chip
                  label="New"
                  size="small"
                  color="primary"
                  sx={{
                    color: "white",
                    fontWeight: "700",
                    mr: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                  }}
                >
                  Link your chatbot to a custom domain
                </Typography>
              </Box>
            }
            color="primary"
            sx={{
              background:
                "linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 100%)",
              border: "1px solid #ffffff1f",
              fontWeight: "700",
              height: "auto",
              borderRadius: "50px",
              p: 1,
              mb: 4,
            }}
          />
        </Fade>
        <Fade in timeout={1000}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "700",
              fontSize: {
                xs: "2.5rem",
                sm: "2.5rem",
                md: "4rem",
                lg: "4rem",
                xl: "4rem",
              },
            }}
          >
            Empower Your Website
          </Typography>
        </Fade>
        <Fade in timeout={2000}>
          <Box
            component="h3"
            sx={{
              m: 0,
              background: "linear-gradient(45deg, #ff70ac, #8a6bea)",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: "700",
              fontSize: {
                xs: "2.5rem",
                sm: "2.5rem",
                md: "4rem",
                lg: "4rem",
                xl: "4rem",
              },
              position: "relative",
              top: "-1.5rem",
            }}
          >
            with Custom AI Agents
          </Box>
        </Fade>
        <Box maxWidth="sm" mx="auto" sx={{ mb: 2, mt: 0 }}>
          <Fade in timeout={4000}>
            <Typography variant="body1" paragraph>
              Unlock the potential of AI for your business with Diddo. Create and deploy custom AI agents seamlessly into your website, enhancing your online presence with intelligent, tailored assistance that caters to your unique business needs.
            </Typography>
          </Fade>
          <Fade in timeout={4000}>
            <Box mt={5}>
              <Button
                size="medium"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                Start free trial
              </Button>
              {/* <Button variant="contained" color="secondary" sx={{ ml: 2 }}>
                View Demo
              </Button> */}
            </Box>
          </Fade>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "white",
            mt: 2,
            mb: 2,
          }}
        >
          Ask Me Anything About Diddo
        </Typography>
        <svg class="arrows">
          <path class="a1" d="M0 0 L15 16 L30 0"></path>
          <path class="a2" d="M0 10 L15 26 L30 10"></path>
          <path class="a3" d="M0 20 L15 36 L30 20"></path>
        </svg>
      </Box>

      {/* Hero Image */}
      {/* <YourModalVideoComponent /> */}
    </Container>
  );
}