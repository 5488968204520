import React from "react";
import { createRoot } from "react-dom/client";

import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import "./styles/styles.css";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { ChatbotProvider } from "./contexts/ChatbotContext";
import { AuthProvider } from "./contexts/AuthContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <SnackbarProvider>
        <AuthProvider>
          <ChatbotProvider>
            <RouterProvider router={router} />
          </ChatbotProvider>
        </AuthProvider>
      </SnackbarProvider>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
