import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import { CheckRounded, FileCopyRounded } from "@mui/icons-material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import UpgradeButton from "../../../../components/buttons/UpgradeButton";

const ChatbotSettings = ({ formData }) => {
  const {
    chatbotName,
    setChatbotName,
    basePrompt,
    setBasePrompt,
    modelName,
    setModelName,
    visibility,
    setVisibility,
    allowedDomains,
    setAllowedDomains,
  } = formData;
  const navigate = useNavigate();
  const { tokenId } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, setChatbotData, setChatbots, subscriptionSettings } =
    useContext(ChatbotContext);
  const { maxCharacters, canUseGPT4 } = subscriptionSettings || {};
  const {
    userId,
    chatbotId,
    filesCharacterCount,
    textCharacterCount,
    questionsCharacterCount,
    websitesCharacterCount,
    audioCharacterCount,
  } = chatbotData || {};
  const [isChanged, setIsChanged] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [permanentlyDelete, setPermanentlyDelete] = useState("");
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleSave = debounce(async () => {
    setIsSaving(true);

    const updates = {
      chatbotName: chatbotName,
      basePrompt: basePrompt,
      modelName: modelName,
      visibility: visibility,
      allowedDomains: allowedDomains,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot`,
        {
          chatbotId: chatbotId,
          chatbotUpdates: updates,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      console.log(response.data);
      setChatbotData({
        ...chatbotData,
        ...updates,
      });

      setIsChanged("");
      showAlert(" saved successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error saving ", "error");
    }
    setIsSaving(false);
  }, 1000);

  const handleDeleteChatbot = debounce(async () => {
    if (permanentlyDelete !== "permanently delete") return;
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chatbot`,
        {
          data: {
            userId: userId,
            chatbotId: chatbotId,
          },
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbots(res.data.chatbots);
      showAlert("Chatbot deleted successfully!");
      navigate("/chatbots");
    } catch (error) {
      console.error(error);
      showAlert("Error deleting chatbot", "error");
    }
  }, 300);

  const handleCopyId = () => {
    navigator.clipboard.writeText(chatbotId);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const handleAllowedDomainsChange = (event) => {
    const domains = event.target.value.split(/\n+/);
    setAllowedDomains(domains);
    setChatbotData({
      ...chatbotData,
      allowedDomains: domains,
    });
    setIsChanged("access");
  };

  return (
    <Box>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          General
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Chatbot ID
        </InputLabel>
        <Stack direction="row" spacing={2}>
          <Typography>{chatbotId}</Typography>
          <IconButton
            variant="outlined"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ p: 0, width: "10px" }}
            onClick={handleCopyId}
          >
            {!copied ? (
              <FileCopyRounded fontSize="small" />
            ) : (
              <CheckRounded fontSize="small" />
            )}
          </IconButton>
        </Stack>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Number of Characters
        </InputLabel>
        <Typography>
          {new Intl.NumberFormat("en-US").format(
            (textCharacterCount || 0) +
              (questionsCharacterCount || 0) +
              (filesCharacterCount || 0) +
              (websitesCharacterCount || 0) +
              (audioCharacterCount || 0)
          )}{" "}
          / {new Intl.NumberFormat("en-US").format(maxCharacters)}
        </Typography>
        {/* <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Number of Messages Sent
        </InputLabel>

        <Typography>
          {new Intl.NumberFormat("en-US").format(messagesCount || 0)} /{" "}
          {new Intl.NumberFormat("en-US").format(maxMessages)}
        </Typography> */}
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Chatbot Name
        </InputLabel>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Chatbot Name"
          value={chatbotName}
          name="chatbotName"
          onChange={(e) => {
            setChatbotName(e.target.value);
            setIsChanged("general");
          }}
        />
        <Button
          onClick={handleSave}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 3, mb: 1 }}
          disabled={isChanged !== "general" || isSaving}
          endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
        >
          Save
        </Button>
      </Paper>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
          LLM
        </Typography>
        <Typography variant="caption">
          The LLM is the AI that will generate responses to user messages.
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Base Prompt
        </InputLabel>
        <Typography variant="caption">
          The base prompt is the text that the AI will use to generate
          responses. The AI will use the base prompt to generate responses to
          user messages. It is recommended to keep the base prompt short and
          simple. The base prompt should be a few sentences at most. The base
          prompt should be written in the same style as the responses that the
          AI will generate. For example, if the AI will generate responses in
          the style of a customer service agent, then the base prompt should be
          written in the style of a customer service agent.
        </Typography>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          sx={{ mt: 2 }}
          size="small"
          fullWidth
          type="text"
          multiline
          rows={7}
          variant="outlined"
          placeholder="Base Prompt"
          value={basePrompt}
          name="basePrompt"
          onChange={(e) => {
            setBasePrompt(e.target.value);
            setIsChanged("llm");
          }}
        />
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>Model</InputLabel>
        <Typography variant="caption">
          The model is the AI that will generate responses to user messages.
          Different models have different capabilities and different
          performance. The gpt-4o-mini model is the default model and is
          recommended for most use cases. The gpt-4o model is the most powerful
          model but is only available to users with a paid subscription.
        </Typography>
        <br />
        <Select
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Model"
          value={modelName}
          name="modelName"
          onChange={(e) => {
            if (e.target.value === "gpt-4o" && !canUseGPT4) return;
            setModelName(e.target.value);
            setIsChanged("llm");
          }}
        >
          <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
          <MenuItem value="gpt-4o" disabled={!canUseGPT4}>
            <Stack direction="row" spacing={2}>
              <Typography>gpt-4o (recommended)</Typography>
              {!canUseGPT4 && <UpgradeButton />}
            </Stack>
          </MenuItem>
        </Select>
        <Button
          onClick={handleSave}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 3, mb: 1 }}
          disabled={isChanged !== "llm" || isSaving}
          endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
        >
          Save
        </Button>
      </Paper>
      <Paper sx={{ p: 3, bgcolor: "transparent", mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold" }}>
          Access
        </Typography>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Privacy
        </InputLabel>
        <Select
          size="small"
          fullWidth
          variant="outlined"
          placeholder="Visibility"
          value={visibility}
          name="visibility"
          onChange={(e) => {
            setVisibility(e.target.value);
            setIsChanged("access");
          }}
        >
          <MenuItem value="private">private</MenuItem>
          <MenuItem value="public">can be embedded on website</MenuItem>
        </Select>
        <InputLabel sx={{ mt: 2, mb: 0.5, fontSize: "13px" }}>
          Allowed Domains
        </InputLabel>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          size="small"
          fullWidth
          type="text"
          multiline
          rows={3}
          variant="outlined"
          value={allowedDomains?.join("\n")}
          name="suggestedQuestions"
          placeholder="Only allow on specific domains. One domain per line."
          onChange={handleAllowedDomainsChange}
        />
        <Button
          onClick={handleSave}
          variant="contained"
          color="secondary"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 3, mb: 1 }}
          disabled={isChanged !== "access" || isSaving}
          endIcon={isSaving && <CircularProgress size={16} thickness={5} />}
        >
          Save
        </Button>
      </Paper>
      <Paper
        sx={{ p: 3, bgcolor: "transparent", mt: 2, mb: 3, borderColor: "red" }}
      >
        <Typography variant="h5" color="error">
          Danger Zone
        </Typography>
        <Typography variant="body2" sx={{ mb: 3, mt: 2 }}>
          Type "permanently delete" to delete this chatbot
        </Typography>
        <TextField
          InputProps={{
            autoComplete: "off",
          }}
          sx={{
            "& .MuiInputBase-input": { padding: "10px" },
          }}
          size="small"
          fullWidth
          type="text"
          variant="outlined"
          placeholder="permanently delete"
          value={permanentlyDelete}
          name="permanentlyDelete"
          onChange={(e) => {
            setPermanentlyDelete(e.target.value);
          }}
        />
        <Button
          onClick={() => setAreYouSureOpen(true)}
          variant="outlined"
          color="error"
          size="small"
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mt: 2, color: "error.main" }}
          disabled={permanentlyDelete !== "permanently delete"}
        >
          Delete Chatbot
        </Button>
        {areYouSureOpen && (
          <Button
            onClick={handleDeleteChatbot}
            variant="contained"
            color="error"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{ ml: 2, mt: 2, color: "white" }}
            disabled={permanentlyDelete !== "permanently delete"}
          >
            Yes, I'm sure. Permanently delete this chatbot.
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default ChatbotSettings;
