import { createTheme } from "@mui/material/styles";

const primary = "#8a6bea";
const secondary = "#ff70ac";
const action = "#F9FAFB";
const info = "#00EACF";
const success = "#0FA958";
const warning = "#E2B93B";
const error = "#F24E1E";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
  palette: {
    action: {
      main: action,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
    info: {
      main: info,
    },
  },
  typography: {
    fontFamily: "Avenir, sans-serif",
    color: "#F9FAFB",
    h1: {
      color: "#F9FAFB",
    },
    h2: {
      color: "#F9FAFB",
    },
    h3: {
      fontWeight: 600,
      background:
        "radial-gradient(ellipse at center, #f1f1f3 0%, #393939 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h4: {
      color: "#F9FAFB",
      fontWeight: 600,
    },
    h5: {
      color: "#F9FAFB",
      fontWeight: 600,
    },
    h6: {
      color: "#F9FAFB",
    },
    subtitle1: {
      color: "#F9FAFB",
    },
    subtitle2: {
      color: "#F9FAFB",
    },
    body1: {
      color: "#F9FAFB",
    },
    body2: {
      color: "#F9FAFB",
    },
    caption: {
      display: "block",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: "smooth",
          outline: "none",
        },
        body: {
          minWidth: "100vw",
          minHeight: "100vh",
          height: "100vh",
          fontFamily: "Avenir, sans-serif",
          margin: 0,
          overflowX: "hidden",
          outline: "none",
          color: "#F9FAFB",
          background: "#050505",
        },
        li: {
          margin: "0.5rem 0",
        },
        "input::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },
        "input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },
        "input[type='number']": {
          MozAppearance: "textfield",
        },
        "Mui-active": {
          color: "white",
        },
        ".css-1cuhuak-MuiFormLabel-root-MuiInputLabel-root": {
          top: "-7px !important",
        },
        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
          padding: "8.5px 14px !important",
        },
        ".css-lmxgfg-MuiFormLabel-root-MuiInputLabel-root": {
          top: "-7px !important",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
        },
        icon: {
          color: "#F9FAFB",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          textTransform: "capitalize",
          // fontSize: ".95rem",
          fontWeight: "500",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlinedPrimary: {
          border: `1px solid rgba(255, 255, 255, 0.48)"`,
          color: "#F9FAFB",
          "&:hover": {
            border: `1px solid rgba(255, 255, 255, 1)"`,
            color: "#F9FAFB",
          },
        },
        outlinedSecondary: {
          border: `1px solid rgba(255, 255, 255, 0.48)"`,
          color: "#F9FAFB",
          "&:hover": {
            border: `1px solid rgba(255, 255, 255, 1)"`,
            color: "#F9FAFB",
          },
        },
        containedPrimary: {
          background: `radial-gradient(69.5% 58.5% at 52.9% 109.7%, ${secondary} 0%, ${primary} 100%)`,
          borderRadius: "50px",
          opacity: 1,
          borderColor: "rgba(255, 255, 255, 0.48)",
          borderStyle: "solid",
          borderWidth: "1px",
          borderLeftWidth: "1px",
          borderRightWidth: "0px",
          borderBottomWidth: ".5px",
          borderTopWidth: "1px",
          color: "#F7F7F7",
          "&:hover": {
            color: "#F7F7F7",
          },
        },
        containedSecondary: {
          backgroundColor: "#26262c",
          color: "#F7F7F7",
          "&:hover": {
            backgroundColor: "#26262c",
          },
          "&:disabled": {
            backgroundColor: "#26262c",
            color: "rgba(255, 255, 255, 0.2)",
          },
        },
        text: {
          color: "rgba(255, 255, 255, 0.2)",
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          textTransform: "capitalize",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "rgba(255, 255, 255, 0.2) !important",
          "& fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
          "&:hover fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
        },
        notchedOutline: {
          borderColor: "rgba(255, 255, 255, 0.2) !important",
          ":hover": {
            borderColor: "rgba(255, 255, 255, 0.2) !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "#F9FAFB",
          ":hover": {
            color: "#F9FAFB",
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
        },
        root: {
          ":hover": {
            color: "#F9FAFB",
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
          "& fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
          "&:hover fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(255, 255, 255, 0.2)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          borderRadius: "6px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "rgba(255, 255, 255, 0.2)",
            },
            "&:hover fieldset": {
              borderColor: "rgba(255, 255, 255, 0.2)",
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(255, 255, 255, 0.2)",
            },
          },
        },
        input: {
          "&::placeholder": {
            color: "gray",
          },
          color: "#F9FAFB",
        },
        icon: {
          fill: "#F9FAFB",
        },
        label: {
          color: "gray",
        },
        fieldset: {
          borderColor: "gray",
          border: "1px solid gray",
        },
        notchedOutline: {
          borderColor: "gray",
          ":hover": {
            borderColor: "gray",
            border: "1px solid gray",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          "&.Mui-focused": {
            color: "#F9FAFB",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          "&.Mui-focused": {
            color: "#F9FAFB",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "#F9FAFB",
          boxShadow: "none",
          background:
            "linear-gradient(rgba(20, 20, 20, 1) 0%,rgba(14, 14, 14, 1) 100%)",
          borderRadius: "8px",
          borderBottomWidth: ".5px",
          borderColor: "rgba(255, 255, 255, 0.2)",
          borderLeftWidth: "1px",
          borderRightWidth: "1px",
          borderStyle: "solid",
          borderTopWidth: "1px",
          "&.MuiPaper-elevation1": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
