import { useState, useContext } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import firebase from "../../firebase";
import {
  Box,
  Container,
  Typography,
  Button,
  Divider,
  TextField,
  Stack,
  CircularProgress,
  Alert,
  Paper,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { SnackbarContext } from "../../contexts/SnackbarContext";
import { AuthContext } from "../../contexts/AuthContext";
// import SolanaSignInButton from "../../components/buttons/SignInWithSolanaButton.jsx";

const provider = new GoogleAuthProvider();

const plans = [
  "starter-monthly",
  "pro-monthly",
  "ultra-monthly",
  "starter-yearly",
  "pro-yearly",
  "ultra-yearly",
];

const RegisterPage = () => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const navigate = useNavigate();
  const {
    authState: { isAuth, isLoading },
  } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleCreateDatabaseUser = async (user) => {
    const { uid, email, emailVerified } = user;
    await axios.post(`${process.env.REACT_APP_VERCEL_API_BASE_URL}/user`, {
      userId: uid,
      userEmail: email,
      isEmailVerified: emailVerified,
    });
  };

  const handleCreateStripeCheckoutSession = async (user) => {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_API_BASE_URL}/stripe/create-checkout-session`,
      {
        email: user.email,
        lookup_key: plan,
      }
    );
    console.log(res);
    const { url } = res.data;
    window.open(url, "_self");
  };

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    try {
      setIsSigningUp(true);
      await createUserWithEmailAndPassword(firebase.auth, email, password).then(
        async (userCredential) => {
          const fireBaseUser = userCredential.user;
          await handleCreateDatabaseUser(fireBaseUser);
          if (plans.includes(plan)) {
            await handleCreateStripeCheckoutSession(fireBaseUser);
          }
        }
      );
    } catch (err) {
      console.error("Error signing up: ", err);
      setIsSigningUp(false);
      showAlert("Error signing up", "error");
    }
  };

  const handleGoogleAuth = async () => {
    const auth = getAuth();
    try {
      setIsSigningUp(true);
      await signInWithPopup(auth, provider).then(async (result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // console.log("token:", token);
        const fireBaseUser = result.user;
        await handleCreateDatabaseUser(fireBaseUser);
        if (plans.includes(plan)) {
          await handleCreateStripeCheckoutSession(fireBaseUser);
        }
      });
    } catch (err) {
      console.error(err);
      setIsSigningUp(false);
      showAlert("Error signing up", "error");
    }
  };

  if (isLoading) {
    return <div></div>;
  }

  if (isAuth) {
    navigate("/chatbots");
  }

  return (
    <Container
      maxWidth={"xs"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 10,
        p: 5,
      }}
      component={Paper}
    >
      <Box width={"100%"}>
        {plans.includes(plan) && (
          <Alert color="secondary" sx={{ my: 4 }}>
            Enjoy a <b>7-day free trial</b> with the{" "}
            <b style={{ textTransform: "capitalize" }}>
              {plan.replace("-", " ")}
            </b>{" "}
            plan. <i>You will be billed after the trial ends in 7 days.</i>
          </Alert>
        )}
        <Typography variant="h5" sx={{ textAlign: "center", mb: 4 }}>
          Create Your Account
        </Typography>
        <TextField
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          fullWidth
          placeholder="Email"
          type="text"
          variant="outlined"
          sx={{ mb: 1 }}
          value={email}
          disabled={isSigningUp}
          onChange={handleEmailChange}
        />
        <TextField
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          fullWidth
          placeholder="Password"
          type="password"
          variant="outlined"
          sx={{ mb: 1 }}
          value={password}
          disabled={isSigningUp}
          onChange={handlePasswordChange}
        />
        <TextField
          size="small"
          InputProps={{
            autoComplete: "off",
          }}
          fullWidth
          placeholder="Confirm Password"
          type="password"
          variant="outlined"
          sx={{ mb: 1 }}
          value={confirmPassword}
          disabled={isSigningUp}
          onChange={handleConfirmPasswordChange}
        />

        <Button
          onClick={handleRegister}
          variant="contained"
          color="secondary"
          fullWidth
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          sx={{ mb: 1, mt: 2 }}
          disabled={isSigningUp}
          endIcon={isSigningUp && <CircularProgress size={16} thickness={5} />}
        >
          Continue
        </Button>
        <Typography
          variant="body1"
          component="p"
          sx={{
            my: ".5rem",
            textAlign: "center",
            width: "100%",
          }}
        >
          Already have an account?{" "}
          <Typography
            variant="body1"
            component="span"
            color="primary"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate("/login")}
          >
            Log In
          </Typography>
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Divider sx={{ width: "45%" }} />
          <Typography
            variant="body1"
            component="p"
            sx={{
              my: ".5rem",
              textAlign: "center",
              width: "10%",
            }}
          >
            or
          </Typography>
          <Divider sx={{ width: "45%" }} />
        </Stack>
        <Button
          onClick={handleGoogleAuth}
          variant="outlined"
          color="inherit"
          fullWidth
          disableRipple
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          startIcon={<GoogleIcon style={{ fontSize: ".95rem" }} />}
          sx={{ mb: 1.5 }}
        >
          Continue with Google
        </Button>
        {/* <SolanaSignInButton /> */}
      </Box>
    </Container>
  );
};

export default RegisterPage;
