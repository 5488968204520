import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeaderText from "../../components/layout/HeaderText";

export default function Faqs() {
  const faqs = [
    {
      title: "What makes Diddo unique?",
      content:
        "Diddo is not just another chatbot builder. It leverages the advanced capabilities of ChatGPT to deliver intelligent, context-aware interactions that are tailored to your specific needs. It's like having a 24/7 customer service agent, but smarter.",
    },
    {
      title: "What types of data can I use to train Diddo?",
      content:
        "Diddo is incredibly versatile, accepting a wide array of formats such as .pdf, .txt, .doc, .docx. You can also input plain text or even a website URL for Diddo to analyze and learn from.",
    },
    {
      title: "How customizable are Diddo's responses?",
      content:
        "Sky's the limit! You can not only modify the base prompts but also imbue your chatbot with a unique personality. Want your chatbot to speak like Shakespeare or reply only in haikus? You got it!",
    },
    {
      title: "What are my Options for website Integration?",
      content:
        "Diddo offers multiple ways to integrate your chatbot into your website. Whether you prefer a discreet chat bubble, a full-screen interface, or an embedded iframe, we've got you covered. Just go to 'Embed on Website' in your dashboard for step-by-step instructions.",
    },
    {
      title: "Can Diddo interact across different platforms?",
      content:
        "Absolutely! Diddo's robust API and Zapier integration allows for seamless integration across a multitude of platforms, from social media to customer relationship management systems, making sure your chatbot is where you need it to be.",
    },
    {
      title: "Is it possible to share my Diddo chatbot?",
      content:
        "Yes, sharing is caring! While your Diddo chatbot is set to private by default, you can easily change the settings to make it public and shareable.",
    },
    {
      title: "How secure is my data with Diddo?",
      content:
        "Your data's security is our top priority. All data is stored locally on your device, and we adhere to stringent data protection regulations to ensure your information remains confidential.",
    },
    {
      title: "Is there a free trial?",
      content:
        "Absolutely! We offer a 7-day free trial for you to explore all the features and capabilities that Diddo has to offer.",
    },
  ];

  return (
    <Container maxWidth="sm">
      <HeaderText title="FAQs" topLine="Your questions" bottomLine="answered" />
      <Box>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0}
            sx={{
              color: "#fafafa",
              background:
                "linear-gradient(rgba(20, 20, 20, 0.539) 0%, rgba(14, 14, 14, 0.8) 100%)",
              fill: "white",
              display: "grid",
              borderBottomWidth: "0px",
              borderColor: "rgba(255, 255, 255, 0.2)",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              borderStyle: "solid",
              borderTopWidth: "1px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
              "&:before": {
                display: "none",
              },
              mb: 2,
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#fafafa",
                  }}
                />
              }
            >
              <Typography variant="h6">{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
