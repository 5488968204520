import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ChatWidget from "./components/ChatWidget";
import EmbedScriptDialog from "./components/EmbedScriptDialog";
import ShareDialog from "./components/ShareDialog";
import SecondaryTabs from "../../../components/tabs/SecondaryTabs";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import ChatbotSettings from "./components/Settings";
import ChatInterfaceForm from "./components/ChatInterfaceForm";
import CustomDomainManager from "./components/CustomDomainManager";
import Instructions from "../../../components/alerts/Instructions";

const demoDescription = `Demo your chatbot. You can also embed your chatbot on your website by clicking the "Embed" button. Click the "Share" button to share your chatbot with others. You can even link your chatbot to a custom domain in the "Domain" tab.`;
const settingsDescription = `Give your chatbot a name, tweak the base prompt, and choose a model. You can also choose whether your chatbot is public or private.`;
const interfaceDescription = `Customize your chatbot's style to fit your brand. You can also customize the chatbot's initial messages and suggested questions.`;
const domainsDescription = `Link your chatbot to a custom domain. Simply enter your domain name and click the "Link Domain" button. You will then be prompted to add a CNAME record to your DNS settings.`;

const SETTINGS_TABS = [
  "Demo",
  "Settings",
  "Styles",
  "Domains",
  "Embed",
  "Share",
];

export default function Chatbot() {
  const { chatbotData } = useContext(ChatbotContext);
  const [tabValue, setTabValue] = useState("Demo");
  const [openEmbedScriptDialog, setOpenEmbedScriptDialog] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [chatbotName, setChatbotName] = useState(chatbotData.chatbotName);
  const [basePrompt, setBasePrompt] = useState(chatbotData.basePrompt);
  const [initialMessages, setInitialMessages] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [modelName, setModelName] = useState(chatbotData.modelName);
  const [visibility, setVisibility] = useState(chatbotData.visibility);
  const [allowedDomains, setAllowedDomains] = useState(
    chatbotData.allowedDomains
  );
  const [primaryColor, setPrimaryColor] = useState(chatbotData.primaryColor);
  const [textColor, setTextColor] = useState(chatbotData.textColor);
  const [darkMode, setDarkMode] = useState(chatbotData.darkMode);
  const [avatarUrl, setAvatarUrl] = useState(chatbotData.avatarUrl);
  const [avatarIcon, setAvatarIcon] = useState(chatbotData.avatarIcon);
  const [language, setLanguage] = useState(chatbotData.language || "English");

  const handleChange = (event, newValue) => {
    if (newValue === "Embed") {
      setOpenEmbedScriptDialog(true);
      return;
    }

    if (newValue === "Share") {
      setOpenShareDialog(true);
      return;
    }

    setTabValue(newValue);
  };

  useEffect(() => {
    setChatbotName(chatbotData.chatbotName);
    setBasePrompt(chatbotData.basePrompt);
    setInitialMessages(chatbotData.initialMessages);
    setSuggestedQuestions(chatbotData.suggestedQuestions);
    setModelName(chatbotData.modelName);
    setVisibility(chatbotData.visibility);
    setAllowedDomains(chatbotData.allowedDomains);
    setPrimaryColor(chatbotData.primaryColor);
    setTextColor(chatbotData.textColor);
    setDarkMode(chatbotData.darkMode);
    setAvatarUrl(chatbotData.avatarUrl);
    setAvatarIcon(chatbotData.avatarIcon);
    setLanguage(chatbotData.language || "English");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatbotData.chatbotId]);

  return (
    <>
      <SecondaryTabs
        tabs={SETTINGS_TABS}
        tabValue={tabValue}
        handleChange={handleChange}
      />
      <Grid container spacing={4}>
        {tabValue === "Domains" && (
          <Grid item xs={12} md={12}>
            <Instructions description={domainsDescription} id="settings" />
            <CustomDomainManager />
          </Grid>
        )}
        {tabValue === "Demo" && (
          <Grid item xs={12} md={12}>
            <Instructions description={demoDescription} id="demo" />
            {/* <ChatWidget isDemo={true} /> */}
            <iframe
              title="diddo-iframe"
              src={`https://demo.diddo.chat/chatbot/${chatbotData?.chatbotId}`}
              id={chatbotData?.chatbotId}
              width="100%"
              height="800px"
              style={{ border: "none", borderRadius: "10px" }}
            ></iframe>
          </Grid>
        )}
        {tabValue === "Settings" && (
          <Grid item xs={12} md={12}>
            <Instructions description={settingsDescription} id="settings" />
            <ChatbotSettings
              formData={{
                chatbotName,
                setChatbotName,
                basePrompt,
                setBasePrompt,
                modelName,
                setModelName,
                visibility,
                setVisibility,
                allowedDomains,
                setAllowedDomains,
              }}
            />
          </Grid>
        )}
        {tabValue === "Styles" && (
          <>
            <Grid item xs={12} md={6}>
              <Instructions description={interfaceDescription} id="interface" />
              <ChatInterfaceForm
                formData={{
                  initialMessages,
                  setInitialMessages,
                  suggestedQuestions,
                  setSuggestedQuestions,
                  primaryColor,
                  setPrimaryColor,
                  avatarUrl,
                  setAvatarUrl,
                  avatarIcon,
                  setAvatarIcon,
                  darkMode,
                  setDarkMode,
                  language,
                  setLanguage,
                  textColor,
                  setTextColor,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ChatWidget
                formData={{
                  initialMessages,
                  chatbotName,
                  primaryColor,
                  textColor,
                  avatarUrl,
                  avatarIcon,
                  darkMode,
                }}
                isDemo={false}
              />
            </Grid>
          </>
        )}
      </Grid>
      <EmbedScriptDialog
        open={openEmbedScriptDialog}
        setOpen={setOpenEmbedScriptDialog}
      />
      <ShareDialog open={openShareDialog} setOpen={setOpenShareDialog} />
    </>
  );
}
