/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
  ListItemText,
  TextField,
  Chip,
  Avatar,
} from "@mui/material";

import { Send as SendIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import { ChatbotContext } from "../../../../contexts/ChatbotContext";
import { Stack } from "@mui/system";

const isMobile = window.innerWidth <= 768;

const styles = {
  typing: {
    position: "relative",
    color: "white",
    fontStyle: "italic",
  },
  dot: (delay) => ({
    content: "",
    animation: `blink 1.5s infinite`,
    animationFillMode: "both",
    height: "9px",
    width: "9px",
    background: "white",
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: "50%",
    animationDelay: `${delay}s`,
    marginLeft: delay === 0 ? 0 : delay * 6 * 10 + "px",
  }),
};

const BotMessage = ({ message, darkMode }) => {
  return (
    <ListItem sx={{ py: 0 }}>
      <ListItemText
        primary={message}
        // primary={message}
        style={{
          flex: "unset",
          backgroundColor: darkMode ? "#3f3f46" : "#e9e9e9",
          color: darkMode ? "white !important" : "black !important",
          borderRadius: 6,
          padding: "14px",
        }}
      />
    </ListItem>
  );
};

const UserMessage = ({ message, primaryColor, textColor }) => (
  <ListItem
    style={{
      display: "flex",
      justifyContent: "flex-end",
      width: "80%",
      float: "right",
    }}
  >
    {console.log("textColor", textColor)}
    <ListItemText
      primary={<Typography sx={{ color: textColor }}>{message}</Typography>}
      style={{
        flex: "unset",
        backgroundColor: primaryColor,
        color: textColor + " !important",
        borderRadius: 6,
        padding: "14px",
      }}
    />
  </ListItem>
);

const TypingIndicator = (darkMode) => (
  <ListItem>
    <Box style={styles.typing}>
      <span style={styles.dot(0)}></span>
      <span style={styles.dot(0.2)}></span>
      <span style={styles.dot(0.4)}></span>
    </Box>
  </ListItem>
);

const ChatWidget = ({ isDemo, formData }) => {
  const { chatbotData } = useContext(ChatbotContext);
  const {
    chatbotName,
    primaryColor,
    textColor,
    avatarUrl,
    darkMode,
    chatbotId,
    userId,
    initialMessages,
  } = formData || chatbotData || {};

  const [sessionId, setSessionId] = useState("");
  const [chatHistory, setChatHistory] = useState(
    initialMessages?.map((message) => ({ message, sender: "Bot" }))
  );

  const [currentMessage, setCurrentMessage] = useState("");
  const [isBotTyping, setIsBotTyping] = useState(false);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!isDemo) {
      setChatHistory([
        ...initialMessages?.map((message) => ({ message, sender: "Bot" })),
        {
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          sender: "User",
        },
      ]);
    } else {
      const history = JSON.parse(
        localStorage.getItem(`chatHistory-${chatbotId}`)
      );
      setChatHistory(
        history ||
          initialMessages?.map((message) => ({ message, sender: "Bot" }))
      );
    }
  }, [isDemo, initialMessages, chatbotId]);

  useEffect(() => {
    const storedSessionId = localStorage.getItem("sessionId");

    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      const newSessionId = uuidv4();
      setSessionId(newSessionId);
      localStorage.setItem("sessionId", newSessionId);
    }
  }, []);

  const fetchReplyFromBot = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/chat`,
        {
          message: currentMessage,
          chatbotId: chatbotId,
          sessionId: sessionId,
          userId: userId,
        }
      );
      return data?.text || "Error fetching response from bot";
    } catch (error) {
      console.log("error:", error);
    }
  }, [currentMessage, sessionId, chatbotId, userId]);

  const addMessageToHistory = useCallback(
    (message) => {
      setChatHistory((chatHistory) => {
        const updatedChatHistory = [...chatHistory, message];
        localStorage.setItem(
          `chatHistory-${chatbotId}`,
          JSON.stringify(updatedChatHistory)
        );
        return updatedChatHistory;
      });
    },
    [chatbotId]
  );

  const handleSendMessage = useCallback(async () => {
    const trimmedMessage = currentMessage.trim();
    if (trimmedMessage) {
      addMessageToHistory({ message: trimmedMessage, sender: "User" });
      setCurrentMessage("");
      setIsBotTyping(true);
      const botReply = await fetchReplyFromBot();
      addMessageToHistory({ message: botReply, sender: "Bot" });
      setIsBotTyping(false);
    }
  }, [currentMessage, addMessageToHistory, fetchReplyFromBot]);

  const handleRestartChat = () => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
    localStorage.setItem("sessionId", newSessionId);

    setChatHistory([
      ...initialMessages?.map((message) => ({ message, sender: "Bot" })),
    ]);
    localStorage.removeItem(`chatHistory-${chatbotId}`);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: isMobile ? window.innerHeight : "750px",
          bgcolor: darkMode ? "#000" : "#fcfcfc",
          borderRadius: isMobile ? 0 : 3,
          p: isMobile ? 0 : 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          border: 1,
          borderColor: darkMode ? "#3f3f46" : "#d3d3d3",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            m: isMobile ? 2 : 1,
            mb: 0,
            pb: 1,
            borderBottom: 1,
            borderColor: darkMode ? "#3f3f46" : "#d3d3d3",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {avatarUrl && <Avatar alt={chatbotName} src={avatarUrl} />}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                color: darkMode ? "#f5f5f5" : "#0e0e0e",
              }}
            >
              {chatbotName}
            </Typography>
          </Stack>
          {isDemo && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: isMobile ? 1 : 0,
              }}
            >
              <IconButton
                color="secondary"
                onClick={handleRestartChat}
                sx={{ outline: "none" }}
              >
                <RefreshIcon
                  sx={{ color: darkMode ? "white" : "black" }}
                  fontSize="small"
                />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            overflowY: "scroll",
            "-webkit-overflow-scrolling": "touch",
            flexGrow: 1,
            // mb: 1,
            height: "calc(100% - 80px)",
            "::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none" /* IE and Edge */,
            "scrollbar-width": "none",
          }}
        >
          <List>
            {chatHistory?.map((chat, index) =>
              chat.sender === "User" ? (
                <UserMessage
                  key={index}
                  message={chat.message}
                  primaryColor={primaryColor}
                  textColor={textColor}
                />
              ) : (
                <BotMessage
                  key={index}
                  message={chat.message}
                  darkMode={darkMode}
                />
              )
            )}
            {isBotTyping && <TypingIndicator />}
            <div ref={messagesEndRef} />
          </List>
        </Box>
        <Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              ml: 2,
              // scroll horizontal
              overflowX: "scroll",
              "-webkit-overflow-scrolling": "touch",
              flexGrow: 1,
              // mb: 1,
              height: "calc(100% - 80px)",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {chatbotData?.suggestedQuestions?.map((question, index) => (
              <Chip
                key={index}
                label={question}
                variant="outlined"
                sx={{
                  backgroundColor: primaryColor + "e0",
                  border: "none",
                  color: textColor || "white",
                  cursor: isDemo ? "pointer" : "default",
                  "&:hover": {
                    backgroundColor: isDemo
                      ? primaryColor + " !important"
                      : primaryColor + "e0 !important",
                  },
                }}
                onClick={() => {
                  if (!isDemo) return;
                  setCurrentMessage(question);
                  inputRef.current.focus();
                }}
              />
            ))}
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 2,
              border: 1,
              borderColor: darkMode ? "#3f3f46" : "#d3d3d3",
              borderRadius: 1,
              backgroundColor: darkMode ? "black" : "white",
              mb: isMobile ? 3 : 1,
            }}
          >
            <TextField
              inputRef={inputRef}
              fullWidth
              size="small"
              type="text"
              placeholder=""
              InputProps={{
                disableUnderline: true,
                style: {
                  borderRadius: "5px",
                  color: darkMode ? "white" : "black",
                  padding: 12,
                },
                autoComplete: "off",
              }}
              style={{ marginRight: "10px" }}
              InputLabelProps={{
                shrink: false,
              }}
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              variant="standard"
              disabled={!isDemo}
            />

            <IconButton
              color="primary"
              onClick={handleSendMessage}
              sx={{ outline: "none" }}
              disabled={!isDemo}
            >
              <SendIcon
                sx={{ color: darkMode ? "white" : "black" }}
                fontSize="small"
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatWidget;
