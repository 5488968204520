import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Container,
  Stack,
  Button,
  Box,
  Hidden,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SecondaryTabs from "../../components/tabs/SecondaryTabs";
import { CheckCircleRounded } from "@mui/icons-material";
import { ChatbotContext } from "../../contexts/ChatbotContext";
import axios from "axios";
import { debounce } from "lodash";
import { AuthContext } from "../../contexts/AuthContext";
import Footer from "../../components/layout/Footer";
import { useNavigate } from "react-router-dom";
import { FEATURES, PLANS } from "../../utils/constants";
import Faqs from "./Faqs";

export default function Pricing() {
  const navigate = useNavigate();
  const { subscriptionSettings } = useContext(ChatbotContext);
  const {
    addOnRemoveBranding,
    addOnCustomDomain,
    addOnApiAccess,
    hasAPIAccess,
    canRemoveBranding,
    canAddCustomDomain,
  } = subscriptionSettings;
  const { authState } = useContext(AuthContext);
  const { isAuth, email } = authState;
  // const myPlanIndex =
  //   plans.findIndex((plan) =>
  //     subscriptionSettings?.lookupKey?.includes(plan.name.toLowerCase())
  //   ) || null;

  const [tabValue, setTabValue] = useState("Monthly");
  const [myPlanIndex, setMyPlanIndex] = useState(null);
  const [isLoadingLink, setIsLoadingLink] = useState(false);

  useEffect(() => {
    const index = PLANS.findIndex((plan) =>
      subscriptionSettings?.lookupKey?.includes(plan.name.toLowerCase())
    );
    setMyPlanIndex(index);
  }, [subscriptionSettings]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCreateStripeCheckoutSession = debounce(async (plan) => {
    setIsLoadingLink(true);
    if (plan.includes("enterprise")) {
      window.open(
        "https://forms.gle/6Qtzx3fB3HgrUCm8A",
        "_blank",
        "noopener noreferrer"
      );
      return;
    }
    if (!isAuth) {
      navigate("/register");
      return;
    }
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_API_BASE_URL}/stripe/create-checkout-session`,
      {
        email: email,
        lookup_key: plan,
      }
    );
    const { url } = res.data;
    window.open(url, "_self");
    setIsLoadingLink(false);
  }, 1000);

  const checkAddOn = (lookup_key) => {
    if (lookup_key === "remove-diddo-branding") {
      return canRemoveBranding || addOnRemoveBranding;
    } else if (lookup_key === "custom-domains") {
      return canAddCustomDomain || addOnCustomDomain;
    } else if (lookup_key === "api-access") {
      return hasAPIAccess || addOnApiAccess;
    } else {
      return false;
    }
  };

  return (
    <Container minWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", mt: 5 }}>
        Pricing
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ textAlign: "center", mb: 4 }}
      >
        All plans come with a 7-day free trial
      </Typography>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 1 }}
      >
        <SecondaryTabs
          tabs={["Monthly", "Annual (2 months free)"]}
          tabValue={tabValue}
          handleChange={handleChange}
        />
      </Grid>
      <Hidden smUp>
        <Grid container spacing={2}>
          {PLANS.map((plan, planIndex) => (
            <Grid item xs={12} key={plan.name}>
              <Paper sx={{ p: 1, bgcolor: "transparent", mb: 1 }}>
                <Box p={3}>
                  <Typography variant="h6">{plan.name}</Typography>
                  <Stack direction="row" alignItems="center">
                    {plan.yearly ? (
                      <Typography variant="body1">
                        $
                        {tabValue.includes("Annual")
                          ? plan.yearly
                          : plan.monthly}{" "}
                      </Typography>
                    ) : (
                      <Typography variant="body1">Custom</Typography>
                    )}
                    {plan.yearly && (
                      <Typography variant="body2">
                        {tabValue.includes("Annual") ? "/year" : "/month"}
                      </Typography>
                    )}
                  </Stack>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disableRipple
                    disableelevation="true"
                    disablefocusripple="true"
                    disableTouchRipple
                    sx={{ my: 2 }}
                    disabled={
                      (isAuth && myPlanIndex >= planIndex) || isLoadingLink
                    }
                    onClick={() => {
                      handleCreateStripeCheckoutSession(
                        plan.name.toLowerCase() + "-" + tabValue.toLowerCase()
                      );
                    }}
                  >
                    {!isAuth
                      ? "Start free trial"
                      : myPlanIndex === planIndex
                      ? "Current plan"
                      : "Subscribe"}
                  </Button>
                  <Box mt={2}>
                    {FEATURES.map((feature, featureIndex) => (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        key={feature.name}
                        sx={{ my: 1 }}
                      >
                        <Typography variant="body2">{feature.name}</Typography>
                        <Typography variant="body2">
                          {feature.values[planIndex] === true ? (
                            <CheckCircleRounded
                              fontSize="small"
                              color="primary"
                            />
                          ) : feature.values[planIndex] === false ? (
                            <CloseIcon fontSize="small" />
                          ) : (
                            feature.values[planIndex]
                          )}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Paper sx={{ p: 2, bgcolor: "transparent", mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow
                // remove border divider
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{ border: "none", pb: 0 }}></TableCell>
                {PLANS.map(({ name, monthly, yearly }, index) => (
                  <TableCell key={name} sx={{ border: "none", pb: 0 }}>
                    <Stack direction="column" justifyContent="center">
                      <Typography variant="h6">{name}</Typography>
                      <Stack direction="row" alignItems="center">
                        {yearly ? (
                          <Typography variant="body1">
                            ${tabValue.includes("Annual") ? yearly : monthly}{" "}
                          </Typography>
                        ) : (
                          <Typography variant="body1">Custom</Typography>
                        )}
                        {yearly && (
                          <Typography variant="body2">
                            {tabValue.includes("Annual") ? "/year" : "/month"}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                {PLANS.map(({ name, monthly, yearly }, index) => (
                  <TableCell key={name}>
                    {myPlanIndex <= index && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        disableRipple
                        disableelevation="true"
                        disablefocusripple="true"
                        disableTouchRipple
                        sx={{ my: 2 }}
                        disabled={
                          (isAuth && myPlanIndex >= index) || isLoadingLink
                        }
                        onClick={() => {
                          handleCreateStripeCheckoutSession(
                            name.toLowerCase() + "-" + tabValue.toLowerCase()
                          );
                        }}
                      >
                        {!isAuth
                          ? "Start free trial"
                          : myPlanIndex === index
                          ? "Current plan"
                          : "Subscribe"}
                      </Button>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {FEATURES.map((feature, featureIndex) => (
                <TableRow key={featureIndex}>
                  <TableCell>{feature.name}</TableCell>
                  {feature.values.map((value, index) => (
                    <TableCell key={index}>
                      {value === true ? (
                        <CheckCircleRounded fontSize="small" color="primary" />
                      ) : value === false ? (
                        <CloseIcon fontSize="small" />
                      ) : (
                        value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Hidden>
      <Typography variant="h4" gutterBottom sx={{ textAlign: "center", my: 7 }}>
        Add-Ons
      </Typography>
      <Grid container spacing={4}>
        {[
          {
            title: "Extra message credits",
            price: 8,
            unit: "per 1,000 message credits/month",
            lookup_key: "extra-message-credits",
          },
          {
            title: 'Remove "Powered by Diddo" branding',
            price: 18,
            unit: "/month",
            lookup_key: "remove-diddo-branding",
          },
          {
            title: "API Access",
            price: 8,
            unit: "/month",
            lookup_key: "api-access",
          },
          {
            title: "Custom Domains",
            price: 48,
            unit: "/month",
            lookup_key: "custom-domains",
          },
        ].map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper sx={{ p: 5, bgcolor: "transparent" }}>
              <Typography variant="h5">{item.title}</Typography>
              <Typography variant="h4" sx={{ my: 1 }}>
                <span style={{ fontSize: "1.5rem", verticalAlign: "middle" }}>
                  $
                </span>
                {item.price}
                <span style={{ fontSize: "1rem", paddingLeft: "5px" }}>
                  {item.unit}
                </span>
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                style={{ marginTop: "15px" }}
                onClick={() => {
                  handleCreateStripeCheckoutSession(item.lookup_key);
                }}
                disabled={checkAddOn(item.lookup_key) || isLoadingLink}
              >
                {checkAddOn(item.lookup_key) ? "Already added" : "Add to plan"}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Faqs />
      <Footer />
    </Container>
  );
}
