export const FEATURES = [
  {
    name: "LLM",
    values: ["GPT-4o-mini", "GPT-4o", "GPT-4o", "GPT-4o", "GPT-4o"],
  },
  {
    name: "Number of chatbots",
    values: ["1", "2", "5", "10", "Custom"],
  },
  {
    name: "Message credits per month",
    values: ["40", "2,500", "15,000", "50,000", "Custom"],
  },
  {
    name: "Characters per chatbot",
    values: ["1,000,000", "11,000,000", "11,000,000", "11,000,000", "Custom"],
  },
  {
    name: "Chatbot customization",
    values: [true, true, true, true, true],
  },
  {
    name: "Voice to text",
    values: [true, true, true, true, true],
  },
  {
    name: "Upload multiple files",
    values: [true, true, true, true, true],
  },
  {
    name: "Upload websites",
    values: [true, true, true, true, true],
  },
  {
    name: "Available in 100+ languages",
    values: [true, true, true, true, true],
  },
  {
    name: "Capture contact information",
    values: [false, true, true, true, true],
  },
  {
    name: "Save conversations",
    values: [false, true, true, true, true],
  },
  {
    name: "Zapier integration",
    values: [false, true, true, true, true],
  },
  {
    name: "Slack integration (coming soon)",
    values: [false, true, true, true, true],
  },
  {
    name: "Messenger integration (coming soon)",
    values: [false, true, true, true, true],
  },
  {
    name: "Discord integration (coming soon)",
    values: [false, true, true, true, true],
  },
  {
    name: "Telegram integration (coming soon)",
    values: [false, true, true, true, true],
  },
  {
    name: "Upload audio",
    values: [false, false, true, true, true],
  },
  {
    name: "Upload video (coming soon)",
    values: [false, false, true, true, true],
  },
  {
    name: "API Access",
    values: [false, false, true, true, true],
  },
  {
    name: `Remove "Powered by Diddo" branding`,
    values: [false, false, false, true, true],
  },
  {
    name: `Custom domains`,
    values: [false, false, false, true, true],
  },
  // {
  //   name: "Advanced chatbot branding",
  //   values: [false, false, false, true, true],
  // },
  {
    name: "Analytics dashboard (coming soon)",
    values: [false, false, false, true, true],
  },
  {
    name: "Dedicated support agent",
    values: [false, false, false, false, true],
  },
];

export const PLANS = [
  {
    name: "Free",
    monthly: "0",
    yearly: "0",
  },
  {
    name: "Starter",
    monthly: "28",
    yearly: "280",
  },
  {
    name: "Pro",
    monthly: "98",
    yearly: "980",
  },
  {
    name: "Ultra",
    monthly: "398",
    yearly: "3,980",
  },
  {
    name: "Enterprise",
    monthly: "",
    yearly: "",
  },
];
