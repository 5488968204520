import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { Button, Grid, Box, CircularProgress } from "@mui/material";
import { RemoveRounded } from "@mui/icons-material";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { AuthContext } from "../../../contexts/AuthContext";
import CharacterCounts from "./CharacterCounts";

function FilesSource() {
  const { tokenId } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { userId, chatbotId, filesSource } = chatbotData || {};
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesMarkedForDeletion, setFilesMarkedForDeletion] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setFiles(
      filesSource?.filter(
        (file) =>
          file.fileName !== "text-source.txt" &&
          file.fileName !== "questions-source.txt"
      )
    );
  }, [filesSource]);

  const handleFileChange = (e) => {
    setFiles((prevState) => [...prevState, e.target.files[0].name]);
    setFilesToUpload((prevState) => [...prevState, e.target.files[0]]);
    setChanged(true);
  };

  const addItem = async () => {
    setIsUploading(true);
    filesToUpload.forEach(async (file) => {
      const formData = new FormData();
      formData.append("pdf", file);
      formData.append("chatbotId", chatbotId);
      formData.append("userId", userId);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/files`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          }
        );
        const charCount = response.data.filesCharacterCount;
        setChatbotData({
          ...chatbotData,
          filesCharacterCount: charCount,
        });
        showAlert("File uploaded successfully!");
        setIsUploading(false);
        setChanged(false);
      } catch (error) {
        console.log(error);
        showAlert("Error uploading file", "error");
        setIsUploading(false);
        setChanged(false);
      }
    });
  };

  const handleMarkForDeletion = (file) => {
    setFilesMarkedForDeletion((prevFiles) => [...prevFiles, file]);
    setFiles(files.filter((f) => f !== file));
    setChanged(true);
  };

  const deleteItem = async (file) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/files`,
        {
          data: {
            fileName: file.fileName,
            chatbotId,
            fileType: "docs",
          },
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setFiles(files.filter((f) => f !== file.fileName));
      const charCount = response.data.filesCharacterCount;
      setChatbotData({
        ...chatbotData,
        filesCharacterCount: charCount,
      });
      showAlert("File deleted successfully!");
    } catch (error) {
      console.log(error);
      showAlert("Error deleting file", "error");
    }
  };

  const handleSubmit = async () => {
    if (filesMarkedForDeletion.length > 0) {
      filesMarkedForDeletion.forEach(async (file) => {
        await deleteItem(file);
      });
      setFilesMarkedForDeletion([]);
    }

    if (filesToUpload.length > 0) {
      await addItem();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Button
          variant="outlined"
          component="label"
          disableelevation="true"
          disablefocusripple="true"
          disableTouchRipple
          disabled={isUploading}
          endIcon={
            isUploading && <CircularProgress size={20} color="inherit" />
          }
        >
          Upload File
          <input
            type="file"
            accept=".pdf,.txt,.docx,.doc,.rtf"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        {files?.map((file, index) => (
          <Button
            key={index}
            variant="outlined"
            size="large"
            fullWidth
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            sx={{
              mt: index === 0 ? 0 : 2,
              py: 2,
              px: 3,
              cursor: "default",
            }}
            endIcon={
              <RemoveRounded
                sx={{
                  cursor: "pointer",
                }}
                fontSize="small"
                onClick={() => handleMarkForDeletion(file)}
              />
            }
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
              }}
            >
              {file?.fileName || file}
            </Box>
          </Button>
        ))}
      </Grid>
      <Grid item xs={12} md={12}>
        <CharacterCounts
          handleClick={handleSubmit}
          isSaving={isUploading}
          changed={changed}
        />
      </Grid>
    </Grid>
  );
}

export default FilesSource;
