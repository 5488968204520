import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HeaderText from "../../components/layout/HeaderText";

export default function Faqs() {
  const pricingFaqs = [
    {
      title: "What do the different plans offer?",
      content:
        "We offer five plans: Free, Starter, Pro, Ultra, and Enterprise. Each plan comes with its own set of features and limitations, such as the number of chatbots you can create, message credits per month, and the type of LLM (Language Learning Model) used.",
    },
    {
      title: "What is LLM?",
      content:
        "LLM stands for Language Learning Model. The type of LLM determines the capabilities and intelligence of your chatbot. Higher-tier plans offer more advanced LLMs like GPT-4o.",
    },
    {
      title: "What are message credits?",
      content:
        "Message credits refer to the number of messages your chatbot can handle per month. Different plans come with different message credit limits.",
    },
    {
      title: "What does 'Characters per chatbot' mean?",
      content:
        "This refers to the maximum number of characters of data your chatbot can be trained on.",
    },
    {
      title: "Can I customize my chatbot?",
      content:
        "Yes, all our plans offer chatbot customization. You can tailor your chatbot's appearance, responses, and more to fit your brand.",
    },
    {
      title: "What is 'Capture contact information'?",
      content:
        "This feature allows your chatbot to capture and store user contact information, such as email addresses or phone numbers, for lead generation or customer service purposes.",
    },
    {
      title: "What integrations are available?",
      content:
        "We offer various integrations like Zapier (others coming soon). Availability depends on the plan you choose.",
    },
    {
      title: "What is 'API Access'?",
      content:
        "API Access allows you to integrate your Diddo chatbot into your own applications or third-party services. This feature is available in the Pro, Ultra, and Enterprise plans.",
    },
    {
      title: "Can I remove the 'Powered by Diddo' branding?",
      content:
        "Yes, the Ultra and Enterprise plans allow you to remove the 'Powered by Diddo' branding for a more customized experience.",
    },
    {
      title: "What does 'Dedicated support agent' mean?",
      content:
        "With the Enterprise plan, you get a dedicated support agent who will be available to assist you with any issues or queries you may have.",
    },
    {
      title: "Do you offer any discounts for yearly plans?",
      content:
        "Yes, we offer a discount when you opt for a yearly subscription. The yearly cost is equivalent to 10 months of the monthly cost, giving you two months free.",
    },
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 10 }}>
      <HeaderText
        title="Pricing FAQs"
        topLine="Your questions"
        bottomLine="answered"
      />
      <Box>
        {pricingFaqs.map((faq, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0}
            sx={{
              color: "#fafafa",
              background:
                "linear-gradient(rgba(20, 20, 20, 0.539) 0%, rgba(14, 14, 14, 0.8) 100%)",
              fill: "white",
              display: "grid",
              borderBottomWidth: "0px",
              borderColor: "rgba(255, 255, 255, 0.2)",
              borderLeftWidth: "1px",
              borderRightWidth: "1px",
              borderStyle: "solid",
              borderTopWidth: "1px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.08) 0px 4px 12px",
              "&:before": {
                display: "none",
              },
              mb: 2,
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#fafafa",
                  }}
                />
              }
            >
              <Typography variant="h6">{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.content}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
