import React, { useContext, useState, useRef, useEffect } from "react";
import { TextField, IconButton, Box, Grid } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import CharacterCounts from "./CharacterCounts";

export default function TextSource() {
  const { tokenId } = useContext(AuthContext);
  const { chatbotData, setChatbotData, subscriptionSettings } =
    useContext(ChatbotContext);
  const { showAlert } = useContext(SnackbarContext);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [inputValue, setInputValue] = useState(chatbotData?.textSource || "");
  const { userId, chatbotId, chatbotName } = chatbotData || {};
  const { maxCharacters } = subscriptionSettings || {};

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (chatbotData) {
      setInputValue(chatbotData?.textSource);
    }
  }, [chatbotData]);

  const handleInputChange = (event) => {
    if (event.target.value.length > maxCharacters) {
      showAlert(`You have reached ${maxCharacters} max characters`, "error");
      return;
    }
    setInputValue(event.target.value);
    setIsChanged(true);
  };

  const handleSaveChanges = debounce(async () => {
    if (inputValue.length > maxCharacters) {
      showAlert(`You have reached ${maxCharacters} max characters`, "error");
      return;
    }
    setIsSaving(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/text`,
        {
          newTextSource: inputValue,
          chatbotId,
          chatbotName,
          userId: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        textSource: inputValue,
        textCharacterCount: inputValue.length,
      });
      setIsChanged(false);
      showAlert("Bot retrained successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error saving changes", "error");
    }
    setIsSaving(false);
  }, 1000);

  const scrollToBottom = () => {
    textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
  };

  const scrollToTop = () => {
    textAreaRef.current.scrollTop = 0;
  };

  return (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} sm={12} md={12}>
        <Box display="flex" alignItems="flex-start">
          <TextField
            InputProps={{
              autoComplete: "off",
            }}
            sx={{
              "&.MuiTextField-root": { marginBottom: 2 },
              "& .MuiInputBase-input": { padding: "10px" },
            }}
            size="small"
            fullWidth
            type="text"
            variant="outlined"
            placeholder="Text Source"
            multiline
            rows={20}
            value={inputValue}
            onChange={(e) => handleInputChange(e)}
            inputRef={textAreaRef}
          />
        </Box>
        <IconButton onClick={scrollToTop} sx={{ float: "right" }}>
          <ArrowUpward sx={{ fontSize: "16px" }} />
        </IconButton>
        <IconButton onClick={scrollToBottom} sx={{ float: "right" }}>
          <ArrowDownward sx={{ fontSize: "16px" }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <CharacterCounts
          handleClick={handleSaveChanges}
          isSaving={isSaving}
          changed={isChanged}
        />
      </Grid>
    </Grid>
  );
}
