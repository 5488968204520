import React from "react";
import Hero from "./Hero";
import FeaturesHome from "./Features";
import Integrations from "./Integrations";
import Languages from "./Languages";
import Faqs from "./Faqs";
import Cta from "./Cta";
import Footer from "../../components/layout/Footer";
import Orb from "./Orb";
// import Carousel from "./Integrations";
// import FeatureCard from "./FeatureCard";

export default function About() {
  return (
    <>
      <div class="bg-animation">
        <div id="stars2"></div>
      </div>
      <Orb />
      <Hero />
      <FeaturesHome />
      <Integrations />
      <Languages />
      <Faqs />
      <Cta />
      <Footer />
    </>
  );
}
