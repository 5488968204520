import axios from "axios";
import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./AuthContext";

export const ChatbotContext = createContext();

export const CHATBOT_DATA_INITIAL_STATE = {
  isLoadingChatbots: true,
  isLoadingChatbotData: true,
  chatbots: [],
  chatbotData: {},
  subscriptionSettings: {},
  apiKey: "",
};

export const ChatbotProvider = ({ children }) => {
  const { authState, tokenId } = useContext(AuthContext);
  const { userId } = authState || { userId: "" };
  const [state, setState] = useState(CHATBOT_DATA_INITIAL_STATE);

  const fetchUser = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      const apiKeyRes = await axios.get(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/user/api-key`,
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setState((prevState) => ({
        ...prevState,
        isLoadingChatbots: false,
        chatbots: response.data.chatbots,
        subscriptionSettings: response.data.subscriptionSettings,
        apiKey: apiKeyRes.data.apiKey,
      }));
    } catch (error) {
      console.error(error);
    }
    setState((prevState) => ({ ...prevState, isLoadingChatbots: false }));
  }, [tokenId]);

  useEffect(() => {
    fetchUser();
  }, [userId, fetchUser]);

  const setChatbotData = (payload) => {
    setState((prevState) => ({
      ...prevState,
      chatbotData: {
        ...prevState.chatbotData,
        ...payload,
      },
    }));
  };

  const setIsLoadingChatbotData = (payload) => {
    setState((prevState) => ({ ...prevState, isLoadingChatbotData: payload }));
  };

  const setIsLoadingChatbots = (payload) => {
    setState((prevState) => ({ ...prevState, isLoadingChatbots: payload }));
  };

  // empty state when logged out
  useEffect(() => {
    if (!userId) {
      setState(CHATBOT_DATA_INITIAL_STATE);
    }
  }, [userId]);

  return (
    <ChatbotContext.Provider
      value={{
        ...state,
        setIsLoadingChatbotData,
        setIsLoadingChatbots,
        chatbotData: {
          ...state.chatbotData,
          userId: userId,
        },
        setChatbotData,
        setChatbots: (payload) => {
          setState((prevState) => ({
            ...prevState,
            chatbots: payload,
          }));
        },
        subscriptionSettings: {
          ...state.subscriptionSettings,
        },
        status: {
          ...state.subscriptionSettings.status,
        },
        apiKey: state.apiKey,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};
