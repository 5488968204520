import React, { useState, useContext } from "react";
import {
  TextField,
  Stack,
  Button,
  CircularProgress,
  Grid,
  Divider,
} from "@mui/material";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import axios from "axios";
import { debounce } from "lodash";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { AuthContext } from "../../../contexts/AuthContext";
import CharacterCounts from "./CharacterCounts";

const WebsitesSource = () => {
  const { tokenId } = useContext(AuthContext);
  const { chatbotData, setChatbotData } = useContext(ChatbotContext);
  const { showAlert } = useContext(SnackbarContext);
  const { websitesSource, chatbotId } = chatbotData;
  const [websites, setWebsites] = useState(websitesSource || []);
  const [newWebsite, setNewWebsite] = useState("");
  const [changed, setChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreatingSourceMap, setIsCreatingSourceMap] = useState(false);

  const updateWebsites = debounce(async () => {
    setIsSaving(true);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/websites`,
        {
          newWebsitesSource: websites,
          chatbotId,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      setChatbotData({
        ...chatbotData,
        websitesSource: websites,
        websitesCharacterCount: res.data.websitesCharacterCount,
      });
      showAlert("Websites updated successfully!");
    } catch (error) {
      console.error(error);
      showAlert("Error updating websites", "error");
    }
    setIsSaving(false);
  }, 1000);

  const createSourceMap = async () => {
    setIsCreatingSourceMap(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/sources/site-map`,
        {
          website: newWebsite,
        }
      );
      const sourceMap = res.data.links.filter(
        (link) => typeof link === "string"
        // link?.includes(newWebsite) &&
        // !websites.includes(link)
      );
      const uniqueSourceMap = [...new Set(sourceMap)];
      const newWebsitesSource = [...websites, ...uniqueSourceMap];
      setWebsites(newWebsitesSource);
      setChatbotData({ ...chatbotData, websitesSource: newWebsitesSource });
      showAlert("Site map created successfully!");
      setChanged(true);
    } catch (error) {
      console.error(error);
      showAlert("Error creating site map", "error");
    }
    setIsCreatingSourceMap(false);
  };

  const handleChange = (index, event) => {
    const newWebsitesSource = [...websites];
    newWebsitesSource[index] = event.target.value;
    setWebsites(newWebsitesSource);
    setChatbotData({ ...chatbotData, websitesSource: newWebsitesSource });
  };

  const handleAddField = () => {
    if (newWebsite === "") return;

    setWebsites([...websites, newWebsite]);
    setNewWebsite("");
    setChatbotData({
      ...chatbotData,
      websitesSource: [...websites, newWebsite],
    });
    setChanged(true);
  };

  const handleRemoveField = (index) => {
    const newWebsitesSource = [...websites];
    newWebsitesSource.splice(index, 1);
    setWebsites(newWebsitesSource);
    setChatbotData({ ...chatbotData, websitesSource: newWebsitesSource });
    setChanged(true);
  };

  return (
    <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12} sm={12} md={12}>
        {websitesSource?.map((site, index) => (
          <Stack key={index} direction="column" spacing={2} sx={{ mb: 2 }}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <TextField
                InputProps={{
                  autoComplete: "off",
                }}
                size="small"
                fullWidth
                type="text"
                variant="outlined"
                placeholder="https://"
                value={site}
                onChange={(event) => handleChange(index, event)}
              />
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disableRipple
                disableelevation="true"
                disablefocusripple="true"
                disableTouchRipple
                onClick={() => handleRemoveField(index)}
                sx={{
                  "& .MuiButton-endIcon": {
                    ml: 0,
                  },
                }}
                endIcon={<RemoveRounded fontSize="small" />}
                disabled={isCreatingSourceMap || isSaving}
              />
            </Stack>
          </Stack>
        ))}
        <Divider sx={{ my: 4 }} />
        <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <TextField
              InputProps={{
                autoComplete: "off",
              }}
              size="small"
              fullWidth
              type="text"
              variant="outlined"
              placeholder="https://"
              value={newWebsite}
              onChange={(event) => setNewWebsite(event.target.value)}
              sx={{
                flex: 1,
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              onClick={createSourceMap}
              disabled={newWebsite === "" || isCreatingSourceMap}
              endIcon={
                isCreatingSourceMap && (
                  <CircularProgress size={16} thickness={5} />
                )
              }
            >
              Create Site Map
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              disableRipple
              disableelevation="true"
              disablefocusripple="true"
              disableTouchRipple
              onClick={handleAddField}
              disabled={newWebsite === "" || isCreatingSourceMap}
              endIcon={<AddRounded fontSize="small" />}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <CharacterCounts
          handleClick={updateWebsites}
          isSaving={isSaving}
          changed={changed}
        />
      </Grid>
    </Grid>
  );
};

export default WebsitesSource;
