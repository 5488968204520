import { Outlet, useLocation } from "react-router-dom";
import { Container, CssBaseline } from "@mui/material";
import TopNavBar from "../components/layout/TopNavBar";
// import Banner from "../components/layout/Banner";

// Type for the data returned by loader could be specified if it was not returning null
export async function loader() {
  // const contacts = await getContacts();
  // return { contacts };
  return null;
}

const Root = () => {
  const { pathname } = useLocation();
  return (
    <>
      <CssBaseline />
      {/* {pathname === "/" && <Banner />} */}
      <Container maxWidth="lg" sx={{ pb: 2, pt: 1 }}>
        <TopNavBar />
        <Outlet />
      </Container>
    </>
  );
};

export default Root;
