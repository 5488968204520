import React from "react";
import { Tab, Tabs } from "@mui/material";

export default function SecondaryTabs({ tabs, handleChange, tabValue }) {
  return (
    <Tabs
      onChange={handleChange}
      value={tabValue}
      sx={{ mb: 4, display: "flex", mt: 0 }}
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
    >
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab}
            label={tab}
            value={tab}
            disablefocusripple="true"
            disableRipple
            disableTouchRipple
          />
        );
      })}
    </Tabs>
  );
}
