import React, { useContext, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Stack } from "@mui/material";
import { ChatbotContext } from "../../../contexts/ChatbotContext";
import { SnackbarContext } from "../../../contexts/SnackbarContext";
import axios from "axios";
import { debounce } from "lodash";
import AddContactDialog from "./AddContactDialog";
import { AuthContext } from "../../../contexts/AuthContext";
import { AddRounded } from "@mui/icons-material";
import UpgradeButton from "../../../components/buttons/UpgradeButton";

const columns = [
  {
    field: "name",
    headerName: "Name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    type: "text",
    sortable: false,
    flex: 1,
  },
  {
    field: "phone",
    headerName: "Phone",
    type: "text",
    sortable: false,
    flex: 1,
  },
];

export default function AllContacts() {
  const { tokenId } = useContext(AuthContext);
  const { showAlert } = useContext(SnackbarContext);
  const { chatbotData, setChatbotData, subscriptionSettings } =
    useContext(ChatbotContext) || {};
  const { canCaptureContacts } = subscriptionSettings || {};
  const { contacts, chatbotId } = chatbotData || {};
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);

  const handleSelectionChange = (newSelection) => {
    setSelectedIds(newSelection);
  };

  const handleDeleteContact = debounce(async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/contacts`,
        {
          data: {
            contactIds: selectedIds,
            chatbotId: chatbotId,
          },
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      const updatedContacts = contacts.filter(
        (contact) => !selectedIds.includes(contact.id)
      );
      setChatbotData({ ...chatbotData, contacts: updatedContacts });
      setSelectedIds([]);
      showAlert("Contact deleted successfully");
    } catch (error) {
      console.error(error);
      showAlert("Error deleting contact", "error");
    }
  }, 500);

  const handleAddContact = debounce(async (contact) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_VERCEL_API_BASE_URL}/contacts`,
        {
          chatbotId: chatbotId,
          contact,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenId}`,
          },
        }
      );
      const resContact = data.contact;
      const updatedContacts = [...contacts, resContact];
      setChatbotData({ ...chatbotData, contacts: updatedContacts });
      showAlert("Contact added successfully");
      setIsAddContactDialogOpen(false);
    } catch (error) {
      console.error(error);
      showAlert("Error adding contact", "error");
    }
  }, 500);

  const openAddContactDialog = () => {
    setIsAddContactDialogOpen(true);
  };

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        {canCaptureContacts ? (
          <Button
            onClick={openAddContactDialog}
            variant="contained"
            color="secondary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
            endIcon={<AddRounded size={20} color="inherit" />}
          >
            Create Contact
          </Button>
        ) : (
          <UpgradeButton size="large" />
        )}
        {selectedIds?.length > 0 && (
          <Button
            onClick={handleDeleteContact}
            variant="outlined"
            color="primary"
            size="small"
            disableRipple
            disableelevation="true"
            disablefocusripple="true"
            disableTouchRipple
          >
            Delete ({selectedIds?.length}) Contact
            {selectedIds?.length > 1 && "s"}
          </Button>
        )}
      </Stack>
      <DataGrid
        rows={
          contacts?.map((contact) => ({
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phone: contact.phone || "N/A",
          })) || []
        }
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionChange}
      />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        disableRipple
        disableelevation="true"
        disablefocusripple="true"
        disableTouchRipple
        sx={{ mt: 4, mb: 1, width: 200 }}
      >
        Export Contacts (CSV)
      </Button>
      {isAddContactDialogOpen && (
        <AddContactDialog
          open={isAddContactDialogOpen}
          setOpen={setIsAddContactDialogOpen}
          handleClick={handleAddContact}
        />
      )}
    </Box>
  );
}
